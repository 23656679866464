import React, { createContext, FC, useContext } from 'react';
import StaffMemberT from '../../../../../types/StaffMember';
import StaffShiftT from '../../../../../types/StaffShift';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  addStaffShiftProcedure,
  addStaffShiftRoom,
  listAssignedStaffMembers,
  listForShift,
  removeStaffShiftProcedure,
  removeStaffShiftRoom,
} from '../../../../../graph/staff';
import { useMutation } from '@apollo/client';

export type StaffShiftScope = StaffShiftProcedureScope | StaffShiftRoomScope;

interface StaffShiftProcedureScope {
  procedureId: number;
  date: string;
}

interface StaffShiftRoomScope {
  roomId: number;
  date: string;
}

interface StaffShiftContextData {
  scope: StaffShiftScope;
  staffShifts: StaffShiftT[];
  staffMembers: {
    list: TypedDocumentNode<{ staffMembers: StaffMemberT[] }, StaffShiftScope>;
    variables: StaffShiftScope;
  };
  add: (staffId?: number) => Promise<void>;
  remove: (id: number, shiftHospitalId: number, staffHospitalId: number) => Promise<void>;
}

const StaffShiftContext = createContext<StaffShiftContextData>({
  scope: { procedureId: 0, date: '' },
  staffShifts: [],
  staffMembers: {} as any,
  add: async () => {},
  remove: async () => {},
});

export function useStaffShiftContext() {
  return useContext(StaffShiftContext);
}

interface StaffShiftProcedureContextProps {
  hospitalId: number;
  date: string;
  procedureId: number;
  children: React.ReactNode;
  staffShifts: StaffShiftT[];
}

export const StaffShiftProcedureContext: FC<StaffShiftProcedureContextProps> = ({
  hospitalId,
  date,
  procedureId,
  staffShifts,
  children,
}) => {
  const [doAdd] = useMutation(addStaffShiftProcedure);
  const [doRemove] = useMutation(removeStaffShiftProcedure);

  const add = async (staffId?: number) => {
    await doAdd({
      variables: {
        procedureId,
        staffHospitalId: hospitalId,
        staffId,
      },
      refetchQueries: [{ query: listAssignedStaffMembers, variables: { date } }],
    });
  };
  const remove = async (id: number, shiftHospitalId: number, staffHospitalId: number) => {
    await doRemove({
      variables: {
        procedureId,
        id,
        shiftHospitalId: shiftHospitalId,
        staffHospitalId: staffHospitalId,
      },
      refetchQueries: [{ query: listAssignedStaffMembers, variables: { date } }],
    });
  };

  const context = {
    scope: { procedureId, date },
    staffShifts,
    staffMembers: {
      list: listForShift,
      variables: { procedureId, date },
    },
    add,
    remove,
  };

  return <StaffShiftContext.Provider value={context}>{children}</StaffShiftContext.Provider>;
};

interface StaffShiftRoomContextProps {
  hospitalId: number;
  date: string;
  roomId: number;
  staffShifts: StaffShiftT[];
  children: React.ReactNode;
}

export const StaffShiftRoomContext: FC<StaffShiftRoomContextProps> = ({
  hospitalId,
  roomId,
  date,
  staffShifts,
  children,
}) => {
  const [doAdd] = useMutation(addStaffShiftRoom);
  const [doRemove] = useMutation(removeStaffShiftRoom);

  const add = async (staffId?: number) => {
    await doAdd({
      variables: {
        roomId,
        date,
        staffHospitalId: hospitalId,
        staffId,
      },
      refetchQueries: [{ query: listAssignedStaffMembers, variables: { date } }],
    });
  };

  const remove = async (id: number, shiftHospitalId: number, staffHospitalId: number) => {
    await doRemove({
      variables: {
        roomId,
        date,
        id,
        shiftHospitalId: shiftHospitalId,
        staffHospitalId: staffHospitalId,
      },
      refetchQueries: [{ query: listAssignedStaffMembers, variables: { date } }],
    });
  };

  const context = {
    scope: { roomId, date },
    staffShifts,
    staffMembers: {
      list: listForShift,
      variables: { roomId, date },
    },
    add,
    remove,
  };

  return <StaffShiftContext.Provider value={context}>{children}</StaffShiftContext.Provider>;
};

import config from '../../../config';
import EverCookie from 'EverCookie';

export const LocalEverCookie = new EverCookie();

export const verifySession = async session => {
  if (session && session.token) {
    const response = await fetch(`${config.apiURL}/identity/session`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();
    if (response.status !== 200) {
      return null;
    } else {
      return json;
    }
  }
  return null;
};

export const requestSession = async organizationId => {
  const response = await fetch(`${config.apiURL}/identity/session`, {
    method: 'POST',
    body: JSON.stringify({ kiosk: true, organization: parseInt(organizationId, 10) }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 201) {
    return { error: true };
  } else {
    return {
      error: false,
      ...json,
    };
  }
};

export const persistSession = session => LocalEverCookie.setItem(true, 'kiosk', JSON.stringify(session));

export const clearSession = () => LocalEverCookie.removeItem(true, 'kiosk');

export const fetchSession = () => {
  const cookie = LocalEverCookie.getItem(true, 'kiosk');
  return JSON.parse(cookie || '{}') || {};
};

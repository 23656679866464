import React, { Fragment } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';

import ChangePassword from '../ChangePassword';
import { light as lightTheme } from '../../../../theme';
import ThemeBasedCSS from '../../../../components/ThemeBasedCSS';
import { unpackSessionObject } from '../../unpackSessionObject';
import { withSession } from '../../../../state/Session';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import get from 'lodash/fp/get';
import ScheduledProcedures from '../../../entities/procedures/ScheduledProcedures';
import { compact } from 'lodash';
import { withScope } from '../../../HospitalInfo';
import { muiThemeLight } from '../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import Training from '../../training/Training';
import Footer from '../admin/Footer';
import Header from '../admin/Header';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import ScheduledRequests from '../../../entities/scheduleRequests/ScheduledRequests';
import { Event, Schedule, Theaters, Today, Tv, VideoLabel } from '@material-ui/icons';

const getSystemScreenRoutes = (basePath, baseUrl, organizationId) => [
  {
    path: `/kiosk/${organizationId}/monitor`,
    to: `/kiosk/${organizationId}/monitor`,
    target: '_blank',
    label: 'Operations Screen',
    icon: <VideoLabel />,
  },
  {
    path: `/kiosk/${organizationId}/schedule`,
    to: `/kiosk/${organizationId}/schedule`,
    label: 'OR Schedule Screen',
    icon: <Event />,
  },
  {
    path: `/kiosk/${organizationId}/waiting-room`,
    to: `/kiosk/${organizationId}/waiting-room`,
    target: '_blank',
    label: 'Waiting Room Screen',
    icon: <Tv />,
    hideOnMobile: true,
  },
];

const getSwitchRoutes = (basePath, baseUrl, modules) => [
  {
    separator: true,
  },
  modules.patientRegistration
    ? {
        path: `${basePath}/scheduled-patients`,
        to: `${baseUrl}/scheduled-patients`,
        label: 'Scheduled Procedures',
        icon: <Schedule />,
        component: ScheduledProcedures,
      }
    : undefined,
  modules.acceptsProcedureTransfer
    ? {
        path: `${basePath}/scheduled-requests`,
        to: `${baseUrl}/scheduled-requests`,
        label: 'Schedule requests',
        icon: <Today />,
        component: ScheduledRequests,
      }
    : undefined,
  {
    separator: true,
  },
  {
    path: `${basePath}/training`,
    to: `${baseUrl}/training`,
    label: 'Video Tutorials',
    icon: <Theaters />,
    component: Training,
  },
];

const getBasePathAndUrl = match => {
  if (!isDefinedAndNotNull(match) || !isDefinedAndNotNull(!match.path) || !isDefinedAndNotNull(!match.url)) {
    return ['', ''];
  }
  return [match.path === '/' ? '' : match.path, match.url === '/' ? '' : match.url];
};

const NurseSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  withScope,
  mapProps(props => {
    const { organization, match, search } = props;
    const organizationId = organization;
    const [basePath, baseUrl] = getBasePathAndUrl(match);
    const modules = Object.assign(
      {},
      {
        covidScreening: !!props.scope?.hospital?.covidScreening,
      },
      get('scope.hospital.modules')(props)
    );
    return {
      ...props,
      links: compact([
        ...getSystemScreenRoutes(basePath, baseUrl, organizationId, search),
        ...getSwitchRoutes(basePath, baseUrl, modules),
      ]),
    };
  })
)(Sidebar);

const Homepage = props => {
  const [basePath, baseUrl] = getBasePathAndUrl(props.match);
  const modules = Object.assign(
    {},
    {
      covidScreening: !!props.scope?.hospital?.covidScreening,
    },
    get('scope.hospital.modules')(props)
  );
  const switchRoutes = compact(getSwitchRoutes(basePath, baseUrl, modules)).filter(_ => !_.separator);
  return (
    <ThemeProvider theme={lightTheme}>
      <MUIThemeProvider theme={muiThemeLight}>
        <style>
          {`body {
            background: ${muiThemeLight.palette.background.default};
          }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider value={props.patientNameFormat || 'FullName'}>
          <OmniSearchProvider>
            <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
            <Fragment>
              <Page Sidebar={NurseSidebar}>
                <MainContainer switchControl>
                  <Switch>
                    <Route exact path={`${basePath}/`} render={() => <Redirect to={`${baseUrl}/analytics`} />} />
                    {switchRoutes.map(({ path, component, subPages }, i) => [
                      path ? <Route key={i} path={path} component={component} /> : null,
                      ...(subPages
                        ? subPages.map(subPage => (
                            <Route
                              key={path ? `${path}${subPage.path}` : subPage.path}
                              path={path ? `${path}${subPage.path}` : subPage.path}
                              component={subPage.component}
                            />
                          ))
                        : []),
                    ])}
                    <Route path="/change-password" component={ChangePassword} />
                    <Route render={() => <Redirect to={`${baseUrl}/scheduled-patients`} />} />
                  </Switch>
                </MainContainer>
              </Page>
              <ThemeBasedCSS />
            </Fragment>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default compose(
  withSession(unpackSessionObject),
  withRouter,
  withSession(get('session.account.metadata')),
  withScope
)(Homepage);

import { gql } from '@apollo/client';
import { reviewIntegrationFragments } from './hospitals';

export const fragments = {
  base: gql`
    fragment PhysicianBase on Physician {
      id
      hstId
      name
      email
    }
  `,
  all: gql`
    fragment PhysicianData on Physician {
      id
      hstId
      name
      email
      speciality
      category
      notificationNumbers
      hasScheduleAccess
      scheduleAccessAll
      shouldSendNotification
      reviewIntegrations {
        google {
          ...ReviewBase
        }
        yelp {
          ...ReviewBase
        }
      }
    }
    ${reviewIntegrationFragments.base}
  `,
};

export const list = gql`
  query physicians {
    physicians {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export const subscription = gql`
  subscription physicians($filter: Filter!) {
    physicians(filter: $filter) {
      physician {
        ...PhysicianData
      }
      stat {
        bookedSlots
        blockUtilization
        relativeUtilization
        bookedHours
        utilizedHours
      }
    }
  }
  ${fragments.all}
`;

export const listForProcedureType = gql`
  query physiciansForProcedureType($procedureType: Long) {
    physicians: physiciansForProcedureType(procedureType: $procedureType) {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query physician($id: Long!) {
    physician(id: $id) {
      ...PhysicianData
      procedures {
        id
        name
      }
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createPhysician(
    $name: String!
    $hstId: Long
    $email: String
    $notificationNumbers: String
    $shouldSendNotification: Boolean
    $speciality: String
    $category: ProcedureCategory
    $reviewIntegrations: ReviewIntegrationsInput
  ) {
    createPhysician(
      name: $name
      hstId: $hstId
      email: $email
      notificationNumbers: $notificationNumbers
      shouldSendNotification: $shouldSendNotification
      speciality: $speciality
      category: $category
      reviewIntegrations: $reviewIntegrations
    ) {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editPhysician(
    $id: Long!
    $hstId: Long
    $name: String!
    $email: String
    $notificationNumbers: String
    $shouldSendNotification: Boolean
    $speciality: String
    $category: ProcedureCategory
    $reviewIntegrations: ReviewIntegrationsInput
  ) {
    editPhysician(
      id: $id
      hstId: $hstId
      name: $name
      email: $email
      notificationNumbers: $notificationNumbers
      shouldSendNotification: $shouldSendNotification
      speciality: $speciality
      category: $category
      reviewIntegrations: $reviewIntegrations
    ) {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deletePhysician($id: Long!) {
    deletePhysician(id: $id)
  }
`;

export const applyPhysicianProcedureTypeChanges = gql`
  mutation applyPhysicianProcedureTypeChanges(
    $physician: Long!
    $assignProcedures: [Long!]!
    $unassignProcedures: [Long!]!
  ) {
    applyPhysicianProcedureTypeChanges(
      physician: $physician
      assignProcedures: $assignProcedures
      unassignProcedures: $unassignProcedures
    )
  }
`;

export const grantScheduleAccess = gql`
  mutation grantScheduleAccessToPhysician($id: Long!) {
    grantScheduleAccessToPhysician(id: $id) {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export const resendScheduleAccessLink = gql`
  mutation resendScheduleAccessLinkToPhysician($id: Long!) {
    resendScheduleAccessLinkToPhysician(id: $id) {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export const setScheduleAccessAllPhysician = gql`
  mutation setScheduleAccessAllPhysician($id: Long!, $scheduleAccessAll: Boolean!) {
    setScheduleAccessAllPhysician(id: $id, scheduleAccessAll: $scheduleAccessAll)
  }
`;

export const getScheduleAccessAllPhysician = gql`
  query getScheduleAccessAllPhysician($id: Long) {
    getScheduleAccessAllPhysician(id: $id)
  }
`;

export const revokeScheduleAccess = gql`
  mutation revokeScheduleAccessToPhysician($id: Long!) {
    revokeScheduleAccessToPhysician(id: $id) {
      ...PhysicianData
    }
  }
  ${fragments.all}
`;

export default {
  list,
  item,
  create,
  update,
  remove,
  applyPhysicianProcedureTypeChanges,
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAllPhysician,
};

import React from 'react';
import { patientChartForm } from '../../../graph/surgeon/forms';
import { CenteredSpinner } from '../../../se/components/Spinner';
import FormContent from './FormContent';
import { useSubscription } from '@apollo/client';

const PatientChartForm = ({ id, procedureId, lastPreOpCompletedEvent, withActions }: any) => {
  const { data, loading } = useSubscription(patientChartForm, {
    variables: { id, procedureId },
  });

  const formData = data?.patientChartForm || {};

  return !loading ? (
    <FormContent
      {...formData}
      procedureId={procedureId}
      lastPreOpCompletedEvent={lastPreOpCompletedEvent}
      withActions={withActions}
    />
  ) : (
    <CenteredSpinner size={undefined} strokeAccessor={undefined} />
  );
};

export default PatientChartForm;

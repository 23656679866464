import React, { useRef, useState } from 'react';
import { NotesAnswer } from '../../types/Answer';
import { NotesQuestion } from '../../types/Question';
import clsx from 'clsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Button, Slide, TextField, Typography, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Edit } from '@material-ui/icons';
import { useNotesContext } from '../../components/pages/kiosk/charting/NotesContextProvider';
import { createPortal } from 'react-dom';

type NotesInputProps = NotesQuestion['props'] & {
  name?: string;
  answer?: NotesAnswer;
  onChange?: (newAnswer: NotesAnswer) => void;
};

export const useStyles = makeStyles(theme => ({
  root: {},
  open: {
    transform: 'translateY(400px)',
  },
  notesButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1.5, 2, 2),
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    width: 600,
    height: 120,
    overflow: 'hidden',
    marginBottom: theme.spacing(-1.25),
    transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NotesInput = ({ name, label, answer, onChange }: NotesInputProps) => {
  const { rootRef } = useNotesContext();

  const [open, setOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement | undefined>();

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Dialog TransitionComponent={Transition} open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogContent>
          <DialogTitle>{label}</DialogTitle>
          <TextField
            multiline
            inputRef={inputRef}
            type="text"
            autoComplete="off"
            label={label}
            variant="filled"
            fullWidth={true}
            aria-label={label}
            name={name}
            value={answer ?? ''}
            onChange={e => onChange?.(e.target.value)}
            minRows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {createPortal(
        <Box
          boxShadow={8}
          className={clsx(classes.notesButton, { [classes.open]: open })}
          onClick={() => {
            setOpen(true);
            setTimeout(() => {
              const input = inputRef.current;
              if (input) {
                input.focus();
                input.setSelectionRange(input.value.length, input.value.length);
              }
            }, 150);
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            <Box display="flex" alignItems="center" color={theme.palette.primary.main}>
              <Box mr={0.5} display="flex">
                <Edit fontSize="inherit" />
              </Box>
              {label}
            </Box>
          </Typography>
          {answer ? (
            <>
              {answer
                .split('\n')
                .map(p => p.trim())
                .filter(p => p.length > 0)
                .map((p, i) => (
                  <Typography key={i} variant="body2" gutterBottom>
                    {p}
                  </Typography>
                ))}
            </>
          ) : (
            <>
              <Skeleton animation={false} width="100%" />
              <Skeleton animation={false} width="100%" />
              <Skeleton animation={false} width="60%" />
            </>
          )}
        </Box>,
        rootRef.current!
      )}
    </>
  );
};

export default NotesInput;

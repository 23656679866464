import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../se/components/Label';
import PhoneInput from '../../se/components/inputs/PhoneInput';
import CheckboxInput from './CheckboxInput';

const Checkbox = compose(
  withProps({
    label: 'Ask patient to rate their experience once the procedure is over.',
    name: 'phoneCheckbox',
  })
)(CheckboxInput);

const PhoneNumberInput = compose(
  withLabel('Patient Phone Number'),
  withProps({ placeholder: 'e.g., (619) 412-9512' })
)(PhoneInput);

export default PhoneNumberInput;

export const OptionalPhoneNumberInput = props => {
  const [showInput, setShowInput] = useState(props.value);

  const handleCheckboxChange = value => {
    setShowInput(value);
    if (value === false) {
      props.onChange('');
    }
  };

  return (
    <React.Fragment>
      <Checkbox value={showInput} onChange={handleCheckboxChange} />
      {showInput && PhoneNumberInput(props)}
    </React.Fragment>
  );
};

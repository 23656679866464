import { useMutation, useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import { notifyReadyForAnesthesiologist } from '../../../graph/patients';
import Tooltip from '../../Tooltip';
import React from 'react';
import { getLogEntries, isPatientReadyForAnesthesiologistNotifyFn } from './tablet/utils';
import Anesthesiologist from '../../../types/Anesthesiologist';
import { Patient } from '../../../types/Patient';
import { NotifyButton } from './PhysicianNotifyButton';

const AnesthesiologistNotifyButton = ({
  patient,
  anesthesiologist,
}: {
  patient: Patient;
  anesthesiologist: Anesthesiologist;
}) => {
  const patientLogs = getLogEntries(patient);
  const { data: template } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ReadyForAnesthesiologistNotify' },
  });

  const [notifyAnesthesiologist] = useMutation(notifyReadyForAnesthesiologist, {
    variables: {
      id: patient?.id,
    },
  });

  const notify = async () => {
    await notifyAnesthesiologist();
  };

  const isAnesthesiologistNotified = isPatientReadyForAnesthesiologistNotifyFn(patientLogs);
  const readyForAnesthesiologistNotification = template?.getNotificationTemplate?.[0] || null;
  const shouldSendNotification = anesthesiologist?.shouldSendNotification || null;
  const hasPhoneNumber = anesthesiologist?.phoneNumber || null;

  const notifyAnesthesiologistEnabled =
    readyForAnesthesiologistNotification?.enabled && hasPhoneNumber && shouldSendNotification;
  const notifyAnesthesiologistTooltip = !readyForAnesthesiologistNotification?.enabled
    ? 'Anesthesiologist messaging has been turned off. Go to message configuration to enable it.'
    : !shouldSendNotification
    ? "Anesthesiologist messaging has been turned off. Anesthesiologist doesn't want to receive notification."
    : !hasPhoneNumber
    ? 'Anesthesiologist does not have a phone number.'
    : null;

  return notifyAnesthesiologistTooltip ? (
    <Tooltip delay={0} content={notifyAnesthesiologistTooltip}>
      <NotifyButton
        isNotified={isAnesthesiologistNotified}
        notifyEnabled={notifyAnesthesiologistEnabled}
        notNotifiedLabel={'Notify Anesthesiologist'}
        notifiedLabel={'Anesthesiologist Notified'}
        notify={notify}
      />
    </Tooltip>
  ) : (
    <NotifyButton
      isNotified={isAnesthesiologistNotified}
      notifyEnabled={notifyAnesthesiologistEnabled}
      notNotifiedLabel={'Notify Anesthesiologist'}
      notifiedLabel={'Anesthesiologist Notified'}
      notify={notify}
    />
  );
};

export default AnesthesiologistNotifyButton;

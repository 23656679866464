import StaffMember, { PrimarySpecialtyLabel, TitleAbbreviationLabel } from '../../../../types/StaffMember';

const filterStaffMember = (
  staffMembers: StaffMember[],
  search: string
): {
  filtered: StaffMember[];
  others: StaffMember[];
} => {
  const filtered = staffMembers.reduce(
    (acc, curr) => {
      const searchChunks = search.toLowerCase().split(/\s+/);
      const nameChunks = curr.name.toLowerCase().split(/\s+/);
      const isSearchedName = nameChunks.some(n => searchChunks.some(s => n.startsWith(s)));
      const primSpec = curr?.primarySpecialty
        ? PrimarySpecialtyLabel?.[curr?.primarySpecialty]?.toLowerCase().split(/\s+/)
        : [];
      const isPrimSpec = primSpec.some(n => searchChunks.some(s => n.startsWith(s)));
      const title = curr?.title ? TitleAbbreviationLabel?.[curr?.title]?.toLowerCase().split(/\s+/) : [];
      const isTitle = title.some(n => searchChunks.some(s => n.startsWith(s)));

      const isFiltered = isSearchedName || isPrimSpec || isTitle;
      return {
        filtered: isFiltered ? [...acc.filtered, curr] : acc.filtered,
        others: !isFiltered ? [...acc.others, curr] : acc.others,
      };
    },
    {
      filtered: [] as StaffMember[],
      others: [] as StaffMember[],
    }
  );
  return filtered;
};

export default filterStaffMember;

export const cardiovascular = [
  ['medicalHistory', 'Diagnosed Abnormal Heart Rhythm'],
  ['medicalHistory', 'High cholesterol or lipids'],
  ['medicalHistory', 'Coronary Artery Disease'],
  ['medicalHistory', 'Heart related chest pain'],
  ['medicalHistory', 'Heart attack'],
  ['medicalHistory', 'High blood pressure'],
  ['medicalHistory', 'Congestive heart failure'],
  ['medicalHistory', 'Stents'],
  ['medicalHistory', 'Pacemaker'],
  ['medicalHistory', 'Heart valve'],
  ['medicalHistory', 'Vascular disease'],
  ['medicalHistory', 'Heart murmur'],
  ['medicalHistory', 'Other cardiac'],
  ['medicalHistory', 'cardiac comment'],
];
export const pulmonary = [
  ['medicalHistory', 'Current symptoms'],
  ['medicalHistory', 'Emphysema'],
  ['medicalHistory', 'Asthma'],
  ['medicalHistory', 'Cough'],
  ['medicalHistory', 'Pneumonia'],
  ['medicalHistory', 'Sleep apnea'],
  ['medicalHistory', 'oxygen'],
  ['medicalHistory', 'Tuberculosis'],
  ['medicalHistory', 'Bronchitis'],
  ['medicalHistory', 'respiratory'],
  ['medicalHistory', 'Other pulmonary'],
  ['medicalHistory', 'pulmonary comment'],
];

export const renal = [
  ['medicalHistory', 'Kidney Failure'],
  ['medicalHistory', 'dialysis'],
  ['medicalHistory', 'Other renal'],
  ['medicalHistory', 'renal comment'],
];

export const hepatic = [
  ['medicalHistory', 'liver failure'],
  ['medicalHistory', 'Hepatitis'],
  ['medicalHistory', 'other hepatic'],
  ['medicalHistory', 'Hepatic comment'],
];

export const neurological = [
  ['medicalHistory', 'Seizures'],
  ['medicalHistory', 'Stroke'],
  ['medicalHistory', 'Neuropathy'],
  ['medicalHistory', 'migraines'],
  ['medicalHistory', 'RLS'],
  ['medicalHistory', 'other neurologic'],
  ['medicalHistory', 'Neurologic comment'],
];

export const gastrointestinal = [
  ['medicalHistory', 'heartburn'],
  ['medicalHistory', 'Stomach ulcers'],
  ['medicalHistory', 'Bowel Problems'],
  ['medicalHistory', 'Gall Bladder Problems'],
  ['medicalHistory', 'Hiatal hernia'],
  ['medicalHistory', 'Colitis'],
  ['medicalHistory', 'Ulcerative colitis'],
  ['medicalHistory', "Crohn's disease"],
  ['medicalHistory', 'Diverticulitis'],
  ['medicalHistory', 'IBD'],
  ['medicalHistory', 'Other GI'],
  ['medicalHistory', 'GI comment'],
];

export const hematological = [
  ['medicalHistory', 'Anemia'],
  ['medicalHistory', 'History of blood clots'],
  ['medicalHistory', 'blood thinners'],
  ['medicalHistory', 'contagious diseases'],
  ['medicalHistory', 'blood clotting'],
  ['medicalHistory', 'Blood transfusion'],
  ['medicalHistory', 'HIV'],
  ['medicalHistory', 'Varicose veins or venous stasis'],
  ['medicalHistory', 'Other hematologic conditions'],
  ['medicalHistory', 'Hematologic comment'],
];

export const endocrine = [
  ['medicalHistory', 'Diabetes'],
  ['medicalHistory', 'Thyroid disease'],
  ['medicalHistory', 'Other endocrine'],
  ['medicalHistory', 'Endocrine comment'],
];

export const musculoskeletal = [
  ['medicalHistory', 'Musculoskeletal problems'],
  ['medicalHistory', 'Myopathy'],
  ['medicalHistory', 'Arthritis'],
  ['medicalHistory', 'Chronic low back pain'],
  ['medicalHistory', 'Lower extremity immobilizing cast'],
  ['medicalHistory', 'Paralysis'],
  ['medicalHistory', 'neck pain'],
  ['medicalHistory', 'jaw pain'],
  ['medicalHistory', 'Other musculoskeletal'],
  ['medicalHistory', 'Musculoskeletal comment'],
];

export const psychiatric = [
  ['medicalHistory', 'depression'],
  ['medicalHistory', 'Bipolar Disorder'],
  ['medicalHistory', 'anxiety attacks'],
  ['medicalHistory', 'Schizophrenia'],
  ['medicalHistory', 'psychiatric'],
  ['medicalHistory', 'Psychiatric comment'],
];

export const gynaecological = [
  ['medicalHistory', 'pregnant'],
  ['medicalHistory', 'Gynecologic comment'],
];

export const other = [
  ['medicalHistory', 'Glaucoma'],
  ['medicalHistory', 'Other conditions'],
  // Rose want to add
  ['medicalHistory', 'Cancer'],
  ['medicalHistory', 'Other oncologic'],
  ['medicalHistory', 'Oncologic comment'],
  ['medicalHistory', 'MRSA'],
  ['medicalHistory', 'C-diff'],
  ['medicalHistory', 'ORSA'],
  ['medicalHistory', 'CMV'],
  ['medicalHistory', 'Shingles'],
  ['medicalHistory', 'Antibiotic Resistant Infection'],
  ['medicalHistory', 'Other Infectious Diseases'],
  ['medicalHistory', 'West African'],
  ['medicalHistory', 'traveled'],
  ['medicalHistory', 'blood or body fluids'],
  ['medicalHistory', 'Other infectious'],
  ['medicalHistory', 'Infectious Diseases comment'],

  ['medicalHistory', 'contagious diseases'],
  ['medicalHistory', 'Recently'],
  ['medicalHistory', 'Contagions comment'],

  ['medicalHistory', 'Other comment'],

  ['medicalHistory', 'skin breakdow'],
  ['medicalHistory', 'bruises or rashes'],
  ['medicalHistory', 'Other skin'],
  ['medicalHistory', 'Skin Integrity comment'],

  ['medicalHistory', 'chronic pain'],
];

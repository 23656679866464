import React, { Fragment, useState } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { listNursing } from '../../../../graph/rooms';
import { OR, PACU, POST_OP, PRE_OP, ROOM_TYPE_LABELS, WAITING_ROOM } from '../../room/enums';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { discharge, setRoom } from '../../../../graph/patients';
import filter from 'lodash/filter';
import get from 'lodash/get';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { RoomPicker } from '../RoomPicker';
import { RouterlessModal } from '../../../../se/components/Modal';
import { ConfirmDischargeModal } from './PatientLocationChangeColumn';
import { Grid } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiCheckboxBlankCircleOutline } from '@mdi/js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useScope } from '../../../HospitalInfo';

const useStyles = makeStyles(theme => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(0),
    flex: 1,
  },
  cardActions: {
    padding: theme.spacing(2, 3, 3),
  },
  location: {
    flex: 1,
    transition: `all .3s ease-in-out`,
    display: 'flex',
  },
  currentLocation: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
  },
  button: {
    width: '100%',
    height: '100%',
    alignItems: 'stretch',
  },
}));

const LocationBox = ({ isActive, handleClick, disabled, label }) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.button} onClick={handleClick} disabled={disabled || isActive}>
      <Card
        elevation={isActive ? 1 : 0}
        classes={{
          root: clsx(classes.location, {
            [classes.currentLocation]: isActive,
          }),
        }}
      >
        <CardContent className={classes.cardContent}>
          <Box mt={2} mb={0.5}>
            {isActive ? (
              <PersonPinIcon fontSize="large" />
            ) : (
              <Icon path={mdiCheckboxBlankCircleOutline} size={1.5} style={{ opacity: 0.25 }} />
            )}
          </Box>
          <Typography variant="body1" color="inherit">
            {label}
          </Typography>

          <Box mt={1}>
            <Typography variant="caption" color="inherit" style={{ opacity: 0.5 }}>
              {isActive ? 'Current Location' : 'Click to move here'}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

const Location = ({ currentRoom, rooms, onSelect, working, discharged }) => {
  const [modal, setModal] = useState(false);

  const isActive = !discharged && rooms.map(_ => _.id).includes(get(currentRoom, 'id'));
  const hasOptions = rooms.length > 1;
  const name = hasOptions ? ROOM_TYPE_LABELS[get(rooms, '[0].type')] || '' : get(rooms, '[0].name');

  const handleMove = id => {
    onSelect(id);
    setModal(false);
  };

  const handleClick = () => {
    if (isActive || discharged) {
      return;
    }

    if (hasOptions) {
      setModal(true);
    } else {
      handleMove(get(rooms, '[0].id'));
    }
  };

  const handleCancel = () => setModal(false);

  return (
    <Fragment>
      {modal && (
        <RouterlessModal onClose={handleCancel}>
          <RoomPicker rooms={rooms} onCancel={handleCancel} onChoose={handleMove} />
        </RouterlessModal>
      )}
      <LocationBox isActive={isActive} handleClick={handleClick} disabled={working || discharged} label={name} />
    </Fragment>
  );
};

const DischargeLocation = ({ discharged, onConfirm, working, patientName }) => {
  const [modal, setModal] = useState(false);

  const openModal = () => (!discharged ? setModal(true) : {});
  const closeModal = () => setModal(false);
  const handleConfirm = () => {
    setModal(false);
    onConfirm();
  };

  return (
    <Fragment>
      {modal && (
        <ConfirmDischargeModal handleCancel={closeModal} handleConfirm={handleConfirm} patientName={patientName} />
      )}
      <LocationBox isActive={discharged} handleClick={openModal} disabled={working} label={'Discharged'} />
    </Fragment>
  );
};

const WithPostOp = [WAITING_ROOM, PRE_OP, OR, PACU, POST_OP];
const WithoutPostOp = [WAITING_ROOM, PRE_OP, OR, PACU];

const filterRoomType = type => rooms => filter(rooms, room => room.type === type);

const PatientLocationWidget = ({ id, name, room, discharged, events }) => {
  const [working, setWorking] = useState(false);
  const { data, loading } = useSubscription(listNursing);
  const [doSetRoom] = useMutation(setRoom);
  const [doSetDischarge] = useMutation(discharge);

  const scope = useScope();
  const hasPostOp = !!scope?.hospital?.modules?.hasPostop;

  const roomTypes = hasPostOp ? WithPostOp : WithoutPostOp;

  const rooms = get(data, 'rooms', []);

  const setHere = async roomId => {
    setWorking(true);
    try {
      await doSetRoom({ variables: { id, roomId: roomId } });
    } catch (e) {
      console.log(e);
    } finally {
      setWorking(false);
    }
  };

  const setDischarge = async roomId => {
    setWorking(true);
    try {
      await doSetDischarge({ variables: { id, roomId: roomId } });
    } catch (e) {
      console.log(e);
    } finally {
      setWorking(false);
    }
  };

  return (
    <Box mt={5}>
      <Box mb={3}>
        <Typography variant="h4" gutterBottom>
          Patient Location
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {roomTypes.map(type => (
          <Grid item md sm={4} xs={12} key={type}>
            <Location
              currentRoom={room}
              rooms={filterRoomType(type)(rooms)}
              onSelect={setHere}
              discharged={discharged}
              working={loading || working}
            />
          </Grid>
        ))}
        <Grid item md sm={4} xs={12}>
          <DischargeLocation
            discharged={discharged}
            onConfirm={setDischarge}
            working={loading || working}
            patientName={name}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientLocationWidget;

import React from 'react';
import styled from 'styled-components';
import { EventLog, EventLogEntry, EventLogTime, IconWrapper } from '../patient/views/components';
import { H4 } from '../../../se/components/typography';
import { format, parse } from 'date-fns';
import get from 'lodash/get';
import { translateToTz } from '../../../util/dateTime';
import responsive from '../../../se/utilities/responsive';
import { withTheme } from '../../../se/theme';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withScope } from '../../HospitalInfo';
import { Typography } from '@material-ui/core';
import { Note, Today } from '@material-ui/icons';

const Span = styled.span`
  display: flex;
  flex: 1;
  > * {
    margin-right: 1em;
  }
`;

const VisitItem = styled.div`
  border-top: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  border-right: 0;
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.4;

  &:last-of-type {
    border-bottom: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  }

  ${responsive.md.andSmaller`
    flex-flow: column-reverse;
    align-items: flex-start;

    > div:first-of-type {
      margin-top: .25rem;
    }
  `}
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  text-decoration: none;
  color: ${withTheme((theme, props) => theme.textColor.string())};
`;

const PatientVisits = compose(
  withRouter,
  withScope
)(({ visits, currentVisit, location, history, scope }) => {
  const onClick = id => e => {
    history.push({
      pathname: `${id}`,
      search: location.search,
    });
    document.location.reload();
  };

  return (
    <EventLog>
      <H4>Patient Visits</H4>
      {visits.map(visit => (
        <VisitItem key={visit.id} onClick={onClick(visit.id)}>
          <Span>
            <label>{visit.visit}</label>
            <EventLogTime>
              {format(translateToTz(get(scope, 'hospital.timezone.id'))(parse(visit.startTime)), 'MM/DD/YYYY HH:mm:ss')}
            </EventLogTime>
            {!!visit.isCanceled && (
              <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                Canceled
              </Typography>
            )}
          </Span>
          <EventLogEntry>
            <IconWrapper>
              {visit.id === currentVisit ? <Today fontSize={'small'} /> : <Note fontSize={'small'} />}
            </IconWrapper>
            {get(visit, 'procedureType', '')}
          </EventLogEntry>
        </VisitItem>
      ))}
    </EventLog>
  );
});

export default props => (props.visits ? <PatientVisits {...props} /> : null);

import React, { FC, useEffect, useState } from 'react';
import { useMultipleStaffShiftSelectionContext } from './MultipleStaffShiftSelection';
import { Box, Button, MenuItem, MenuList, Popover, TextField } from '@material-ui/core';
import { list } from '../../../../../graph/staff';
import { useQuery } from '@apollo/client';
import StaffMemberT from '../../../../../types/StaffMember';
import StaffMember from './StaffMember';
import AddStaffSlot from './AddStaffSlot';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

interface MultipleStaffShiftSelectionBoxProps {}

const MultipleStaffShiftSelectionBox: FC<MultipleStaffShiftSelectionBoxProps> = () => {
  const classes = useStyles();
  const { selectedItems, assignStaffMember, addOpenPosition, deselectAll } = useMultipleStaffShiftSelectionContext();

  const { data } = useQuery(list);
  const staffMembers = data?.staffMembers || [];

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [search, setSearch] = useState('');
  const searchEl = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const filteredStaffMembers = (staffMembers || []).filter((staffMember: StaffMemberT) => {
    const chunks = search.toLowerCase().split(/\s+/);
    const staffMemberName = staffMember.name.toLowerCase();
    return chunks.every(chunk => staffMemberName.indexOf(chunk) >= 0);
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseStaffMember = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>, staffMember: StaffMemberT) => {
    event.stopPropagation();
    await assignStaffMember(staffMember?.staffId);
  };

  const chooseOpenPosition = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    await addOpenPosition();
  };

  return (
    <>
      {selectedItems > 0 && (
        <Box className={classes.box}>
          <Box>{`${selectedItems} ${selectedItems === 1 ? 'item' : 'items'} selected`}</Box>
          <Box>
            <Button variant="contained" onClick={handleClick}>
              Assign Staff Members
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                value={search}
                onChange={handleSearchUpdate}
                inputRef={searchEl}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                  autoCapitalize: 'off',
                  autoComplete: 'off',
                  autoCorrect: 'off',
                  autoFocus: true,
                  classes: {
                    root: classes.inputRoot,
                    inputAdornedStart: classes.inputAdornedStart,
                  },
                }}
                classes={{
                  root: classes.textFieldRoot,
                }}
              />
              <MenuList className={classes.menu}>
                <MenuItem onClick={chooseOpenPosition}>
                  <AddStaffSlot interactive />
                </MenuItem>
                {filteredStaffMembers.map((staffMember: StaffMemberT) => (
                  <MenuItem key={staffMember.id} onClick={event => chooseStaffMember(event, staffMember)}>
                    <StaffMember
                      inputRef={null}
                      inputRefText={null}
                      editTime={false}
                      editText={false}
                      {...staffMember}
                    />
                  </MenuItem>
                ))}
              </MenuList>
            </Popover>
          </Box>
          <Box>
            <Button color="primary" onClick={deselectAll} className={classes.deselect}>
              Deselect all
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  box: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    backgroundColor: 'rgba(0,167,247,0.2)',
  },
  menu: {
    minWidth: '14rem',
  },
  deselect: {
    textTransform: 'capitalize',
  },
  textFieldRoot: {
    margin: '1rem',
    marginBottom: 0,
    padding: 0,
  },
  inputRoot: {
    width: '14rem',
    fontSize: '0.8125em',
    padding: '0.25em',
    color: 'white',
  },
  searchIcon: {
    marginLeft: '0.25em',
    marginRight: '0.25em',
  },
  inputAdornedStart: {
    paddingLeft: 0,
  },
}));

export default MultipleStaffShiftSelectionBox;

import { Query } from '@apollo/client/react/components';
import React from 'react';
import styled from 'styled-components';
import { AbsoluteSpinner } from '../../../se/components/Spinner';
import LineChart from './LineChart';
import get from 'lodash/get';
import {
  ConfigButton,
  currentHumidityColumn,
  currentTempColumn,
  humidityMinMaxColumn,
  tempMinMaxColumn,
} from './SensorList';
import { TruncatedText } from '../../../se/components/typography';
import { sensorsDistribution } from '../../../graph/sensors';
import { withTheme } from '../../../se/theme';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import responsive from '../../../se/utilities/responsive';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin: 1em 0;
  }

  > :first-child {
    margin: 0;
    margin-top: 1.5em;
  }
  min-height: 20em;
`;

const colors = ['#E34141', '#00A7F7'];

const Row = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  font-size: 0.7em;
  opacity: 0.8;

  span {
    font-size: 1.1em;
  }

  > span {
    margin-left: 1.2em;
  }

  ${responsive.md.andSmaller`
    flex-direction: column;

    > span {
      margin: .5em 0;
    }
  `};
`;

const Empty = styled.label`
  justify-content: center;
  display: flex;
  flex: 1 0 auto;
  margin: auto;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  ${responsive.md.andSmaller`
    flex-direction: column;
  `};
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;

  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.44px;
  font-weight: 500;

  h4 {
    font-size: 1em;
    margin-bottom: 0.2em;
  }

  h5 {
    font-size: 0.75em;
    color: ${withTheme(theme => theme.textColor.alpha(0.5).string())};
  }
`;

const Action = styled.div`
  ${responsive.md.andSmaller`
    position: absolute;
    top: 1.5em;
    right: 2em;
  `};
`;

const minForStat = (sensor, id) =>
  id.indexOf('temperature') > 0 ? get(sensor, 'temperatureMin.value') : sensor.humidityMin;
const maxForStat = (sensor, id) =>
  id.indexOf('temperature') > 0 ? get(sensor, 'temperatureMax.value') : sensor.humidityMax;

const SensorStats = props => (
  <TitleContainer>
    <Title>
      <h4>{props.location}</h4>
      <h5>{props.id}</h5>
    </Title>
    <Row>
      <span>
        <TruncatedText>Current Temp</TruncatedText>
        <currentTempColumn.Component data={currentTempColumn.lens(props)} />
      </span>
      <span>
        <TruncatedText>T Min / Max</TruncatedText>
        <tempMinMaxColumn.Component data={tempMinMaxColumn.lens(props)} />
      </span>
      <span>
        <TruncatedText>Current Humidity</TruncatedText>
        <currentHumidityColumn.Component data={currentHumidityColumn.lens(props)} />
      </span>
      <span>
        <TruncatedText>H Min / Max</TruncatedText>
        <humidityMinMaxColumn.Component data={humidityMinMaxColumn.lens(props)} />
      </span>
      <Action style={{ marginLeft: '2em', alignSelf: 'top' }}>
        <ConfigButton data={props} />
      </Action>
    </Row>
  </TitleContainer>
);

const Sensor = ({ sensor, stats }) => (
  <Box component={Paper} p={2} style={{ flex: '0 1 100%', position: 'relative' }}>
    <SensorStats {...sensor} />
    {stats.length > 0 ? (
      stats.map(({ id, distribution, unit, title }, i) => (
        <LineChart
          data={distribution}
          unit={unit}
          key={id}
          color={colors[i % colors.length]}
          label={title}
          min={minForStat(sensor, id)}
          max={maxForStat(sensor, id)}
        />
      ))
    ) : (
      <Empty>No data for selected period</Empty>
    )}
  </Box>
);

const getDistributions = (id, data) => get(data, 'sensorsDistribution', []).filter(_ => _.id.startsWith(id));

const Sensors = ({ sensors, loading, sensorsDistribution }) =>
  !loading ? (
    get(sensors, 'length') > 0 ? (
      sensors.map(sensor => (
        <Sensor sensor={sensor} key={sensor.id} stats={getDistributions(sensor.id, sensorsDistribution)} />
      ))
    ) : (
      <p>No reports for selected criteria</p>
    )
  ) : (
    <AbsoluteSpinner />
  );

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  * + * {
    margin-left: 0.5em;
  }
`;

const SensorGrid = ({ filter, filteredSensors, loading: sensorsLoading, sensorIds }) => (
  <Container>
    <Query
      query={sensorsDistribution}
      variables={{ dateRange: filter.dateRange.toJSON(), sensors: sensorIds }}
      pollInterval={60000}
      fetchPolicy={'network-only'}
    >
      {({ data }) => <Sensors sensorsDistribution={data} sensors={filteredSensors} loading={sensorsLoading} />}
    </Query>
  </Container>
);

export default SensorGrid;

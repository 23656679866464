import React, { FC, useEffect, useRef, useState } from 'react';
import StaffShiftT from '../../../../../types/StaffShift';
import StaffMember from './StaffMember';
import StaffSlot from './StaffSlot';
import StaffSlotMenu from './StaffSlotMenu';
import StaffEmptySlotMenu from './StaffEmptySlotMenu';
import { Procedure } from '../../../../../types/Procedure';

interface StaffShiftProps extends StaffShiftT {
  procedure?: Procedure;
  isKiosk?: boolean;
  editableStaff?: boolean;
}

const StaffShift: FC<StaffShiftProps> = ({
  id,
  staff,
  openPosition,
  from,
  to,
  additionalText,
  procedure,
  isKiosk,
  editableStaff,
  ...rest
}) => {
  const [editTime, setEditTime] = useState<boolean>(false);
  const [editText, setEditText] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefText = useRef<HTMLInputElement>(null);

  const onSetEditTime = (val: boolean) => {
    setEditTime(val);
    inputRef?.current?.focus();
  };

  useEffect(() => {
    if (editTime) {
      inputRef?.current?.focus();
    }
  }, [editTime]);

  const onSetEditText = (val: boolean) => {
    setEditText(val);
    inputRefText?.current?.focus();
  };

  useEffect(() => {
    if (editText) {
      inputRefText?.current?.focus();
    }
  }, [editText]);

  return staff ? (
    isKiosk || !editableStaff ? (
      <StaffMember
        {...staff}
        procedure={procedure}
        editableStaff={editableStaff}
        editTime={editTime}
        setEditTime={setEditTime}
        editText={editText}
        setEditText={setEditText}
        from={from}
        to={to}
        additionalText={additionalText}
        shiftId={id}
        hideClock={true}
        hideText={true}
        inputRef={inputRef}
        inputRefText={inputRefText}
      />
    ) : (
      <StaffSlotMenu
        id={id}
        staff={staff}
        from={from}
        to={to}
        additionalText={additionalText}
        editTime={editTime}
        setEditTime={onSetEditTime}
        setEditText={onSetEditText}
        {...rest}
      >
        {onClick => (
          <StaffMember
            {...staff}
            procedure={procedure}
            editableStaff={editableStaff}
            editTime={editTime}
            setEditTime={setEditTime}
            editText={editText}
            setEditText={setEditText}
            onClick={onClick}
            from={from}
            to={to}
            additionalText={additionalText}
            shiftId={id}
            hideClock={true}
            hideText={true}
            inputRef={inputRef}
            inputRefText={inputRefText}
          />
        )}
      </StaffSlotMenu>
    )
  ) : isKiosk || !editableStaff ? (
    <StaffSlot id={id} from={from} to={to} openPosition={openPosition} />
  ) : (
    <StaffEmptySlotMenu
      id={id}
      staff={undefined}
      from={from}
      to={to}
      additionalText={additionalText}
      openPosition={openPosition}
      procedure={procedure}
      {...rest}
    >
      {onClick => <StaffSlot id={id} from={from} to={to} onClick={onClick} openPosition={openPosition} />}
    </StaffEmptySlotMenu>
  );
};

export default StaffShift;

import styled from 'styled-components';
import { Status } from './common';

export const ModalAction = styled.div`
  display: flex;
  flex-wrap: wrap;

  > button {
    min-width: 15rem;
  }

  > button + button {
    margin-left: 1rem;
  }
`;

export const Title = styled(Status)`
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
`;

export const Subtitle = styled.p`
  opacity: ${props => (props.prominent ? '1' : '0.5')};
  margin-bottom: 4rem;
  font-size: 1.25rem;
  line-height: 1.5;
`;

export const Modal = styled.div`
  background-color: ${props => props.theme.backgroundColor.string()};
  padding: 3rem;
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ModalBody = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  flex: 1 0 content;
  min-height: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${props => (props.right ? 'flex-end' : 'space-between')};
  margin-bottom: 1rem;
`;

export const Exit = styled.button`
  color: ${props => props.theme.button.primary.backgroundColor.string()};
  font-size: 0.875rem;
  padding: 0;
  display: inline-block;
  background: transparent;
  border: none;
`;

export const Patients = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const Error = styled.div`
  margin: 1em 0;
  padding: 2em;
  background: ${props => props.theme.toaster.warning.backgroundColor.alpha(0.8).string()};
  color: ${props => props.theme.toaster.warning.textColor.alpha(0.8).string()};
  cursor: pointer;
  :active {
    opacity: 0.9;
  }
`;

import { makeStyles } from '@material-ui/core/styles';
import { Theme, withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const Pills = withStyles({
  root: {},
})(Tabs);

export const Pill = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 1),
    borderRadius: theme.spacing(1),
  },
}))(Tab);

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  status: {
    minWidth: 200,
    marginRight: theme.spacing(3),
  },
  titleSearch: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    minWidth: 0,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.getContrastText(theme.palette.warning.dark),
  },
  info: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.getContrastText(theme.palette.info.dark),
  },
  titleActions: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  titleButton: {
    marginLeft: 'auto',
  },
  search: {
    width: 400,
  },
  anchorTopRight: {
    transform: 'translate(100%, -50%)',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    zIndex: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const cardiovascular = [
  ['Cardiac', 'Diagnosed Abnormal Heart Rhythm'],
  ['Cardiac', 'High cholesterol or lipids'],
  ['Cardiac', 'Coronary Artery Disease'],
  ['Cardiac', 'Heart related chest pain'],
  ['Cardiac', 'Heart attack'],
  ['Cardiac', 'High blood pressure'],
  ['Cardiac', 'Congestive heart failure'],
  ['Cardiac', 'Stents'],
  ['Cardiac', 'Pacemaker'],
  ['Cardiac', 'Heart valve'],
  ['Cardiac', 'Vascular disease'],
  ['Cardiac', 'Heart murmur'],
  ['Cardiac', 'Other cardiac'],
  ['Cardiac', 'cardiac comment'],
];

export const vascular = [
  ['Vascular', 'Anemia'],
  ['Vascular', 'Bleeding or blood clotting disorders'],
  ['Vascular', 'Glaucoma'],
  ['Vascular', 'History of blood clots'],
  ['Vascular', 'Vascular comment'],
  ['Vascular', 'blood thinners'],
  ['Vascular', 'Vascular disease'],
];

export const pulmonary = [
  ['Pulmonary', 'Current symptoms'],
  ['Pulmonary', 'Emphysema'],
  ['Pulmonary', 'Asthma'],
  ['Pulmonary', 'Cough'],
  ['Pulmonary', 'Pneumonia'],
  ['Pulmonary', 'Sleep apnea'],
  ['Pulmonary', 'oxygen'],
  ['Pulmonary', 'Tuberculosis'],
  ['Pulmonary', 'Bronchitis'],
  ['Pulmonary', 'respiratory'],
  ['Pulmonary', 'Other pulmonary'],
  ['Pulmonary', 'pulmonary comment'],
];

export const renal = [
  ['Renal', 'Kidney Failure'],
  ['Renal', 'dialysis'],
  ['Renal', 'kidney stones'],
  ['Renal', 'chronic renal insufficiency'],
  ['Renal', 'Other renal'],
  ['Renal', 'renal comment'],
];

export const hepatic = [
  ['Hepatic', 'liver failure'],
  ['Hepatic', 'Hepatitis'],
  ['Hepatic', 'other hepatic'],
  ['Hepatic', 'Hepatic comment'],
];

export const neurological = [
  ['Neurologic', 'Seizures'],
  ['Neurologic', 'Stroke'],
  ['Neurologic', 'Neuropathy'],
  ['Neurologic', 'migraines'],
  ['Neurologic', 'RLS'],
  ['Neurologic', 'other neurologic'],
  ['Neurologic', 'Neurologic comment'],
  ['Neurologic', 'Myopathy'],
  ['Neurologic', 'Sclerosis'],
];

export const gastrointestinal = [
  ['GI', 'heartburn'],
  ['GI', 'Stomach ulcers'],
  ['GI', 'Bowel Problems'],
  ['GI', 'Gall Bladder Problems'],
  ['GI', 'Hiatal hernia'],
  ['GI', 'Colitis'],
  ['GI', 'Ulcerative colitis'],
  ['GI', "Crohn's disease"],
  ['GI', 'Diverticulitis'],
  ['GI', 'IBD'],
  ['GI', 'Other GI'],
  ['GI', 'GI comment'],
];

export const hematological = [
  ['Hematological', 'Anemia'],
  ['Hematological', 'History of blood clots'],
  ['Hematological', 'blood thinners'],
  ['Hematological', 'contagious diseases'],
  ['Hematological', 'blood clotting'],
  ['Hematological', 'Blood transfusion'],
  ['Hematological', 'HIV'],
  ['Hematological', 'Varicose veins or venous stasis'],
  ['Hematological', 'Other hematologic conditions'],
  ['Hematological', 'Hematologic comment'],
];

export const endocrine = [
  ['Endocrine', 'Diabetes'],
  ['Endocrine', 'Thyroid disease'],
  ['Endocrine', 'Other endocrine'],
  ['Endocrine', 'Endocrine comment'],
];

export const musculoskeletal = [
  ['Musculoskeletal', 'Musculoskeletal problems'],
  ['Musculoskeletal', 'Myopathy'],
  ['Musculoskeletal', 'Arthritis'],
  ['Musculoskeletal', 'Chronic low back pain'],
  ['Musculoskeletal', 'Lower extremity immobilizing cast'],
  ['Musculoskeletal', 'Paralysis'],
  ['Musculoskeletal', 'neck pain'],
  ['Musculoskeletal', 'jaw pain'],
  ['Musculoskeletal', 'Other musculoskeletal'],
  ['Musculoskeletal', 'Musculoskeletal comment'],
];

export const psychiatric = [
  ['Psychiatric', 'depression'],
  ['Psychiatric', 'Bipolar Disorder'],
  ['Psychiatric', 'anxiety attacks'],
  ['Psychiatric', 'Schizophrenia'],
  ['Psychiatric', 'psychiatric'],
  ['Psychiatric', 'Psychiatric comment'],
];

export const gynaecological = [
  ['Gynaecological', 'pregnant'],
  ['Gynaecological', 'Gynecologic comment'],
];

export const oncologic = [
  ['Oncologic', 'Cancer'],
  ['Oncologic', 'Other oncologic'],
  ['Oncologic', 'Oncologic comment'],
];

export const infectious = [
  ['Infectious', 'C-diff'],
  ['Infectious', 'COVID19'],
  ['Infectious', 'HIV'],
  ['Infectious', 'MRSA'],
  ['Infectious', 'Other comment'],
  ['Infectious', 'Other conditions'],
  ['Infectious', 'Recently'],
  ['Infectious', 'skin breakdown'],
  ['Infectious', 'traveled'],
  ['Infectious', 'vaccination'],
  ['Infectious', 'Pneumonia'],
];

export const other = [
  ['Other', 'Glaucoma'],
  ['Other', 'Other conditions'],
  ['Other', 'Cancer'],
  ['Other', 'Other oncologic'],
  ['Other', 'Oncologic comment'],
  ['Other', 'MRSA'],
  ['Other', 'C-diff'],
  ['Other', 'ORSA'],
  ['Other', 'CMV'],
  ['Other', 'Shingles'],
  ['Other', 'Antibiotic Resistant Infection'],
  ['Other', 'Other Infectious Diseases'],
  ['Other', 'West African'],
  ['Other', 'traveled'],
  ['Other', 'blood or body fluids'],
  ['Other', 'Other infectious'],
  ['Other', 'Infectious Diseases comment'],
  ['Other', 'contagious diseases'],
  ['Other', 'Recently'],
  ['Other', 'Contagions comment'],
  ['Other', 'Other comment'],
  ['Other', 'skin breakdow'],
  ['Other', 'bruises or rashes'],
  ['Other', 'Other skin'],
  ['Other', 'Skin Integrity comment'],
  ['Other', 'chronic pain'],
];

export const pain = [
  ['Pain', 'chronic pain'],
  ['Pain', 'Pain comment'],
];

import graphql from '../hocs/graphql';
import PropTypes from 'prop-types';
import { compose, mapProps, withProps } from 'recompose';
import get from 'lodash/fp/get';

import { graphReject } from '../utilities/graph';
import CRUD from './CRUD';
import EntityView from './entity/EntityView';

import { isDefinedAndNotNull, isFunction } from '../utilities/check';
import omitDeep from 'omit-deep-lodash';

export const propTypes = {
  Create: PropTypes.shape({
    prepareCreateData: PropTypes.func,
  }),
  Edit: PropTypes.shape({
    prepareUpdateData: PropTypes.func,
  }),
  scheme: PropTypes.object.isRequired,
  Input: PropTypes.func,
};

export const mapFilter = filter => {
  if (isDefinedAndNotNull(filter)) {
    const { dateRange, ...rest } = filter;
    return { dateRange: dateRange && dateRange.toJSON(), ...rest };
  } else {
    return filter;
  }
};

export const sanitize = data => omitDeep(data, '__typename');

export default props => {
  const { entityName, idType, scheme, List, Create, Show, Edit, baseRouteUrlAffix, additionalRoutes, searchSource } =
    props;
  PropTypes.checkPropTypes(propTypes, props, 'prop', 'GraphQLCRUD');

  return CRUD({
    entityName,
    idType,
    baseRouteUrlAffix,
    additionalRoutes,
    List:
      List && List.columns
        ? {
            ...List,
            defaultFilterValues: List.defaultFilterValues,
            highlightedProvider: List.highlightedProvider,
            highlightedRowStyles: List.highlightedRowStyles,
            highlightedRowStyleProvider: List.highlightedRowStyleProvider,
            FilterComponent: List.FilterComponent,
            FooterComponent: List.FooterComponent,
            MiniDashboardComponent: List.MiniDashboardComponent,
            containsSeparator: List.containsSeparator,
            useColumnSelection: List.useColumnSelection,
            useCSVExport: List.useCSVExport,
            MobileItemComponent: List.MobileItemComponent,
            tableKey: List.tableKey,
            itemsProvider: List.itemsProvider
              ? List.itemsProvider
              : graphql(scheme.list, {
                  options: props =>
                    List.queryOptionsProvider
                      ? List.queryOptionsProvider(props)
                      : {
                          variables: {
                            filter: List.pickFilter
                              ? List.pickFilter(mapFilter(props.filter))
                              : mapFilter(props.filter),
                          },
                          fetchPolicy: 'cache-and-network',
                          shouldResubscribe: true,
                          pollInterval: List.pollInterval,
                        },
                }),
            columns: List.columns,
            hideColumns: List.hideColumns,
            idProvider: List.idProvider || get('id'),
          }
        : undefined,
    Create:
      Create && Create.Input
        ? {
            ...Create,
            Input: Create.Input,
            withHandleCreate: compose(
              graphql(scheme.create, {
                options: props => (Create.mutationOptionsProvider ? Create.mutationOptionsProvider(props) : {}),
              }),
              mapProps(({ mutate, ...rest }) => ({
                handleCreate: _ => {
                  const data = isFunction(Create.prepareCreateData) ? Create.prepareCreateData(_) : _;
                  return mutate({
                    variables: sanitize(data),
                  }).catch(graphReject);
                },
                ...rest,
              }))
            ),
          }
        : undefined,
    Show:
      Show && (Show.columns || (List && List.columns))
        ? {
            ...Show,
            View: Show.View || EntityView,
            titleProvider: Show.titleProvider || (data => data.name),
            itemProvider:
              Show.itemProvider ||
              graphql(scheme.item, {
                options: ({ idProvider, ...rest }) => ({
                  variables: {
                    id: parseInt(idProvider(rest), 10),
                    fetchPolicy: Show.fetchPolicy || 'cache-and-network',
                  },
                }),
              }),
            effects: [
              withProps({
                columns: Show.columns || (List && List.columns),
              }),
              ...(Show.effects || []),
            ],
          }
        : undefined,
    Edit:
      Edit && Edit.Input
        ? {
            ...Edit,
            Input: Edit.Input,
            withHandleUpdate: compose(
              graphql(scheme.update, {
                options: ({ idProvider, ...rest }) => ({
                  refetchQueries: !Edit.skipRefetch
                    ? [
                        {
                          query: scheme.item,
                          variables: {
                            id: parseInt(idProvider(rest), 10),
                          },
                        },
                      ]
                    : undefined,
                }),
              }),
              mapProps(({ mutate, ...rest }) => ({
                handleUpdate: _ => {
                  const data = isFunction(Edit.prepareUpdateData) ? Edit.prepareUpdateData(_) : _;
                  return mutate({
                    variables: sanitize(data),
                  }).catch(graphReject);
                },
                ...rest,
              }))
            ),
            withHandleRemove: scheme.remove
              ? compose(
                  graphql(scheme.remove),
                  mapProps(({ mutate, ...rest }) => ({
                    handleRemove: id =>
                      mutate({
                        variables: { id: parseInt(id, 10) },
                        refetchQueries: Edit.removeRefetchQueries,
                      }).catch(graphReject),
                    ...rest,
                  }))
                )
              : null,
            mapEditItemProps: Edit.mapEditItemProps,
          }
        : undefined,
    searchSource,
  });
};

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { matchPath, withRouter } from 'react-router-dom';
import { branch, compose, renderComponent } from 'recompose';
import enterFullScreen from '../../../se/utilities/enter-full-screen';
import { withTheme } from '../../../se/theme';
import Button from '../../../se/components/html/Button';
import { isFunction } from '../../../se/utilities/check';
import ClientUpdater from '../../ClientUpdater';

const FullScreenStartRoot = styled.div`
  position: fixed;
  display: flex;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${withTheme(theme => theme.backgroundColor.string())};
  align-items: center;
  justify-content: center;
`;

const StartButton = styled(Button)`
  padding: 6rem;
  text-transform: uppercase;
  border-radius: 0.25rem;
  font-weight: 600;
  letter-spacing: 0.2rem;
`;

const EnterFullScreen = ({ isInFullScreen, onFullScreenEnter }) => (
  <Fragment>
    <FullScreenStartRoot
      onClick={async () => {
        try {
          await enterFullScreen(document.documentElement);

          if (isFunction(onFullScreenEnter)) {
            onFullScreenEnter();
          }
        } catch (error) {
          console.error(`Can't go to full screen`, error.message);
        }
      }}
    >
      <StartButton xl>Start</StartButton>
    </FullScreenStartRoot>
    <ClientUpdater />
  </Fragment>
);

const isTabletScreen = (match, location) =>
  matchPath(location.pathname, {
    path: `${match.path}/tablet`,
    exact: false,
    strict: false,
  }) ||
  matchPath(location.pathname, {
    path: `${match.path}/nursing`,
    exact: false,
    strict: false,
  }) ||
  matchPath(location.pathname, {
    path: `${match.path}/charting`,
    exact: false,
    strict: false,
  });

export default compose(
  withRouter,
  branch(
    ({ isInFullScreen, match, location }) => !isInFullScreen && !isTabletScreen(match, location),
    renderComponent(EnterFullScreen)
  )
)(() => null);

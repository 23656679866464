import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AutomaticPatientTracking } from '../../../ModuleOptional';
import Chip from '@material-ui/core/Chip';
import {
  AirlineSeatFlat,
  Cancel,
  DirectionsWalk,
  Hotel,
  HowToReg,
  LowPriority,
  Message,
  PauseCircleOutline,
  Textsms,
  TimeToLeave,
  TransferWithinAStation,
} from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, fade } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CaretakerChatDialog } from '../../../chat/ChatDialog';
import { PACU, POST_OP, PRE_OP } from '../../../entities/room/enums';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../graph/notificationTemplates';
import Tooltip from '../../../Tooltip';
import PreOpNoteInput from '../PreOpNoteInput';
import DescriptionIcon from '@material-ui/icons/Description';
import PhysicianNotifyButton from '../PhysicianNotifyButton';
import AnesthesiologistNotifyButton from '../AnesthesiologistNotifyButton';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#fff', 0.05),
    color: fade('#fff', 0.5),
    marginBottom: -theme.spacing(3),
  },
  icon: {
    opacity: 0.45,
    color: fade('#fff', 0.5),
  },
  label: {
    lineHeight: 1.2,
  },
  buttonLabelHorizontal: {
    justifyContent: 'flex-start',
  },
  buttonLabel: {
    flexDirection: 'column',
    gap: '1rem',
  },
  buttonRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: theme.spacing(2.5),
  },
  sideButtonRoot: {
    color: fade('#fff', 0.5),
    marginBottom: -theme.spacing(3),
    fontSize: theme.spacing(2.5),
    padding: theme.spacing(2),
  },
  startIcon: {
    marginRight: 0,
  },
}));

const OptionWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${props => `rgba(255, 255, 255, ${props.disabled ? '0.05' : '0.1'})`};

  ${props => !props.disabled && 'cursor: pointer;'}

  i {
    font-size: 4rem;
    color: ${props => props.theme.linkColor.string()};
  }
`;

const Label = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
`;

const PacuOption = ({ icon, label, onClick, disabled, smsIndicator, children }) => {
  const classes = useStyles();
  return (
    <OptionWrapper onClick={!disabled ? onClick : undefined} disabled={disabled}>
      {icon}
      <Label>{label}</Label>

      {smsIndicator && (
        <Chip
          size="small"
          icon={<Textsms />}
          label="Sends Message"
          classes={{
            root: classes.root,
            icon: classes.icon,
            label: classes.label,
          }}
        />
      )}
      {children}
    </OptionWrapper>
  );
};

const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-template-rows: repeat(${props => props.rows || 0}, 1fr);
  grid-gap: ${props => (props.moreSpace ? '2.5em' : '1em')};
`;

export const Option = ({ isHorizontal, icon, label, onClick, disabled, smsIndicator, children }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{
        root: classes.buttonRoot,
        label: isHorizontal ? classes.buttonLabelHorizontal : classes.buttonLabel,
        startIcon: isHorizontal ? '' : classes.startIcon,
      }}
      size="large"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
    >
      {label}

      {smsIndicator && (
        <Chip
          size="small"
          icon={<Textsms />}
          label="Sends Message"
          classes={{
            root: classes.root,
            icon: classes.icon,
            label: classes.label,
          }}
        />
      )}
      {children}
    </Button>
  );
};

export const SideOption = ({ icon, label, onClick, disabled, smsIndicator, children = null }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{
        root: classes.sideButtonRoot,
        label: classes.buttonLabelHorizontal,
      }}
      size="large"
      variant="text"
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
      fullWidth
    >
      {label}

      {smsIndicator && (
        <Chip
          size="small"
          icon={<Textsms />}
          label="Sends Message"
          classes={{
            root: classes.root,
            icon: classes.icon,
            label: classes.label,
          }}
        />
      )}
      {children}
    </Button>
  );
};

const fontSizeIcon = '3.5rem';
export const fontSizeIconSide = '3rem';

const MoveToOrButton = ({ onSelect }) => (
  <Option
    icon={
      <TransferWithinAStation
        color={'primary'}
        style={{
          fontSize: fontSizeIcon,
        }}
      />
    }
    label="Move To OR"
    onClick={() => onSelect('moveToOr')}
  />
);
const ConditionalMoveToOr = AutomaticPatientTracking(null, MoveToOrButton);

const PatientOptions = ({
  hasPreOpPriorityModule,
  hasNoteModule,
  assignBed,
  markAsReady,
  markAsFamilyReady,
  markAsFamilyReadyPreOp,
  markAsReadyForSurgeon,
  dischargePatient,
  isPatientReady,
  isReadyToSeeFamilyPreOp,
  isReadyForSurgeon,
  onSelect,
  moveToOR,
  cancelVisit,
  onMarkFamilyReady,
  isFamilyReady,
  hasCaretakerConsent,
  smsIndicator,
  holdProcedure,
  isHoldProcedure,
  roomType,
  columns = 3,
  patient,
}) => {
  const isPreOp = roomType === PRE_OP;
  const isPacu = roomType === PACU;
  const isPostOp = roomType === POST_OP;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { data: readyToSeeFamilyMessage } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'PatientReadyForFamily', channel: 'SMS', destination: 'Caretaker' },
  });

  const readyToSeeFamilyMessageEnabled = readyToSeeFamilyMessage?.getNotificationTemplate?.[0]?.enabled || false;

  const { data: readyForPickupMessage } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'FamilyReady', channel: 'SMS', destination: 'Caretaker' },
  });
  const readyForPickupMessageEnabled = readyForPickupMessage?.getNotificationTemplate?.[0]?.enabled || false;

  const [openNote, setOpenNote] = useState(false);

  if (isPacu || isPostOp) {
    return (
      <Box p={4}>
        <Grid container spacing={2} style={{ maxHeight: '50%', minHeight: '45%' }}>
          <Grid item xs={6}>
            {assignBed && (
              <PacuOption
                icon={
                  <HowToReg
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label="Assign Bed"
                onClick={() => onSelect('assignBed')}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {dischargePatient && (
              <PacuOption
                icon={
                  <DirectionsWalk
                    color={'primary'}
                    style={{
                      fontSize: '4rem',
                    }}
                  />
                }
                label="Discharge Patient"
                onClick={() => onSelect('dischargePatient')}
              />
            )}
          </Grid>
        </Grid>

        <Box mt={4} mb={4}>
          <Typography variant="h4">Caretaker Notification</Typography>
        </Box>

        <Grid container spacing={2} style={{ maxHeight: '50%', minHeight: '45%' }}>
          <Grid item xs={4}>
            {markAsFamilyReady && (
              <PacuOption
                icon={
                  <HowToReg
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                disabled={!hasCaretakerConsent || !readyToSeeFamilyMessageEnabled}
                label={isPatientReady ? 'Undo Ready' : 'Ready To See Family'}
                onClick={() => onSelect(isPatientReady ? 'markAsNotReady' : 'markAsReadyConfirm')}
                smsIndicator={!isPatientReady && smsIndicator}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {onMarkFamilyReady && (
              <PacuOption
                icon={
                  <TimeToLeave
                    color={'primary'}
                    style={{
                      fontSize: '4rem',
                    }}
                  />
                }
                label={isFamilyReady ? 'Family Notified' : 'Ready For Pickup'}
                disabled={isFamilyReady || !hasCaretakerConsent || !readyForPickupMessageEnabled}
                onClick={() => onSelect('markAsFamilyReady')}
                smsIndicator={smsIndicator}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <PacuOption
              icon={
                <Message
                  color={'primary'}
                  style={{
                    fontSize: '4rem',
                  }}
                />
              }
              label={'Message Caretaker'}
              disabled={!hasCaretakerConsent}
              onClick={() => !open && handleOpen()}
              smsIndicator={smsIndicator}
            >
              <CaretakerChatDialog patient={patient} open={open} handleClose={handleClose} withButton={false} />
            </PacuOption>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (isPreOp) {
    return (
      <Grid style={{ padding: '2em' }} container spacing={4}>
        <Grid item md={4}>
          <Options moreSpace columns={1} rows={hasNoteModule ? 3 : 2}>
            <Tooltip content={hasCaretakerConsent ? null : 'No caretaker consent'}>
              <SideOption
                isHorizontal
                icon={
                  <Message
                    color={!hasCaretakerConsent ? 'disabled' : 'primary'}
                    style={{
                      fontSize: fontSizeIconSide,
                    }}
                  />
                }
                label={'Message Caretaker'}
                disabled={!hasCaretakerConsent}
                onClick={() => !open && handleOpen()}
              >
                <CaretakerChatDialog patient={patient} open={open} handleClose={handleClose} withButton={false} />
              </SideOption>
            </Tooltip>

            <PhysicianNotifyButton patient={patient} physician={patient?.physicianData} />

            <AnesthesiologistNotifyButton patient={patient} anesthesiologist={patient?.anesthesiologistData} />

            {!!hasNoteModule && (
              <SideOption
                isHorizontal
                icon={
                  <DescriptionIcon
                    color="primary"
                    style={{
                      fontSize: fontSizeIconSide,
                    }}
                  />
                }
                label={'Write PreOp Note'}
                disabled={false}
                onClick={() => setOpenNote(true)}
              >
                <PreOpNoteInput patient={patient} open={openNote} handleClose={() => setOpenNote(false)} />
              </SideOption>
            )}
          </Options>
        </Grid>

        <Grid item md={8} style={{ width: '100%', height: '100%' }}>
          <Options columns={columns} style={{ width: '100%', height: '100%' }}>
            {assignBed && (
              <Option
                icon={
                  <Hotel
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label="Assign Bed"
                onClick={() => onSelect('assignBed')}
              />
            )}
            {hasPreOpPriorityModule && (
              <Option
                icon={
                  <LowPriority
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label="Bed Priority"
                onClick={() => onSelect('preOpPriority')}
              />
            )}
            {markAsReadyForSurgeon && (
              <Option
                icon={
                  <HowToReg
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label={isReadyForSurgeon ? 'Undo Ready For Surgeon' : 'Mark as Ready For Surgeon'}
                onClick={() => onSelect(isReadyForSurgeon ? 'markAsNotReadyForSurgeon' : 'markAsReadyForSurgeon')}
                disabled={isPatientReady}
              />
            )}
            {markAsReady && markAsReadyForSurgeon && (
              <Option
                icon={
                  <AirlineSeatFlat
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label={isPatientReady ? 'Undo Ready For OR' : 'Mark as Ready For OR'}
                onClick={() => onSelect(isPatientReady ? 'markAsNotReady' : 'markAsReady')}
              />
            )}
            {holdProcedure && (
              <Option
                icon={
                  <PauseCircleOutline
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label={isHoldProcedure ? 'Undo Hold Procedure' : 'Hold Procedure'}
                onClick={() => onSelect(isHoldProcedure ? 'undoHoldProcedure' : 'holdProcedure')}
              />
            )}
            {dischargePatient && (
              <Option
                icon={
                  <DirectionsWalk
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label="Discharge Patient"
                onClick={() => onSelect('dischargePatient')}
              />
            )}
            {cancelVisit && (
              <Option
                icon={
                  <Cancel
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label="Cancel Patient Visit"
                onClick={() => onSelect('cancelVisit')}
              />
            )}
            {moveToOR && <ConditionalMoveToOr onSelect={onSelect} />}
            {markAsFamilyReadyPreOp && (
              <Option
                icon={
                  <HowToReg
                    color={'primary'}
                    style={{
                      fontSize: fontSizeIcon,
                    }}
                  />
                }
                label={isReadyToSeeFamilyPreOp ? 'Undo Ready To See Family' : 'Ready To See Family'}
                onClick={() =>
                  onSelect(isReadyToSeeFamilyPreOp ? 'markAsNotReadyToSeeFamilyPreOp' : 'markAsReadyToSeeFamilyPreOp')
                }
                smsIndicator={!isReadyToSeeFamilyPreOp && smsIndicator && hasCaretakerConsent}
              />
            )}
          </Options>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export const PatientOptionsProps = {
  assignBed: PropTypes.bool,
  isFamilyReady: PropTypes.bool,
  markAsReady: PropTypes.bool,
  dischargePatient: PropTypes.bool,
  isPatientReady: PropTypes.bool,
  isReadyForSurgeon: PropTypes.bool,
  moveToOR: PropTypes.bool,
  cancelVisit: PropTypes.bool,
  hasCaretakerConsent: PropTypes.bool,
  holdProcedure: PropTypes.bool,
  isHoldProcedure: PropTypes.bool,
};

PatientOptions.propTypes = {
  ...PatientOptionsProps,
  onSelect: PropTypes.func.isRequired,
};

PatientOptions.defaultProps = {
  assignBed: true,
  markAsReady: true,
  markAsReadyForSurgeon: true,
  dischargePatient: false,
  moveToOR: false,
  cancelVisit: false,
};

export default PatientOptions;

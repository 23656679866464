import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import PatientSlotAdditionalInfo from './PatientSlotAdditionalInfo';
import PatientSlotInfo from './PatientSlotInfo';

const PatientInfoWrapper = styled.div`
  flex: 1;
  overflow: hidden;

  ${props =>
    props.miniView &&
    css`
      min-height: 3.8em;
    `}
`;

const PatientInfo = ({
  status,
  physician,
  procedure,
  patient,
  patientType,
  bed,
  timeEntered,
  compactView,
  readyForOr,
  readyForSurgeon,
  readyInOr,
  roomName,
  familyReady,
  isHoldProcedure,
  expectedExitTime,
  size,
  allowWrap,
  miniView,
  preOpFocused,
  showPreOpNote,
  preOpNote,
  showPacuNote,
  pacuNote,
  isDPU,
}) => (
  <PatientInfoWrapper miniView={miniView}>
    <PatientSlotInfo
      physician={physician}
      patient={patient}
      patientType={patientType}
      size={size}
      readyForSurgeon={readyForSurgeon}
      readyForOr={readyForOr}
      readyInOr={readyInOr}
      familyReady={familyReady}
      allowWrap={allowWrap}
      miniView={miniView}
      isHoldProcedure={isHoldProcedure}
      isDPU={isDPU}
    />
    <PatientSlotAdditionalInfo
      bed={bed}
      procedure={procedure}
      timeEntered={timeEntered}
      compactView={compactView}
      status={status}
      size={size}
      miniView={miniView}
      roomName={roomName}
      expectedExitTime={expectedExitTime}
      preOpFocused={preOpFocused}
      showPreOpNote={showPreOpNote}
      showPacuNote={showPacuNote}
      preOpNote={preOpNote}
      pacuNote={pacuNote}
    />
  </PatientInfoWrapper>
);

PatientInfo.propTypes = {
  status: PropTypes.oneOf(['alert']),
  physician: PropTypes.string,
  procedure: PropTypes.string,
  patient: PropTypes.any,
  timeEntered: PropTypes.instanceOf(Date),
  compactView: PropTypes.bool,
  ready: PropTypes.bool,
  bed: PropTypes.string,
};

export default PatientInfo;

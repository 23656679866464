import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { compose, withProps } from 'recompose';
import GraphQLCRUD from '../../../entities/patient/../../../se/components/GraphQLCRUD';
import { mapListProps } from '../../../entities/patient/transducers';
import {
  dateOfVisit,
  note,
  PatientNameColumn,
  phoneNumberColumn,
  physicianColumn,
  procedureTypeColumn,
  providerIdColumn,
  roomOrDateOfVisit,
  smsNotificationNumbersColumn,
} from '../../../entities/patient/columns';
import Filters from '../../../entities/patient/../../pages/analytics/Filters';
import PatientListView from '../../../entities/patient/./views/PatientListView';
import PatientShowTitle from '../../../entities/patient/../../patient/PatientShowTitle';
import responsive from '../../../entities/patient/../../../se/utilities/responsive';
import PatientMobileListItem from '../../../entities/patient/./views/PatientMobileListItem';
import { NamedRange } from '../../../core/DatePicker';
import activeAndPastProceduresSource from '../../../entities/patient/../../../omnisearch/sources/hospital/activeAndPastProcedures';
import pick from 'lodash/fp/pick';
import { withScope } from '../../../HospitalInfo';
import { get, set } from 'lodash/fp';
import ChartingPatientView, { PatientChartButton } from './ChartingPatientView';
import { TruncatedText } from '../../../../se/components/typography';
import scheme from '../../../../graph/patientChart';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { sortString } from '../../../../util/sort';
import { LinearProgress, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const Empty = { illustration: theme => theme.illustrations.patients };

const FiltersWrapper = styled.div`
  ${responsive.md.andSmaller`
    padding-bottom: 0rem;
    display: flex;
    flex-flow: column;
    .hidden {
      margin-bottom: 2em;
    }
  `};
`;

const filterEventColumns = props => {
  const hasPostop = get('scope.hospital.modules.hasPostop')(props);
  return hasPostop
    ? props
    : set(
        'columns',
        get('columns')(props).filter(column => column.title !== 'Post Op Entry')
      )(props);
};

export const CustomFilters = props => (
  <FiltersWrapper>
    <Filters {...props} />
  </FiltersWrapper>
);

const anesthesiologistColumn = {
  title: 'Anesthesiologist',
  lens: data => data?.procedure?.anesthesiologist?.name,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const anesthesiaTypeColumn = {
  title: 'Anesthesia Type',
  lens: data => data?.procedure?.anesthesiaType,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartingButton = ({ patient, handleClick }) => {
  const hasProcedure = !!patient?.procedure?.id;

  if (!hasProcedure) return null;

  const charts = patient?.procedure?.chartsAndAnswers || [];
  const count = charts?.length || 0;

  const completed = charts?.map(chart => chart?.completed);
  const completedNumber = completed?.filter(e => e)?.length || 0;
  const completedAll = completed?.every(e => e);

  const progress = count ? (completedNumber / count) * 100 : 0;

  return (
    <Row>
      <Button
        style={{ overflow: 'hidden' }}
        variant="contained"
        onClick={handleClick}
        endIcon={completedAll && <Check color="palette.success.main" fontSize="inherit" />}
      >
        <Box display="flex" alignItems="center">
          {completedAll ? 'Complete' : 'Charting'}
          {!completedAll && (
            <Box ml={1}>
              <Typography variant="body2" color="textSecondary">
                {completedNumber}/{count}
              </Typography>
            </Box>
          )}
        </Box>
        {!completedAll && (
          <Box position="absolute" bottom={0} left={0} right={0} display="flex" width="100%">
            <LinearProgress variant="determinate" value={progress} style={{ flex: 1 }} />
          </Box>
        )}
      </Button>
    </Row>
  );
};

export const chartingLink = {
  title: 'Charting',
  lens: data => data,
  Component: withRouter(props => {
    const patient = props?.data;

    const handleClick = e => {
      e.stopPropagation();
      props.history.push(`${props.location.pathname}/patient-chart/${patient?.id}`);
    };
    return <ChartingButton patient={patient} handleClick={handleClick} />;
  }),
};

export const nameColumn = sortField => ({
  title: 'Name',
  key: 'name',
  lens: data => ({ id: data.id, icon: data.icon, color: data.color, name: data.name, type: data.type, patient: data }),
  sortFunction: (l, r) => sortString(l[sortField], r[sortField]),
  Component: props => (
    <PatientNameColumn {...props} showIcon={false} showPatientType={false} showPatientChartCompleted={false} />
  ),
});

const ChartingPatients = withScope(
  GraphQLCRUD({
    entityName: 'Patient',
    scheme,
    List: {
      tableKey: 'ActiveAndPastPatients',
      MobileItemComponent: PatientMobileListItem,
      useColumnSelection: true,
      columns: [
        providerIdColumn,
        nameColumn('name'),
        physicianColumn,
        procedureTypeColumn,
        anesthesiologistColumn,
        anesthesiaTypeColumn,
        roomOrDateOfVisit,
        chartingLink,
      ],
      View: PatientListView,
      FilterComponent: withProps({
        textSearchKey: 'name',
        hideSpecialitySelectInput: true,
        hideRoomSelectInput: false,
        hideProcedureTypeSelectInput: true,
        hideMobile: true,
      })(CustomFilters),
      defaultFilterValues: {
        dateRange: NamedRange.lastSevenDays(),
      },
      pickFilter: pick([
        'name',
        'physician',
        'procedureType',
        'hospital',
        'status',
        'dateRange',
        'category',
        'speciality',
        'room',
      ]),
      Empty,
      mapListProps: compose(mapListProps, filterEventColumns),
      containsSeparator: true,
    },
    Show: {
      columns: [
        providerIdColumn,
        physicianColumn,
        procedureTypeColumn,
        anesthesiologistColumn,
        anesthesiaTypeColumn,
        smsNotificationNumbersColumn,
        phoneNumberColumn,
        dateOfVisit,
        note,
      ],
      Empty,
      View: ChartingPatientView,
      Title: PatientShowTitle,
      Actions: PatientChartButton,
      itemProvider: graphql(scheme.item, {
        options: ({ idProvider, ...rest }) => ({
          variables: {
            id: parseInt(idProvider(rest), 10),
            fetchPolicy: 'cache-and-network',
          },
        }),
      }),
    },
    searchSource: activeAndPastProceduresSource,
  })
);

export default props => (
  <ThemeProvider theme={{ gridListColumnsTemplate: 'auto auto auto minmax(10vw,2fr) auto' }}>
    <ChartingPatients {...props} />
  </ThemeProvider>
);

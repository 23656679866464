import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useClickAway from '../../../../../hooks/useClickAway';
import { useStaffSlotContext } from './StaffSlotContext';
import TimeRangeInput from './TimeRangeInput';
import formatTimeRange from '../../../calendar/util/formatTimeRange';

interface StaffMemberProps {
  assignedShiftId?: number;
  from?: string;
  to?: string;
  editTime: boolean;
  setEditTime?: Dispatch<SetStateAction<boolean>>;
  hideClock?: boolean;
  editableStaff?: boolean;
  inputRef: any;
}

const StaffShiftTime: FC<StaffMemberProps> = ({
  inputRef,
  hideClock,
  editTime,
  setEditTime,
  assignedShiftId,
  from,
  to,
  editableStaff,
}) => {
  const classes = useStyles();
  const timeText = formatTimeRange(from, to);
  const defaultValue = from && to ? `${from}-${to}` : from ? `${from}-` : '';

  const onClockClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    editableStaff && setEditTime?.(true);
  };

  const onTimeTextClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    editableStaff && setEditTime?.(true);
  };

  const onClickAway = () => {
    setEditTime?.(false);
  };

  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, onClickAway);

  const showClock = !hideClock && !editTime && assignedShiftId && !to && !from;
  const showTime = !editTime && (to || from);

  const staffSlotContext = useStaffSlotContext();

  const saveWorkingTime = async (from: string | null, to: string | null) => {
    assignedShiftId && (await staffSlotContext.setFrom(assignedShiftId, from || undefined));
    assignedShiftId && (await staffSlotContext.setTo(assignedShiftId, to || undefined));
  };

  const onInputChange = async (from: string | null, to: string | null, isValid: boolean, isCompleted: boolean) => {
    if (isCompleted || isValid) {
      await saveWorkingTime(from, to);
      if (isCompleted) {
        setEditTime?.(false);
      }
    }
  };

  return (
    <>
      {showClock && (
        <IconButton size="small" className={classes.noteIcon} onClick={onClockClick}>
          <AccessTimeIcon fontSize="small" />
        </IconButton>
      )}

      {editTime && (
        <div ref={wrapperRef}>
          <TimeRangeInput
            defaultValue={defaultValue}
            inputRef={inputRef}
            onInputChange={onInputChange}
            className={classes.noteInput}
          />
        </div>
      )}

      {showTime && (
        <Typography className={classes.noteText} variant="caption" onClick={onTimeTextClick}>
          {timeText}
        </Typography>
      )}
    </>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noteIcon: {
      marginLeft: '0.2rem',
      color: theme.palette.primary.light,
    },
    noteText: {
      lineHeight: 1,
      marginLeft: theme.spacing(1),
      color: 'rgba(255, 255, 255, .5)',
    },
    noteInput: {
      marginLeft: theme.spacing(1),
      backgroundColor: 'rgb(0, 12, 63)',
      color: 'rgba(255, 255, 255, .5)',
      width: '5rem',
      fontSize: '0.75rem',
      fontFamily:
        'Rubik,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  })
);

export default StaffShiftTime;

import React, { useContext } from 'react';
import { format } from 'date-fns';
import { TruncatedText } from '../../../se/components/typography';
import ExpandableText from '../../core/ExpandableText';
import Icon from '../../../se/components/Icon';
import styled from 'styled-components';
import get from 'lodash/get';
import { withProps } from 'recompose';
import { Scope } from '../../HospitalInfo';
import { PatientIdFormatter } from '../../../util/PatientNameFormatter';
import { sortDate, sortNumber } from '../../../util/sort';
import { ZonedDateTime } from '@js-joda/core';
import { withRouter } from 'react-router';
import LinkButton from '../../../se/components/LinkButton';
import PhoneLink from '../../../se/components/html/PhoneLink';
import secretValue from '../../../util/secretValue';

const Text = TruncatedText as any;

const Inline = styled.div`
  display: inline-flex;
  padding: 0;
  position: relative;
`;

const Star = withProps(({ full }: any) => ({
  xl: true,
  children: full ? 'star' : 'star_border',
}))(styled(Icon)`
  user-select: none;
  cursor: default;
  color: ${(props: any) => (props.full ? 'orange' : 'white')};
`);

const StarProgress = styled.div`
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  width: ${(props: any) => props.percent}%;
`;

export const StarRating = ({ rating }: any) => (
  <Inline>
    <div style={{ opacity: 0.5 }}>
      {[...Array(5)].map((_, i) => (
        <Star key={i} full={false} />
      ))}
    </div>
    {/* @ts-ignore */}
    <StarProgress percent={(rating / 5) * 100}>
      {[...Array(5)].map((_, i) => (
        <Star key={i} full={true} />
      ))}
    </StarProgress>
  </Inline>
);

export const listColumns = [
  {
    title: 'Patient ID',
    key: 'patientId',
    lens: (data: any) => data.patientId,
    // @ts-ignore
    Component: withRouter((props: any) => {
      const { hospital } = useContext(Scope);

      const onClick = (e: any) => {
        e.stopPropagation();
        const urlPart = props?.location.pathname.includes(`/feedback/patient`)
          ? `/feedback/patient`
          : `/feedback/caretaker`;
        props?.history?.push(props?.location.pathname.replace(urlPart, `/patients/${props.data}`));
      };

      return (
        <LinkButton color="primary" onClick={onClick}>
          {PatientIdFormatter(hospital.id, hospital.groupId)({ id: props.data })}
        </LinkButton>
      );
    }),
  },
  {
    title: 'Physician',
    key: 'physician',
    lens: (data: any) => get(data, 'physician.name', '-'),
    Component: (props: any) => <Text>{props.data}</Text>,
  },
  {
    title: 'Rating',
    key: 'rating',
    lens: (data: any) => data.rating,
    Component: (props: any) => <StarRating rating={props.data} />,
    sortFunction: (l: any, r: any) => sortNumber(l.rating, r.rating),
  },
  {
    title: 'Date Submitted',
    key: 'submittedAt',
    lens: (data: any) => data.submittedAt,
    sortFunction: (l: any, r: any) => sortDate(l.submittedAt, r.submittedAt),
    Component: (props: any) => <Text>{format(props.data, 'MM/DD/YYYY HH:mm')}</Text>,
  },
  {
    title: 'Suggestion',
    key: 'suggestion',
    lens: (data: any) => data.suggestion,
    style: { overflow: 'hidden', maxWidth: '30em' },
    // @ts-ignore
    Component: (props: any) => <ExpandableText text={props.data} />,
  },
];

export const patientViewColumns = [
  {
    title: 'Patient ID',
    key: 'patientId',
    lens: (data: any) => data.patientId,
    // @ts-ignore
    Component: withRouter((props: any) => {
      const { hospital } = useContext(Scope);

      const onClick = (e: any) => {
        e.stopPropagation();
        const urlPart = props?.location.pathname.includes(`/feedback/patient`)
          ? `/feedback/patient`
          : `/feedback/caretaker`;
        props?.history?.push(props?.location.pathname.replace(urlPart, `/patients/${props.data}`));
      };

      return (
        <LinkButton color="primary" onClick={onClick}>
          {PatientIdFormatter(hospital.id, hospital.groupId)({ id: props.data })}
        </LinkButton>
      );
    }),
  },
  {
    title: 'Patient Name',
    key: 'patientName',
    lens: (data: any) => data?.patient?.name || '-',
    Component: (props: any) => <Text>{props.data}</Text>,
  },
  {
    title: 'Patient Phone Number',
    key: 'patientPhoneNumber',
    lens: (data: any) => data?.patient?.phoneNumber || '-',
    Component: (props: any) => (
      <Text>
        {props.data && props.data !== secretValue ? (
          <PhoneLink>{props.data}</PhoneLink>
        ) : props.data === secretValue ? (
          secretValue
        ) : (
          '-'
        )}
      </Text>
    ),
  },
  {
    title: 'Date Of Surgery',
    key: 'dateOfSurgery',
    lens: (data: any) => data?.patient?.dateOfSurgery,
    Component: (props: any) =>
      props.data ? (
        <Text>{format(ZonedDateTime.parse(props.data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}</Text>
      ) : null,
  },
  {
    title: 'Physician',
    key: 'physician',
    lens: (data: any) => data?.physician?.name || '-',
    Component: (props: any) => <Text>{props.data}</Text>,
  },
];

export const caretakerViewColumns = [
  {
    title: 'Patient ID',
    key: 'patientId',
    lens: (data: any) => data.patientId,
    // @ts-ignore
    Component: withRouter((props: any) => {
      const { hospital } = useContext(Scope);

      const onClick = (e: any) => {
        e.stopPropagation();
        const urlPart = props?.location.pathname.includes(`/feedback/patient`)
          ? `/feedback/patient`
          : `/feedback/caretaker`;
        props?.history?.push(props?.location.pathname.replace(urlPart, `/patients/${props.data}`));
      };

      return (
        <LinkButton color="primary" onClick={onClick}>
          {PatientIdFormatter(hospital.id, hospital.groupId)({ id: props.data })}
        </LinkButton>
      );
    }),
  },
  {
    title: 'Patient Name',
    key: 'patientName',
    lens: (data: any) => data?.patient?.name || '-',
    Component: (props: any) => <Text>{props.data}</Text>,
  },
  {
    title: 'Patient Phone Number',
    key: 'patientPhoneNumber',
    lens: (data: any) => data?.patient?.phoneNumber,
    Component: (props: any) => (
      <Text>
        {props.data && props.data !== secretValue ? (
          <PhoneLink>{props.data}</PhoneLink>
        ) : props.data === secretValue ? (
          secretValue
        ) : (
          '-'
        )}
      </Text>
    ),
  },
  {
    title: 'Caretaker Phone Number',
    key: 'caretakerPhoneNumber',
    lens: (data: any) => data?.caretakerPhoneNumber,
    Component: (props: any) => (
      <Text>
        {props.data && props.data !== secretValue ? (
          <PhoneLink>{props.data}</PhoneLink>
        ) : props.data === secretValue ? (
          secretValue
        ) : (
          '-'
        )}
      </Text>
    ),
  },
  {
    title: 'Date Of Surgery',
    key: 'dateOfSurgery',
    lens: (data: any) => data?.patient?.dateOfSurgery,
    Component: (props: any) =>
      props.data ? (
        <Text>{format(ZonedDateTime.parse(props.data).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}</Text>
      ) : null,
  },
  {
    title: 'Physician',
    key: 'physician',
    lens: (data: any) => data?.physician?.name || '-',
    Component: (props: any) => <Text>{props.data}</Text>,
  },
];

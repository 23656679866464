import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useMutation } from '@apollo/client';
import { create, update } from '../../../graph/scheduleRequests';
import { list as listSchedule } from '../../../graph/surgeon/bookings';
import { listUnscheduled } from '../../../graph/surgeon/procedures';
import FormSectionsInput from '../../inputs/FormSectionsInput';
import SignPhysicianInput from '../../inputs/SignPhysicianInput';
import TimeInputControl from '../../../se/components/controls/TimeControl';
import Form from '../../../se/components/Form';
import { RouterlessModal } from '../../../se/components/Modal';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import DateInput from '../../../se/components/inputs/DateInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import { FieldRow } from '../../pages/kiosk/schedule/edit/ProcedureEditor';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import SelectInput from '../../../se/components/inputs/SelectInput';
import get from 'lodash/get';
import useLocalStorage from 'use-localstorage-hook';
import { BaseError } from '../../../se/components/errors/Error';
import { ErrorMessageWrapper } from '../../../se/components/errors/FormError';
import styled from 'styled-components';
import { hourToTime, timeToHour } from './util/time';
import { ArrowBack } from '@material-ui/icons';

export const Columns = styled.div`
  display: flex;
  > * {
    margin-right: 2rem;
    min-width: 0;
  }
  > :last-child {
    margin-right: 0;
    flex-basis: 20%;
  }
`;

export const Column = styled.div`
  flex: 1;
`;

const ProcedureDetailsInput = (isEdit, organizationId, organizationName) =>
  withProps({
    schema: {
      surgicalCenterId: compose(
        withLabel('Surgery Center'),
        withProps({
          required: true,
          disabled: true,
          options: { [organizationId]: organizationName },
          value: organizationId,
        })
      )(SelectInput),
      surgeryDate: withProps({ required: true, disabled: true })(withLabel('Surgery Date')(DateInput)),
      surgeryTime: withProps({ placeholder: '12:15', required: true })(withLabel('Surgery Time')(TimeInputControl)),
      duration: withProps({ min: 1, max: 1440, required: true })(withLabel('Duration')(NumberInput)),
      formIds: withLabel('Sections from patient file to transfer')(FormSectionsInput),
      signPhysician: SignPhysicianInput,
    },
    render: props => {
      const { surgicalCenterId, surgeryDate, surgeryTime, duration, formIds, signPhysician } = props;
      return (
        <Columns>
          <Column>
            <FieldRow style={{ marginBottom: '1.5rem' }}>{surgicalCenterId}</FieldRow>
            <FieldRow>
              <div>{surgeryDate}</div>
              <div>{surgeryTime}</div>
            </FieldRow>
            <FieldRow style={{ marginBottom: '1.5rem' }}>{duration}</FieldRow>
          </Column>
          <Column>
            <FieldRow>{formIds}</FieldRow>
            <FieldRow style={{ marginTop: '1.5rem' }}>{signPhysician}</FieldRow>
          </Column>
        </Columns>
      );
    },
  })(ObjectInput);

const getErrorMessage = (from, to) =>
  `The surgery can be scheduled between ${hourToTime(from)} and ${hourToTime(
    to
  )}. Please select the start time of the surgery and the duration within this interval.`;

const ProcedureDetailsModal = ({ date, from, to, slot, procedure, open, onClose, onGoBack }) => {
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [createSchedule] = useMutation(create);
  const [updateSchedule] = useMutation(update);

  const [id, setId] = useState(0);

  useEffect(() => setId(prev => prev + 1), [slot, setId]);

  const isEdit = procedure && procedure.scheduleRequest;
  const organizationId = slot && slot.organizationId;
  const organizationName = slot && slot.organizationName;

  const Input = useMemo(() => ProcedureDetailsInput(isEdit, organizationId, organizationName), [
    isEdit,
    organizationId,
    organizationName,
  ]);

  const [defaultFormIds, setDefaultFormIds] = useLocalStorage(`formIds`, []);

  const validate = value =>
    timeToHour(value.surgeryTime) >= from && timeToHour(value.surgeryTime) + value.duration / 60 <= to;

  const handleSubmit = async value => {
    setBusy(true);
    try {
      if (validate(value)) {
        setErrorMessage(null);
        if (isEdit) {
          await updateSchedule({
            variables: {
              id: procedure.scheduleRequest.id,
              surgicalCenterId: slot.organizationId,
              formIds: value.formIds,
              signPhysician: value.signPhysician,
              scheduleTransferDate: value.surgeryDate,
              scheduleTransferTime: value.surgeryTime,
              duration: parseInt(value.duration, 10),
              bookingId: slot.id,
            },
            refetchQueries: [
              {
                query: listSchedule,
                variables: {
                  from: format(startOfMonth(date), 'YYYY-MM-DD'),
                  to: format(endOfMonth(date), 'YYYY-MM-DD'),
                },
              },
              { query: listUnscheduled },
            ],
          });
        } else {
          await createSchedule({
            variables: {
              surgicalCenterId: slot.organizationId,
              procedureId: procedure.id,
              formIds: value.formIds,
              signPhysician: value.signPhysician,
              scheduleTransferTime: value.surgeryTime,
              scheduleTransferDate: value.surgeryDate,
              duration: parseInt(value.duration, 10),
              scheduleTransferType: 'SURGERY',
              bookingId: slot.id,
            },
            refetchQueries: [
              {
                query: listSchedule,
                variables: {
                  from: format(startOfMonth(date), 'YYYY-MM-DD'),
                  to: format(endOfMonth(date), 'YYYY-MM-DD'),
                },
              },
              { query: listUnscheduled },
            ],
          });
        }
        setDefaultFormIds(value.formIds || []);
        onClose();
      } else {
        setErrorMessage(getErrorMessage(from, to));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Fragment>
      {open && (
        <RouterlessModal
          title={
            <Box display="flex">
              <Box mr={1}>
                <IconButton color="primary" edge="start" onClick={onGoBack} aria-label="delete">
                  <ArrowBack fontSize={'small'} />
                </IconButton>
              </Box>
              {procedure && (
                <Box flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography component="h2" variant="h6">
                      {procedure.patient ? procedure.patient.name || procedure.patientName : procedure.patientName}{' '}
                      {procedure.patient && (
                        <Box component="span" ml={1} color="text.secondary">
                          {procedure.patient.age}/{procedure.patient.sex}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                  {procedure.procedureType && <Typography color="textSecondary">{procedure.procedureType}</Typography>}
                </Box>
              )}
            </Box>
          }
          onClose={() => {
            setErrorMessage(null);
            onClose();
          }}
          width="60em"
        >
          <Form
            key={`pd-${id}`}
            autoFocus
            label={isEdit ? 'ReSchedule' : 'Schedule'}
            input={Input}
            onSubmit={handleSubmit}
            busy={busy}
            initialValue={{
              surgicalCenterId: organizationId,
              surgeryDate: format(slot.date, 'YYYY-MM-DD'),
              surgeryTime: hourToTime(from),
              duration: '' + Math.min(60, (to - from) * 60),
              formIds: defaultFormIds,
              signPhysician: false,
              physician: get(procedure, 'patient.physician', null),
            }}
          />
          {errorMessage && (
            <Box mt={2}>
              <BaseError>
                <ErrorMessageWrapper>
                  <p>{errorMessage}</p>
                </ErrorMessageWrapper>
              </BaseError>
            </Box>
          )}
        </RouterlessModal>
      )}
    </Fragment>
  );
};

export default ProcedureDetailsModal;

import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';

import { MONITOR_STATUS_ICONS, STATUS_LABELS as PATIENT_STATUS_LABELS } from '../../../entities/patient/enums';
import { STATUS_LABELS as ROOM_STATUS_LABELS } from '../../../entities/room/enums';
import { Status } from './common';
import responsive from '../../../../se/utilities/responsive';

const Wrapper = styled.div`
  min-height: 9.75rem;
  margin: 0 auto;
  display: ${props => (props.noicon ? 'none' : 'initial')};

  ${responsive.sm.andSmaller`
    display: none;
  `};
`;

const Meter = posed.img({
  enter: {
    opacity: 1,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
      delay: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const CurrentProgress = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusContainer = styled.div`
  text-align: center;
  margin-top: auto;
`;

const CurrentStatus = ({ status, prev, prevLabel, room }) => (
  <StatusContainer>
    <Status>{status}</Status>
  </StatusContainer>
);

const ProcedureProgress = ({ status, room, action, noicon }) => {
  const statusLabel = PATIENT_STATUS_LABELS[status] || ROOM_STATUS_LABELS[status];

  return (
    <CurrentProgress>
      <Wrapper noicon={noicon}>
        <PoseGroup animateOnMount flipMove={true}>
          <Meter key={status} src={MONITOR_STATUS_ICONS[status]} flipMove />
        </PoseGroup>
      </Wrapper>
      <CurrentStatus status={statusLabel} prev={action.prev} room={room} />
    </CurrentProgress>
  );
};

export default ProcedureProgress;

import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../ScheduleProcedureForm';
import { FormParams, PatientDetails, sexTypes } from '../types/types';
import set from 'lodash/fp/set';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { phoneNumberFormatPipeline } from '../../../../../../se/components/inputs/PhoneInput';
import { format } from 'date-fns';
import { DateInput } from '../components/DateInput';
import { setValueAndResetError } from '../utils/values';
import { Autocomplete } from '@material-ui/lab';

const PatientInfoForm: FC<FormParams<PatientDetails>> = ({ value, setValue }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Patient Info
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={8}>
            <Typography color="textSecondary" gutterBottom>
              Basic info
            </Typography>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <TextField
                  required
                  error={!!value?.basicInfo?.firstName?.error}
                  helperText={value?.basicInfo?.firstName?.error}
                  value={value?.basicInfo?.firstName?.value}
                  onChange={event => setValue(setValueAndResetError('basicInfo.firstName', event.target.value, value))}
                  label="First Name"
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  required
                  error={!!value?.basicInfo?.lastName?.error}
                  helperText={value?.basicInfo?.lastName?.error}
                  value={value?.basicInfo?.lastName?.value}
                  onChange={event => setValue(setValueAndResetError('basicInfo.lastName', event.target.value, value))}
                  label="Last Name"
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={5}>
                <TextField
                  required
                  error={!!value?.basicInfo?.medicalRecord?.error}
                  helperText={value?.basicInfo?.medicalRecord?.error}
                  value={parseInt(value?.basicInfo?.medicalRecord?.value, 10)}
                  onChange={event =>
                    setValue(setValueAndResetError('basicInfo.medicalRecord', parseInt(event.target.value, 10), value))
                  }
                  label="Medical Record Number"
                  type="number"
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <DateInput
                  value={value?.basicInfo?.dateOfBirth?.value}
                  onChange={event => setValue(set('basicInfo.dateOfBirth.value', event.target.value)(value))}
                  label="Date of Birth"
                  max={format(new Date(), 'YYYY-MM-DD')}
                />
              </Grid>
              <Grid item md={3}>
                <Autocomplete
                  options={sexTypes}
                  getOptionLabel={(option: string) => option}
                  renderInput={params => (
                    <TextField {...params} label="Sex" variant="filled" margin="dense" fullWidth />
                  )}
                  value={value?.basicInfo?.sex?.value}
                  onChange={(event, val) => setValue(setValueAndResetError('basicInfo.sex', val || '', value))}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  value={value?.basicInfo?.diagnosis?.value}
                  onChange={event => setValue(set('basicInfo.diagnosis.value', event.target.value)(value))}
                  label="Diagnosis"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  multiline
                  minRows={2}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  value={value?.basicInfo?.allergies?.value}
                  onChange={event => setValue(set('basicInfo.allergies.value', event.target.value)(value))}
                  label="Allergies"
                  variant="filled"
                  margin="dense"
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Typography color="textSecondary" gutterBottom>
              Contact info
            </Typography>
            <PhoneNumberInput
              value={value?.contactInfo?.patientPhoneNumber?.value}
              onChange={event =>
                setValue(
                  set('contactInfo.patientPhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value)
                )
              }
              label="Patient Phone Number"
            />
            <PhoneNumberInput
              value={value?.contactInfo?.careTakerPhoneNumber?.value}
              onChange={event =>
                setValue(
                  set('contactInfo.careTakerPhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value)
                )
              }
              label="Caretaker Phone Number"
            />
            <PhoneNumberInput
              value={value?.contactInfo?.familyPhoneNumber?.value}
              onChange={event =>
                setValue(
                  set('contactInfo.familyPhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value)
                )
              }
              label="Family Phone Number"
            />
            <PhoneNumberInput
              value={value?.contactInfo?.homePhoneNumber?.value}
              onChange={event =>
                setValue(set('contactInfo.homePhoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value))
              }
              label="Home Phone Number"
            />
            <TextField
              value={value?.contactInfo?.patientEmail?.value}
              onChange={event => setValue(set('contactInfo.patientEmail.value', event.target.value)(value))}
              label="Patient Email"
              variant="filled"
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <TextField
              value={value?.basicInfo?.note?.value}
              onChange={event => setValue(set('basicInfo.note.value', event.target.value)(value))}
              label="Note"
              variant="filled"
              margin="dense"
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PatientInfoForm;

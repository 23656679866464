import { gql } from '@apollo/client';
import { fragments as procedureTypeFragments } from './procedureTypes';
import { fragments as physicianFragments } from './physicians';
import { fragments as proceduresFragments } from './procedures';

const procedureBaseFields = `
  id
  status
  startTime
  startTimeText
  endTimeText
  duration
  orId
  or
  isCanceled
  physicianId
  physicianHstId
  physicianName
  physicianNameHST
  physicianColor
  patientId
  patientHstId
  patientName
  patientNameUnformatted
  patientInitials
  patientDateOfBirth
  patientDateOfBirthISO
  patientAge
  patientSex
  patientHomePhone
  patientCellPhone
  caretakerCellPhone
  familyCellPhone
  patientEmailAddress
  procedureTypeId
  procedureType
  procedureMod
  diagnosis
  equipment
  cptCodes
  bilaterally
  visitType
  room
  insuranceName
  policyName
  anesthesiologistId
  anesthesiologistHstId
  anesthesiologistName
  anesthesiaType
  allergy
  notes
  visit
  preferredLanguage
`;

export const fragments = {
  all: gql`
    fragment PatientWithProcedureData on Patient {
      id
      hstId
      name
      initials
      braceletId
      type
      notificationNumbers
      procedureType {
        ...ProcedureTypeData
      }
      physician {
        ...PhysicianData
      }
      room {
        id
        name
        type
      }
      status
      phoneNumber
      bed
      priority
      icon
      color
      createdAt
      changedRoomAt
      dischargedAt
      voidedAt
      note
      caretakerConsent
      caretakerMessages {
        phoneNumber
        content
      }
      caretakerThread {
        id
        hasUnreadMessages
      }
      patientThread {
        id
        hasUnreadMessages
      }
      procedure {
        ${procedureBaseFields}
        anesthesiologist {
          id
          name
        }
        entryQuestionnaire {
          ...Questionnaire
        }
        chartsAndAnswers {
          id
          questionnaire {
            id
            name
            icon
            type
            content
            order
          }
          questionnaireExchange {
            id
            procedureId
            answers
            submittedAt
          }
          completed
        }
        events {
          id
          type
          timestamp
          patientPin
          channel
          content
          user {
            id
            name
          }
          text
          status
          anesthesiaReviewed
          exchangeId
        }
      }
      preOpNote
      pacuNote
    }
    ${procedureTypeFragments.all}
    ${physicianFragments.all}
    ${proceduresFragments.questionnaire}
  `,
  list: gql`
    fragment PatientWithProcedureDataList on Patient {
      id
      hstId
      name
      procedureType {
        ...ProcedureTypeData
      }
      physician {
        ...PhysicianData
      }
      room {
        id
        name
        type
      }
      status
      createdAt
      changedRoomAt
      dischargedAt
      voidedAt
      procedure {
        id
        anesthesiologist {
          id
          name
        }
        anesthesiaType
        chartsAndAnswers {
          id
          completed
        }
      }
      preOpNote
      pacuNote
    }
    ${procedureTypeFragments.all}
    ${physicianFragments.all}
  `,
};

export const item = gql`
  subscription patient($id: Long!) {
    patient(id: $id) {
      ...PatientWithProcedureData
      log {
        id
        entries {
          type
          room {
            id
            name
            type
          }
          manualEntryBy {
            id
            name
          }
          status
        }
        timestamp
      }
    }
  }
  ${fragments.all}
`;

export const list = gql`
  subscription patients($filter: Filter) {
    patients: activeAndPastPatients(filter: $filter) {
      ...PatientWithProcedureDataList
    }
  }
  ${fragments.list}
`;

export default {
  list,
  item,
};

import { FormFulfillmentCell } from '../columns';
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const FormFulfillmentWrapper = styled.div`
  flex: 1;
  padding-right: 2rem;
  margin-right: 2rem;
`;

const QuestionnaireFulfilment = ({ formProgress }) => (
  <FormFulfillmentWrapper>
    <Box flex={1}>
      <Typography variant="body1" color="textSecondary">
        Form Fulfillment
      </Typography>
      <FormFulfillmentCell percentage={isFinite(formProgress) ? Math.round(formProgress * 100) : 0} />
    </Box>
  </FormFulfillmentWrapper>
);

export default QuestionnaireFulfilment;

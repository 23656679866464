import { isValid, parse } from 'date-fns';
import { createTheme, Theme, ThemeProvider } from '@material-ui/core/styles';
import {
  CheckboxesQuestion,
  ChecklistQuestion,
  DateQuestion,
  ImagesQuestion,
  ListQuestion,
  NotesQuestion,
  ObjectQuestion,
  Question,
  SelectQuestion,
  SignatureQuestion,
  TextQuestion,
  YesNoQuestion,
} from '../types/Question';
import {
  Answer,
  CheckboxesAnswer,
  ChecklistAnswer,
  DateAnswer,
  ImagesAnswer,
  ListAnswer,
  NotesAnswer,
  ObjectAnswer,
  SelectAnswer,
  SignatureAnswer,
  TextAnswer,
  YesCheckboxAnswerWithChildren,
  YesNoAnswer,
  YesNoAnswerWithChildren,
} from '../types/Answer';
import YesNoTextAnswer from './answers/YesNoTextAnswer';
import DateTextAnswer from './answers/DateText';
import TextInputAnswer from './answers/TextInputAnswer';
import React, {
  cloneElement,
  ComponentClass,
  ComponentType,
  createElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import ImagesPreview from './answers/ImagesPreview';
import YesNoInput from './questions/YesNoInput';
import TextInput from './questions/TextInput';
import DateInput from './questions/DateInput';
import CheckboxesInput from './questions/CheckboxesInput';
import SelectInput from './questions/SelectInput';
import ListInput from './questions/ListInput';
import groupBy from 'lodash/fp/groupBy';
import { Box } from '@material-ui/core';
import RadioGroupInput from './questions/RadioGroupInput';
import SignatureInput from './questions/SignatureInput';
import { ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Form.css';
import NotesInput from './questions/NotesInput';
import NotesInputAnswer from './answers/NotesInputAnswer';
import Button from '@material-ui/core/Button';
import { useSave } from '../components/pages/kiosk/charting/SaveProvider';
import isFunction from 'lodash/isFunction';
import ChecklistInput from './questions/ChecklistInput';
import { ArrowBack, ArrowForwardOutlined } from '@material-ui/icons';
import classes from './Form.module.css';
import SlideProvider, { useSlide } from '../components/pages/kiosk/charting/SlideProvider';
import SliderProvider, { useSlider } from '../components/pages/kiosk/charting/SliderProvider';
import produce from 'immer';

export type ObjectQuestionChildren<T, Q extends ObjectQuestion> = { [key in keyof Q['props']['fields']]: T };
export type YesNoQuestionChildren<T> = { child: T | null };

export type Tags = {
  [key: string]: string;
};

type FormRendererSpec<T> = {
  renderObject<Q extends ObjectQuestion>(
    children: ObjectQuestionChildren<T, Q>,
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderList<Q extends ListQuestion>(
    children: ReactNode[] | undefined,
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderYesNo<Q extends YesNoQuestion>(
    children: YesNoQuestionChildren<T>,
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderText<Q extends TextQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderNotes<Q extends NotesQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderCheckboxes<Q extends CheckboxesQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderSelect<Q extends SelectQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderDate<Q extends DateQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderImages<Q extends ImagesQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderSignature<Q extends SignatureQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
  renderChecklist<Q extends ChecklistQuestion>(
    tags: Tags,
    question: Q['props'],
    answer?: Answer<Q>,
    onChange?: (newAnswer: Answer<Q>) => void
  ): T;
};

type FormRenderer<T, Q extends Question> = (
  tags: Tags,
  question?: Q,
  answer?: Answer<Q>,
  onChange?: (newAnswer: Answer<Q> | ((prev: Answer<Q>) => Answer<Q>)) => void
) => T;
type FormRendererProvider<T> = (spec: FormRendererSpec<T>) => FormRenderer<T, Question>;

const provideFormRenderer: FormRendererProvider<ReactNode> = spec => (tags, question, answer, onChange) => {
  if (question) {
    switch (question.type) {
      case 'Object': {
        const children = Object.entries(question.props.fields).reduce((res, curr) => {
          const [name, child] = curr;
          return {
            ...res,
            [name]: provideFormRenderer(spec)(
              tags,
              child,
              answer ? (answer as ObjectAnswer<ObjectQuestion>)[name] : undefined,
              newAnswer => {
                if (question?.props?.slides) {
                  onChange?.((prev: object) => (prev ? { ...prev, [name]: newAnswer } : { [name]: newAnswer }));
                } else {
                  onChange?.(answer ? { ...answer, [name]: newAnswer } : { [name]: newAnswer });
                }
              }
            ),
          };
        }, {});
        return spec.renderObject(children, tags, question.props, answer as ObjectAnswer<ObjectQuestion>);
      }
      case 'List': {
        const children = !!answer
          ? (answer as ObjectAnswer<ObjectQuestion>[]).map((answerItem, index) =>
              provideFormRenderer(spec)(tags, question.props.listItem, answerItem ? answerItem : undefined, newAnswer =>
                onChange?.(
                  (answer as ObjectAnswer<ObjectQuestion>[]).map((e, eIndex) => (eIndex === index ? newAnswer : e))
                )
              )
            )
          : undefined;
        return spec.renderList(children, tags, question.props, answer as ListAnswer, onChange);
      }
      case 'Text':
        return spec.renderText(tags, question['props'], answer as TextAnswer, onChange);
      case 'Notes':
        return spec.renderNotes(tags, question['props'], answer as NotesAnswer, onChange);
      case 'Checkboxes': {
        const checkboxesWithChild = question.props.checkboxes.map(checkbox => ({
          ...checkbox,
          child:
            checkbox?.ifYes &&
            provideFormRenderer(spec)(
              tags,
              checkbox?.ifYes,
              answer ? (answer[checkbox.name] as YesCheckboxAnswerWithChildren)?.ifYes : undefined,
              newAnswer => {
                onChange?.(
                  answer
                    ? { ...(answer as object), [checkbox.name]: { value: true, ifYes: newAnswer } }
                    : { [checkbox.name]: { value: true, ifYes: newAnswer } }
                );
              }
            ),
        }));

        return spec.renderCheckboxes(
          tags,
          { ...question['props'], checkboxes: checkboxesWithChild },
          answer as CheckboxesAnswer,
          onChange
        );
      }
      case 'Select':
        return spec.renderSelect(tags, question['props'], answer as SelectAnswer, onChange);
      case 'YesNo':
        const children = {
          child:
            question['props'].ifYes && (answer === true || (answer as YesNoAnswerWithChildren)?.value === true)
              ? provideFormRenderer(spec)(
                  tags,
                  question['props'].ifYes,
                  answer ? (answer as YesNoAnswerWithChildren).ifYes : undefined,
                  newAnswer => {
                    onChange?.(answer ? { ...(answer as object), ifYes: newAnswer } : { ifYes: newAnswer });
                  }
                )
              : question['props'].ifNo && (answer === false || (answer as YesNoAnswerWithChildren)?.value === false)
              ? provideFormRenderer(spec)(
                  tags,
                  question['props'].ifNo,
                  answer ? (answer as YesNoAnswerWithChildren).ifNo : undefined,
                  newAnswer =>
                    onChange?.(
                      answer
                        ? {
                            ...(answer as object),
                            ifNo: newAnswer,
                          }
                        : { ifNo: newAnswer }
                    )
                )
              : null,
        };
        const onYesNoChange =
          question['props'].ifYes || question['props'].ifNo
            ? (newAnswer: YesNoAnswer) =>
                onChange?.(answer ? { ...(answer as object), value: newAnswer } : { value: newAnswer })
            : onChange;
        return spec.renderYesNo(children, tags, question['props'], answer as YesNoAnswer, onYesNoChange);
      case 'Date':
        return spec.renderDate(tags, question['props'], answer as DateAnswer, onChange);
      case 'Images':
        return spec.renderImages(tags, question['props'], answer as ImagesAnswer, onChange);
      case 'Signature':
        return spec.renderSignature(tags, question['props'], answer as SignatureAnswer, onChange);
      case 'Checklist':
        return spec.renderChecklist(tags, question['props'], answer as ChecklistAnswer, onChange);
    }
  }
  return undefined;
};

export function useSlideIn(): () => void {
  const slider = useSlider();
  const slide = useSlide();

  const slideRef = useRef(slide);

  useEffect(() => {
    slideRef.current = slide;
  }, [slide]);

  return useCallback(() => {
    const s = slideRef.current;

    if (slider !== null && s !== null) {
      slider.slideTo(s);
    }
  }, [slideRef, slider]);
}

export const renderEditableFormUI = provideFormRenderer({
  renderDate(
    tags: Tags,
    question: DateQuestion['props'],
    answer: Answer<DateQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <DateInput {...question} answer={answer} onChange={onChange as (newAnswer: DateAnswer) => void} />
    ) : null;
  },
  renderText(
    tags: Tags,
    question: TextQuestion['props'],
    answer: Answer<TextQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <TextInput {...question} answer={answer} onChange={onChange as (newAnswer: TextAnswer) => void} />
    ) : null;
  },
  renderNotes(
    tags: Tags,
    question: NotesQuestion['props'],
    answer: Answer<NotesQuestion> | undefined,
    onChange
  ): ReactNode {
    return <NotesInput {...question} answer={answer} onChange={onChange as (newAnswer: TextAnswer) => void} />;
  },
  renderCheckboxes(
    tags: Tags,
    question: CheckboxesQuestion['props'],
    answer: Answer<CheckboxesQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <CheckboxesInput {...question} answer={answer} onChange={onChange as (newAnswer: CheckboxesAnswer) => void} />
    ) : null;
  },
  renderSelect(
    tags: Tags,
    question: SelectQuestion['props'],
    answer: Answer<SelectQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    const size = question.options.length * 3 + question.options.reduce((acc, option) => acc + option.label.length, 0);
    return render ? (
      size < 120 ? (
        <RadioGroupInput {...question} answer={answer} onChange={onChange as (newAnswer: SelectAnswer) => void} />
      ) : (
        <SelectInput {...question} answer={answer} onChange={onChange as (newAnswer: SelectAnswer) => void} />
      )
    ) : null;
  },
  renderYesNo(
    children,
    tags: Tags,
    question: YesNoQuestion['props'],
    answer: Answer<YesNoQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <YesNoInput {...children} {...question} answer={answer} onChange={onChange as (newAnswer: YesNoAnswer) => void} />
    ) : null;
  },
  renderObject(
    children,
    tags: Tags,
    question: ObjectQuestion['props'],
    answer: Answer<ObjectQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;

    const renderFields = (fieldNames?: string[]) =>
      question.columns ? (
        <Box display="flex" style={{ gap: 32 }} p={4}>
          {Object.entries(
            groupBy(1)(
              Object.entries(question.columns).filter(([fieldName]) =>
                fieldNames ? fieldNames.indexOf(fieldName) >= 0 : true
              )
            )
          )
            .map(entry => entry as unknown as [string, [string, number][]])
            .map(([key, fields]) => (
              <Box key={key}>
                {fields
                  .map(([fieldName]) => [fieldName, children[fieldName]] as const)
                  .filter(([, child]) => !!child)
                  .map(([name, child]) => (
                    <div key={name} style={(question as any).inline ? { flex: 1 } : undefined}>
                      {cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}
                    </div>
                  ))}
              </Box>
            ))}
        </Box>
      ) : (
        <Box p={4}>
          {Object.entries(children)
            .map(([fieldName]) => [fieldName, children[fieldName]] as const)
            .filter(([, child]) => !!child)
            .map(([name, child], index) => (
              <div key={index} style={(question as any).inline ? { flex: 1 } : undefined}>
                {cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}
              </div>
            ))}
        </Box>
      );

    return render ? (
      <ThemeProvider
        theme={(theme: Theme) =>
          createTheme({
            ...theme,
            typography: {
              fontSize: 20,
            },
          })
        }
      >
        {question.slides ? (
          <Slides
            slides={Object.entries(groupBy(1)(Object.entries(question.slides))).map(
              entry => entry as unknown as [string, [string, number][]]
            )}
            renderFields={renderFields}
          />
        ) : (
          renderFields()
        )}
      </ThemeProvider>
    ) : null;
  },
  renderList(
    children,
    tags: Tags,
    question: ListQuestion['props'],
    answer: Answer<ListQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <ListInput
        {...question}
        answer={answer}
        onChange={onChange as (newAnswer: ListAnswer) => void}
        children={children}
      />
    ) : null;
  },
  // TODO Jelena: check this
  renderImages(): ReactNode {
    // const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    // return render ? <ImagesInput {...question} answer={answer} onChange={onChange as (newAnswer: ImagesAnswer) => void} /> : null;
    return null;
  },
  // TODO Jelena: check this
  renderSignature(
    tags: Tags,
    question: SignatureQuestion['props'],
    answer: Answer<SignatureQuestion> | undefined,
    onChange
  ): ReactNode {
    return (
      <SignatureInput
        label={question?.label}
        answer={answer}
        onChange={onChange as (newAnswer: SignatureAnswer) => void}
      />
    );
  },
  renderChecklist(
    tags: Tags,
    question: ChecklistQuestion['props'],
    answer: Answer<ChecklistQuestion> | undefined,
    onChange
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <ChecklistInput {...question} answer={answer} onChange={onChange as (newAnswer: ChecklistAnswer) => void} />
    ) : null;
  },
});

export const renderFormAnswer = provideFormRenderer({
  renderDate(tags: Tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <DateTextAnswer {...question} answer={answer} /> : null;
  },
  renderText(tags: Tags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={answer} /> : null;
  },
  renderNotes(tags: Tags, question: NotesQuestion['props'], answer: Answer<NotesQuestion> | undefined): ReactNode {
    return <NotesInputAnswer {...question} answer={answer} />;
  },
  renderCheckboxes(
    tags: Tags,
    question: CheckboxesQuestion['props'],
    answer: Answer<CheckboxesQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={Object.values(answer || {}).join(',')} /> : null;
  },
  renderSelect(tags: Tags, question: SelectQuestion['props'], answer: Answer<SelectQuestion> | undefined): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={answer?.label || 'sdfsdf'} /> : null;
  },
  renderYesNo(
    children,
    tags: Tags,
    question: YesNoQuestion['props'],
    answer: Answer<YesNoQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <YesNoTextAnswer {...children} {...question} answer={answer} /> : null;
  },
  renderList(): ReactNode {
    return null;
  },
  renderObject(
    children,
    tags: Tags,
    question: ObjectQuestion['props'],
    answer: Answer<ObjectQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? (
      <div>
        {Object.entries(children)
          .filter(([child]) => !!child)
          .map(([name, child], index) => (
            <div key={index}>{cloneElement(child as any, { name, answer: answer ? answer[name] : undefined })}</div>
          ))}
      </div>
    ) : null;
  },
  renderImages(tags: Tags, question: ImagesQuestion['props'], answer?: Answer<ImagesQuestion> | undefined): ReactNode {
    return <ImagesPreview label={question.label} fileNames={answer} />;
  },
  renderSignature(
    tags: Tags,
    question: SignatureQuestion['props'],
    answer?: Answer<SignatureQuestion> | undefined
  ): ReactNode {
    return <img src={answer} alt="signature" />;
  },
  renderChecklist(
    tags: Tags,
    question: ChecklistQuestion['props'],
    answer?: Answer<ChecklistQuestion> | undefined
  ): ReactNode {
    const render = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
    return render ? <TextInputAnswer {...question} answer={Object.values(answer || {}).join(',')} /> : null;
  },
});

const provideFormStatusRenderer: FormRendererProvider<boolean> =
  spec =>
  (tags, question, answer): boolean => {
    if (question) {
      switch (question.type) {
        case 'Object': {
          const children = Object.entries(question.props.fields).reduce((res, curr) => {
            const [name, child] = curr;
            return {
              ...res,
              [name]: provideFormStatusRenderer(spec)(
                tags,
                child,
                answer ? (answer as ObjectAnswer<ObjectQuestion>)[name] : undefined
              ),
            };
          }, {});
          return spec.renderObject(children, tags, question.props, answer as ObjectAnswer<ObjectQuestion>);
        }
        case 'List': {
          const children = !!answer
            ? (answer as ObjectAnswer<ObjectQuestion>[]).map(answerItem =>
                provideFormStatusRenderer(spec)(tags, question.props.listItem, answerItem ? answerItem : undefined)
              )
            : undefined;
          return spec.renderList(children, tags, question['props'], answer as ListAnswer);
        }
        case 'Text':
          return spec.renderText(tags, question['props'], answer as TextAnswer);
        case 'Notes':
          return spec.renderNotes(tags, question['props'], answer as NotesAnswer);
        case 'Checkboxes':
          return spec.renderCheckboxes(tags, question['props'], answer as CheckboxesAnswer);
        case 'Select':
          return spec.renderSelect(tags, question['props'], answer as SelectAnswer);
        case 'YesNo':
          const children = {
            child:
              question['props'].ifYes && (answer === true || (answer as YesNoAnswerWithChildren)?.value === true)
                ? provideFormStatusRenderer(spec)(
                    tags,
                    question['props'].ifYes,
                    answer ? (answer as YesNoAnswerWithChildren).ifYes : undefined
                  )
                : question['props'].ifNo && (answer === false || (answer as YesNoAnswerWithChildren)?.value === false)
                ? provideFormStatusRenderer(spec)(
                    tags,
                    question['props'].ifNo,
                    answer ? (answer as YesNoAnswerWithChildren).ifNo : undefined
                  )
                : null,
          };
          return spec.renderYesNo(children, tags, question['props'], answer as YesNoAnswer);
        case 'Date':
          return spec.renderDate(tags, question['props'], answer as DateAnswer);
        case 'Images':
          return spec.renderImages(tags, question['props'], answer as ImagesAnswer);
        case 'Signature':
          return spec.renderSignature(tags, question['props'], answer as SignatureAnswer);
        case 'Checklist':
          return spec.renderChecklist(tags, question['props'], answer as ChecklistAnswer);
        default:
          return false;
      }
    }
    return false;
  };

export const isPass = provideFormStatusRenderer({
  renderDate(): boolean {
    return true;
  },
  renderText(): boolean {
    return true;
  },
  renderNotes(): boolean {
    return true;
  },
  renderCheckboxes(): boolean {
    return true;
  },
  renderSelect(): boolean {
    return true;
  },
  renderYesNo(children, tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined): boolean {
    if (answer === undefined) return true;
    if (isBoolean(answer) && isBoolean(question.isClear)) {
      return answer === question.isClear;
    } else if (isBoolean((answer as YesNoAnswerWithChildren)?.value) && isBoolean(question.isClear)) {
      return (answer as YesNoAnswerWithChildren)?.value === question.isClear;
    } else {
      return true;
    }
  },
  renderImages(): boolean {
    return true;
  },
  renderList(): boolean {
    return true;
  },
  renderObject(children): boolean {
    return Object.entries(children).reduce((res: boolean, e: [string, boolean]) => {
      const [, child] = e;
      return child && res;
    }, true);
  },
  renderSignature(): boolean {
    return true;
  },
  renderChecklist(): boolean {
    return true;
  },
});

export const isCompleted = provideFormStatusRenderer({
  renderDate(tags, question: DateQuestion['props'], answer: Answer<DateQuestion> | undefined): boolean {
    return question?.optional ? true : answer === '******' || isValid(parse(answer as string));
  },
  renderText(tags, question: TextQuestion['props'], answer: Answer<TextQuestion> | undefined): boolean {
    return question?.optional ? true : answer ? answer?.trim() !== '' : false;
  },
  renderNotes(): boolean {
    return true;
  },
  renderList(): boolean {
    return true;
  },
  renderCheckboxes(
    tags,
    question: CheckboxesQuestion['props'],
    answer: Answer<CheckboxesQuestion> | undefined
  ): boolean {
    return question?.optional ? true : !!answer ? Object.values(answer).some(e => !!e) : false;
  },
  renderSelect(tags, question: SelectQuestion['props'], answer: Answer<SelectQuestion> | undefined): boolean {
    return question?.optional ? true : !!answer;
  },
  renderYesNo(children, tags, question: YesNoQuestion['props'], answer: Answer<YesNoQuestion> | undefined): boolean {
    if (question?.optional) {
      return true;
    } else {
      const include = question?.ifTags ? isEqual(question?.ifTags, tags) : true;
      if (include) {
        if (isBoolean(answer)) {
          return true;
        } else {
          if (isBoolean((answer as YesNoAnswerWithChildren)?.value)) {
            return (answer as YesNoAnswerWithChildren).value
              ? question?.ifYes
                ? !!children.child
                : true
              : question?.ifNo
              ? !!children.child
              : true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    }
  },
  renderObject(children, tags, question: ObjectQuestion['props']): boolean {
    if (question?.optional) {
      return true;
    } else {
      const answerKeys = children ? Object.keys(children) : [];
      return Object.keys(question.fields).every(e => answerKeys.includes(e)) && Object.values(children).every(e => e);
    }
  },
  renderImages<Q extends ImagesQuestion>(tags: Tags, question: Q['props'], answer?: ImagesAnswer): boolean {
    return question?.optional ? true : (answer?.length ?? 0) > 0;
  },
  renderSignature(tags: Tags, question: SignatureQuestion['props'], answer?: SignatureAnswer): boolean {
    return answer ? answer?.length > 0 : false;
  },
  renderChecklist(tags: Tags, question: ChecklistQuestion['props'], answer?: ChecklistAnswer): boolean {
    return question?.optional ? true : !!answer ? Object.values(answer).every(e => !!e) : false;
  },
});

interface SlidesProps {
  slides: [string, [string, number][]][];
  renderFields: (fieldNames?: string[]) => ReactNode;
}

const Slides = ({ slides, renderFields }: SlidesProps) => {
  const rootRef = useRef<HTMLDivElement | undefined>();

  const [rootSize, setRootSize] = useState<{ width: number; height: number } | undefined>();

  useEffect(() => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    setRootSize({ width: root.clientWidth, height: root.clientHeight });

    const observer = new ResizeObserver(() => {
      setRootSize({ width: root.clientWidth, height: root.clientHeight });
    });

    observer.observe(root);

    return () => {
      observer.unobserve(root);
      observer.disconnect();
    };
  }, []);

  const { forceSave, save } = useSave();

  return (
    <CarouselProvider
      naturalSlideWidth={rootSize?.width ?? 0}
      naturalSlideHeight={rootSize?.height ?? 0}
      totalSlides={slides.length}
      dragEnabled={false}
    >
      <Box display="flex" justifyContent="Center" p={4}>
        <DotGroup />
      </Box>
      <Box {...({ ref: rootRef } as any)} flex="1" position="relative">
        <Box position="absolute" top={0} bottom={0} left={0} right={0}>
          {rootSize && (
            <SliderProvider>
              <Slider>
                {slides.map(([key, fields], i) => (
                  <Slide
                    key={key}
                    index={i}
                    className={classes.slide}
                    classNameHidden={classes.slideHidden}
                    classNameVisible={classes.slideVisible}
                  >
                    <Box width={rootSize?.width} display="flex" justifyContent="center" zIndex={9000}>
                      <SlideProvider slide={i}>{renderFields(fields.map(f => f[0]))}</SlideProvider>
                    </Box>
                  </Slide>
                ))}
              </Slider>
            </SliderProvider>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" className="carousel__buttons" p={4}>
        <SlideButton startIcon={<ArrowBack />} as={ButtonBack}>
          Previous
        </SlideButton>
        <SlideButton endIcon={<ArrowForwardOutlined />} as={ButtonNext} onDisabledClick={save}>
          {disabled => (disabled ? (forceSave ? 'Save Anyway' : 'Save') : 'Next')}
        </SlideButton>
      </Box>
    </CarouselProvider>
  );
};

interface SlideButtonProps {
  as: ComponentType<any> | ComponentClass<any>;
  onDisabledClick?: () => void;
  children: ReactNode | ((disabled: boolean) => ReactNode);
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

const SlideButton = ({ as, startIcon, endIcon, onDisabledClick, children }: SlideButtonProps) => {
  const rootRef = useRef<HTMLDivElement>();

  const handleClick = () => {
    if (disabled) {
      onDisabledClick?.();
      return;
    }

    const root = rootRef.current;

    if (!root) {
      return;
    }

    const button = root.querySelector('button');

    if (!button) {
      return;
    }

    button.click();
  };

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    const button = root.querySelector('button');

    if (!button) {
      return;
    }

    setDisabled(button.disabled);

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'attributes') {
          setDisabled(button.disabled);
        }
      });
    });

    observer.observe(button, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={rootRef as Ref<HTMLDivElement>}
        style={{ position: 'absolute', width: 0, height: 0, visibility: 'hidden' }}
      >
        {createElement(as, { children })}
      </div>
      <Button
        variant={onDisabledClick && disabled ? 'contained' : 'outlined'}
        color="primary"
        onClick={handleClick}
        disabled={disabled && !onDisabledClick}
        startIcon={startIcon}
        endIcon={!disabled && endIcon}
      >
        {isFunction(children) ? children(disabled) : children}
      </Button>
    </div>
  );
};

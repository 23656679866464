import React, { createContext, Ref, RefObject, useContext, useRef } from 'react';
import { ReactNode } from 'react';
import classes from './NotesContextProvider.module.css';

interface NotesContext {
  rootRef: RefObject<HTMLDivElement>;
}

const notesContext = createContext<NotesContext | null>(null);

export const useNotesContext = (): NotesContext => {
  const notes = useContext(notesContext);

  if (notes === null) {
    throw new Error('NotesContextProvider not found');
  }

  return notes;
};

interface NotesContextProviderProps {
  children: ReactNode;
}

const NotesContextProvider = ({ children }: NotesContextProviderProps) => {
  const rootRef = useRef<HTMLDivElement>();

  return (
    <notesContext.Provider value={{ rootRef: rootRef as RefObject<HTMLDivElement> }}>
      {children}
      <div ref={rootRef as Ref<HTMLDivElement>} className={classes.root} />
    </notesContext.Provider>
  );
};

export default NotesContextProvider;

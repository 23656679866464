import React from 'react';
import styled, { withTheme } from 'styled-components';
import { branch, compose, renderComponent, withProps, withState } from 'recompose';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { keyBy } from 'lodash';
import withSize from '../../../hocs/withSize';
import Color from 'color';

const GraphContainer = styled.div`
  align-self: stretch;
`;

const Graph = ({
  value,
  unit,
  theme,
  width,
  height,
  label,
  labelColor = undefined,
  labels,
  colors,
  setMouse,
  mouse,
  ratio,
}) => (
  <GraphContainer onMouseEnter={_ => setMouse({ x: _.clientX, y: _.clientY })}>
    <BarChart width={width} height={height || Math.floor(width * (ratio || 0.75))} data={value} margin={{ left: -30 }}>
      <YAxis
        tick={{
          stroke: !!labelColor ? labelColor : colors.stroke.string(),
          fill: !!labelColor ? labelColor : colors.stroke.string(),
          fontSize: '0.7em',
          strokeWidth: '0.1',
        }}
        axisLine={false}
        tickLine={false}
      />
      <CartesianGrid stroke={colors.stroke.alpha(0.2).string()} strokeArray="1 1" />
      <XAxis
        dataKey="date"
        tickFormatter={p => (labels[p] ? labels[p].name : '')}
        tick={{
          stroke: !!labelColor ? labelColor : colors.stroke.string(),
          fill: !!labelColor ? labelColor : colors.stroke.string(),
          fontSize: '0.8em',
          strokeWidth: '0.1',
        }}
        tickLine={false}
        axisLine={false}
      />
      {window.innerWidth > 500 && (
        <Tooltip
          separator=": "
          cursor={{ fill: colors.bar.darken(0.6).string() }}
          labelStyle={{ color: 'black' }}
          itemStyle={{ color: !!labelColor ? 'black' : theme.backgroundColor.string() }}
          wrapperStyle={{ zIndex: 1000, position: 'fixed', x: `${mouse.x}px`, top: `${mouse.y / 1.5}px` }}
          formatter={x => x}
        />
      )}
      <Bar
        name={label}
        unit={unit}
        dataKey="value"
        barSize={(width / value.length / 3) * 2}
        fill={colors.bar.string()}
      />
    </BarChart>
  </GraphContainer>
);

export const nurseColor = '#00a4fa';

export const colors = ({ theme }) => ({
  colors: {
    stroke: theme.border.color.default.lighten(0.4),
    bar: Color(nurseColor),
  },
});

const Distribution = compose(
  withTheme,
  withSize,
  withProps(colors),
  withState('mouse', 'setMouse', { x: 0, y: 0 }),
  branch(props => !Number.isFinite(props.width), renderComponent(GraphContainer))
)(Graph);

export default ({ distribution = [], unit = '', label, tooltipLabel, ratio, color }) => {
  const labels = keyBy(distribution, 'date');

  return (
    <Distribution
      value={distribution.length > 0 ? distribution : []}
      unit={unit}
      label={tooltipLabel || label}
      labels={labels}
      ratio={ratio}
      labelColor={color}
    />
  );
};

import React from 'react';
import update from 'lodash/update';
import form from '../../../graph/surgeon/forms';
import { CenteredSpinner } from '../../../se/components/Spinner';
import isString from 'lodash/isString';
import FormContent from './FormContent';
import { useSubscription } from '@apollo/client';

const tryParseJson = (data: any) => {
  if (isString(data)) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  } else {
    return data;
  }
};

const PreOpForm = ({ id, procedureId, lastPreOpCompletedEvent }: any) => {
  const { data, loading } = useSubscription(form.getSubscription, {
    variables: { id, procedureId },
  });

  const formData = update(
    update(data?.form || {}, 'entryQuestionnaire.questions', tryParseJson),
    'entryQuestionnaire.answers',
    tryParseJson
  );

  return !loading ? (
    <FormContent
      {...formData}
      procedureId={procedureId}
      lastPreOpCompletedEvent={lastPreOpCompletedEvent}
      withActions
    />
  ) : (
    <CenteredSpinner size={undefined} strokeAccessor={undefined} />
  );
};

export default PreOpForm;

import { useMutation, useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import { notifyReadyForSurgeon } from '../../../graph/patients';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '../../Tooltip';
import React from 'react';
import { fontSizeIconSide, SideOption } from './pacuAndPrep/PatientOptions';
import { useTheme } from '@material-ui/core/styles';
import { getLogEntries, isPatientReadyForSurgeonNotifyFn } from './tablet/utils';
import Physician from '../../../types/Physician';
import { Patient } from '../../../types/Patient';

export const NotifyButton = ({
  notifiedLabel,
  notNotifiedLabel,
  isNotified,
  notifyEnabled,
  notify,
}: {
  notifiedLabel: string;
  notNotifiedLabel: string;
  isNotified: boolean;
  notify: () => Promise<void>;
  notifyEnabled: boolean;
}) => {
  const theme = useTheme();
  return (
    <SideOption
      smsIndicator={false}
      icon={
        isNotified ? (
          <CheckCircleIcon
            style={{
              fontSize: fontSizeIconSide,
              color: theme.palette.success.main,
            }}
          />
        ) : (
          <NotificationsIcon
            color={!notifyEnabled ? 'disabled' : 'primary'}
            style={{
              fontSize: fontSizeIconSide,
            }}
          />
        )
      }
      label={isNotified ? notifiedLabel : notNotifiedLabel}
      disabled={!notifyEnabled}
      onClick={notify}
    />
  );
};

const PhysicianNotifyButton = ({ patient, physician }: { patient?: Patient; physician?: Physician }) => {
  const patientLogs = getLogEntries(patient);
  const { data: template } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ReadyForSurgeonNotify' },
  });

  const [notifyPhysician] = useMutation(notifyReadyForSurgeon, {
    variables: {
      id: patient?.id,
    },
  });

  const notify = async () => {
    await notifyPhysician();
  };

  const isSurgeonNotified = isPatientReadyForSurgeonNotifyFn(patientLogs);
  const readyForSurgeonNotification = template?.getNotificationTemplate?.[0] || null;
  const shouldSendNotification = physician?.shouldSendNotification || null;
  const hasPhoneNumber = physician?.notificationNumbers || null;

  const notifyPhysicianEnabled = readyForSurgeonNotification?.enabled && hasPhoneNumber && shouldSendNotification;
  const notifyPhysicianTooltip = !readyForSurgeonNotification?.enabled
    ? 'Physician messaging has been turned off. Go to message configuration to enable it.'
    : !shouldSendNotification
    ? "Physician messaging has been turned off. Physician doesn't want to receive notification."
    : !hasPhoneNumber
    ? 'Physician does not have a phone number.'
    : null;

  return notifyPhysicianTooltip ? (
    <Tooltip delay={0} content={notifyPhysicianTooltip}>
      <NotifyButton
        isNotified={isSurgeonNotified}
        notifyEnabled={notifyPhysicianEnabled}
        notNotifiedLabel={'Notify Physician'}
        notifiedLabel={'Physician Notified'}
        notify={notify}
      />
    </Tooltip>
  ) : (
    <NotifyButton
      isNotified={isSurgeonNotified}
      notifyEnabled={notifyPhysicianEnabled}
      notNotifiedLabel={'Notify Physician'}
      notifiedLabel={'Physician Notified'}
      notify={notify}
    />
  );
};

export default PhysicianNotifyButton;

import { Calendar } from 'react-date-range';
import { format, parse } from 'date-fns';
import React from 'react';
import Button from '../Button';
import Dropdown from '../../../components/core/Dropdown';
import styled from 'styled-components';
import TextInput from './TextInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  > * + * {
    margin-top: 1em;
  }
`;

const DropdownInput = ({
  Element,
  onChange,
  placeholder,
  formatter,
  shouldConfirm,
  disabled,
  autocomplete,
  required,
  ...rest
}) => {
  const dropdownContent = ({ close }) => {
    const handleChange = value => {
      onChange(value);
      if (!shouldConfirm) {
        close();
      }
    };

    return props => (
      <Container>
        <Element {...props} onChange={handleChange} />
        {shouldConfirm && <Button onClick={() => close()}>Ok</Button>}
      </Container>
    );
  };
  return (
    <Dropdown content={dropdownContent} contentProps={rest} style={{ display: 'inline-flex' }} disabled={disabled}>
      <TextInput
        onChange={() => {}}
        disabled={disabled}
        value={rest.value ? (formatter ? formatter(rest.value) : rest.value) : undefined}
        placeholder={placeholder}
        required={required}
        autocomplete={autocomplete}
      />
    </Dropdown>
  );
};

const DateInput = ({ value, onChange, ...props }) => (
  <Calendar date={value ? parse(value) : new Date()} onChange={val => onChange(format(val, 'YYYY-MM-DD'))} {...props} />
);

const DateDropdownInput = ({ value, disabled, onChange, required = false }) => (
  <DropdownInput
    required={required}
    value={value}
    Element={DateInput}
    onChange={onChange}
    formatter={val => format(parse(val), 'D MMMM YYYY')}
    placeholder={'Click to edit date'}
    disabled={disabled}
    autocomplete={false}
  />
);

export default DateDropdownInput;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Icon } from '../../Schedule';
import { ErrorLabel } from '../Time';
import AnesthesiaType from './AnesthesiaType';
import AnesthesiologistName from './AnesthesiologistName';

const PersonnelWrapper = styled.div<{ hasError?: boolean; condensView?: boolean }>`
  font-weight: 400;
  font-size: 1.333333em;
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: ${props => (props.hasError ? 'rgba(255, 137, 0, 0.25)' : 'rgba(255, 255, 255, 0.1)')};
  margin-bottom: 1px;
  padding: ${props => (props.condensView ? '0.375em 0 0.375em 0.375em;' : '0.375em;')};
`;

const PhysicianWrapper = styled.div<{ inline?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: ${props => (props.inline ? '0 1 auto' : '1 1 auto')};
  flex: 1;
  margin-right: 0.375em;
  overflow: visible;
`;

const PhysicianName = styled.div<{ hasError?: boolean }>`
  display: ${props => (props.hasError ? 'flex' : 'initial')};
  align-items: center;
  white-space: nowrap;
  color: ${props => (props.hasError ? '#FF8900' : 'inherit')};
  line-height: 1.0625em;
  letter-spacing: -0.015em;
  span {
    font-size: 0.75em;
  }
`;

export const PhysicianIndicator = styled.div<{ color?: string }>`
  width: 0.4em;
  height: 0.4em;
  border-radius: 0.75em;
  background-color: ${props => (props.color ? props.color : 'rgba(255,255,255,.1)')};
  margin-right: 0.3em;
  flex-shrink: 0;
`;

const Anes = styled.div<{ inline?: boolean; condensView?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: ${props => (props.inline ? '0 1 auto' : '1 0 auto')};
  justify-content: ${props => (props.condensView ? 'space-between' : 'flex-end')};
  background-color: ${props => (props.condensView ? 'rgba(255,255,255,0.1)' : undefined)};
  padding: ${props => (props.condensView ? '0px 5px 0px 3px;' : undefined)};
  margin-bottom: ${props => (props.condensView ? '0' : undefined)};
  font-size: ${props => (props.condensView ? '1.33em' : 'inherit')};
  line-height: inherit;
`;

export const Physician = ({
  color,
  physicianName,
  inline,
}: {
  color: string;
  physicianName?: string;
  inline?: boolean;
}) => (
  <PhysicianWrapper inline>
    {physicianName && <PhysicianIndicator color={color} />}
    <PhysicianName hasError={!physicianName}>
      {physicianName ? (
        physicianName
      ) : (
        <Fragment>
          <Icon style={{ color: '#FF8900', marginRight: '.25em' }}>error_outline</Icon>{' '}
          <ErrorLabel>Physician Unassigned</ErrorLabel>
        </Fragment>
      )}
    </PhysicianName>
  </PhysicianWrapper>
);

export const Anesthesiologist = ({
  date,
  procedureId,
  editableAnesthesiologist,
  anesthesiologistName,
  anesthesiaType,
  inline,
  style,
  condensView,
}: {
  date?: Date;
  procedureId: number;
  editableAnesthesiologist?: boolean;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  inline?: boolean;
  condensView?: boolean;
  style?: React.CSSProperties;
}) => (
  <Anes condensView={condensView} inline={inline} style={style} onClick={e => e.stopPropagation()}>
    <AnesthesiologistName
      date={date}
      procedureId={procedureId}
      editableAnesthesiologist={editableAnesthesiologist}
      anesthesiologistName={anesthesiologistName}
      condensView={condensView}
    />
    <AnesthesiaType
      procedureId={procedureId}
      editableAnesthesiologist={editableAnesthesiologist}
      anesthesiaType={anesthesiaType}
    />
  </Anes>
);

export const Personnel = ({
  date,
  procedureId,
  color,
  physicianName,
  editableAnesthesiologist,
  anesthesiologistName,
  anesthesiaType,
  style,
  condensView,
}: {
  date: Date;
  procedureId: number;
  color: string;
  editableAnesthesiologist: boolean;
  anesthesiologistName?: string;
  physicianName: string;
  anesthesiaType: string;
  condensView?: boolean;
  style: React.CSSProperties;
}) => (
  <PersonnelWrapper hasError={!physicianName} style={style} condensView={condensView}>
    <Physician color={color} physicianName={physicianName} />
    {!condensView && (
      <Anesthesiologist
        date={date}
        procedureId={procedureId}
        editableAnesthesiologist={editableAnesthesiologist}
        anesthesiologistName={anesthesiologistName}
        anesthesiaType={anesthesiaType}
      />
    )}
  </PersonnelWrapper>
);

import React, { FC, useEffect, useMemo, useState, VFC } from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import graphqlSelectInput from '../../inputs/graphqlSelectInput';
import {
  addStaffAvailability,
  list,
  listOthersPTOs,
  listStaffAvailability,
  removeStaffAvailability,
} from '../../../graph/staff';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import StaffAvailabilityEditor from './StaffAvailabilityEditor';
import { RouteComponentProps } from 'react-router';
import { useMutation, useSubscription } from '@apollo/client';
import { format } from 'date-fns';
import { StaffAvailability } from '../../../types/StaffAvailability';
import CalendarYear from './CalendarYear';
import { Virtuoso } from 'react-virtuoso';
import ImmutableSet from './types/ImmutableSet';
import formatTimeRange from './util/formatTimeRange';
import classNames from 'clsx';
import GroupedByDate from './types/GroupedByDate';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import { DateString } from './util/groupStaffAvailability';
import { getStaffFormattedName } from '../kiosk/schedule/staff/StaffMember';
import useHolidayCalendar from './hooks/useHolidayCalendar';
import { HolidayCalendar } from '../../../types/HolidayCalendar';
import { DateViewCalendar, holidayColor, ptoColor } from './HolidayCalendar';
import { useDeselect } from './hooks/useDeselect';
import { CalendarTabs, Params } from './MultipleCalendarPage';
import StaffMember from '../../../types/StaffMember';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  day: {
    flex: 1,
    display: 'flex',
    zIndex: 5,
    pointerEvents: 'none',
  },
  selected: {
    // transform: 'scale(0.85)',
  },
}));

const StaffDropdown = graphqlSelectInput({
  entityName: 'staffMember',
  placeholder: 'Staff Members',
  graphqlList: list,
  sortOptions: true,
  transformOptions: (data?: StaffMember[]) => (data || []).map(e => ({ ...e, id: e.staffId })),
  getOptionValue: (option: any) => (option ? parseInt(option.value, 10) : undefined),
} as any);

export interface CalendarPageProps {
  scope: any;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isGroupAdmin: boolean;
}

export const StaffMemberPicker: VFC<{ history: RouteComponentProps['history']; baseUrl: string; staffId?: string }> = ({
  history,
  baseUrl,
  staffId,
}) => (
  <StaffDropdown
    onChange={(staffId: string | undefined) => history.push(staffId ? baseUrl + '/' + staffId : baseUrl)}
    value={staffId}
    style={{ width: '30em' }}
  />
);

export const THIS_YEAR = new Date().getFullYear();
export const START_YEAR = THIS_YEAR - 1;
export const END_YEAR = THIS_YEAR + 2;

const StaffMemberCalendar: VFC<RouteComponentProps<Params>> = props => {
  const classes = useStyles();

  const { match } = props;
  const { staffId } = match.params;

  const { selectedDates, setSelectedDates, highlightedDates, setHighlightedDates } = useDeselect();

  const [doAddStaffAvailability] = useMutation<
    any,
    {
      staffId: number;
      dates: string[];
      from?: string;
      to?: string;
      available: boolean;
    }
  >(addStaffAvailability, {
    refetchQueries: ['staffAvailabilitySuggestedTimeRanges'],
  });
  const [doRemoveStaffAvailability] = useMutation<
    any,
    {
      eventIds: number[];
    }
  >(removeStaffAvailability, {
    refetchQueries: ['staffAvailabilitySuggestedTimeRanges'],
  });

  const [years, setYears] = useState<ImmutableSet<number>>(ImmutableSet.of<number>());

  const yearValues = years.isEmpty() ? [new Date().getFullYear()] : years.values();

  const { data } = useSubscription<
    { listStaffAvailability: StaffAvailability[] },
    { from: string; to: string; staffId: number }
  >(listStaffAvailability, {
    variables: {
      from: `${Math.min(...yearValues)}-01-01`,
      to: `${Math.max(...yearValues) + 1}-01-01`,
      staffId: Number(staffId),
    },
  });

  const { data: othersPTOsData } = useSubscription<
    { listOthersPTOs: StaffAvailability[] },
    { from: string; to: string; staffId: number }
  >(listOthersPTOs, {
    variables: {
      from: `${Math.min(...yearValues)}-01-01`,
      to: `${Math.max(...yearValues) + 1}-01-01`,
      staffId: Number(staffId),
    },
  });

  const [staffAvailabilities, setStaffAvailabilities] = useState<StaffAvailability<DateString>[]>([]);
  const [othersPTOs, setOthersPTOs] = useState<StaffAvailability<DateString>[]>([]);

  useDebouncedEffect(
    () => {
      if (data?.listStaffAvailability) {
        setStaffAvailabilities(
          data?.listStaffAvailability.map(({ id, staffId, date, from, to, available }) => ({
            id,
            staffId,
            date: format(date, 'YYYYMMDD'),
            from,
            to,
            available,
          }))
        );
      }
    },
    350,
    [data?.listStaffAvailability]
  );

  useDebouncedEffect(
    () => {
      if (othersPTOsData?.listOthersPTOs) {
        setOthersPTOs(
          othersPTOsData?.listOthersPTOs.map(({ id, staffId, staff, date, from, to, available }) => ({
            id,
            staffId,
            staff,
            date: format(date, 'YYYYMMDD'),
            from,
            to,
            available,
          }))
        );
      }
    },
    350,
    [othersPTOsData?.listOthersPTOs]
  );

  const availabilitiesPerDate = useMemo<GroupedByDate<StaffAvailability<DateString>>>(
    () =>
      staffAvailabilities.reduce(
        (groups, availability) => groups.add(availability.date, availability),
        GroupedByDate.empty<StaffAvailability<DateString>>()
      ),
    [staffAvailabilities]
  );

  const othersPTOsPerDate = useMemo<GroupedByDate<StaffAvailability<DateString>>>(
    () =>
      othersPTOs.reduce(
        (groups, availability) => groups.add(availability.date, availability),
        GroupedByDate.empty<StaffAvailability<DateString>>()
      ),
    [othersPTOs]
  );

  const { holidaysPerDate } = useHolidayCalendar(
    `${Math.min(...yearValues)}-01-01`,
    `${Math.max(...yearValues) + 1}-01-01`
  );

  return (
    <CalendarTabs {...props}>
      <Box className={classes.container}>
        <Virtuoso
          totalCount={END_YEAR - START_YEAR}
          initialTopMostItemIndex={THIS_YEAR - START_YEAR}
          itemContent={index => (
            <Lifecycle
              key={index}
              onMount={() => setYears(prev => prev.add(START_YEAR + index))}
              onUnmount={() => setYears(prev => prev.delete(START_YEAR + index))}
            >
              <CalendarYear
                year={START_YEAR + index}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                highlightedDates={highlightedDates}
                renderDate={date => (
                  <DateView
                    date={date}
                    selected={selectedDates.has(format(date, 'YYYYMMDD'))}
                    staffAvailabilities={availabilitiesPerDate.get(format(date, 'YYYYMMDD'))}
                    holidays={holidaysPerDate.get(format(date, 'YYYYMMDD'))}
                  />
                )}
                renderDateTooltip={date =>
                  availabilitiesPerDate.get(format(date, 'YYYYMMDD')).length > 0 ||
                  othersPTOsPerDate.get(format(date, 'YYYYMMDD')).length > 0 ||
                  holidaysPerDate.get(format(date, 'YYYYMMDD')).length > 0 ? (
                    <DateTooltip
                      date={date}
                      staffAvailabilities={availabilitiesPerDate.get(format(date, 'YYYYMMDD'))}
                      othersPTOs={othersPTOsPerDate.get(format(date, 'YYYYMMDD'))}
                      holidays={holidaysPerDate.get(format(date, 'YYYYMMDD'))}
                    />
                  ) : null
                }
              />
            </Lifecycle>
          )}
        />
        {!selectedDates.isEmpty() && (
          <StaffAvailabilityEditor
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            highlightedDates={highlightedDates}
            setHighlightedDates={setHighlightedDates}
            staffAvailabilities={staffAvailabilities}
            createStaffAvailabilities={async (dates, staffAvailability) => {
              if (!dates.isEmpty()) {
                await doAddStaffAvailability({
                  variables: {
                    staffId: Number(staffId),
                    dates: dates.values().map(date => format(date, 'YYYY-MM-DD')),
                    from: staffAvailability.from,
                    to: staffAvailability.to,
                    available: staffAvailability.available,
                  },
                });
              }
            }}
            deleteStaffAvailabilities={async ids => {
              if (!ids.isEmpty()) {
                await doRemoveStaffAvailability({
                  variables: {
                    eventIds: ids.values(),
                  },
                });
              }
            }}
            staffId={Number(staffId)}
          />
        )}
      </Box>
    </CalendarTabs>
  );
};

export const Lifecycle: FC<{ onMount?: () => void; onUnmount?: () => void }> = ({ onMount, onUnmount, children }) => {
  useEffect(() => {
    onMount && onMount();
    return onUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

const DateTooltip: FC<{
  date: Date;
  staffAvailabilities: StaffAvailability<DateString>[];
  othersPTOs: StaffAvailability<DateString>[];
  holidays: HolidayCalendar<DateString>[];
}> = ({ date, staffAvailabilities, othersPTOs, holidays }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.column}>
      <Box>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          {format(date, 'MM/DD/YYYY')}
        </Typography>
        {staffAvailabilities.map(e => (
          <Box className={classes.row}>
            <Typography>{formatTimeRange(e.from, e.to)}</Typography>
            {e.available && <Typography style={{ color: theme.palette.success.main }}>Available</Typography>}
            {!e.available && <Typography style={{ color: ptoColor }}>PTO</Typography>}
          </Box>
        ))}
      </Box>
      {(othersPTOs || []).length > 0 && (
        <Box>
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            Others PTOs
          </Typography>
          {othersPTOs.map(e => (
            <Box className={classes.row}>
              <Typography>{formatTimeRange(e.from, e.to)}</Typography>
              <Typography color="textSecondary">{getStaffFormattedName(e?.staff?.name, e?.staff?.title)}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {(holidays || []).length > 0 && (
        <Box>
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            Holidays
          </Typography>
          {holidays.map(e => (
            <Box className={classes.row}>
              <Typography color="textSecondary">{e.description}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const DateView: VFC<{
  date: Date;
  selected: boolean;
  staffAvailabilities: StaffAvailability<DateString>[];
  holidays: HolidayCalendar<DateString>[];
}> = ({ date, selected, staffAvailabilities, holidays }) => {
  const classes = useStyles();

  const theme = useTheme();
  const sortedStaffAvailabilities =
    staffAvailabilities.length === 2
      ? staffAvailabilities[0].available
        ? [...staffAvailabilities]
        : [staffAvailabilities[1], staffAvailabilities[0]]
      : [...staffAvailabilities];

  return sortedStaffAvailabilities.length === 0 ? (
    <DateViewCalendar date={format(date, 'YYYYMMDD')} selected={selected} holiday={holidays} />
  ) : (
    <div className={classNames(classes.day, { [classes.selected]: selected })}>
      {[...sortedStaffAvailabilities, ...holidays].map(e => (
        <div
          key={e.id}
          style={{
            flex: '1 1 1px',
            backgroundColor: e?.hasOwnProperty('description')
              ? holidayColor
              : (e as any)?.available
              ? theme.palette.primary.main
              : ptoColor,
          }}
        />
      ))}
    </div>
  );
};

export default StaffMemberCalendar;

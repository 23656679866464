import styled from 'styled-components';
import LinkButton from '../../../../se/components/LinkButton';
import responsive from '../../../../se/utilities/responsive';

export const Label = styled.div`
  display: inline-block;
  opacity: 0.5;
  margin-bottom: 0.25rem;
  line-height: 1.2;
  font-size: initial;
`;

export const ButtonLabel = styled.div`
  font-size: 1rem;
`;

export const Status = styled.div`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;

  ${responsive.sm.andSmaller`
    font-size: 3rem;
  `};
`;

export const MainButton = styled.button`
  background-color: ${props => props.theme.button.primary.backgroundColor.string()};
  border: 0.25rem solid transparent;
  border-radius: 0.25rem;
  color: ${props => props.theme.button.primary.color.string()};
  flex: 1 0 calc(50% - 1.5rem);
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: inherit;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 0 0.75rem;
  outline: none;
  flex-shrink: 0 @media (min-width: 50rem) {
    padding: 1rem 0;
  }

  transition: all 0.3s ease;
`;

export const TimeOutButton = styled(MainButton)`
  background-color: transparent;
  border: 0.25rem solid rgba(255, 255, 255, 0.25);
  color: inherit;

  > * {
    transition: color 0.3s ease;
  }

  &:disabled {
    opacity: 0.5;

    > * {
      color: rgba(255, 255, 255, 0.25);
    }
  }

  ${responsive.sm.andSmaller`
    margin-top: 1rem;
  `};
`;

export const TimeOut2Button = styled(LinkButton)`
  // padding: 1.25rem 2rem;
  // border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
  text-align: right;
  display: block;
  width: 100%;

  ${responsive.sm.andSmaller`
    width: auto;
  `};
`;

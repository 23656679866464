import ThreadInfo from './ThreadInfo';
import { Room } from './Room';
import { Procedure, ProcedureType } from './Procedure';
import Physician from './Physician';

export interface PatientEventTimes {
  admittedAt: any;
  preOpAt: any;
  orAt: any;
  recoveryAt: any;
  ableToVisitPACUAt: any;
  ableToVisitPOSTOPAt: any;
  dischargedAt: any;
  readyForSurgeonAt: any;
  readyForOrAt: any;
  holdProcedureAt: any;
}

export type Bed = string | number | undefined | null;
export type Note = string | undefined | null;

export interface Patient {
  id: number;
  name?: string;
  nameUnformatted?: string;
  type: PatientType;
  initials?: string;
  notificationNumbers?: string;
  phoneNumber?: string;
  procedureId?: number;
  caretakerThread?: ThreadInfo;
  patientThread?: ThreadInfo;
  status: string;
  room?: Room;
  bed?: Bed;
  priority?: string;
  events?: PatientEventTimes;
  note?: string;
  preOpNote?: string;
  pacuNote?: string;
  procedureType?: ProcedureType;
  physician?: Physician;
  procedure?: Procedure;
  changedRoomAt?: string;
  log?: any[];
}

export enum PatientType {
  In = 'In',
  Out = 'Out',
  Emergency = 'Emergency',
  ICU = 'ICU',
}

import React from 'react';
import styled from 'styled-components';
import RouterLink from '../../../../se/components/RouterLink';
import icon from '../../../../assets/images/scan_bracelet.png';
import responsive from '../../../../se/utilities/responsive';
import { AutomaticPatientTracking } from '../../../ModuleOptional';
import ButtonRouterLink from '../../../../se/components/ButtonRouterLink';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import UploadButton from '../../../inputs/upload/UploadButton';
import { withProps } from 'recompose';
import { UPLOAD_RESOURCE } from '../../../inputs/upload/Uploader';
import { ScheduleUploadConfirmation } from '../../../inputs/upload/ScheduleUploadConfirmation';
import ProtectedContent from '../../../../security/ProtectedContent';

const Container = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const LeftBox = styled.div`
  display: flex;
  flex: 1;
  margin: 0.5em 0;
  h2 {
    font-size: 2rem;
    margin-top: 1em;
    margin-bottom: 1em;
    margin: 0;
    ${responsive.md.andSmaller`
        margin-top: 0;
        margin-bottom: 0;
    `};
  }
`;

const RightBox = styled.div`
  display: flex;
  flex: 2;
  max-width: 860px;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  padding: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  background: ${props => props.theme.backgroundColor.string()};

  strong {
    font-weight: 600;
  }

  img {
    height: 54px;
    margin-left: 1em;
    margin-right: 2em;
  }
`;

const AutoTrackingHeader = () => (
  <RightBox>
    <img src={icon} alt="" />
    <div>
      <p style={{ marginBottom: 8 }}>
        <strong>To enter the patient into the system, please place the bracelet on the scanning device.</strong>
      </p>
      <p>
        Scanning device not working? <RouterLink to="/patients/create">Click here</RouterLink> to enter the patient
        manually.
      </p>
    </div>
  </RightBox>
);

const ManualTrackingHeader = () => (
  <ProtectedContent
    accessRight="patient.create"
    content={
      <ButtonRouterLink
        to={'/patients/create'}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        Create Patient
      </ButtonRouterLink>
    }
  />
);

const EnrollmentHeader = AutomaticPatientTracking(AutoTrackingHeader, ManualTrackingHeader);

export const PatientImport = withProps({
  resource: UPLOAD_RESOURCE.SCHEDULE,
  ButtonComponent: (
    <Button
      size={'small'}
      variant="outlined"
      color="primary"
      startIcon={<CloudUploadIcon />}
      style={{ marginLeft: '1em', textTransform: 'none' }}
    >
      Import Patients
    </Button>
  ),
  UploadConfirmation: ScheduleUploadConfirmation,
})(UploadButton);

const ManualOptionalImport = AutomaticPatientTracking(null, PatientImport);

export default () => (
  <Container>
    <LeftBox>
      <h2>Patients</h2>
      <ManualOptionalImport />
    </LeftBox>
    <EnrollmentHeader />
  </Container>
);

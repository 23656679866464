import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import identity from 'lodash/fp/identity';
import PatientSlot from '../../../patient/PatientSlot/PatientSlot';
import {
  getFrameColor,
  getLogEntries,
  isHoldProcedureFn,
  isPatientReady,
  isPatientReadyForSurgeonFn,
  isPreOpAsDPU,
  isReadyForFamilyPreOp,
} from '../tablet/utils';
import { Idle, Illustration } from '../tablet/Tablet';
import waiting from '../../../../assets/images/waiting.svg';
import ClientUpdater, { ClientUpdaterPresetTvInternal } from '../../../ClientUpdater';
import { AutomaticPatientTracking } from '../../../ModuleOptional';
import Button from '../../../../se/components/Button';
import { setRoom } from '../../../../graph/patients';
import { useMutation } from '@apollo/client';
import { Subtitle, Title } from '../tablet/Modal';
import get from 'lodash/get';
import PatientSelection from '../nursingApp/PatientSelection';
import { OR, PACU, POST_OP, PRE_OP, ROOM_TYPES, WAITING_ROOM } from '../../../entities/room/enums';
import filter from 'lodash/filter';
import { orderBy } from 'lodash';
import { getScreenType } from '../../../entities/screens/enums';

export const PatientListContainer = styled.div`
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  padding: 1em 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
    margin-left: 0.5em;
  }
`;

const SlotContainerWrapper = styled.div`
  ${props =>
    props.columns > 1 &&
    `
    flex: 1;
    columns: 2;
    column-fill: auto;
  `};
`;

const SlotContainer = styled.div`
  margin-bottom: 0.5rem;
  cursor: pointer;
  break-inside: avoid;
  display: inline-block;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  flex-shrink: 0;
  h1 {
    margin: 0;
  }

  .modal-body {
    margin-top: 0;
  }
`;

const EmptyState = () => (
  <Idle>
    <Illustration src={waiting} />
    <Title>Waiting for patients</Title>
    <Subtitle>Once a patient enters the room, this screen will automatically show the controls.</Subtitle>
  </Idle>
);

const MovePatient = ({ room }) => {
  const [modal, setModal] = useState(false);
  const [moveTo] = useMutation(setRoom);

  const filterPatients = patients => {
    const targetRoom =
      room?.type === PRE_OP
        ? [WAITING_ROOM]
        : room?.type === PACU
        ? [WAITING_ROOM, PRE_OP, OR]
        : [WAITING_ROOM, PRE_OP, OR, PACU];
    return orderBy(
      filter(patients, patient => targetRoom.includes(get(patient, 'room.type'))),
      'room.type'
    );
  };

  const movePatient = id => async () => {
    try {
      await moveTo({ variables: { id, roomId: get(room, 'id') } });
      setModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (modal) {
    return (
      <PatientSelection
        handleCancel={() => setModal(false)}
        handlePatientClick={movePatient}
        filterFn={filterPatients}
        TitleAndActions={() => null}
        withRoom={true}
      />
    );
  }

  return <Button onClick={() => setModal(true)}>{`Move Patient To ${get(room, 'name', 'Here')}`}</Button>;
};

const MovePatients = AutomaticPatientTracking(null, MovePatient);

class PatientList extends React.Component {
  render() {
    const patients = this.props.patients.map(patient =>
      flow(
        set('isReady', isPatientReady(getLogEntries(patient))(this.props.roomType)),
        set('isFamilyReadyPreOp', isReadyForFamilyPreOp(getLogEntries(patient))(this.props.roomType)),
        this.props.showHoldProcedure ? set('isHoldProcedure', isHoldProcedureFn(getLogEntries(patient))) : identity,
        this.props.showReadyForSurgeon
          ? set('isReadyForSurgeon', isPatientReadyForSurgeonFn(getLogEntries(patient)))
          : identity
      )(patient)
    );

    const { hasNoteModule } = this.props;

    const isPreOp = this.props.roomType === PRE_OP;
    const isPacu = this.props.roomType === PACU;
    const isPostOp = this.props.roomType === POST_OP;

    const columns = patients.length < 11 ? 1 : 2;

    return (
      <PatientListContainer>
        <Header>
          {this.props.roomName && <h1>{this.props.roomName}</h1>}
          {this.props.room && <MovePatients room={this.props.room} />}
        </Header>
        {patients.length === 0 && <EmptyState />}
        <SlotContainerWrapper columns={columns}>
          {patients.length > 0 ? (
            patients.map(patient => (
              <SlotContainer key={patient.id} onClick={() => this.props.selectPatient(patient.id)}>
                <PatientSlot
                  patient={patient}
                  {...patient}
                  showPriority={this.props.hasPreOpPriorityModule}
                  caretakerMessage={get(patient, 'caretakerMessages', null)}
                  status="occupied"
                  frameColor={getFrameColor(this.props.roomType, patient?.log)}
                  ready={patient.isReady}
                  isHoldProcedure={patient.isHoldProcedure}
                  readyForSurgeon={patient.isReadyForSurgeon}
                  readyForOr={patient.isReady && this.props.roomType === ROOM_TYPES.PRE_OP}
                  familyReady={isPacu ? patient.familyReadyPACU : isPostOp ? patient.familyReadyPOSTOP : false}
                  preventAnimation
                  size={patients.length < 8 ? 'large' : null}
                  screenType={getScreenType(this.props.roomType)}
                  patientData={patient}
                  showPreOpNote={isPreOp && hasNoteModule}
                  showPacuNote={isPacu && hasNoteModule}
                  preOpNote={patient?.preOpNote}
                  pacuNote={patient?.pacuNote}
                  isDPU={isPreOpAsDPU(this.props.roomType, getLogEntries(patient))}
                />
              </SlotContainer>
            ))
          ) : (
            <ClientUpdater {...ClientUpdaterPresetTvInternal} />
          )}
        </SlotContainerWrapper>
      </PatientListContainer>
    );
  }
}

PatientList.propTypes = {
  roomType: PropTypes.string.isRequired,
  roomName: PropTypes.string,
  selectPatient: PropTypes.func.isRequired,
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
};

export default PatientList;

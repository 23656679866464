import React from 'react';
import styled, { css } from 'styled-components';
import { PatientTypeIconSwitch } from '../../inputs/PatientTypeButtonGroup';
import { isEmpty } from 'lodash/fp';

export const Container = styled.h4`
  display: flex;
  flex-wrap: ${props => (props.allowWrap ? 'wrap' : 'none')};
  justify-content: space-between;
  margin: 0;
  line-height: 1.5;

  ${props =>
    props.miniView &&
    css`
      flex-direction: column;
    `}
`;

export const Info = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 100%;
  font-size: ${props => (props.size === 'large' ? '2.25em' : '1.5em')};
  line-height: 1.4;
  color: white;
  display: flex;

  @media (max-height: 900px) {
    font-size: ${props => (props.size === 'large' ? '2.25em' : '1rem')};
  }

  @media (max-height: 600px) {
    font-size: ${props => (props.size === 'large' ? '2.25em' : '2vh')};
    line-height: ${props => (props.size === 'large' ? '1' : '1.4')};
  }

  ${props =>
    props.miniView &&
    css`
      font-size: 1rem;
      line-height: 1.4;
    `}
`;

const InfoRight = styled(Info)`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  ${props =>
    props.miniView &&
    css`
      margin-left: 0;
      color: #91c4ff;
      opacity: 0.5;
    `}
`;

const Physician = styled.div`
  display: inline-block
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  color: #003c0a;
  background: #43be57;
  border-radius: 0.1875rem;
  font-size: 0.5em;
  padding: 0 0.5em;
  margin-left: 0.6em;
  height: 80%;
`;

const DelayTag = styled.div`
  display: flex;
  align-items: center;
  color: #003c0a;
  background: #ffbd00;
  border-radius: 0.1875rem;
  font-size: 0.5em;
  padding: 0 0.5em;
  margin-left: 0.6em;
  height: 80%;
`;

const containsWord = (str, word) => str?.match(new RegExp('\\b' + word + '\\b')) != null;

const getPhysicianPrefix = physician => (containsWord(physician?.toLowerCase(), 'dr') ? '' : 'Dr. ');

const PatientSlotInfo = ({
  physician,
  patient,
  patientType,
  size,
  readyForOr,
  readyForSurgeon,
  readyInOr,
  familyReady,
  isHoldProcedure,
  allowWrap,
  miniView,
  isDPU,
}) => (
  <Container allowWrap={allowWrap} miniView={miniView}>
    <Info style={{ minWidth: 0, marginRight: '1rem' }} size={size} miniView={miniView}>
      <Physician>
        {!isEmpty(physician) && getPhysicianPrefix(physician.replaceAll('.', ''))}
        {physician}
      </Physician>
    </Info>
    <InfoRight size={size} miniView={miniView}>
      <>
        {patient}
        <PatientTypeIconSwitch patientType={patientType} />
        {readyForSurgeon && !miniView && !isDPU && !readyForOr && <Tag>Ready For Surgeon</Tag>}
        {readyForOr && !miniView && !isDPU && <Tag>Ready For OR</Tag>}
        {readyInOr && !miniView && <Tag>Ready For Surgery</Tag>}
        {familyReady && !miniView && <Tag>Family Ready</Tag>}
        {isHoldProcedure && !miniView && <DelayTag>On Hold</DelayTag>}
      </>
    </InfoRight>
  </Container>
);

export default PatientSlotInfo;

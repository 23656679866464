import { Patient } from '../../../../../types/Patient';
import { QuestionnaireAndAnswers } from '../../../../../types/Questionnaire';
import { optionalFormat, patientEnteredRoomTypeTime } from '../../tablet/utils';
import { PRE_OP } from '../../../../entities/room/enums';
import React, { Ref, useEffect, useRef, useState } from 'react';
import { Answer } from '../../../../../types/Answer';
import { Question } from '../../../../../types/Question';
import { useMutation } from '@apollo/client';
import { createQuestionnaireAnswers, updateQuestionnaireAnswers } from '../../../../../graph/forms';
import SaveProvider, { SaveProviderHandle } from '../SaveProvider';
import { isCompleted, renderEditableFormUI, Tags } from '../../../../../form/Form';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import SectionButton from './SectionButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import assessments from '../../../../../assets/images/illustrations/assesments.svg';
import check_in from '../../../../../assets/images/illustrations/check_in.svg';
import diagnostic from '../../../../../assets/images/illustrations/diagnostic.svg';
import medications from '../../../../../assets/images/illustrations/medications.svg';
import nurse_sign_in from '../../../../../assets/images/illustrations/nurse_sign_in.svg';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import NotesContextProvider from '../NotesContextProvider';

const ICONS = {
  event: assessments,
  person: check_in,
  assignment: diagnostic,
  local_pharmacy: medications,
  playlist_add_check: nurse_sign_in,
  search: assessments,
};

const InlineIcon = ({ children }: { children: any }) => (
  <img alt="icon" width={56} height={56} src={ICONS[children as keyof typeof ICONS] as keyof typeof ICONS} />
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormButtonDialog = ({
  patient,
  form,
  open,
  handleClickOpen,
  handleClose,
}: {
  index: number;
  patient: Patient;
  form: QuestionnaireAndAnswers;
  open: boolean;
  handleClickOpen: (form: QuestionnaireAndAnswers) => void;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const questions = JSON.parse(form?.questionnaire?.content) || {};
  const answers = form?.questionnaireExchange?.answers ? JSON.parse(form?.questionnaireExchange?.answers) : {};
  // @ts-ignore
  const enteredAt = optionalFormat(patientEnteredRoomTypeTime(patient, PRE_OP), 'HH:mm', null);

  const initialValues = {
    admissionTime: enteredAt,
  };

  const [answer, setAnswer] = useState<Answer<Question> | undefined>({
    ...initialValues,
    ...answers,
  });

  const [createAnswers] = useMutation(createQuestionnaireAnswers);
  const [updateAnswers] = useMutation(updateQuestionnaireAnswers);

  const [forceSave, setForceSave] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!error) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setError(undefined);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [error]);

  const saveProviderRef = useRef<SaveProviderHandle | undefined>();

  useEffect(() => {
    setForceSave(false);
  }, [answer]);

  const saveAnswers = async () => {
    const save = async () => {
      if (!!form?.questionnaireExchange?.id) {
        await updateAnswers({
          variables: {
            questionnaireExchangeId: form?.questionnaireExchange?.id,
            answers: JSON.stringify(answer),
          },
        });
      } else {
        await createAnswers({
          variables: {
            procedureId: patient?.procedure?.id,
            questionnaireId: form?.questionnaire?.id,
            answers: JSON.stringify(answer),
          },
        });
      }
    };

    if (!forceSave) {
      const completed = isCompleted({}, questions, answer);

      if (!completed) {
        setError('Some fields are not filled.');
        saveProviderRef.current!.requestValidation();
        setForceSave(true);
        // await save();
        return;
      }
    }

    await save();

    close();
  };

  const close = () => {
    setForceSave(false);
    saveProviderRef.current!.clearValidationRequest();
    handleClose();
  };

  return (
    <>
      <SectionButton
        onClick={() => handleClickOpen(form)}
        Icon={<InlineIcon>{form?.questionnaire?.icon}</InlineIcon>}
        text={form?.questionnaire?.name || 'Patient Form'}
        completed={form?.completed}
      />
      <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {form?.questionnaire?.name || 'Patient Form'}
            </Typography>
            <Button autoFocus color="inherit" onClick={saveAnswers}>
              {forceSave ? 'SAVE ANYWAY' : 'SAVE'}
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          flex={1}
          className={classes.form}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          position="relative"
        >
          {error && (
            <Box position="absolute" top={10} left={0} right={0} display="flex" justifyContent="center">
              <Box
                padding={2}
                style={{ backgroundColor: theme.palette.error.main, borderRadius: theme.shape.borderRadius }}
              >
                {error}
              </Box>
            </Box>
          )}
          <NotesContextProvider>
            <SaveProvider
              ref={saveProviderRef as Ref<SaveProviderHandle>}
              forceSave={forceSave}
              onSave={saveAnswers}
              onClearForceSave={() => setForceSave(false)}
            >
              {renderEditableFormUI({} as Tags, questions as unknown as Question, answer, setAnswer)}
            </SaveProvider>
          </NotesContextProvider>
        </Box>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default FormButtonDialog;

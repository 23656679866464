import React from 'react';
import { Box } from '@material-ui/core';
import ChartingPatients from './ChartingPatients';

const ChartingPage = () => (
  <Box
    position="fixed"
    top={0}
    bottom={0}
    left={0}
    right={0}
    padding={4}
    display="flex"
    flexDirection="column"
    alignItems="stretch"
    justifyContent="start"
    overflow="auto"
  >
    <ChartingPatients />
  </Box>
);

export default ChartingPage;

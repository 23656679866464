import React, { FC } from 'react';
import Thread from './Thread';
import Composer from './Composer';
import { gql, useMutation } from '@apollo/client';

interface ChatProps {
  threadId?: string | null;
  createThread: () => Promise<string>;
}

const Chat: FC<ChatProps> = ({ threadId, createThread }) => {
  const [sendMessage] = useMutation(gql`
    mutation sendMessage($threadId: String!, $content: String!) {
      sendMessage(threadId: $threadId, content: $content)
    }
  `);

  const handleSend = async (content: string) => {
    sendMessage({
      variables: {
        threadId: threadId || (await createThread()),
        content,
      },
    });
  };

  return (
    <>
      {threadId && <Thread threadId={threadId} />}
      <Composer onSend={handleSend} />
    </>
  );
};

export default Chat;

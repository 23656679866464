import findIndex from 'lodash/findIndex';
import { OR_FLOW, STATUSES } from '../../../entities/patient/enums';

export const getInOrStatus = status => {
  const curr = findIndex(OR_FLOW, x => x === status);
  if (status && curr !== -1) {
    if (curr >= findIndex(OR_FLOW, x => x === STATUSES.CLOSING)) {
      return STATUSES.CLOSING;
    } else if (curr >= findIndex(OR_FLOW, x => x === STATUSES.ONGOING)) {
      return STATUSES.ONGOING;
    }
  }
};

export const getInOrStatusLabel = status => {
  const inOr = getInOrStatus(status);
  switch (inOr) {
    case STATUSES.ONGOING:
      return 'Ongoing';
    case STATUSES.CLOSING:
      return 'Closing';
    default:
      return undefined;
  }
};

import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import get from 'lodash/get';
import set from 'lodash/set';
import EntityView from '../../../se/components/entity/EntityView';
import ProcedureEventLog from '../procedures/ProcedureEventLog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { a11yProps, TabPanel } from '../procedures/TabPanel';
import useCareAppAccess from '../procedures/utils/useCareAppAccess';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { procedureForms } from '../../../graph/surgeon/forms';
import SurgeonFormPage from '../surgeonProcedures/PreOpForm';
import QuestionnairePage from '../surgeonProcedures/QuestionnairePage';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';
import FileUploader from '../../FileUploader';
import DoneIcon from '@material-ui/icons/Done';
import { CircularProgress, Grow, Popper, Typography, withStyles } from '@material-ui/core';
import { format } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Button from '@material-ui/core/Button';
import { markRequestTransferChangeAsSeen } from '../../../graph/scheduleRequests';
import RequestedDocumentsContainer from '../scheduleRequests/RequestedDocumentsContainer';
import Divider from '@material-ui/core/Divider';
import { CancelTransfer } from '../scheduleRequests/ScheduledRequests';
import ProcedureDocumentsModal from '../schedule/ProcedureDocumentsModal';
import { CenteredSpinner } from '../../../se/components/Spinner';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import ProcedureNotes from './ProcedureNotes';
import { RouteComponentProps } from 'react-router';
import { NotesStatus } from './columns';
import { itemQuestionnaireSubscription } from '../../../graph/surgeon/procedures';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import Tooltip from '../../Tooltip';
import MuiAccordion from '@material-ui/core/Accordion';
import { ProcedureEvent } from '../../../types/ProcedureEvent';

const Spinner = CenteredSpinner as any;

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  accordionDetailsForms: {
    padding: theme.spacing(0),
  },
  tabs: {
    flexShrink: 0,
  },
  flexContainer: {
    flexWrap: 'wrap',
  },
  scheduleContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heading: {
    fontSize: theme.typography.h3.fontSize,
  },
  canceled: {
    textDecoration: 'line-through',
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

interface Form {
  id: number;
  name: string;
  isSigned: boolean;
}

interface ScheduleRequestIssue {
  id: number;
  message: string;
  state: string;
  resolved: boolean;
  confirmed: boolean;
  raisedAt: string;
  resolvedAt: string;
  confirmedAt: string;
}

interface PatientProfileProps extends RouteComponentProps {
  Before: any;
  scope: {
    hospital: {
      id: number;
    };
  };
  accessToken: string;
  exchangeOnly: boolean;
  data: {
    id: number;
    events: Array<ProcedureEvent>;
    patient: {
      physician: any;
    };
    scheduleRequest?: {
      id: number;
      notesStatus: string;
      state: string;
      toOrg: {
        name: string;
      };
      scheduleTransferDate: string;
      scheduleTransferTime: string;
      duration: number;
      issues?: Array<ScheduleRequestIssue>;
    };
  };
}

const PatientProfile = (props: PatientProfileProps) => {
  const { Before, scope, accessToken, exchangeOnly } = props;
  const classes = useStyles();
  const organizationId = scope?.hospital?.id;
  const procedureId = props?.data?.id;
  const procedureNotes = (props?.data?.events || []).filter((event: any) => event.type === 'Note');
  const procedureEvents = (props?.data?.events || []).filter((event: any) => event.type !== 'Note');
  const physician = props?.data?.patient?.physician;
  const hasAccessRight = useHasAccessRight();
  const isAllowedToEdit = hasAccessRight('patient.edit');

  useCareAppAccess(organizationId, accessToken);

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const anchorRef = useRef(null);

  const [formTab, setFormTab] = useState<number>(0);
  const handleChange = (event: any, tabNumber: number) => {
    setFormTab(tabNumber);
  };

  const scheduleRequest = props?.data?.scheduleRequest;
  const toOrganizationName = scheduleRequest?.toOrg?.name;
  const scheduleRequestDate =
    scheduleRequest?.scheduleTransferDate && format(scheduleRequest?.scheduleTransferDate, 'MM/DD/YYYY');
  const scheduleRequestTime = scheduleRequest?.scheduleTransferTime;
  const scheduleRequestDuration = scheduleRequest?.duration || '0';
  const scheduleRequestDetails = `${toOrganizationName} • ${scheduleRequestDate} · ${scheduleRequestTime} · ${scheduleRequestDuration}`;
  const scheduleRequestState = scheduleRequest?.state ? JSON.parse(scheduleRequest.state) : null;
  const isCanceled = scheduleRequestState?.__typename === 'Cancelled';
  const scheduleRequestIssues = scheduleRequest?.issues || [];
  const isNewNote = scheduleRequest?.notesStatus === NotesStatus.NewNoteFromSC;

  const [acknowledgeUpdate] = useMutation(markRequestTransferChangeAsSeen);
  const onAcknowledgeUpdate = useCallback(
    () => acknowledgeUpdate({ variables: { scheduleTransferId: scheduleRequest?.id } }),
    [acknowledgeUpdate, scheduleRequest?.id]
  );

  useEffect(() => {
    if (isNewNote && scheduleRequest?.id) {
      onAcknowledgeUpdate().then(() => {});
    }
  }, [isNewNote, onAcknowledgeUpdate, scheduleRequest?.id]);

  const { data: formData, loading } = useQuery(procedureForms, { variables: { procedureId } });
  const forms = formData?.procedureForms || [];
  const [updateDocuments, setUpdateDocuments] = useState<boolean>(false);

  const handleUpdateDocuments = () => setUpdateDocuments(true);
  const redirectToSurgerySchedulePage = () => {
    const prefix = (props.location.pathname.match(/\/su\/(\d+)/) ?? [''])[0];
    props.history.push(`${prefix}/schedule-procedure?procedure=${procedureId}`);
  };

  const handleToggle = () => {
    setOpenMenu(open => !open);
  };

  const questionnaireQuery = useSubscription(itemQuestionnaireSubscription, {
    variables: {
      id: props.data.id,
    },
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size="3rem" aria-label="Patient Profile" />
      </Box>
    );
  }

  return (
    <Fragment>
      {Before && (
        <Before
          scheduleData={scheduleRequest}
          history={props.history}
          location={props.location}
          match={props.match}
          data={props.data}
        />
      )}

      <Grid container spacing={3}>
        <Grid item lg={9} md={8}>
          {scheduleRequest && (
            <Paper>
              <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <Box>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    Schedule
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {!isCanceled ? <DateRangeIcon color="disabled" /> : <EventBusyIcon color="disabled" />}

                    <Box ml={1}>
                      {!isCanceled ? (
                        <Typography variant="h5">{scheduleRequestDetails}</Typography>
                      ) : (
                        <Box>
                          <Typography variant="h5">Procedure is canceled.</Typography>
                          <Typography variant="body2" color="textSecondary" className={classes.canceled}>
                            {scheduleRequestDetails}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                {!isCanceled && (
                  <Fragment>
                    <ButtonGroup variant="text" color="default" ref={anchorRef} aria-label="split button">
                      <Button color="primary" onClick={redirectToSurgerySchedulePage}>
                        Reschedule
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                      >
                        <ArrowDropDownIcon />
                      </Button>
                    </ButtonGroup>
                    <Popper
                      open={openMenu}
                      anchorEl={anchorRef.current}
                      placement="bottom-end"
                      role={undefined}
                      transition
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper>
                            <MenuList id="split-button-menu">
                              <MenuItem onClick={handleUpdateDocuments}>Configure Docs Sharing</MenuItem>
                              <Box my={1}>
                                <Divider />
                              </Box>
                              <CancelTransfer
                                isSO={true}
                                data={{ id: scheduleRequest.id, status: scheduleRequestState }}
                              />
                            </MenuList>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Fragment>
                )}
                {isCanceled && (
                  <Button variant="outlined" color="primary" onClick={redirectToSurgerySchedulePage}>
                    Reschedule
                  </Button>
                )}
              </Box>
              {scheduleRequestIssues && scheduleRequestIssues?.length > 0 && !isCanceled && (
                <Box borderTop={1} borderColor="divider">
                  <RequestedDocumentsContainer requestedDocuments={scheduleRequestIssues} isSO={true} />
                </Box>
              )}
            </Paper>
          )}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box flex={1} display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={classes.heading}>Patient Details</Typography>
                <Box my={-0.5}>
                  {/* @ts-ignore */}
                  <Tooltip
                    content={!isAllowedToEdit ? 'You don’t have sufficient permissions to edit this document.' : null}
                  >
                    <Button
                      color="primary"
                      onClick={(event: any) => event.stopPropagation()}
                      component={Link}
                      replace={true}
                      to={`${props.match.url}/edit`}
                      disabled={!isAllowedToEdit}
                    >
                      Edit
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <EntityView {...props} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography className={classes.heading}>Documents</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FileUploader procedureId={procedureId} />
            </AccordionDetails>
          </Accordion>
          {!exchangeOnly && (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                  <Typography className={classes.heading}>Questionnaire</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {questionnaireQuery.data ? (
                    <QuestionnairePage
                      entryQuestionnaire={questionnaireQuery.data.patient?.entryQuestionnaire}
                      procedure={set(
                        set(
                          set(get(props, 'data', {}), 'patientCellPhone', get(props, 'data.patient.cellPhone')),
                          'patientEmailAddress',
                          get(props, 'data.patient.emailAddress')
                        ),
                        'entryQuestionnaire',
                        questionnaireQuery.data.patient?.entryQuestionnaire
                      )}
                    />
                  ) : (
                    <Spinner />
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                  <Typography className={classes.heading}>Forms</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsForms}>
                  <Box mt={5}>
                    <Tabs
                      value={formTab}
                      onChange={handleChange}
                      aria-label="Patient Tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                      style={{ width: '80%' }}
                      indicatorColor={'primary'}
                    >
                      {forms.map(({ id, name, isSigned }: Form, i: number) => (
                        <Tab
                          key={id}
                          label={
                            <Box
                              display="flex"
                              alignItems="center"
                              alignSelf="start"
                              justifyContent={'space-between'}
                              style={{ width: '100%' }}
                            >
                              <label style={{ textAlign: 'start' }}>{name}</label>
                              {isSigned && (
                                <Chip
                                  size="small"
                                  color="primary"
                                  icon={<DoneIcon />}
                                  label="Signed"
                                  style={{ marginLeft: '0.5em', fontSize: '0.6em', color: 'white' }}
                                />
                              )}
                            </Box>
                          }
                          {...a11yProps(i + (exchangeOnly ? 0 : 1))}
                        />
                      ))}
                    </Tabs>
                    {forms.map((form: Form, i: number) => (
                      <TabPanel key={i} index={i} value={formTab}>
                        <SurgeonFormPage id={form.id} procedureId={procedureId} lastPreOpCompletedEvent={null} />
                      </TabPanel>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Grid>
        <Grid item lg={3} md={4}>
          <ProcedureNotes procedureId={procedureId} procedureNotes={procedureNotes} />
          <Box my={3}>
            <Divider />
          </Box>
          <ProcedureEventLog events={procedureEvents} name="Procedure Events" />
        </Grid>
      </Grid>

      <ProcedureDocumentsModal
        open={updateDocuments}
        procedure={props.data}
        physician={physician}
        onClose={() => setUpdateDocuments(false)}
        onGoBack={() => setUpdateDocuments(false)}
      />
    </Fragment>
  );
};

export default PatientProfile;

import { Subscription } from '@apollo/client/react/components';
import PatientSlot from '../../../patient/PatientSlot/PatientSlot';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { parse } from 'date-fns';
import styled from 'styled-components';
import React, { Fragment, useMemo, useState } from 'react';
import { patientPickerSubscription } from '../../../../graph/patients';
import { Error, Exit, Modal, ModalBody, Title } from '../tablet/Modal';
import { compose, mapProps, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withPatientNameFormat } from '../../../../contexts/PatientNameFormat';
import { withScope } from '../../../HospitalInfo';
import update from 'lodash/update';
import { mapInitialsForPatientList } from '../tablet/mapInitialsForPatientList';
import get from 'lodash/get';
import EntityState from '../../../../se/components/EntityState';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FlexList from '../../../../se/components/collection/FlexList';
import PatientSlotListView from '../../../entities/room/views/PatientSlotListView';

const useStyles = makeStyles({
  container: {
    flex: 1,
    top: 'auto',
    bottom: 'auto',
    overflowY: 'scroll',
  },
});

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

const EmptyState = props => (
  <EntityState title={'Waiting for patients.'} hint={`Currently there are no patients to choose from.`} {...props} />
);

const AdmittedPatients = columns =>
  compose(
    withRouter,
    withPatientNameFormat,
    withScope,
    mapProps(({ data, patientNameFormat, scope, ...props }) => ({
      ...props,
      data: update(data, 'patients', patients => {
        if (!patients) {
          return patients;
        }
        return mapInitialsForPatientList(patients, patientNameFormat, scope);
      }),
    })),
    withProps({
      entityName: 'Patient',
      idProvider: item => item.id,
      getList: data => data['patients'],
      columns: columns,
      View: PatientSlotListView,
      hideColumns: true,
      Empty: EmptyState,
    })
  )(FlexList);

const AdmittedPatientsBed = AdmittedPatients([
  {
    lens: data => data,
    Component: ({ data }) => (
      <PatientSlot
        procedure={getNestedValue('procedureType.name', data)}
        physician={getNestedValue('physician.name', data)}
        patient={data.name}
        timeEntered={parse(data.createdAt)}
        preventAnimation={true}
      />
    ),
  },
]);

const AdmittedPatientsRoom = AdmittedPatients([
  {
    lens: data => data,
    Component: ({ data }) => (
      <PatientSlot
        roomName={getNestedValue('room.name', data)}
        procedure={getNestedValue('procedureType.name', data)}
        physician={getNestedValue('physician.name', data)}
        patient={data.name}
        patientType={data.type}
        timeEntered={parse(data.createdAt)}
        preventAnimation={true}
        hideBedInfo={true}
      />
    ),
  },
]);

const filterOpt = (list, fn) => {
  if (list && fn) {
    return fn(list);
  } else {
    return list;
  }
};

export default ({ handleCancel, handlePatientClick, filterFn, TitleAndActions, withRoom, style }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const handlePatientSelect = async data => {
    try {
      await handlePatientClick(data.id)();
      handleCancel();
    } catch (error) {
      console.error(error);
      if (error.message.indexOf('Selected entity does not exist')) {
        setError(true);
      }
    }
  };

  const onRefresh = refetch => async () => {
    await refetch();
    setError(false);
  };

  const Item = useMemo(() => (withRoom ? AdmittedPatientsRoom : AdmittedPatientsBed), [withRoom]);

  return (
    <Modal style={style}>
      <Container maxWidth={false} className={classes.container}>
        <Header>
          <Title style={{ fontSize: '1rem' }}>Choose patient to move</Title>
          <Exit onClick={handleCancel}>Exit</Exit>
        </Header>
        <ModalBody className="modal-body">
          <Subscription subscription={patientPickerSubscription}>
            {props => (
              <Fragment>
                {error && (
                  <Error onClick={onRefresh(props.refetch)}>
                    There was a problem with moving the patient. Click here to refresh the list.
                  </Error>
                )}
                <Box display="flex" flexDirection="column">
                  <Item
                    onViewClick={handlePatientSelect}
                    TitleAndActions={TitleAndActions}
                    {...props}
                    data={{ patients: filterOpt(get(props, 'data.patients', []).slice(0, 11), filterFn) }}
                  />
                </Box>
              </Fragment>
            )}
          </Subscription>
        </ModalBody>
      </Container>
    </Modal>
  );
};

import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { matchPath } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

interface Tab extends RouteProps {
  title: string;
  path?: string;
}

interface TabRoutesProps extends RouteComponentProps {
  tabs: Tab[];
}

const TabRoutes: FC<TabRoutesProps> = ({ history, location, match, tabs }) => {
  const handleChange = (event: any, newValue: any) => {
    history.replace(`${match.url}${tabs[newValue]?.path ?? tabs[0].path}`);
  };

  const value = tabs.findIndex(tab => matchPath(location.pathname, { ...tab, path: `${match.url}${tab.path}` }));

  return (
    <>
      <Tabs
        indicatorColor={'primary'}
        value={value < 0 ? 0 : value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {tabs.map(tab => (
          <MuiTab key={tab.path} label={tab.title} />
        ))}
      </Tabs>
      <Switch>
        {tabs.map(tab => (
          <Route key={tab.path} {...tab} path={`${match.url}${tab.path}`} />
        ))}
        <Redirect to={`${match.url}${tabs[0].path}`} />
      </Switch>
    </>
  );
};

export default TabRoutes;

import set from 'lodash/set';
import update from 'lodash/fp/update';
import map from 'lodash/fp/map';
import flatMap from 'lodash/fp/flatMap';
import { getNestedValue } from '../../../se/utilities/data/object';
import { OR, PACU, POST_OP, PRE_OP, STATUS_LABELS, STATUSES, WAITING_ROOM } from './enums';
import {
  addFamilyReadyPACU,
  addFamilyReadyPOSTOP,
  assignInitials,
  getMonitorStatus,
  getORPatient,
  getPatientNameAsInitials,
  getStatus as getPatientStatus,
  mapMonitorRoomPatients,
} from '../patient/transducers';
import { sortByIdDesc } from '../common/transducers';
import { MONITOR_IGNORE_STATUSES, STATUSES as PATIENT_STATUSES, TABLET_IGNORE_STATUSES } from '../patient/enums';
import {
  getLogEntries,
  isHoldProcedureFn,
  isPatientReady,
  isPatientReadyForSurgeonFn,
} from '../../pages/kiosk/tablet/utils';
import get from 'lodash/get';

const typeKeys = {
  [WAITING_ROOM]: 'waitingRooms',
  [PRE_OP]: 'preOps',
  [OR]: 'operationRooms',
  [PACU]: 'pacus',
  [POST_OP]: 'postOps',
};

export const getStatus = (room, ignoreRoom) => {
  if (!ignoreRoom && getNestedValue('awaitingCleanup', room)) {
    return {
      status: STATUSES.CLEANING,
      statusLabel: STATUS_LABELS[STATUSES.CLEANING],
    };
  } else if (getNestedValue('patient.status', room)) {
    return getPatientStatus(getNestedValue('patient', room));
  }

  return {
    status: STATUSES.VACANT,
    statusLabel: STATUS_LABELS[STATUSES.VACANT],
  };
};

export const patientNameAsInitials = (patient, deduper, fullFirstName) => {
  if (patient) {
    return deduper({ ...patient, ...getPatientNameAsInitials(patient.name, 1, fullFirstName) });
  } else {
    return null;
  }
};

export const patientNameToId = (rooms = [], hospitalId, groupId = 1) =>
  rooms.map(({ patients, patient, ...rest }) => ({
    ...rest,
    patients: patients.map(p => set(p, 'initials', (hospitalId ? `G${groupId}H${hospitalId}-` : '#') + p.id)),
    patient: patient && set(patient, 'initials', (hospitalId ? `G${groupId}H${hospitalId}-` : '#') + patient.id),
  }));

export const patientNameToFullName = (rooms = []) =>
  rooms.map(({ patients, patient, ...rest }) => ({
    ...rest,
    patients: patients.map(p => set(p, 'initials', p.name)),
    patient: patient && set(patient, 'initials', patient.name),
  }));

export const patientNameToInitialsUnique = (rooms = [], fullFirstName, fullLastName) => {
  const allPatients = flatMap(_ => _.patients)(rooms).map(p => ({
    ...p,
    ...getPatientNameAsInitials(p.name, 1, fullFirstName, fullLastName),
  }));
  const assignInitialsFor = assignInitials(allPatients, fullFirstName, fullLastName);

  return rooms.map(({ patients, patient, ...rest }) => ({
    ...rest,
    patients: patients.map(p => patientNameAsInitials(p, assignInitialsFor, fullFirstName, fullLastName)),
    patient: patientNameAsInitials(patient, assignInitialsFor, fullFirstName, fullLastName),
  }));
};

export const hideProcedureType = hide => patient =>
  hide
    ? {
        ...patient,
        procedureType: patient.procedureType ? { ...patient.procedureType, name: undefined } : undefined,
      }
    : patient;

export const monitorHideProcedureType = (rooms = [], shouldHide) =>
  rooms.map(({ patients, patient, ...rest }) => ({
    ...rest,
    patients: patients.map(hideProcedureType(shouldHide)),
    patient: patient && hideProcedureType(shouldHide)(patient),
  }));

export const createMapOfRoomsByType = rooms => ({
  ...rooms.reduce((acc, room) => {
    const type = typeKeys[room.type];

    return {
      ...acc,
      [type]: [
        ...(acc[type] ? acc[type] : []),
        {
          ...room,
          ...getORPatient(room),
        },
      ].sort(sortByIdDesc),
    };
  }, {}),
});

export const mapMonitorRoom = room => ({
  ...room,
  ...mapMonitorRoomPatients(room)(room.patients),
});

export const mapTabletOperationRoom = room => {
  const statusMeta = getStatus(room);

  if (TABLET_IGNORE_STATUSES.includes(statusMeta.status)) {
    return {
      ...room,
      ...statusMeta,
      patient: undefined,
    };
  }

  return {
    ...room,
    ...statusMeta,
  };
};

export const mapMonitorOperationRoom = room => {
  const statusMeta = getStatus(room);

  if (MONITOR_IGNORE_STATUSES.includes(statusMeta.status)) {
    return {
      ...room,
      ...statusMeta,
      patient: undefined,
    };
  }

  const { monitorStatus } = getMonitorStatus(OR)(get(room, 'patient', {}));

  return {
    ...room,
    ...statusMeta,
    monitorStatus,
  };
};

const isWaitingReady = patient => !!patient.log.find(item => item.entries.find(entry => entry.type === 'BecameReady'));
const isPacuReady = patient => isPatientReady(getLogEntries(patient))(PACU);
const isPostOpReady = patient => isPatientReady(getLogEntries(patient))(POST_OP);
const isPrepReady = patient => isPatientReady(getLogEntries(patient))(PRE_OP);
const isOrReady = patient => getNestedValue('status', patient) === PATIENT_STATUSES.READY;

const isRoomReady = roomType => {
  switch (roomType) {
    case WAITING_ROOM:
      return isWaitingReady;
    case PRE_OP:
      return isPrepReady;
    case PACU:
      return isPacuReady;
    case POST_OP:
      return isPostOpReady;
    default:
      return () => false;
  }
};

export const mapReadyFor =
  roomType =>
  (room = {}) => ({
    ...room,
    patients: (room.patients || []).map(patient => {
      const ready = isRoomReady(roomType)(patient);
      if (roomType === PRE_OP) {
        return {
          ...patient,
          ready,
          readyForOr: ready,
          readyForSurgeon: isPatientReadyForSurgeonFn(getLogEntries(patient)),
          isHoldProcedure: isHoldProcedureFn(getLogEntries(patient)),
        };
      } else {
        return { ...patient, ready };
      }
    }),
  });

export const mapORReady = (rooms = []) =>
  rooms.map(room => ({
    ...room,
    patient: {
      ...(room.patient || {}),
      ready: isOrReady(room.patient),
    },
  }));

export const mapWaitingReady = mapReadyFor(WAITING_ROOM);
export const mapPrepReady = mapReadyFor(PRE_OP);
export const mapPacuReady = mapReadyFor(PACU);
export const mapPostOpReady = mapReadyFor(POST_OP);

export const mapFamilyReadyPACU = update('patients', map(addFamilyReadyPACU));
export const mapFamilyReadyPOSTOP = update('patients', map(addFamilyReadyPOSTOP));

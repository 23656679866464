import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setNotReadyToSeeFamilyPreOp, setReadyToSeeFamilyPreOp } from '../../../../graph/patients';

export const withMarkAsReadyToSeeFamilyPreOp = Component => props => (
  <Mutation mutation={setReadyToSeeFamilyPreOp}>
    {setReadyToSeeFamilyPreOp => <Component {...props} setReadyToSeeFamilyPreOp={setReadyToSeeFamilyPreOp} />}
  </Mutation>
);

export const withMarkAsNotReadyToSeeFamilyPreOp = Component => props => (
  <Mutation mutation={setNotReadyToSeeFamilyPreOp}>
    {setNotReadyToSeeFamilyPreOp => <Component {...props} setNotReadyToSeeFamilyPreOp={setNotReadyToSeeFamilyPreOp} />}
  </Mutation>
);

import React from 'react';
import styled from 'styled-components';
import responsive from '../../se/utilities/responsive';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from '../../components/pages/unpackSessionObject';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexShrink: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  backLink: {
    marginBottom: theme.spacing(1),
  },
}));

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${responsive.md.andSmaller`
    justify-content: flex-start;

    > * {
      margin-left: 0;
    }

    > a {
      margin-right: 0.5rem;
    }

    ${p => (p.responsiveStyle ? p.responsiveStyle : '')}
  `};
`;

const TitleAction = ({ Back, Title, children, style = {}, actionStyle = {} }) => {
  const classes = useStyles();

  return (
    <Box style={style} className={classes.root}>
      <Box className={classes.title}>
        {Back ? <Back className={classes.backLink} /> : null}
        {Title ? <Title /> : null}
      </Box>
      <Actions style={actionStyle.style} responsiveStyle={actionStyle.responsiveStyle}>
        {children}
      </Actions>
    </Box>
  );
};
export default withSession(unpackSessionObject)(TitleAction);

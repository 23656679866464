import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment ScreenData on Screen {
      id
      code
      connection {
        type {
          type
          room {
            id
            name
          }
        }
        active
        clientVersion
        lastDisconnectedAt
      }
      configuration {
        description
        showProcedure
        patientNameFormat
        physicianNameFormat
        dischargeSound
        orReadySound
        waitingReadySound
        pacuReadySound
        prepReadySound
        readyForSurgeonSound
        readyToPickupSound
        holdProcedureSound
        sendNotifications
        preOpFocused
        isTimeline
        limit
        minimumRating
        showBedNumber
        showStaffListOnSchedule
        useCssGridLayout
        showPostOp
      }
    }
  `,
};

export const list = gql`
  subscription {
    screens {
      ...ScreenData
    }
  }
  ${fragments.all}
`;

export const get = gql`
  query screen($screenCode: String!) {
    screen(screenCode: $screenCode) {
      ...ScreenData
    }
  }
  ${fragments.all}
`;

export const screenConfiguration = gql`
  subscription {
    screenConfiguration {
      description
      showProcedure
      patientNameFormat
      physicianNameFormat
      dischargeSound
      orReadySound
      waitingReadySound
      pacuReadySound
      prepReadySound
      readyForSurgeonSound
      readyToPickupSound
      holdProcedureSound
      sendNotifications
      preOpFocused
      isTimeline
      showBedNumber
      showStaffListOnSchedule
      useCssGridLayout
      showPostOp
    }
  }
`;

export const approveScreen = gql`
  mutation approveScreen($screenCode: String!, $screenType: IScreenType!) {
    approveScreen(screenCode: $screenCode, screenType: $screenType)
  }
`;

export const removeScreen = gql`
  mutation removeScreen($screenCode: String!) {
    removeScreen(screenCode: $screenCode)
  }
`;

export const updateScreen = gql`
  mutation updateScreen(
    $screenCode: String!
    $description: String
    $showProcedure: Boolean
    $patientNameFormat: PatientNameFormat
    $physicianNameFormat: PhysicianNameFormat
    $dischargeSound: Boolean
    $orReadySound: Boolean
    $waitingReadySound: Boolean
    $pacuReadySound: Boolean
    $prepReadySound: Boolean
    $readyForSurgeonSound: Boolean
    $readyToPickupSound: Boolean
    $holdProcedureSound: Boolean
    $sendNotifications: Boolean
    $preOpFocused: Boolean
    $isTimeline: Boolean
    $limit: Int
    $minimumRating: Int
    $showBedNumber: Boolean
    $showStaffListOnSchedule: Boolean
    $useCssGridLayout: Boolean
    $showPostOp: Boolean
  ) {
    updateScreen(
      screenCode: $screenCode
      description: $description
      showProcedure: $showProcedure
      patientNameFormat: $patientNameFormat
      physicianNameFormat: $physicianNameFormat
      dischargeSound: $dischargeSound
      orReadySound: $orReadySound
      waitingReadySound: $waitingReadySound
      pacuReadySound: $pacuReadySound
      prepReadySound: $prepReadySound
      readyForSurgeonSound: $readyForSurgeonSound
      readyToPickupSound: $readyToPickupSound
      holdProcedureSound: $holdProcedureSound
      sendNotifications: $sendNotifications
      preOpFocused: $preOpFocused
      isTimeline: $isTimeline
      limit: $limit
      minimumRating: $minimumRating
      showBedNumber: $showBedNumber
      showStaffListOnSchedule: $showStaffListOnSchedule
      useCssGridLayout: $useCssGridLayout
      showPostOp: $showPostOp
    )
  }
`;

export default {
  list,
};

import React from 'react';
import styled from 'styled-components';

const PatientIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  text-align: center;
  overflow: hidden;
  padding: 0.25rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
`;

const CustomIcon = styled.img`
  width: ${props => (props.inline ? '1em' : '2.5vw')};
  opacity: 0.5;
`;

export default ({ icon, backgroundColor, inline }) => (
  <PatientIcon style={{ backgroundColor }}>
    <CustomIcon src={require(`../../assets/images/icons/${icon}.png`).default} inline={inline} />
  </PatientIcon>
);

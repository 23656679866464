import React, { Fragment } from 'react';
import { format, isToday } from 'date-fns';
import styled from 'styled-components';
import isEmpty from 'lodash/fp/isEmpty';
import Procedures, { CondensedProcedures } from '../edit/Procedures';
import dateRange from '../../../../../assets/images/icons/date_range.png';
import responsive, { breakpoint } from '../../../../../se/utilities/responsive';
import { RoomName } from '../shared/RoomName';
import { AirFiltrationIndicatorTimer } from '../../widgets/AirFiltrationIndicator';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import { Box, Button } from '@material-ui/core';
import StaffShifts from '../staff/StaffShifts';
import StaffShiftMenuButton from '../staff/StaffShiftMenuButton';
import { StaffShiftRoomContext } from '../staff/StaffShiftContext';
import { StaffSlotRoomContext } from '../staff/StaffSlotContext';
import MultipleStaffShiftSelectionCheckbox from '../staff/MultipleStaffShiftSelectionCheckbox';
import { useScope } from '../../../../HospitalInfo';

const Columns = styled.div`
  display: flex;
  flex: 1 0 0;
  width: 100vw;
  overflow: ${props => (props.isKiosk ? 'initial' : 'auto')};
  padding-bottom: 0.375em;
  min-height: 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed', sans-serif;
  flex: 1 0 0;
  background-color: rgba(255, 255, 255, 0.07);
  margin: 0 0.1875em;
  border-radius: 0.3333em;
  padding: 0.66666em 0.375em 0;
  min-height: min-content;
  overflow: hidden;

  ${props =>
    props.isKiosk
      ? `
    display: flex;
    flex-direction: column;
    min-height: 0;
  `
      : null}

  ${responsive.md.andSmaller`
    margin: 0;
    padding: 0;
    overflow: initial;
    width: 100vw;
    flex: 1 0 auto;
    min-height: auto;

    scroll-snap-align: center;

    ${props => (props.current ? null : 'display: none;')}
  `};
`;

const EmptyColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  text-align: center;
  opacity: 0.2;
  padding: 1em;
`;

const RoomNameError = styled(RoomName)`
  color: #ff8900;
  opacity: 1;
`;

const indicatorStyles = {
  margin: '0.5em',
  width: '4rem',
  height: '4rem',
  borderWidth: '2px',
};

const size = ({ lg, md }) => {
  if (lg) {
    return '1.3333em';
  } else if (md) {
    return '1em';
  } else {
    return '1.3333em';
  }
};

export const RoomHeader = styled.div`
  display: flex;
  justify-content: ${props => (props.onlyRoomName ? 'center' : 'space-between')};
  align-items: center;
  margin-bottom: 0.5em;
  color: #abe3ff;
  > span {
    opacity: 0.5;
  }
  ${responsive.md.andSmaller`
    display: none;
  `};

  font-size: ${props => size(props)};
`;

const Overview = ({
  hospitalId,
  currentOR,
  isKiosk,
  operationRooms,
  canUseScheduleViewProcedure,
  isFrontDesk,
  isPowerUser,
  date,
  setEditProcedure,
  unassignedProcedures,
  editableStaff,
  showBedNumber,
  scheduleStaffList,
  openProcedureForm,
}) => {
  const scope = useScope();
  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;
  const isMobile = window.innerWidth < breakpoint.md;
  const canAddStaff = !isKiosk && !isFrontDesk && editableStaff;
  const editableAnesthesiologist = !isKiosk && !isFrontDesk && editableStaff;

  return (
    <Fragment>
      <Columns isKiosk={isKiosk}>
        {operationRooms.map((room, i) => {
          const { id, name, procedures, staffShifts } = room;
          return (
            <Column
              isKiosk={isKiosk}
              key={id || name}
              id={name}
              current={currentOR === name || (!currentOR && i === 0) ? 'true' : null}
            >
              <StaffShiftRoomContext
                hospitalId={hospitalId}
                roomId={id}
                date={format(date, 'YYYY-MM-DD')}
                staffShifts={staffShifts || []}
              >
                <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                  <div>
                    <RoomHeader onlyRoomName={!(canAddStaff && id !== undefined)}>
                      {canAddStaff && <Box>{!!id && <MultipleStaffShiftSelectionCheckbox roomId={id} />}</Box>}
                      <span>{name}</span>
                      {canAddStaff && (
                        <Box mr={1}>
                          {!!id && (
                            <StaffShiftMenu>{onClick => <StaffShiftMenuButton onClick={onClick} />}</StaffShiftMenu>
                          )}
                        </Box>
                      )}
                    </RoomHeader>

                    {isKiosk && (
                      <Box display="flex" flex={1} flexDirection="row" justifyContent="center">
                        <AirFiltrationIndicatorTimer roomId={id} style={indicatorStyles} radius={32} />
                      </Box>
                    )}

                    <Box display={'flex'} flexDirection={'row'} mb={1} ml={isMobile ? 1 : 0}>
                      {canAddStaff && isMobile ? (
                        <Box pr={1}>
                          {!!id && (
                            <StaffShiftMenu>{onClick => <StaffShiftMenuButton onClick={onClick} />}</StaffShiftMenu>
                          )}
                        </Box>
                      ) : null}
                      {(staffShifts || []).length !== 0 && (
                        <StaffShifts staffShifts={staffShifts || []} editableStaff={editableStaff} isKiosk={isKiosk} />
                      )}
                    </Box>
                    {!!id && createProcedureSchedule && canUseScheduleViewProcedure && (
                      <Button
                        color="primary"
                        onClick={() => {
                          openProcedureForm(undefined, operationRooms, room, date);
                        }}
                      >
                        Add Procedure
                      </Button>
                    )}
                  </div>
                  {procedures.length > 0 ? (
                    isKiosk ? (
                      <CondensedProcedures
                        hospitalId={hospitalId}
                        operationRooms={operationRooms}
                        operationRoom={room}
                        procedures={procedures}
                        isUnassigned={false}
                        date={date}
                        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                        setEditProcedure={setEditProcedure}
                        openProcedureForm={openProcedureForm}
                        isKiosk={isKiosk}
                        editableStaff={canAddStaff && editableStaff}
                        editableAnesthesiologist={editableAnesthesiologist}
                        showBedNumber={showBedNumber}
                        totalRoomNumber={operationRooms?.length}
                      />
                    ) : (
                      <Procedures
                        hospitalId={hospitalId}
                        operationRooms={operationRooms}
                        operationRoom={room}
                        procedures={procedures}
                        isUnassigned={false}
                        date={date}
                        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                        setEditProcedure={setEditProcedure}
                        openProcedureForm={openProcedureForm}
                        isKiosk={isKiosk}
                        editableStaff={canAddStaff && editableStaff}
                        editableAnesthesiologist={editableAnesthesiologist}
                        showBedNumber={showBedNumber}
                        totalRoomNumber={operationRooms?.length}
                      />
                    )
                  ) : (
                    <EmptyColumn>
                      <img
                        src={dateRange}
                        alt={'logo'}
                        style={{
                          fontSize: '4em',
                          width: '1em',
                          height: '1em',
                          margin: '0 auto .125em',
                          opacity: '.35',
                        }}
                      />
                      <span>
                        There are no procedures in {name} {isToday(date) ? 'for today' : 'on this day'}.
                      </span>
                    </EmptyColumn>
                  )}
                </StaffSlotRoomContext>
              </StaffShiftRoomContext>
            </Column>
          );
        })}
        {!isEmpty(unassignedProcedures) && (
          <Column key={0} isUnassigned>
            <RoomNameError>OR Unassigned</RoomNameError>
            <Procedures
              hospitalId={hospitalId}
              procedures={unassignedProcedures}
              isUnassigned={true}
              date={date}
              isPowerUser={isPowerUser}
              setEditProcedure={setEditProcedure}
              openProcedureForm={openProcedureForm}
              showBedNumber={showBedNumber}
              editableAnesthesiologist={editableAnesthesiologist}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
            />
          </Column>
        )}
        {scheduleStaffList}
      </Columns>
    </Fragment>
  );
};

export default Overview;

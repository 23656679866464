import { withProps } from 'recompose';

import SelectInput from '../../se/components/inputs/SelectInput';

export const options = {
  InitialsOnly: 'Initials Only (e.g. J. D.)',
  LastNameInitial: 'First Name and Last Name Initial (e.g. John D.)',
  FirstNameInitial: 'Last Name and First Name Initial (e.g. Doe J.)',
  FullName: 'Full Name (e.g. John Doe)',
  PatientId: 'Patient ID (e.g. G1H0-312)',
  ProviderId: 'Provider ID (e.g. 139212)',
  ProviderIdAndFirstName: 'Provider ID and First Name (e.g. 139212 John)',
};

export default withProps({
  options,
  name: 'nameFormat',
  required: true,
})(SelectInput);

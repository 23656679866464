import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../ScheduleProcedureForm';
import { RoomType } from '../../../../../../types/Room';
import { list as rooms } from '../../../../../../graph/rooms';
import { AutocompleteInputSetter } from '../components/AutocompleteInputSetter';
import { anesthesiaTypes } from '../../anesthesiologist/AnesthesiaType';
import { Autocomplete } from '@material-ui/lab';
import { bilaterallyTypes, FormParams, patientTypes, ProcedureDetails, RoomPicked } from '../types/types';
import set from 'lodash/fp/set';
import { format } from 'date-fns';
import { isAcceptableState } from '../../../../../../se/components/controls/TimeControl';
import { DateInput } from '../components/DateInput';
import { roomPickedInitial, setValueAndResetError } from '../utils/values';
import { transformValue } from '../utils/functions';
import ProcedureTypeAutocomplete from '../components/ProcedureTypeAutocomplete';

// rewrite when you have more time
export const handleTimeOnChange = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  value: ProcedureDetails,
  setValue: (value: ProcedureDetails) => void
): void => {
  const potentialNewValue = event.target.value;
  const acceptableState = isAcceptableState(event);
  acceptableState &&
    setValue(
      setValueAndResetError(
        'timeInfo.timeRange.startTime',
        acceptableState.transform
          ? acceptableState.transform(
              potentialNewValue,
              event.target.value.length < (value?.timeInfo?.timeRange?.startTime?.value || '')?.length
            )
          : potentialNewValue,
        value
      )
    );
};

const padToDigits = (num: number, digits: number) => num.toString().padStart(digits, '0');

export const toHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  if (hours === 0) return `${padToDigits(minutes, 2)}mins`;

  return `${padToDigits(hours, 1)} h${padToDigits(minutes, 2)}mins`;
};

const ProcedureInfoForm: FC<FormParams<ProcedureDetails>> = ({ value, setValue }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Procedure
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <AutocompleteInputSetter
              list={rooms}
              label="Operation Room"
              variant="filled"
              margin="dense"
              fullWidth
              required
              error={!!value?.room?.name?.error}
              helperText={value?.room?.name?.error}
              filter={(room: RoomPicked) => room.type === RoomType.OR}
              onChange={event => {
                if (event?.constructor?.name === 'SyntheticEvent') {
                  setValue(setValueAndResetError('room.name', event.target.value, value));
                } else {
                  setValue(set('room', transformValue(event || {}, roomPickedInitial))(value));
                }
              }}
              value={value?.room?.name?.value}
              clearOnClick={true}
            />
            <ProcedureTypeAutocomplete value={value} setValue={setValue} />
            <Autocomplete
              options={anesthesiaTypes}
              getOptionLabel={(option: string) => option}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  error={!!value?.anesthesiaType?.error}
                  helperText={value?.anesthesiaType?.error}
                  label="Type of Anesthesia"
                  variant="filled"
                  margin="dense"
                  fullWidth
                />
              )}
              value={value?.anesthesiaType?.value}
              onChange={(event, val) => setValue(setValueAndResetError('anesthesiaType', val || '', value))} //id missing
            />
            <TextField
              label="CPT Codes"
              variant="filled"
              margin="dense"
              fullWidth
              value={value?.cptCodes?.value || ''}
              onChange={event => setValue(set('cptCodes.value', event.target.value)(value))}
            />
            <Autocomplete
              options={bilaterallyTypes}
              getOptionLabel={(option: string) => option}
              renderInput={params => (
                <TextField {...params} label="Bilaterality" variant="filled" margin="dense" fullWidth />
              )}
              value={value?.bilaterally?.value}
              onChange={(event, val) => setValue(set('bilaterally.value', val)(value))}
            />
          </Grid>

          <Grid item md={5}>
            <Grid container spacing={1}>
              <Grid item md={5}>
                <TextField
                  required
                  error={!!value?.timeInfo?.timeRange?.startTime?.error}
                  helperText={value?.timeInfo?.timeRange?.startTime?.error}
                  label="Start Time"
                  variant="filled"
                  margin="dense"
                  value={value?.timeInfo?.timeRange?.startTime?.value || ''}
                  onChange={event => handleTimeOnChange(event, value, setValue)}
                />
              </Grid>
              <Grid item md={7}>
                <TextField
                  required
                  error={!!value?.timeInfo?.timeRange?.duration?.error}
                  helperText={value?.timeInfo?.timeRange?.duration?.error}
                  label={
                    !!value?.timeInfo?.timeRange?.duration?.value
                      ? `Duration (${toHoursAndMinutes(value?.timeInfo?.timeRange?.duration?.value)})`
                      : 'Duration'
                  }
                  variant="filled"
                  type={'number'}
                  margin="dense"
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 720,
                      step: 15,
                    },
                  }}
                  value={value?.timeInfo?.timeRange?.duration?.value || ''}
                  onChange={event =>
                    setValue(
                      setValueAndResetError('timeInfo.timeRange.duration', parseInt(event.target.value, 10) || 0, value)
                    )
                  }
                />
              </Grid>
            </Grid>
            <DateInput
              required
              disabled={true}
              label="Date of Service"
              error={!!value?.timeInfo?.dateOfService?.error}
              helperText={value?.timeInfo?.dateOfService?.error}
              value={value?.timeInfo?.dateOfService?.value || ''}
              onChange={event => setValue(set('timeInfo.dateOfService.value', event.target.value)(value))}
              min={format(new Date(), 'YYYY-MM-DD')}
            />

            <Autocomplete
              options={patientTypes}
              getOptionLabel={(option: string) => option}
              renderInput={params => (
                <TextField {...params} label="Type of Visit" variant="filled" margin="dense" fullWidth />
              )}
              value={value?.visit?.visitType?.value}
              onChange={(event, val) => setValue(set('visit.visitType.value', val)(value))}
            />
            {value?.visit?.visitType?.value === 'In' && (
              <TextField
                label="Inpatient Room # "
                variant="filled"
                margin="dense"
                value={value?.visit?.room?.value || ''}
                onChange={event => setValue(set('visit.room.value', event.target.value)(value))}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ProcedureInfoForm;

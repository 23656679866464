import React, { FC, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import StaffShiftT from '../../../../../types/StaffShift';
import StaffShift from './StaffShift';
import { sortBy } from 'lodash';
import { Procedure } from '../../../../../types/Procedure';

interface StaffShiftsProps {
  staffShifts: StaffShiftT[];
  procedure?: Procedure;
  isKiosk?: boolean;
  editableStaff?: boolean;
  isFooter?: boolean;
}

const StaffShifts: FC<StaffShiftsProps> = ({
  staffShifts,
  procedure,
  isKiosk,
  isFooter = true,
  editableStaff,
  children,
}) => {
  const classes = useStyles();

  const sorted = useMemo(() => sortBy(staffShifts, 'id'), [staffShifts]);

  return (
    <div className={classes.staffShifts} style={{ flexDirection: isFooter ? 'row' : 'column' }}>
      {children}
      {sorted.map(staffShift => (
        <StaffShift
          key={staffShift.id}
          {...staffShift}
          from={staffShift.from}
          to={staffShift.to}
          additionalText={staffShift.additionalText}
          procedure={procedure}
          isKiosk={isKiosk}
          editableStaff={editableStaff}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    staffShifts: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: -2,

      '& > *': {
        margin: 2,
      },
    },
  })
);

export default StaffShifts;

import React, { ChangeEvent, FC, FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Button, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import Send from '@material-ui/icons/Send';

interface ComposerProps {
  onSend: (content: string) => Promise<void>;
}

const Composer: FC<ComposerProps> = ({ onSend }) => {
  const classes = useStyles();

  const input = useRef<HTMLInputElement>(null);

  const [busy, setBusy] = useState(false);
  const [content, setContent] = useState('');

  const send = async () => {
    if (busy || !content.trim()) {
      return;
    }

    try {
      setBusy(true);
      await onSend(content.trim());
      setContent('');
    } catch (e) {
      // TODO
    } finally {
      setBusy(false);
      input.current?.focus();
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    send().then(r => r);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === 'enter' && e.metaKey) {
      send().then(r => r);
    }
  };

  useEffect(() => {
    if (input.current && document.activeElement !== input.current) {
      input.current.focus();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        multiline
        label="Aa"
        variant="outlined"
        color="primary"
        inputRef={input}
        inputProps={{
          'aria-autocomplete': 'none',
          'aria-haspopup': 'false',
          'aria-label': 'Compose message',
        }}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        autoFocus
        contentEditable="true"
        role="textbox"
        spellCheck={false}
        value={content}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={busy}
        className={classes.inputField}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={busy || !content.trim()}
        endIcon={<Send />}
        className={classes.button}
      >
        Send
      </Button>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      width: '100%',
      marginRight: theme.spacing(2),
    },
    button: {
      textTransform: 'uppercase',
    },
    form: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  })
);

export default Composer;

import { addMinutes, compareAsc } from 'date-fns';
import { get } from 'lodash';
import { NO_STATUS, STATUSES as PATIENT_STATUSES } from '../../../entities/patient/enums';

export const getCurrentProcedureAndNextProcedures = procedures => {
  const currentProcedure = (procedures || []).find(
    p =>
      compareAsc(new Date(addMinutes(new Date(p.startTime), p.duration)), new Date()) !== -1 &&
      (get(p, 'patient.status', '') === NO_STATUS || get(p, 'patient.status', '') === PATIENT_STATUSES.ADMITTED)
  );
  const currentProcedureIndex = (procedures || []).findIndex(
    p =>
      compareAsc(new Date(addMinutes(new Date(p.startTime), p.duration)), new Date()) !== -1 &&
      (get(p, 'patient.status', '') === NO_STATUS || get(p, 'patient.status', '') === PATIENT_STATUSES.ADMITTED)
  );
  const nextProceduresInOperationRoom = currentProcedureIndex !== -1 ? procedures.slice(currentProcedureIndex) : [];
  return [currentProcedure, nextProceduresInOperationRoom];
};

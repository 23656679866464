import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, Chip, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import StaffMemberT, { PrimarySpecialtyColors, Title, TitleAbbreviationLabel } from '../../../../../types/StaffMember';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'clsx';
import StaffShiftTime from './StaffShiftTime';
import StaffShiftText from './StaffShiftText';
import formatTimeRange from '../../../calendar/util/formatTimeRange';
import { Procedure } from '../../../../../types/Procedure';
import { LocalTime } from '@js-joda/core';

interface StaffMemberProps extends StaffMemberT {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | Promise<void>;
  toggleStaffMember?: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  className?: string;
  from?: string;
  to?: string;
  additionalText?: string;
  shiftId?: number;
  procedure?: Procedure;
  editTime: boolean;
  editText: boolean;
  setEditTime?: Dispatch<SetStateAction<boolean>>;
  setEditText?: Dispatch<SetStateAction<boolean>>;
  hideClock?: boolean;
  hideText?: boolean;
  inputRef: any;
  inputRefText: any;
  interactive?: boolean;
  editableStaff?: boolean;
  isMenuListItem?: boolean;
}

export const getStaffFormattedName = (name?: String, title?: Title | null) => {
  if (!name) return null;
  const [firstName, lastName] = name?.split(/\s+/);
  const lastNameInitial = lastName?.charAt(0).toUpperCase();

  return title
    ? firstName && lastName
      ? `${firstName} ${lastNameInitial}. ${TitleAbbreviationLabel[title]}`
      : `${name} ${TitleAbbreviationLabel[title]}`
    : firstName && lastName
    ? `${firstName} ${lastNameInitial}.`
    : name;
};

export const toLocalTime = (time: string): LocalTime | null => {
  const [hours, minutes] = time.split(':');
  if (!hours && !minutes) return null;

  const hoursNumber = parseInt(hours, 10);
  const minutesNumber = parseInt(minutes, 10);

  return isFinite(hoursNumber) && isFinite(minutesNumber) ? LocalTime.of(hoursNumber, minutesNumber, 0, 0) : null;
};

export const isPTO = (from?: string, to?: string, ptoFrom?: string, ptoTo?: string): boolean => {
  if (!ptoFrom && !ptoTo) return true;
  if (!from && !to) return true;

  const fromTime = from ? toLocalTime(from) : toLocalTime('00:00');
  const toTime = to ? toLocalTime(to) : toLocalTime('23:59');
  const ptoFromTime = ptoFrom ? toLocalTime(ptoFrom) : toLocalTime('00:00');
  const ptoToTime = ptoTo ? toLocalTime(ptoTo) : toLocalTime('23:59');

  if (
    (fromTime && ptoFromTime?.isAfter(fromTime) && toTime && ptoFromTime?.isBefore(toTime)) ||
    (fromTime && ptoToTime?.isAfter(fromTime) && toTime && ptoToTime?.isBefore(toTime))
  )
    return true;

  return !!(fromTime && ptoFromTime?.isBefore(fromTime) && toTime && ptoToTime?.isAfter(toTime));
};

const StaffMember: FC<StaffMemberProps> = ({
  shiftId,
  editTime,
  editText,
  setEditTime,
  setEditText,
  hideClock,
  hideText,
  onClick,
  inputRef,
  inputRefText,
  interactive,
  editableStaff,
  className,
  from,
  to,
  additionalText,
  isMenuListItem,
  procedure,
  ...staffMember
}) => {
  const { name, title, primarySpecialty, assignedShift, availability } = staffMember;
  const classes = useStyles();
  const formattedName = getStaffFormattedName(name, title);
  const hasPTO = (availability || [])?.some(
    e => !e.available && isPTO(procedure?.startTimeText, procedure?.endTimeText, e.from, e.to)
  );

  return (
    <div className={classNames(classes.root, className)}>
      <Chip
        icon={
          <FiberManualRecordOutlinedIcon
            className={classes.icon}
            style={{
              color: primarySpecialty ? PrimarySpecialtyColors[primarySpecialty] : undefined,
            }}
          />
        }
        label={
          <Box className={classes.content}>
            <Box>{formattedName}</Box>
            <StaffShiftTime
              editTime={editTime}
              setEditTime={setEditTime}
              from={assignedShift?.from || from}
              to={assignedShift?.to || to}
              assignedShiftId={assignedShift?.id || shiftId}
              hideClock={hideClock}
              inputRef={inputRef}
              editableStaff={editableStaff}
            />
            <StaffShiftText
              editText={editText}
              setEditText={setEditText}
              additionalText={assignedShift?.additionalText || additionalText}
              assignedShiftId={assignedShift?.id || shiftId}
              hideText={hideText}
              inputRef={inputRefText}
              editableStaff={editableStaff}
            />

            {hasPTO && !isMenuListItem && (
              <Tooltip
                title={
                  <Box>
                    {(availability || [])
                      .filter(e => !e.available)
                      .map(e => (
                        <Box>{`${formatTimeRange(e.from, e.to)} PTO`}</Box>
                      ))}
                  </Box>
                }
              >
                <WarningIcon className={classes.warning} />
              </Tooltip>
            )}
          </Box>
        }
        size="small"
        onClick={onClick}
        className={classNames(
          classes.chip,
          { [classes.interactive]: interactive !== undefined ? interactive : Boolean(onClick) },
          { [classes.notAvailable]: hasPTO && isMenuListItem }
        )}
      />
      {!!assignedShift && !hasPTO && <CheckCircle className={classes.checkmark} />}
      {hasPTO && isMenuListItem && (
        <Tooltip
          title={
            <Box>
              {(availability || [])
                .filter(e => !e.available)
                .map(e => (
                  <Box>{`${formatTimeRange(e.from, e.to)} PTO`}</Box>
                ))}
            </Box>
          }
        >
          <WarningIcon className={classes.warning} />
        </Tooltip>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chip: {
      backgroundColor: '#1a2553',
      color: '#fff',
      fontWeight: 500,
    },
    icon: {
      color: 'rgba(127, 127, 127, 0.2)',
    },
    checkmark: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      fontSize: '0.9rem',
      color: theme.palette.success.main,
    },
    warning: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      fontSize: '0.9rem',
      color: theme.palette.warning.main,
    },
    noteIcon: {
      marginLeft: '0.2rem',
      color: theme.palette.primary.light,
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noteInput: {
      marginLeft: theme.spacing(1),
      color: 'inherit',
      backgroundColor: theme.palette.background.default,
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    interactive: {
      cursor: 'pointer',
    },
    notAvailable: {
      opacity: 0.2,
    },
  })
);

export default StaffMember;

import React from 'react';
import { useMutation } from '@apollo/client';
import { sendNewScheduleNotification } from '../../../../../graph/anesthesiologists';
import { format } from 'date-fns';
import SendNotificationDialog, { Contact } from '../SendNotificationDialog';
import { Destination } from '../../../../../types/NotificationTemplate';

const SendNotification = ({
  open,
  handleClose,
  date,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  date: Date;
  data: any;
}) => {
  const [doSend] = useMutation(sendNewScheduleNotification);

  const sendNotifications = async (recipients: Contact[], message: String) => {
    await doSend({
      variables: {
        contacts: recipients,
        message,
        date: format(date, 'YYYY-MM-DD'),
      },
    });
  };

  return (
    <SendNotificationDialog
      date={date}
      open={open}
      recipients={data?.listAssignedAnesthesiologists || []}
      sendNotifications={sendNotifications}
      destination={Destination.Anesthesiologist}
      handleClose={handleClose}
    />
  );
};

export default SendNotification;

import {
  ADMIN,
  FRONT_DESK,
  SENSOR_SUPERVISOR,
  MONITORING,
  NURSING,
  OPERATIONS_MANAGER,
  BUSINESS_MANAGER,
  PREOP_NURSE,
  SUPER_ADMIN,
  GROUP_ADMIN,
  SCHEDULER,
  NURSE,
} from './enums';

export const superUserRoleLabels = {
  [SUPER_ADMIN]: 'Super Administrator',
  [GROUP_ADMIN]: 'Group Administrator',
  [MONITORING]: 'Monitoring Engineer',
};

export const userRoleLabels = {
  [BUSINESS_MANAGER]: 'Business Manager',
  [PREOP_NURSE]: 'PreOP Nurse',
  [OPERATIONS_MANAGER]: 'Operations Manager',
  [NURSING]: 'Nursing',
  [NURSE]: 'Nurse',
  [SCHEDULER]: 'Scheduler',
  [FRONT_DESK]: 'Front Desk',
  [ADMIN]: 'Administrator',
  [SENSOR_SUPERVISOR]: 'Sensor Supervisor',
};

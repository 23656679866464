import { withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

import PatientCapacityInput from '../patient/PatientCapacityInput';
import ToggleInput from '../../../se/components/inputs/ToogleInput';

export default withProps({
  schema: {
    capacity: PatientCapacityInput,
    isAlphabeticBedOrder: withProps({ label: 'The beds are marked with letters in alphabetical order' })(ToggleInput),
  },
})(ObjectInput);

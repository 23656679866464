import { compose, mapProps, withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { withLabel } from '../../../se/components/Label';
import SwitchInput from '../../../se/components/inputs/SwitchInput';
import { OR, PACU, PRE_OP, POST_OP, ROOM_TYPE_LABELS, UTILITY, WAITING_ROOM } from '../../entities/room/enums';

import EmptyInput from './EmptyInput';
import PreparationRoomInput from './PreparationRoomInput';
import OperationRoomInput from './OperationRoomInput';
import PacuRoomInput from './PacuRoomInput';
import { withScope } from '../../HospitalInfo';
import omit from 'lodash/omit';
import PostOpRoomInput from './PostOpRoomInput';

export const options = {
  WAITING_ROOM: { label: ROOM_TYPE_LABELS[WAITING_ROOM], Input: EmptyInput },
  PRE_OP: { label: ROOM_TYPE_LABELS[PRE_OP], Input: PreparationRoomInput },
  OR: { label: ROOM_TYPE_LABELS[OR], Input: OperationRoomInput },
  PACU: { label: ROOM_TYPE_LABELS[PACU], Input: PacuRoomInput },
  UTILITY: { label: ROOM_TYPE_LABELS[UTILITY], Input: EmptyInput },
  POST_OP: { label: ROOM_TYPE_LABELS[POST_OP], Input: PostOpRoomInput },
};

export default compose(
  withScope,
  mapProps(({ scope, ...props }) => {
    const hasInpatients = scope?.hospital?.modules?.hasPostop;

    return hasInpatients ? { options, ...props } : { options: omit(options, 'POST_OP'), ...props };
  }),
  mapProps(({ options, ...props }) => ({
    options: Object.entries(options).reduce(
      (acc, [roomType, item]) => ({
        ...acc,
        [roomType]: {
          ...item,
          valueKey: [roomType],
          flatten: true,
        },
      }),
      {}
    ),
    ...props,
  })),
  withProps({
    SelectInput: compose(withProps({ placeholder: 'e.g., OR', required: true }), withLabel('Type'))(SelectInput),
    typeKey: 'type',
    flatten: ['capacity', 'availability'],
  })
)(SwitchInput);

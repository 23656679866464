import React, { Fragment } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { format } from 'date-fns';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import renderNode from '../components/renderNode';
import { Label } from './Common';
import parseJson from '../utils/parseJson';
import useHasAccessRight from '../../../../hooks/useHasAccessRight';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../../NoPermissionText';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  blurred: {
    filter: 'blur(1.5rem)',
  },
}));

const QuestionnaireAnswersPreview = ({ questions, answers, submittedAt, children }) => {
  const classes = useStyles();
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  return (
    <Fragment>
      {answers ? (
        <ExpansionPanel square elevation={0}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            {children}
            {submittedAt && (
              <Label>
                Submitted on {format(submittedAt, 'MM/DD/YYYY')} at {format(submittedAt, 'HH:mm:ss')}
              </Label>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={clsx({ [classes.root]: !isAllowedToView })}>
            <Box>
              {!isAllowedToView && <NoPermissionText withText={true} style={{ top: '50%', left: '30%' }} />}
              <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
                {renderNode(parseJson(questions), { value: parseJson(answers) })}
              </Box>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ) : (
        <Label style={{ marginBottom: 0, marginLeft: 0, marginRight: 'auto' }}>
          Waiting for patient to fill the form
        </Label>
      )}
    </Fragment>
  );
};

export default QuestionnaireAnswersPreview;

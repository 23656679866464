import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';

import theme from '../theme';

import routerHistory from '../essentials/routerHistory';
import { ClickAwayPresenceProvider } from '../contexts/ClickawayPresence';

import Application from './Application';
import Toaster from './core/Toaster';
import { SessionProvider } from '../state/Session';
import scrollSnapPolyfill from 'css-scroll-snap-polyfill';
import { muiThemeLight } from '../muiTheme';
import { CssBaseline } from '@material-ui/core';

export default () => {
  useEffect(() => {
    scrollSnapPolyfill();
  });
  return (
    <Router history={routerHistory}>
      <ThemeProvider theme={theme}>
        <MUIThemeProvider theme={muiThemeLight}>
          <CssBaseline />
          <SessionProvider>
            <Toaster>
              <ClickAwayPresenceProvider>
                <Application />
              </ClickAwayPresenceProvider>
            </Toaster>
          </SessionProvider>
        </MUIThemeProvider>
      </ThemeProvider>
    </Router>
  );
};

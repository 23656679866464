import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import schema from '../../../graph/procedures';
import { listColumns } from './columns';
import { format } from 'date-fns';

const Empty = {
  title: 'There are no registered patients today.',
  hint: '',
  illustration: theme => theme.illustrations.patients,
};

const FrontdeskProcedures = GraphQLCRUD({
  entityName: 'Unregistered Patients',
  scheme: { list: schema.frontListSubscription },
  List: {
    tableKey: 'FScheduledProcedure',
    useColumnSelection: false,
    columns: listColumns,
    queryOptionsProvider: () => ({
      variables: {
        date: format(new Date(), 'YYYY-MM-DD'),
        isCanceled: false,
      },
    }),
    Empty,
  },
});

export default FrontdeskProcedures;

import { gql } from '@apollo/client';

export const chartsAndAnswers = gql`
  query chartsAndAnswers($procedureId: Long!) {
    chartsAndAnswers(procedureId: $procedureId) {
      id
      questionnaire {
        id
        name
        icon
        type
        content
        order
      }
      questionnaireExchange {
        id
        procedureId
        answers
        submittedAt
      }
      completed
    }
  }
`;

export const createQuestionnaireAnswers = gql`
  mutation createQuestionnaireExchange($procedureId: Long!, $questionnaireId: Long!, $answers: Json!) {
    createQuestionnaireExchange(procedureId: $procedureId, questionnaireId: $questionnaireId, answers: $answers)
  }
`;

export const updateQuestionnaireAnswers = gql`
  mutation updateQuestionnaireExchange($questionnaireExchangeId: Long!, $answers: Json!) {
    updateQuestionnaireExchange(questionnaireExchangeId: $questionnaireExchangeId, answers: $answers)
  }
`;

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { list, patientFile } from '../../../../graph/surgeon/forms';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { formSaveTypeLabels } from '../../procedures/enums';
import DialogActions from '@material-ui/core/DialogActions';
import Spinner from '../../../../se/components/Spinner';

const PatientFileGenerator = ({ procedureId }) => {
  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);

  const [state, setState] = useState({});
  const { data: formQuery } = useQuery(list);
  const reportForms = useMemo(() => get(formQuery, 'forms', []), [formQuery]);

  const sections = useMemo(() => groupBy(reportForms, _ => _.saveType), [reportForms]);

  useEffect(() => {
    setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
  }, [reportForms]);

  const anyChecked = useMemo(() => Object.values(state).includes(true), [state]);

  const client = useApolloClient();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleGenerate = async () => {
    if (anyChecked) {
      setBusy(true);
      try {
        const formIds = Object.entries(state)
          .filter(([_, value]) => !!value)
          .map(([key, _]) => parseInt(key, 10))
          .sort((a, b) => reportForms.find(x => x.id === a).order - reportForms.find(x => x.id === b).order);

        const buildResult = await client.query({
          query: patientFile,
          variables: { procedureId, ids: formIds, mdSignature: false },
          fetchPolicy: 'network-only',
        });

        if (buildResult.data) {
          const link = document.createElement('a');
          link.download = 'PatientFile' + procedureId + Date.now() + '.pdf';
          link.target = '_blank';
          link.href = buildResult.data.patientFile;
          link.text = 'PDF download';
          link.dispatchEvent(new MouseEvent('click'));
          URL.revokeObjectURL(link.href);
        }
      } finally {
        setBusy(false);
      }
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (id, checked) => {
    setState({ ...state, [id]: checked });
  };

  const selectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
  };

  const deselectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
  };

  return (
    <Fragment>
      {reportForms.length > 0 && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
          <Hidden smDown>
            <Button width={200} color="primary" onClick={handleClickOpen}>
              Generate Medical File
            </Button>
          </Hidden>
        </Box>
      )}
      <Dialog fullWidth={true} open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle>Generate Medical File</DialogTitle>
        <DialogContent>
          <DialogContentText>Please select sections for patient file.</DialogContentText>
          <Box mt={2} display="flex" flexDirection="row" width="100%">
            <FormGroup style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Button color="primary" size="small" onClick={selectAll}>
                      Select all
                    </Button>
                    <Button color="primary" size="small" onClick={deselectAll}>
                      Deselect all
                    </Button>
                  </Box>
                </Grid>
                {Object.entries(sections)
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([type, items], index) => (
                    <Grid item md={6} key={`section-patient-file-generator-${index}`}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formSaveTypeLabels[type]}
                      </Typography>
                      <Box display="flex" flexDirection="column">
                        {items.map(item => (
                          <FormControlLabel
                            key={`${type}-${item.id}`}
                            control={
                              <Checkbox
                                checked={state[item.id]}
                                onChange={e => handleCheckboxChange(item.id, e.target.checked)}
                                name={item.name}
                              />
                            }
                            label={item.name}
                          />
                        ))}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button disabled={busy || !anyChecked} onClick={handleGenerate} color="primary">
            Generate & Download
          </Button>

          {busy && (
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PatientFileGenerator;

import React, { ReactComponentElement } from 'react';
import { alpha, Box, ButtonBase, fade, Typography, useTheme } from '@material-ui/core';
import { CheckCircleTwoTone } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const SectionButton = ({
  text,
  Icon,
  completed,
  onClick,
  ...rest
}: {
  text: string;
  Icon?: ReactComponentElement<any>;
  completed?: boolean;
  onClick: () => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      className={classes.sectionButton}
      style={{
        backgroundColor: !completed ? fade(theme.palette.common.white, 0.1) : fade(theme.palette.common.white, 0.025),
        border: completed ? `1px solid ${alpha(theme.palette.common.white, 0.1)}` : undefined,
      }}
      {...rest}
    >
      <Box position="relative" display="flex" style={{ opacity: completed ? 0.5 : 1 }}>
        <div>{Icon}</div>
      </Box>
      <Box>
        <Typography variant="h4" color={completed ? 'textSecondary' : 'textPrimary'}>
          {text}
        </Typography>
        {completed && (
          <Typography color="primary">
            <Box display="flex" alignItems="center" justifyContent="center">
              Completed
              <Box ml={0.5} display="flex">
                <CheckCircleTwoTone fontSize="inherit" />
              </Box>
            </Box>
          </Typography>
        )}
      </Box>
    </ButtonBase>
  );
};

const useStyles = makeStyles({
  sectionButton: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: alpha('#fff', 0.05),
    fontSize: '4rem',
    border: `2px solid ${alpha('#fff', 0.1)}`,
    borderRadius: '4px',
  },
});

export default SectionButton;

import React, { FC, Reducer, useEffect, useReducer, useRef } from 'react';
import { Chip, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { gql, useSubscription } from '@apollo/client';
import classNames from 'clsx';
import update from 'lodash/fp/update';
import { format } from 'date-fns';
import { sortedIndexBy } from 'lodash';
import { set } from 'lodash/fp';
import flow from 'lodash/fp/flow';
import identity from 'lodash/fp/identity';
import DeliveryStatusComponent from './DeliveryStatus';

type Reset = {
  type: 'Reset';
};

type WatchMessagePayload = CreateMessage | UpdateDeliveryStatus;

interface CreateMessage {
  type: 'CreateMessage';
  message: Message;
}

interface UpdateDeliveryStatus {
  type: 'UpdateDeliveryStatus';
  messageId: string;
  contactInformation: string;
  deliveryStatus: DeliveryStatus;
}

type DeliveryStatus = Queued | Sent | Delivered | Failed | Rejected | Unknown;

interface Queued {
  type: 'Queued';
}
interface Sent {
  type: 'Sent';
}
interface Delivered {
  type: 'Delivered';
}
interface Failed {
  type: 'Failed';
}
interface Rejected {
  type: 'Rejected';
  reason: string;
}
interface Unknown {
  type: 'Unknown';
  json: string;
}

interface Message {
  id: string;
  content: string;
  createdAt: string;
  sender: string;
  recipients: Recipient[];
}

export interface Recipient {
  contactInformation: string;
  deliveryStatus: DeliveryStatus;
}

interface MessageProps {
  message: Message;
}

const ThreadMessage: FC<MessageProps> = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.message, { [classes.incomingMessage]: message.recipients.length === 0 })}>
      <div className={classes.time}>
        <Typography>{format(message.createdAt, 'H:mm')}</Typography>
      </div>
      <div
        className={classNames(classes.messageContent, {
          [classes.incomingMessageContent]: message.recipients.length === 0,
        })}
      >
        <Typography>{message.content}</Typography>
        <div className={classes.sender}>{message.sender && <Typography>Sent by {message.sender}</Typography>}</div>
        <div className={classes.deliveryStatus}>
          <DeliveryStatusComponent recipients={message.recipients} />
        </div>
      </div>
    </div>
  );
};

interface DateGroup {
  date: string;
  messages: Message[];
}

function splice<T>(start: number, deleteCount: number, ...items: T[]) {
  return (array: T[]) => {
    const clone = [...array];
    clone.splice(start, deleteCount, ...items);
    return clone;
  };
}

interface ReducerState {
  messages: { [id: string]: Message };
  groups: DateGroup[];
}

function threadReducer(state: ReducerState, action: WatchMessagePayload | Reset) {
  switch (action.type) {
    case 'Reset':
      return { messages: {}, groups: [] };
    case 'CreateMessage': {
      const date = action.message.createdAt.substr(0, 10);
      const dateIndex = sortedIndexBy(state.groups, { date, messages: [] }, 'date');
      return flow(
        set(['messages', action.message.id], action.message),
        dateIndex === state.groups.length || state.groups[dateIndex].date !== date
          ? update('groups', splice(dateIndex, 0, { date, messages: [action.message] }))
          : update(['groups', dateIndex, 'messages'], messages => {
              const messageIndex = sortedIndexBy(messages, action.message, 'createdAt');
              return splice(messageIndex, 0, action.message)(messages);
            })
      )(state);
    }
    case 'UpdateDeliveryStatus': {
      const message = state.messages[action.messageId];
      if (message) {
        const date = message.createdAt.substr(0, 10);
        const dateIndex = sortedIndexBy(state.groups, { date, messages: [] }, 'date');
        const updateRecipients = (recipients: Recipient[]) => {
          const recipientIndex = recipients.findIndex(
            recipient => recipient.contactInformation === action.contactInformation
          );

          if (recipientIndex < 0) {
            return [
              ...recipients,
              {
                contactInformation: action.contactInformation,
                deliveryStatus: action.deliveryStatus,
              },
            ];
          } else {
            return set([recipientIndex, 'deliveryStatus'], action.deliveryStatus)(recipients);
          }
        };
        return flow(
          update(['messages', message.id, 'recipients'], updateRecipients),
          dateIndex === state.groups.length || state.groups[dateIndex].date !== date
            ? identity
            : update(['groups', dateIndex, 'messages'], messages => {
                let messageIndex = sortedIndexBy(messages, message, 'createdAt');

                if (messageIndex < 0) {
                  return messages;
                } else {
                  while (messageIndex < messages.length && messages[messageIndex].id !== message.id) {
                    messageIndex++;
                  }

                  if (messageIndex >= messages.length) {
                    return messages;
                  }

                  return update(messageIndex, (existingMessage: Message) => {
                    const recipientIndex = existingMessage.recipients.findIndex(
                      recipient => recipient.contactInformation === action.contactInformation
                    );

                    if (recipientIndex < 0) {
                      return update('recipients', existing => [
                        ...existing,
                        {
                          contactInformation: action.contactInformation,
                          deliveryStatus: action.deliveryStatus,
                        },
                      ])(existingMessage);
                    } else {
                      return set(
                        ['recipients', recipientIndex, 'deliveryStatus'],
                        action.deliveryStatus
                      )(existingMessage);
                    }
                  })(messages);
                }
              })
        )(state);
      } else {
        return state;
      }
    }
  }
}

interface ThreadProps {
  threadId: string;
}

const Thread: FC<ThreadProps> = ({ threadId }) => {
  const classes = useStyles();

  const { data, loading } = useSubscription<
    { watchMessages?: WatchMessagePayload },
    { threadId: string; limit?: number }
  >(
    gql`
      subscription watchMessages($threadId: String!, $limit: Int) {
        watchMessages(threadId: $threadId, limit: $limit) {
          type
          message {
            id
            content
            createdAt
            sender
            recipients {
              contactInformation
              deliveryStatus {
                type
                reason
                json
              }
            }
          }
          messageId
          contactInformation
          deliveryStatus {
            type
            reason
            json
          }
        }
      }
    `,
    {
      variables: {
        threadId,
      },
    }
  );

  const [{ groups }, dispatch] = useReducer<Reducer<ReducerState, WatchMessagePayload | Reset>>(threadReducer, {
    messages: {},
    groups: [],
  });

  const payload = data?.watchMessages;

  useEffect(() => {
    if (payload) {
      dispatch(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (loading) {
      dispatch({ type: 'Reset' });
    }
  }, [loading]);

  const containerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView({});
  }, [messagesEndRef, groups]);

  useEffect(() => {
    const handler = (e: HTMLElementEventMap['scroll']) => {};

    const container = containerRef.current!;

    container.addEventListener('scroll', handler);

    return () => container.removeEventListener('scroll', handler);
  }, []);

  return (
    <div ref={containerRef} className={classes.root}>
      <div className={classes.thread}>
        {groups.map(group => (
          <div key={group.date} className={classes.group}>
            <div className={classes.date}>
              <Chip label={format(group.date, 'MM/DD/YYYY')} color="primary" />
            </div>
            {group.messages.map(message => (
              <ThreadMessage key={message.id} message={message} />
            ))}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      maxWidth: '50em',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    thread: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
    group: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),
    },
    date: {
      alignSelf: 'center',
    },
    time: {
      opacity: 0.5,
    },
    sender: {
      opacity: 0.5,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: theme.spacing(2),
    },
    incomingMessage: {
      flexDirection: 'row-reverse',
    },
    messageContent: {
      background: theme.palette.primary.dark,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      position: 'relative',
      maxWidth: '75%',
      width: 'fit-content',
      '&::after': {
        content: '""',
        background: theme.palette.primary.dark,
        display: 'block',
        width: '1rem',
        height: '1rem',
        clipPath: 'polygon(0 0, 67% 50%, 0% 100%)',
        position: 'absolute',
        bottom: '1rem',
        right: '-1rem',
      },
    },
    incomingMessageContent: {
      marginLeft: '0.67rem',
      background: theme.palette.grey.A100,
      '&::after': {
        background: theme.palette.grey.A100,
        clipPath: 'polygon(100% 0, 33% 50%, 100% 100%)',
        left: '-1rem',
      },
    },
    deliveryStatus: {
      position: 'absolute',
      bottom: 0,
      left: '1rem',
      transform: 'translate(0, 50%)',
    },
  })
);

export default Thread;

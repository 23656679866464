import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import ToggleInput from '../../se/components/inputs/ToogleInput';

const ScheduleAccessToggle = ({ scheduleUserId, scheduleAccessAll, setScheduleAccessAll, refetchQueries }) => {
  const [toggleScheduleAccessAll] = useMutation(setScheduleAccessAll);
  const [toggleInput, setToggleInput] = useState(scheduleAccessAll || false);

  const toggle = async val => {
    setToggleInput(val);
    await toggleScheduleAccessAll({
      variables: {
        id: scheduleUserId,
        scheduleAccessAll: val,
      },
      refetchQueries: refetchQueries || [],
    });
  };

  return <ToggleInput label="Allow the user to see the whole Schedule" value={toggleInput} onChange={toggle} />;
};

export default ScheduleAccessToggle;

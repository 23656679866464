import React from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import ActivePatientListHeader from './views/ActivePatientListHeader';
import ActivePatientListEmptyState from './views/ActivePatientListEmptyState';
import { mapEditItemProps, prepareCreateData } from './transducers';
import {
  braceletIdColumn,
  caretakerChatColumn,
  entryTimeColumn,
  familySmsStatusColumn,
  idColumn,
  nameColumn,
  note,
  phoneNumberColumn,
  physicianColumn,
  procedureTypeColumn,
  procedureTypeColumnList,
  roomColumn,
  smsNotificationNumbersColumn,
  visitDurationColumn,
} from './columns';
import PatientInputFactory from '../../inputs/patient/PatientInput';
import PatientShowTitle from '../../patient/PatientShowTitle';
import scheme, { patientSubscription } from '../../../graph/patients';
import { getLogEntries, isPatientReady } from '../../pages/kiosk/tablet/utils';
import { lifecycle } from 'recompose';
import { getNestedValue } from '../../../se/utilities/data/object';
import { soundAlert } from '../../pages/kiosk/Monitor';
import waitingReady from '../../../assets/sound/waitingReady.mp3';
import { ScopeProvider, withScope } from '../../HospitalInfo';
import { ThemeProvider } from 'styled-components';
import { AutomaticPatientTracking } from '../../ModuleOptional';
import { actionColumn } from './views/PatientLocationChangeColumn';
import LocationPatientView from './views/LocationPatientView';
import { PatientType } from '../../../types/Patient';

const PatientCreationInput = PatientInputFactory(true, true);
const PatientUpdateInput = PatientInputFactory(false);
const Empty = { illustration: theme => theme.illustrations.patients };

const isPACUReady = patient => isPatientReady(getLogEntries(patient))('PACU');
const readyPatientCount = patients =>
  (patients || []).filter(patient => isPatientReady(getLogEntries(patient))('PACU')).length;

/**
 * Used for the view given to the front desk users.
 */
const AutoCRUD = withScope(
  GraphQLCRUD({
    entityName: 'Patient',
    scheme: { ...scheme, list: patientSubscription, item: scheme.subscription },
    List: {
      tableKey: 'ActivePatients',
      useColumnSelection: true,
      columns: [
        nameColumn('name'),
        physicianColumn,
        procedureTypeColumnList,
        visitDurationColumn,
        roomColumn,
        caretakerChatColumn,
        familySmsStatusColumn,
      ],
      TitleAndActions: ActivePatientListHeader,
      Empty: ActivePatientListEmptyState,
      highlightedProvider: isPACUReady,
      highlightedRowStyles: {
        border: '3px solid #48e400',
        background: '#ecffe5',
      },
      effects: [
        lifecycle({
          UNSAFE_componentWillUpdate(nextProps) {
            if (!getNestedValue('data.loading', this.props)) {
              const shouldPlaySound =
                readyPatientCount(getNestedValue('data.patients', nextProps)) >
                readyPatientCount(getNestedValue('data.patients', this.props));
              soundAlert(waitingReady, shouldPlaySound);
            }
          },
        }),
      ],
    },
    Show: {
      columns: [
        physicianColumn,
        procedureTypeColumn,
        braceletIdColumn,
        idColumn,
        smsNotificationNumbersColumn,
        phoneNumberColumn,
        note,
      ],
      Empty,
      Title: PatientShowTitle,
      View: LocationPatientView,
    },
    Create: {
      Input: PatientCreationInput,
      Empty,
      prepareCreateData,
      initialValue: { type: PatientType.Out },
    },
    Edit: {
      mapEditItemProps,
      Input: PatientUpdateInput,
      Empty,
      prepareUpdateData: prepareCreateData,
      skipRefetch: true,
    },
  })
);

const ManualCRUD = withScope(
  GraphQLCRUD({
    entityName: 'Patient',
    scheme: { ...scheme, list: patientSubscription, item: scheme.subscription },
    List: {
      tableKey: 'ActivePatients',
      useColumnSelection: true,
      columns: [
        nameColumn('name'),
        entryTimeColumn,
        physicianColumn,
        roomColumn,
        familySmsStatusColumn,
        caretakerChatColumn,
        actionColumn,
      ],
      TitleAndActions: ActivePatientListHeader,
      Empty: ActivePatientListEmptyState,
      highlightedProvider: isPACUReady,
      highlightedRowStyles: {
        background: 'hsl(0, 0%, 95%)',
      },
      effects: [
        lifecycle({
          UNSAFE_componentWillUpdate(nextProps) {
            if (!getNestedValue('data.loading', this.props)) {
              const shouldPlaySound =
                readyPatientCount(getNestedValue('data.patients', nextProps)) >
                readyPatientCount(getNestedValue('data.patients', this.props));
              soundAlert(waitingReady, shouldPlaySound);
            }
          },
        }),
      ],
    },
    Show: {
      columns: [idColumn, physicianColumn, procedureTypeColumn, smsNotificationNumbersColumn, phoneNumberColumn, note],
      Empty,
      Title: PatientShowTitle,
      View: LocationPatientView,
    },
    Create: {
      Input: PatientCreationInput,
      Empty,
      prepareCreateData,
      initialValue: { type: PatientType.Out },
    },
    Edit: {
      mapEditItemProps,
      Input: PatientUpdateInput,
      Empty,
      prepareUpdateData: prepareCreateData,
      skipRefetch: true,
    },
  })
);

const CRUD = AutomaticPatientTracking(AutoCRUD, ManualCRUD);

export default props => (
  <ScopeProvider>
    <ThemeProvider theme={{ gridListColumnsTemplate: '1fr' }}>
      <CRUD {...props} />
    </ThemeProvider>
  </ScopeProvider>
);

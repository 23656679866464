import { differenceInYears } from 'date-fns';
import emptyReducer from './emptyReducer';

export const age65AndOlderYes = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 65 : false),
});

export const age65AndOlderNo = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) < 65 : false),
});

export const ageFrom41to60YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) >= 41 && differenceInYears(new Date(), date) <= 60 : false,
});

export const ageFrom61to74YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) >= 61 && differenceInYears(new Date(), date) <= 74 : false,
});

export const ageFrom75YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 75 : false),
});

const calculateBMI = (weightLb: number, heightFt: number, heightIn: number) => {
  const heightInInches = 12 * heightFt + heightIn;
  const bodyMassIndex = (703 * weightLb) / heightInInches / heightInInches;
  return isFinite(bodyMassIndex) ? bodyMassIndex.toFixed(0) : 0;
};

export const bmiGreaterThan30 = emptyReducer<boolean>(false).with({
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) > 30 : false,
});

export const toBooleanNegative = emptyReducer<boolean>(false).with({
  YesNo: (question, positive) => positive === false,
});

export const testReducer = (checkBoxLabel: string) =>
  emptyReducer<boolean>(false).with({
    OneOf: (label, options, answer) =>
      options.filter(option => option.label === answer?.label)?.[0]?.selected &&
      answer?.label.toLowerCase() === checkBoxLabel,
  });

import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import delay from 'lodash/delay';
import { superUserRoleLabels, userRoleLabels } from '../components/entities/role/labels';

export const CrispContext = React.createContext();

export const CrispContextProvider = ({ children }) => {
  const [client, setClient] = useState(undefined);

  const identifyFromCache = useCallback(() => {
    const session = JSON.parse(localStorage.getItem('session'));

    const email = get(session, 'account.email.Email.address');
    const user = get(session, 'user.name');
    const organization = get(session, 'membership.organization.name');
    const role = get(session, 'membership.metadata[0]');
    setCurrentUser(user, email, role, organization);
  }, []);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '32f50973-715b-4f0f-a75c-12a9f7ba8a10';

    window.CRISP_READY_TRIGGER = function () {
      setClient(window.$crisp);
    };

    const d = document;
    const s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
    window.$crisp.push(['safe', true]);
    window.$crisp.push(['on', 'chat:opened', identifyFromCache]);
    return () => s.parentNode.removeChild(s);
  }, [setClient, identifyFromCache]);

  // Client API

  const setCurrentUser = (user, email, role, organization) => {
    const roles = { ...userRoleLabels, ...superUserRoleLabels };
    window.$crisp.push(['set', 'user:nickname', [user]]);
    window.$crisp.push(['set', 'user:email', [email]]);
    window.$crisp.push([
      'set',
      'user:company',
      [organization, { employment: [roles[role], userRoleLabels[role] ? 'User' : 'SuperUser'] }],
    ]);
    window.$crisp.push(['set', 'user:role', [roles[role]]]);
  };

  const isVisible = () => {
    client.is('chat:visible');
  };

  const hideChat = () => {
    client.do('chat:hide');
  };

  const showChat = () => {
    client.do('chat:show');
  };

  const clearSession = () => {
    client.do('session:reset');
    delay(hideChat, 1000);
  };

  return (
    <CrispContext.Provider
      value={{ crisp: client ? { client, showChat, hideChat, isVisible, setCurrentUser, clearSession } : undefined }}
    >
      {children}
    </CrispContext.Provider>
  );
};

export const withCrispCtx = Component => props =>
  <CrispContext.Consumer>{ctx => <Component {...props} {...ctx} />}</CrispContext.Consumer>;

import React from 'react';
import { format } from 'date-fns';
import { surgeonProcedureStatuses, surgeonProcedureStatusLabels } from '../surgeonProcedures/enums';
import { Link, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ZonedDateTime } from '@js-joda/core';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { withSession } from '../../../state/Session';
import { ProcedureEvent } from '../../../types/ProcedureEvent';

export const smsStatusType = {
  queued: 'queued',
  sending: 'sending',
  sent: 'sent',
  dispatched: 'dispatched',
  failed: 'failed',
  delivered: 'delivered',
  undelivered: 'undelivered',
  receiving: 'receiving',
  received: 'received',
  accepted: 'accepted',
  scheduled: 'scheduled',
  read: 'read',
  partiallyDelivered: 'partially_delivered',
};

export const smsStatusLabel = {
  queued: 'Sending',
  sending: 'Sending',
  sent: 'Sent',
  failed: 'Failed',
  delivered: 'Delivered',
  undelivered: 'Failed',
  receiving: 'Receiving',
  received: 'Received',
  accepted: 'Accepted',
  scheduled: 'Sending',
  read: 'Read',
  partiallyDelivered: 'Partially Delivered',
};

export const eventTypes = {
  InstructionsSent: 'InstructionsSent',
  QuestionnaireSent: 'QuestionnaireSent',
  RegisterReady: 'RegisterReady',
  InstructionsAccepted: 'InstructionsAccepted',
  StatusChange: 'StatusChange',
  AnesthesiaReviewedChange: 'AnesthesiaReviewedChange',
  CanceledTransfer: 'CanceledTransfer',
  MessageDeliveryQuestionnaire: 'MessageDelivery@QuestionnaireInvite',
  MessageDeliveryCovidScreening: 'MessageDelivery@CovidScreening',
  MessageDeliveryInstructions: 'MessageDelivery@InstructionsInvite',
  MessageDeliveryReadyToRegister: 'MessageDelivery@ReadyToRegister',
  PatientFileGenerated: 'PatientFileGenerated',
  ScheduleProcedure: 'ScheduleProcedure',
  UpdateScheduledProcedure: 'UpdateScheduledProcedure',
  FormSubmitted: 'FormSubmitted',
  Note: 'Note',
};

export const procedureStatuses = {
  preopCompleted: 'preopCompleted',
  infoPending: 'infoPending',
  PreOpApptMade: 'PreOpApptMade',
  PreOpApptNeeded: 'PreOpApptNeeded',
  Cancelled: 'Cancelled',
  PhonePreOpNeeded: 'PhonePreOpNeeded',
  SpanishSpeaker: 'SpanishSpeaker',
  EkgOnFile: 'EkgOnFile',
  SeePreOpNotes: 'SeePreOpNotes',
};

export const procedureStatuseLabels = {
  [procedureStatuses.SeePreOpNotes]: 'Please See PreOp Notes',
  [procedureStatuses.preopCompleted]: 'PreOP Completed',
  [procedureStatuses.infoPending]: 'Info Pending',
  [procedureStatuses.PreOpApptMade]: 'Pre-Op Appt Made',
  [procedureStatuses.PreOpApptNeeded]: 'Pre-Op Appt Needed',
  [procedureStatuses.Cancelled]: 'Cancelled',
};

const deprecatedStatusLabels = {
  [procedureStatuses.PhonePreOpNeeded]: 'Phone Pre-Op Needed',
  [procedureStatuses.SpanishSpeaker]: 'Spanish Speaker',
  [procedureStatuses.EkgOnFile]: 'EKG On File',
};

const allProcedureStatuses = Object.assign(
  {},
  procedureStatuseLabels,
  surgeonProcedureStatusLabels,
  deprecatedStatusLabels
);

const rewordAnon = (name: string) => (name === 'Anonymous' ? 'Patient' : name);

const getEvent = ({ type, user, channel, text, status, anesthesiaReviewed, content, cancelReason }: ProcedureEvent) => {
  switch (type) {
    case eventTypes.QuestionnaireSent:
      if (channel === 'Email') {
        return `Questionnaire Invitation sent via ${channel}`;
      }
      return `Questionnaire Invitation enqueued via ${channel}`;
    case eventTypes.RegisterReady:
      return `Patient Register Ready ${channel} sent`;
    case eventTypes.InstructionsSent:
      return `Surgery Instructions sent via ${channel}`;
    case eventTypes.InstructionsAccepted:
      return `Patient Accepted Instructions`;
    case eventTypes.MessageDeliveryQuestionnaire:
      return [`Questionnaire Invitation ${status} ${content ? ` - ${content}` : ''}`, text].filter(_ => !!_).join('\n');
    case eventTypes.MessageDeliveryCovidScreening:
      return [`Covid Screening Invitation ${status} ${content ? ` - ${content}` : `via ${channel}`}`, text]
        .filter(_ => !!_)
        .join('\n');
    case eventTypes.MessageDeliveryInstructions:
      return [`Surgery Instructions ${status}${content ? ` - ${content}` : ''}`, text].filter(_ => !!_).join('\n');
    case eventTypes.PatientFileGenerated:
      return `Patient Record Generated by ${user?.name ? rewordAnon(user.name) : 'Super Admin'}`;
    case eventTypes.ScheduleProcedure:
      return `Procedure scheduled by ${user?.name ? rewordAnon(user.name) : 'Super Admin'}`;
    case eventTypes.UpdateScheduledProcedure:
      return `Scheduled procedure updated by ${user?.name ? rewordAnon(user.name) : 'Super Admin'}`;
    case eventTypes.FormSubmitted:
      return `< ${content} > updated by ${user?.name ? rewordAnon(user.name) : 'Super Admin'}`;
    case eventTypes.StatusChange:
      if (status) {
        if (status === surgeonProcedureStatuses.SurgeryMissingDocuments) {
          return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} requested new documents`;
        } else if (status === surgeonProcedureStatuses.Received) {
          return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} received request`;
        } else {
          return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} changed the status to ${
            allProcedureStatuses[status] || '???'
          }`;
        }
      } else {
        return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} removed the status`;
      }
    case eventTypes.AnesthesiaReviewedChange:
      return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} ${
        anesthesiaReviewed ? 'checked' : 'unchecked'
      } Anesthesia Reviewed`;
    case eventTypes.CanceledTransfer:
      if (cancelReason) {
        return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} canceled procedure with text: ${
          cancelReason || '???'
        }`;
      } else {
        return `${user?.name ? rewordAnon(user.name) : 'Super Admin'} canceled procedure`;
      }
    case eventTypes.Note:
      return text;
    default:
      return '';
  }
};

interface RemoveProps {
  noteId: number;
  deleteNote: (noteId: number) => Promise<void>;
}

const RemoveNote = ({ noteId, deleteNote }: RemoveProps) => {
  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      await deleteNote(noteId);
    }
  };

  return (
    <Link component="button" variant="body2" color="textSecondary" aria-label="delete" onClick={handleDelete}>
      Delete note
    </Link>
  );
};

interface ProcedureEventLogProps {
  events?: Array<ProcedureEvent>;
  name?: string;
  deleteNote?: (noteId: number) => Promise<void>;
  session: any;
}

const ProcedureEventLog = ({ session, events, name, deleteNote }: ProcedureEventLogProps) => {
  if (!events) return null;

  return (
    <Box>
      {name && <Typography variant="h4">{name}</Typography>}
      {events.length > 0 ? (
        events.map((event, index) => (
          <Box display="flex" flexDirection="column" key={`${event.type}-${event.id}-${index}`} my={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="caption">
                {event.type === 'Note' && (event.user && event.user.name ? rewordAnon(event.user.name) : 'Super Admin')}{' '}
                <Box display="inline" color="text.secondary">
                  {format(ZonedDateTime.parse(event.timestamp).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss')}
                </Box>
              </Typography>
              {event.type === eventTypes.Note && event.user?.id === session.user.id && deleteNote && (
                <RemoveNote noteId={event.id} deleteNote={deleteNote} />
              )}
            </Box>
            <Box display="flex">
              <Typography variant="body2" color={event.type !== 'Note' ? 'textSecondary' : 'textPrimary'}>
                {getEvent(event)}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Typography color="textSecondary">{`No ${
          name ? name.toLowerCase() : 'entries'
        } for this patient yet.`}</Typography>
      )}
    </Box>
  );
};

export default withSession(unpackSessionObject)(ProcedureEventLog);

import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RegularText } from './overview/Procedure';

const Container = styled.span``;

const Highlighted = styled.span<any>`
  color: ${props => props.color || '#f55323'};
`;

const tokens: string[] = ['latex', 'iodine', 'pediatric', 'ekg', 'minor'];
const colors = {
  latex: '#f55323',
  iodine: '#f55323',
  pediatric: '#FF5ABB',
  ekg: '#ee9955',
  minor: '#ee9955',
};

const tokenAtIdx = (children: any, idx: any) => {
  const rest = children.slice(idx);
  return tokens.find(token => rest.match(new RegExp(`^${token}`, 'gi')));
};

const Notes: FC<{ highContrast?: any; children: any }> = ({ children, highContrast }) => {
  const indexes = [...children.matchAll(new RegExp(tokens.join('|'), 'gi'))].map(match => match.index);

  var lastIndex = indexes.length === 0 ? children.length : 0;
  // @ts-ignore
  var result = indexes.length === 0 ? <RegularText highContrast={highContrast}>{children}</RegularText> : null;

  const appendToResult = (value: any) => {
    result = (
      <Fragment>
        {result}
        {value}
      </Fragment>
    );
  };

  for (var i = 0, n = indexes.length; i < n; i++) {
    const index = indexes[i];

    if (lastIndex >= children.length) {
      break;
    }

    if (lastIndex < index) {
      appendToResult(<RegularText>{children.slice(lastIndex, index)}</RegularText>);
    }

    const token = tokenAtIdx(children, index);
    lastIndex = index + (token || '').length;

    appendToResult(
      <Highlighted color={token && (colors as any)[token]}>{children.slice(index, lastIndex)}</Highlighted>
    );
  }

  if (lastIndex < children.length) {
    appendToResult(<RegularText>{children.slice(lastIndex, children.length)}</RegularText>);
  }

  return <Container>{result}</Container>;
};

Notes.defaultProps = {
  children: '',
};

Notes.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Notes;

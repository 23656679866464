import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment ConfigData on Config {
      id
      configType
      values
    }
  `,
};

export const list = gql`
  query configs {
    configs {
      ...ConfigData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query config($id: Long!) {
    config(id: $id) {
      ...ConfigData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createConfig($values: Json!) {
    createConfig(values: $values) {
      ...ConfigData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation updateConfig($id: Long!, $values: Json!) {
    updateConfig(id: $id, values: $values) {
      ...ConfigData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteConfig($id: Long!) {
    deleteConfig(id: $id)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};

import React from 'react';
import logoWhite from '../assets/images/logo_white.svg';
import logoDark from '../assets/images/logo_dark.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
});

const Logo = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <img
      src={theme.palette.type === 'dark' ? logoWhite : logoDark}
      alt="logo"
      className={classes.root}
      width={112}
      height={65}
    />
  );
};

export default Logo;

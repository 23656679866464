import React, { ReactNode } from 'react';
import { Box, Button, Divider, FormLabel } from '@material-ui/core';
import { ListAnswer, ObjectAnswer } from '../../types/Answer';
import { ListQuestion, ObjectQuestion } from '../../types/Question';

type ListInputProps = ListQuestion['props'] & {
  name?: string;
  answer?: ListAnswer;
  onChange?: (newAnswer: ListAnswer) => void;
};

const ListInput = ({ name, label, answer, children, onChange }: ListInputProps) => {
  const onAdd = () => {
    onChange?.(answer ? [...answer, {}] : [{}]);
  };

  const onDelete = (index: number) => {
    onChange?.((answer as ObjectAnswer<ObjectQuestion>[]).filter((e, i) => i !== index));
  };

  return (
    <Box my={2} mt={4}>
      <FormLabel component="legend">{label}</FormLabel>
      {!((children as any)?.length > 0) ? (
        <Box mt={2}>
          <Button onClick={onAdd} variant="outlined">
            Add
          </Button>
        </Box>
      ) : (
        <>
          <Box mt={2}>
            <Button onClick={onAdd} variant="outlined">
              Add
            </Button>
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          {(children as ReactNode[]).map((listItem, index) => (
            <Box key={index}>
              {listItem}
              <Button onClick={() => onDelete(index)} variant="outlined">
                Delete
              </Button>
            </Box>
          ))}
          <Box my={2}>
            <Divider />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ListInput;

import React, { createContext, FC, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  assignVendorProcedure,
  list,
  listAssignedVendors,
  removeVendorProcedure,
} from '../../../../../../graph/vendors';
import Vendor from '../../../../../../types/Vendor';

interface VendorProcedureContextData {
  vendors: Vendor[];
  assign: (vendorId: number) => Promise<void>;
  remove: (vendorId: number) => Promise<void>;
}

const VendorProcedureContext = createContext<VendorProcedureContextData>({
  vendors: [],
  assign: async () => {},
  remove: async () => {},
});

export function useVendorProcedureContext() {
  return useContext(VendorProcedureContext);
}

interface VendorProcedureProviderProps {
  procedureId: number;
  date: string;
  children: React.ReactNode;
}

export const VendorProcedureProvider: FC<VendorProcedureProviderProps> = ({ date, procedureId, children }) => {
  const { data } = useQuery(list);
  const [doAssign] = useMutation(assignVendorProcedure);
  const [doRemove] = useMutation(removeVendorProcedure);

  const assign = async (vendorId: number) => {
    await doAssign({
      variables: {
        procedureId,
        vendorId,
      },
      refetchQueries: [{ query: listAssignedVendors, variables: { date } }],
    });
  };

  const remove = async (vendorId: number) => {
    await doRemove({
      variables: {
        procedureId,
        vendorId,
      },
      refetchQueries: [{ query: listAssignedVendors, variables: { date } }],
    });
  };

  const context = {
    vendors: data?.vendors || [],
    assign,
    remove,
  };

  return (
    <>
      <VendorProcedureContext.Provider value={context}>{children}</VendorProcedureContext.Provider>
    </>
  );
};

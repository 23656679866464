import { gql } from '@apollo/client';
import { fragments as physicianFragments } from '../physicians';
import { fragmentTransferIssue } from '../scheduleRequests';

const baseFields = `
  id
  status
  appointmentDateTime
  appointmentTime
  appointmentDate
  serviceDateTime
  serviceTime
  serviceDate
  consultationDateTime
  consultationTime
  consultationDate
  duration
  visit
  notes
  procedureType
  events {
    id
    type
    timestamp
    patientPin
    cancelReason
    channel
    content
    user {
      id
      name
    }
    text
    status
    exchangeId
  }
  organizationLocation {
    id
    name
  }
`;

const baseListFields = `
  id
  status
  appointmentDateTime
  appointmentTime
  appointmentDate
  serviceDateTime
  serviceTime
  serviceDate
  consultationDateTime
  consultationTime
  consultationDate
  duration
  visit
  notes
  procedureType
  events {
    id
    type
    timestamp
    patientPin
    cancelReason
    channel
    content
    text
    status
    exchangeId
  }
`;

const PatientFragment = gql`
  fragment PatientData on Patient {
    id
    name
    homePhone
    cellPhone
    emailAddress
    dateOfBirth
    sex
    age
    initials
    ratingInviteSent
    ratingInviteSentAt
    physician {
      ...PhysicianBase
    }
  }
  ${physicianFragments.base}
`;

export const fragments = {
  all: gql`
    fragment ProcedureData on Procedure {
      ${baseFields}
      patient {
        ...PatientData
      }
    }
    ${PatientFragment}
  `,
  withoutEvents: gql`
    fragment ProcedureDataWithoutEvents on Procedure {
      id
      status
      appointmentDateTime
      appointmentTime
      appointmentDate
      serviceDateTime
      serviceTime
      serviceDate
      consultationDateTime
      consultationTime
      consultationDate
      duration
      visit
      notes
      procedureType
      patient {
        ...PatientData
      }
    }
    ${PatientFragment}
  `,
  base: gql`
    fragment ProcedureBase on Procedure {
     ${baseFields}
      scheduleRequest {
        id
        scheduleTransferDate
        scheduleTransferTime
        duration
        payload {
          forms
          signedByPhysician
        }
        state
        notesStatus
        toOrg {
          name
        }
        issues {
          ...ScheduleTransferIssueData
        }
      }
    }
    ${fragmentTransferIssue}
  `,
  questionnaire: gql`
    fragment Questionnaire on QuestionnaireAnswers {
      id
      progress
      questions
      answers
      progress
      submittedAt
    }
  `,
  list: gql`
    fragment ProcedureData on Procedure {
      ${baseListFields}
      patient {
        ...PatientData
      }
    }
    ${PatientFragment}
  `,
};

export const itemSubscription = gql`
  subscription procedure($id: Long!) {
    patient: procedure(id: $id) {
      ...ProcedureBase

      patient {
        ...PatientData
      }
    }
  }
  ${fragments.base}
  ${PatientFragment}
`;

export const itemQuestionnaireSubscription = gql`
  subscription procedureQuestionnaire($id: Long!) {
    patient: procedure(id: $id) {
      entryQuestionnaire {
        ...Questionnaire
      }
    }
  }
  ${fragments.questionnaire}
`;

export const update = gql`
  mutation (
    $id: Long!
    $appointmentDate: Date
    $appointmentTime: String
    $procedureType: String
    $notes: String
    $patient: PatientInput
    $physician: Long
    $serviceDate: Date
    $serviceTime: String
    $duration: Int
    $consultationDate: Date
    $consultationTime: String
    $locationId: Long
  ) {
    editProcedure(
      id: $id
      notes: $notes
      appointmentDate: $appointmentDate
      appointmentTime: $appointmentTime
      consultationDate: $consultationDate
      consultationTime: $consultationTime
      procedureType: $procedureType
      patient: $patient
      physician: $physician
      serviceDate: $serviceDate
      serviceTime: $serviceTime
      duration: $duration
      locationId: $locationId
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

const create = gql`
  mutation (
    $patient: PatientInput!
    $physician: Long
    $consultationDate: Date
    $consultationTime: String
    $procedureType: String
    $locationId: Long
  ) {
    createProcedure(
      patient: $patient
      physician: $physician
      consultationDate: $consultationDate
      consultationTime: $consultationTime
      procedureType: $procedureType
      locationId: $locationId
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const sendRatingInvite = gql`
  mutation ($id: Long!) {
    sendRatingInvite(id: $id) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query procedure($id: Long!) {
    patient: procedure(id: $id) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const list = gql`
  query procedures($filter: Filter) {
    patients: procedures(filter: $filter) {
      ...ProcedureData
      entryQuestionnaire {
        id
        progress
        submittedAt
      }
    }
  }
  ${fragments.list}
`;

export const listUnscheduled = gql`
  query unscheduledProcedures {
    procedures: unscheduledProcedures {
      id
      patientName
      patient {
        id
        name
        age
        sex
        physician {
          id
          hstId
          name
          email
        }
      }
      procedureType
      serviceDateTime
      serviceTime
      serviceDate
      duration
      scheduleRequest {
        id
      }
    }
  }
`;

export const getProcedure = gql`
  query procedure($id: Long!) {
    procedure(id: $id) {
      id
      patientName
      patient {
        id
        name
        age
        sex
        physician {
          id
          hstId
          name
          email
        }
      }
      procedureType
      serviceDateTime
      serviceTime
      serviceDate
      duration
      scheduleRequest {
        id
      }
    }
  }
`;

export const listSubscription = gql`
  subscription procedures($filter: Filter) {
    patients: procedures(filter: $filter) {
      ...ProcedureData
      entryQuestionnaire {
        id
        progress
        submittedAt
      }
    }
  }
  ${fragments.list}
`;

export const procedureStatistics = gql`
  subscription procedureStatistics($filter: Filter) {
    procedureStatistics(filter: $filter) {
      questionnaireSent
      questionnaireCompleted
      toBeScheduled
      surgeryScheduled
      missingInformation
    }
  }
`;

export default {
  item,
  list,
  listSubscription,
  update,
  create,
  itemSubscription,
  sendRatingInvite,
};

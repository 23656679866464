import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { differenceInMinutes, parse } from 'date-fns';
import {
  fullNameToDrLastName,
  fullNameToDrLastNameMiddleEast,
  fullNameToLastNameRest,
  getDelayInMinutes,
  getOrLateEntryInMinutes,
  isDelayed,
  isDimmed,
  isNoShow,
  NormalizedStatus,
  normalizeStatus,
} from '../shared/procedureUtils';
import { ROOM_TYPES } from '../../../../entities/room/enums';
import has from 'lodash/has';
import { Anesthesiologist, Physician } from '../anesthesiologist/Personnel';
import { translateToTz } from '../../../../../util/dateTime';
import { PatientStat } from '../shared/PatientStat';
import { Icon } from '../../Schedule';
import localHospital from '../../../../../assets/images/icons/local_hospital.png';
import { ImageIcon } from '../overview/Procedure';
import Notes from '../Notes';
import Appendix from '../overview/Appendix';
import { Bed, PatientName } from '../Patient';
import { useScope } from '../../../../HospitalInfo';
import { Procedure as ProcedureT } from '../../../../../types/Procedure';
import isMiddleEast from '../../../../../util/isMiddleEast';
import { getLogEntries, isPreOpAsDPU } from '../../tablet/utils';

const Card = styled.div<any>`
  width: 100%;
  position: absolute;
  left: 0;
  padding: ${props => (props.editMode ? '0 -.2em .375em -.2em' : '0 0.375em')};

  top: ${props => props.top}%;
  height: ${props => props.length}%;
  box-sizing: border-box;
  z-index: ${props => (props.fullHeight ? 5 : 1)};
`;

const Wrapper = styled.div<any>`
  height: 100%;
  width: 100%;
  border: ${props => (props.isDpuFromOr ? '1px solid purple' : 'none')};
  background-color: #2a345d;
  border-radius: 0.16666em;
  box-shadow: 0 0 8px #121d4d;
  overflow: hidden;
  ${props =>
    props.completed &&
    css`
      opacity: 0.3;

      &: after {
        content: '';
        background: linear-gradient(0, rgba(34, 44, 87, 1) 0%, rgba(34, 44, 87, 0) 100%);
      }
    `}

  ${props =>
    props.onClick &&
    !props.disabled &&
    css`
      cursor: pointer;
      user-select: none;
      transition: transform 100ms ease-in-out, background-color 150ms ease-in-out;

      :hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      :active {
        background-color: rgba(255, 255, 255, 0.075);
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      border: none;
      animation: none;
    `}

  &:after {
    content: '';
    display: block;
    border-radius: 0 0 0.16666em 0.16666em;
    position: absolute;
    bottom: 0;
    left: 0.5em;
    right: 0.5em;
    height: 0.4em;
    background: linear-gradient(0, rgba(42, 52, 93, 1) 0%, rgba(42, 52, 93, 0) 100%);
  }
`;

const Header = styled.div<any>`
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  font-size: 1em;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ${props =>
    props.stretch
      ? css`
          height: 100%;
        `
      : null}

  ${props =>
    props.row
      ? css`
          flex-direction: row;
        `
      : null}

  ${props =>
    props.column
      ? css`
          flex-direction: column;
          height: 100%;

          > * {
            flex: 1 0 auto;
            padding-top: 0;
            padding-bottom: 0;
          }
        `
      : null}
`;

const Body = styled.div<any>`
  padding: 0.4em;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  label {
    opacity: ${props => (props.warning ? 1 : '.35')};
  }
`;

const StartTime = styled.div<any>`
  display: flex;

  align-items: center;
  padding-right: 0.375em;
`;

const StartAndEndTime = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 0.25em;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 1px;
  min-width: 3em;

  > span + span {
    margin-top: 0.25em;
  }
`;

const HeaderRowSingle = styled.div<any>`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  line-height: normal;
  padding-left: 0.375em;

  ${props =>
    props.withPadding
      ? css`
          padding: 0.25em 0;
        `
      : null}

  ${props =>
    props.stretch
      ? css`
          height: 100%;
        `
      : null}
`;

const BodySegment = styled.div`
  display: flex;
  margin-bottom: 0.25em;
`;

export const getDisposition = (dayStart: Date, current: number, dayEnd: Date) => {
  if (current) {
    return (differenceInMinutes(current, dayStart) * 100) / differenceInMinutes(dayEnd, dayStart);
  } else {
    return 0;
  }
};

const getRelativeDuration = (dayStart: Date, duration: number, dayEnd: Date) => {
  if (duration) {
    return (duration * 100) / differenceInMinutes(dayEnd, dayStart);
  } else {
    return 10;
  }
};

const CardCompactHeader: FC<{
  procedure: ProcedureT;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  isCanceled?: any;
  noShow: boolean;
  color: string;
  status: string;
  showBedNumber: boolean;
  stretch?: boolean;
  hideStartTime?: boolean;
}> = ({
  procedure,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  isCanceled,
  noShow,
  color,
  status,
  stretch,
  hideStartTime,
  showBedNumber,
}) => (
  <HeaderRowSingle stretch={stretch}>
    {!hideStartTime && <StartTime>{get(procedure, 'startTimeText')}</StartTime>}
    <Physician physicianName={physicianName} color={color} inline />
    <PatientName
      name={procedure?.patient?.name || procedure?.patientName}
      age={get(procedure, 'patientAge')}
      sex={get(procedure, 'patientSex')}
      inline
    />
    <Anesthesiologist
      procedureId={procedure.id}
      anesthesiologistName={anesthesiologistName}
      anesthesiaType={anesthesiaType}
      inline
      style={{ fontWeight: 400 }}
    />
    <PatientStat inline style={{ marginLeft: 'auto', marginRight: '.375em' }}>
      {isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
      {showBedNumber && !!procedure?.patient?.bed && <Bed bed={get(procedure, 'patient.bed')} />}
    </PatientStat>
  </HeaderRowSingle>
);

const FULL_HEIGHT_DURATION = 140;
const secondInMili = 1000;

const Procedure: FC<{
  procedure: ProcedureT;
  dayStart: Date;
  dayEnd: Date;
  onClick: () => void;
  editMode: boolean;
  isTouchDevice: boolean;
  showBedNumber: boolean;
}> = ({ procedure, dayStart, dayEnd, onClick, editMode, isTouchDevice, showBedNumber }) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;

  const [fullHeight, setFullHeight] = useState(false);

  const handleOnClick = () => {
    if (isTouchDevice) {
      setFullHeight(!fullHeight);
      setTimeout(() => setFullHeight(false), 10 * secondInMili);
    }
    onClick();
  };

  // @ts-ignore
  const startTime = translateToTz(get(scope, 'hospital.timezone.id'))(parse(get(procedure, 'startTime')));
  const duration = !isTouchDevice
    ? procedure?.duration || 0
    : !fullHeight
    ? procedure?.duration || 0
    : FULL_HEIGHT_DURATION;
  const normalizedStatus = normalizeStatus(
    procedure?.patient?.status,
    procedure?.patient?.room,
    isMiddleEast(hospitalId)
  );
  const status = has(procedure, 'patient.id') ? normalizedStatus : 'PreAdm';

  const noShow = isNoShow(procedure);
  const orLateInMinutes = getOrLateEntryInMinutes(procedure, isMiddleEast(hospitalId));
  const isInactive = isDimmed(procedure);
  const isCanceled = get(procedure, 'isCanceled');

  const physicianName = isMiddleEast(hospitalId)
    ? fullNameToDrLastNameMiddleEast(procedure?.physician?.name)
    : fullNameToDrLastName(procedure?.physician?.name);
  const color = get(procedure, 'physician.color') || get(procedure, 'physicianColor') || '#888888';

  const anesthesiologistName = isMiddleEast(hospitalId)
    ? fullNameToDrLastName(procedure?.anesthesiologist?.name)
    : fullNameToLastNameRest(procedure?.anesthesiologist?.name);
  const anesthesiaType = procedure?.anesthesiaType;

  const delayInMinutes = getDelayInMinutes(procedure);
  const isWaiting = [ROOM_TYPES.WAITING_ROOM, ROOM_TYPES.PRE_OP].includes(get(procedure, 'patient.room.type'));
  const isHoldProcedure = get(procedure, 'patient.events.holdProcedureAt');
  const readyForSurgeon = get(procedure, 'patient.events.readyForSurgeonAt');
  const readyForOr = get(procedure, 'patient.events.readyForOrAt');
  const isDPU = isPreOpAsDPU(procedure?.patient?.room?.type, getLogEntries(procedure?.patient));
  if ((duration || 0) <= 15) {
    return (
      <Card
        fullHeight={fullHeight}
        editMode={editMode}
        top={getDisposition(dayStart, startTime, dayEnd)}
        length={getRelativeDuration(dayStart, 15, dayEnd)}
      >
        <Wrapper
          isDpuFromOr={isDPU}
          completed={isInactive}
          onClick={handleOnClick}
          disabled={[NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus)}
        >
          <Header stretch>
            <CardCompactHeader
              procedure={procedure}
              physicianName={physicianName}
              anesthesiologistName={anesthesiologistName}
              anesthesiaType={anesthesiaType}
              isCanceled={isCanceled}
              noShow={noShow}
              showBedNumber={showBedNumber}
              color={color}
              status={status}
              stretch
            />
          </Header>
        </Wrapper>
      </Card>
    );
  } else {
    return (
      <Card
        fullHeight={fullHeight}
        editMode={editMode}
        top={getDisposition(dayStart, startTime, dayEnd)}
        length={getRelativeDuration(dayStart, duration, dayEnd)}
      >
        <Wrapper
          isDpuFromOr={isDPU}
          completed={isInactive}
          onClick={handleOnClick}
          disabled={[NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus)}
        >
          <Header row stretch={(duration || 0) <= 30}>
            <StartAndEndTime>
              <span>{get(procedure, 'startTimeText')}</span>
              <span>{get(procedure, 'endTimeText')}</span>
            </StartAndEndTime>
            <Header column style={{ flex: '1 0 auto', height: 'auto', background: 'transparent' }}>
              <CardCompactHeader
                hideStartTime={true}
                procedure={procedure}
                physicianName={physicianName}
                anesthesiologistName={anesthesiologistName}
                anesthesiaType={get(procedure, 'anesthesiaType')}
                isCanceled={isCanceled}
                noShow={noShow}
                showBedNumber={showBedNumber}
                color={get(procedure, 'physician.color') || get(procedure, 'physicianColor')}
                status={isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
              />
              <Appendix
                isDPU={isDPU}
                isDimmed={isInactive}
                late={orLateInMinutes}
                isDelayed={isDelayed(procedure, isMiddleEast(hospitalId)) && !noShow}
                delay={delayInMinutes}
                isWaiting={isWaiting}
                readyForSurgeon={get(procedure, 'patient.room.type') === ROOM_TYPES.PRE_OP && !!readyForSurgeon}
                readyForOr={get(procedure, 'patient.room.type') === ROOM_TYPES.PRE_OP && !!readyForOr}
                readyForSurgery={get(procedure, 'patient.status') === NormalizedStatus.Ready}
                isHoldProcedure={get(procedure, 'patient.room.type') === ROOM_TYPES.PRE_OP && !!isHoldProcedure}
              />
            </Header>
          </Header>
          {(duration || 0) > 30 && (
            <Body>
              {get(procedure, 'procedureType') && (
                <BodySegment>
                  <ImageIcon src={localHospital} style={{ opacity: 0.2 }} />
                  <Notes>{get(procedure, 'procedureType')}</Notes>
                </BodySegment>
              )}
              {get(procedure, 'notes') && (
                <BodySegment>
                  <Icon style={{ color: '#ABE3FF', opacity: 0.2 }}>assignment</Icon>{' '}
                  <Notes>{get(procedure, 'notes')}</Notes>
                </BodySegment>
              )}
              {get(procedure, 'allergy') && (
                <BodySegment>
                  <Icon style={{ color: '#ABE3FF', opacity: 0.2 }}>warning</Icon>{' '}
                  <Notes>{get(procedure, 'allergy')}</Notes>
                </BodySegment>
              )}
            </Body>
          )}
        </Wrapper>
      </Card>
    );
  }
};

export default Procedure;

import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { compose, withProps } from 'recompose';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import PatientInputFactory from '../../inputs/patient/PatientInput';
import scheme, { listActiveAndPastPatients } from '../../../graph/patients';
import { mapEditItemProps, mapListProps, prepareCreateData } from './transducers';
import {
  braceletIdColumn,
  dateOfVisit,
  eventColumns,
  idColumn,
  nameColumn,
  note,
  phoneNumberColumn,
  physicianColumn,
  procedureTypeColumn,
  providerIdColumn,
  roomOrDateOfVisit,
  smsNotificationNumbersColumn,
  visitDurationColumn,
} from './columns';
import Filters from '../../pages/analytics/Filters';
import PatientListView from './views/PatientListView';
import PatientView from './views/PatientView';
import PatientShowTitle from '../../patient/PatientShowTitle';
import responsive from '../../../se/utilities/responsive';
import PatientMobileListItem from './views/PatientMobileListItem';
import { NamedRange } from '../../core/DatePicker';
import activeAndPastProceduresSource from '../../../omnisearch/sources/hospital/activeAndPastProcedures';
import pick from 'lodash/fp/pick';
import { withScope } from '../../HospitalInfo';
import { get, set } from 'lodash/fp';
import { PatientType } from '../../../types/Patient';

const PatientCreationInput = PatientInputFactory(true, false);
const PatientUpdateInput = PatientInputFactory(false);
const Empty = { illustration: theme => theme.illustrations.patients };

const FiltersWrapper = styled.div`
  ${responsive.md.andSmaller`
    padding-bottom: 0rem;
    display: flex;
    flex-flow: column;
    .hidden {
      margin-bottom: 2em;
    }
  `};
`;

const filterEventColumns = props => {
  const hasPostop = get('scope.hospital.modules.hasPostop')(props);
  return hasPostop
    ? props
    : set(
        'columns',
        get('columns')(props).filter(column => column.title !== 'Post Op Entry')
      )(props);
};

export const CustomFilters = props => (
  <FiltersWrapper>
    <Filters {...props} />
  </FiltersWrapper>
);

const ActiveAndPastPatients = withScope(
  GraphQLCRUD({
    entityName: 'Patient',
    scheme: {
      ...scheme,
      list: listActiveAndPastPatients,
    },
    List: {
      tableKey: 'ActiveAndPastPatients',
      MobileItemComponent: PatientMobileListItem,
      useColumnSelection: true,
      useCSVExport: true,
      columns: [
        idColumn,
        providerIdColumn,
        braceletIdColumn,
        nameColumn('id'),
        physicianColumn,
        procedureTypeColumn,
        visitDurationColumn,
        roomOrDateOfVisit,
        ...eventColumns,
      ],
      View: PatientListView,
      FilterComponent: withProps({
        textSearchKey: 'name',
        hideSpecialitySelectInput: false,
        hideProcedureTypeSelectInput: true,
        hideMobile: true,
      })(CustomFilters),
      defaultFilterValues: {
        dateRange: NamedRange.lastSevenDays(),
      },
      pickFilter: pick([
        'name',
        'physician',
        'procedureType',
        'hospital',
        'status',
        'dateRange',
        'category',
        'speciality',
      ]),
      Empty,
      mapListProps: compose(mapListProps, filterEventColumns),
      containsSeparator: true,
    },
    Show: {
      columns: [
        idColumn,
        providerIdColumn,
        physicianColumn,
        procedureTypeColumn,
        smsNotificationNumbersColumn,
        phoneNumberColumn,
        dateOfVisit,
        note,
      ],
      Empty,
      View: PatientView,
      Title: PatientShowTitle,
      itemProvider: graphql(scheme.subscription, {
        options: ({ idProvider, ...rest }) => ({
          variables: {
            id: parseInt(idProvider(rest), 10),
            fetchPolicy: 'cache-and-network',
          },
        }),
      }),
    },
    Create: {
      Input: PatientCreationInput,
      Empty,
      prepareCreateData,
      initialValue: { type: PatientType.Out },
    },
    Edit: {
      mapEditItemProps,
      Input: PatientUpdateInput,
      Empty,
      prepareUpdateData: prepareCreateData,
    },
    searchSource: activeAndPastProceduresSource,
  })
);

export default props => (
  <ThemeProvider theme={{ gridListColumnsTemplate: 'auto auto auto minmax(10vw,2fr) auto' }}>
    <ActiveAndPastPatients {...props} />
  </ThemeProvider>
);

import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { assignMultipleShifts, listAssignedStaffMembers } from '../../../../../graph/staff';

interface MultipleStaffShiftSelectionContextState {
  roomIds: number[];
  procedureIds: number[];
  selectedItems: number;
  deselectAll: () => void;
  addRoom: (id: number) => void;
  removeRoom: (id: number) => void;
  addProcedure: (id: number) => void;
  removeProcedure: (id: number) => void;
  assignStaffMember: (id: number) => void;
  addOpenPosition: () => void;
}

const MultipleStaffShiftSelectionContext = createContext<MultipleStaffShiftSelectionContextState>({
  roomIds: [],
  procedureIds: [],
  selectedItems: 0,
  deselectAll: () => {},
  addRoom: () => {},
  removeRoom: () => {},
  addProcedure: () => {},
  removeProcedure: () => {},
  assignStaffMember: () => {},
  addOpenPosition: () => {},
});

export function useMultipleStaffShiftSelectionContext() {
  return useContext(MultipleStaffShiftSelectionContext);
}

interface MultipleStaffShiftSelectionContextProviderProps {
  date: string;
  children: React.ReactNode;
}

export const MultipleStaffShiftSelectionContextProvider: FC<MultipleStaffShiftSelectionContextProviderProps> = ({
  date,
  children,
}) => {
  const [roomIds, setRoomIds] = useState<number[]>([]);
  const [procedureIds, setProcedureIds] = useState<number[]>([]);

  const addRoom = (id: number) => setRoomIds((prev: number[]) => [...prev, id]);
  const removeRoom = (id: number) => setRoomIds((prev: number[]) => prev.filter(e => e !== id));

  const addProcedure = (id: number) => setProcedureIds((prev: number[]) => [...prev, id]);
  const removeProcedure = (id: number) => setProcedureIds((prev: number[]) => prev.filter(e => e !== id));

  const deselectAll = () => {
    setProcedureIds([]);
    setRoomIds([]);
  };

  const [assign] = useMutation(assignMultipleShifts);

  const assignStaffMember = async (staffId: number) => {
    await assign({
      variables: {
        date,
        staffId,
        roomIds,
        procedureIds,
      },
      refetchQueries: [{ query: listAssignedStaffMembers, variables: { date } }],
    });
  };

  const addOpenPosition = async () => {
    await assign({
      variables: {
        date,
        roomIds,
        procedureIds,
      },
    });
  };

  const selectedItems = useMemo(() => roomIds.length + procedureIds.length, [roomIds, procedureIds]);

  const context = {
    roomIds,
    procedureIds,
    selectedItems,
    deselectAll,
    addRoom,
    removeRoom,
    addProcedure,
    removeProcedure,
    assignStaffMember,
    addOpenPosition,
  };

  return (
    <MultipleStaffShiftSelectionContext.Provider value={context}>
      {children}
    </MultipleStaffShiftSelectionContext.Provider>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { branch, compose, lifecycle, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';

import RouterLink from './RouterLink';
import { H3 } from './typography';
import { withTheme } from '../theme';
import responsive from '../utilities/responsive';
import withKeyEvent from '../hocs/withKeyEvent';
import LinkButton from './LinkButton';
import { Close } from '@material-ui/icons';

const ModalWrapper = styled.div`
  padding: ${withTheme(theme => theme.defaultSpacing)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: ${props => `auto ${props.width || '35rem'} auto`};
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 1202;
  ${props =>
    props.fullWindow &&
    css`
      padding: 0;
    `}
  ${responsive.md.andSmaller`
    grid-template-columns: auto 100% auto;
  `};
`;

export const ModalHeader = styled.div`
  display: grid;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  border-radius: 0.25rem;
  padding: 1.5rem 2rem 2em;
  background-color: ${withTheme(theme => theme.backgroundColor.string())};
  color: ${withTheme(theme => theme.textColor.string())};

  ${responsive.md.andSmaller`
    padding: 1.5rem 1.25rem 1.25rem;
  `};
`;

const closeButtonStyles = `
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-self: end;
  align-self: baseline;
  margin-top: 0.5rem;
  z-index: 1;
  text-decoration: none;
  opacity: 0.25;
  transition: all 0.1s ease;

  &:visited {
    color: inherit;
  }

  &:hover {
    opacity: 1;
  }
`;

const CloseRouterLink = styled(RouterLink)`
  ${closeButtonStyles}
`;

const CloseRouterButton = styled(LinkButton)`
  ${closeButtonStyles}
`;

export const ModalTitle = styled(H3)`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  margin-top: 0.5rem;
`;

const EnhancedModalWrapper = compose(
  withKeyEvent('escKeyEvent', 'esc'),
  branch(
    props => props.withRouter,
    compose(
      withRouter,
      withHandlers({
        handleEscKeyEvent: ({ history, location, closeTo }) => () => {
          history.push({ ...location, pathname: closeTo });
        },
      })
    ),
    withHandlers({
      handleEscKeyEvent: ({ onClose }) => () => {
        if (onClose) {
          onClose();
        }
      },
    })
  ),
  lifecycle({
    componentDidMount() {
      const { escKeyEvent, handleEscKeyEvent } = this.props;
      escKeyEvent.listen(handleEscKeyEvent);
    },
    componentWillUnmount() {
      const { escKeyEvent, handleEscKeyEvent } = this.props;
      escKeyEvent.unlisten(handleEscKeyEvent);
    },
  })
)(ModalWrapper);

const ModalHeaderWithLink = ({ to, replace }) => (
  <CloseRouterLink to={to} className="material-icons" replace={replace}>
    <Close fontSize={'medium'} />
  </CloseRouterLink>
);

const ModalHeaderWithButton = ({ onClose }) => (
  <CloseRouterButton onClick={onClose} className="material-icons">
    <Close fontSize={'medium'} />
  </CloseRouterButton>
);

const Modal = props => {
  const { title, closeTo, onClose, children, elementId = 'spicy-modal', withRouter = true, width, replace } = props;

  return ReactDOM.createPortal(
    <EnhancedModalWrapper {...props} width={width}>
      <ModalBody onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          {withRouter ? (
            <ModalHeaderWithLink to={closeTo} replace={replace} />
          ) : (
            onClose && <ModalHeaderWithButton onClose={onClose} />
          )}
        </ModalHeader>
        {children}
      </ModalBody>
    </EnhancedModalWrapper>,
    document.getElementById(elementId)
  );
};

export default Modal;

export const RouterlessModal = props => <Modal {...props} withRouter={false} />;

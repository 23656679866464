import { compose, mapProps } from 'recompose';
import get from 'lodash/get';
import { getPatientNameAsInitials } from '../components/entities/patient/transducers';
import { withPatientNameFormat } from '../contexts/PatientNameFormat';
import { withScope } from '../components/HospitalInfo';

export const InitialsOnlyFormatter = patient => getPatientNameAsInitials(patient.name, 1, false).initials;

export const LastNameInitialFormatter = patient => getPatientNameAsInitials(patient.name, 1, true).initials;

export const FirstNameInitialFormatter = patient => getPatientNameAsInitials(patient.name, 1, false, true).initials;

export const FullNameFormatter = patient => patient.name;

export const PatientIdFormatter =
  (hospitalId, groupId = 1) =>
  patient =>
    (hospitalId ? `G${groupId}H${hospitalId}-` : '#') + patient.id;

export const getPatientNameFormatter = (format, hospitalId, groupId = 1) => {
  const formatter = {
    LastNameInitial: LastNameInitialFormatter,
    FirstNameInitial: FirstNameInitialFormatter,
    FullName: FullNameFormatter,
    PatientId: PatientIdFormatter(hospitalId, groupId),
  };

  return formatter[format] || InitialsOnlyFormatter;
};

export const withPatientNameFormatter = compose(
  withPatientNameFormat,
  withScope,
  mapProps(({ patientNameFormat, scope, ...props }) => ({
    patientNameFormatter: getPatientNameFormatter(
      patientNameFormat,
      get(scope, 'hospital.id'),
      get(scope, 'hospital.groupId')
    ),
    ...props,
  }))
);

import React from 'react';

import { isFunction } from '../../utilities/check';
import EntityState from '../EntityState';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}));

export default ({ error, children, ...rest }) => {
  const classes = useStyles();
  const devEnv = process.env.NODE_ENV !== 'production';

  if (devEnv) {
    console.error(error);
  }

  return (
    <EntityState
      icon={'error_outline'}
      title={'An error happened'}
      hint={'Please try again or contact our support if the error persists.'}
      {...rest}
    >
      {devEnv ? (
        <Alert severity="error" className={classes.alert}>
          {error && isFunction(error.toString) && error.toString()}
          {children}
        </Alert>
      ) : null}
    </EntityState>
  );
};

import { gql } from '@apollo/client';

import { fragments as patientFragments } from './patients';
import { fragments as staffFragments } from './staff';

const baseFields = `
  id
  status
  startTime
  startTimeText
  endTimeText
  duration
  orId
  or
  isCanceled
  physicianId
  physicianHstId
  physicianName
  physicianNameHST
  physicianColor
  patientId
  patientHstId
  patientName
  patientNameUnformatted
  patientInitials
  patientDateOfBirth
  patientDateOfBirthISO
  patientAge
  patientSex
  patientHomePhone
  patientCellPhone
  caretakerCellPhone
  familyCellPhone
  patientEmailAddress
  procedureTypeId
  procedureType
  procedureMod
  diagnosis
  equipment
  cptCodes
  bilaterally
  visitType
  room
  insuranceName
  policyName
  anesthesiologistId
  anesthesiologistHstId
  anesthesiologistName
  anesthesiaType
  allergy
  notes
  visit
  preferredLanguage
  caretakerThread {
    id
    hasUnreadMessages
  }
  patientThread {
    id
    hasUnreadMessages
  }
  vendorRepresentative {
    id
    vendor {
      name
    }
    implant
    price
    representative {
      name
      email
      phoneNumber
    }
  }
`;

export const fragments = {
  all: gql`
    fragment ProcedureData on Procedure {
      ${baseFields}
      physician {
        id
        name
        color
        notificationNumbers
        email
      }
      patient {
        id
        name
        nameUnformatted
        type
        initials
        status
        log {
          id
          entries {
            type
            room {
              id
              name
              type
            }
            manualEntryBy {
              id
              name
              superRole
            }
            manualTimestamp
            status
            delivery
            value
            performedBy {
              id
              name
              superRole
            }
          }
          timestamp
        }
        room {
          id
          type
          name
        }
        bed
        ...PatientEvents
      }
      anesthesiologist {
        id
        name
        phoneNumber
        email
      }
      vendors {
        id
        name
      }
    }
    ${patientFragments.patientEvents}
  `,
  base: gql`
    fragment ProcedureBase on Procedure {
     ${baseFields}
    }
  `,
  questionnaire: gql`
    fragment Questionnaire on QuestionnaireAnswers {
      id
      questionnaireVersion
      progress
      questions
      answers
      progress
      submittedAt
    }
  `,
};

export const dailyProcedures = gql`
  query dailyProcedures($date: Date!) {
    procedures: dailyProcedures(date: $date) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const itemSubscription = gql`
  subscription procedure($id: Long!) {
    scheduledProcedure: procedure(id: $id) {
      ...ProcedureBase

      anesthesiologist {
        id
        name
      }

      physician {
        id
        name
      }

      visits {
        id
        startTime
        procedureType
        visit
        isCanceled
      }

      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        anesthesiaReviewed
        exchangeId
      }

      patient {
        id
        notificationNumbers
        initials
      }

      entryQuestionnaire {
        ...Questionnaire
      }

      procedureInstructions
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
      instructionsAccepted
      anesthesiaReviewed
    }
  }
  ${fragments.base}
  ${fragments.questionnaire}
`;

export const listSubscription = gql`
  subscription ($date: Date!, $physician: Long, $operationRoomId: Long, $isCanceled: Boolean) {
    procedures(date: $date, physician: $physician, operationRoomId: $operationRoomId, isCanceled: $isCanceled) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;
export const listWithStaffShiftSubscription = gql`
  subscription ($date: Date!, $physician: Long, $operationRoomId: Long, $isCanceled: Boolean) {
    procedures(date: $date, physician: $physician, operationRoomId: $operationRoomId, isCanceled: $isCanceled) {
      ...ProcedureData
      staffShifts {
        ...StaffShiftData
      }
    }
  }
  ${fragments.all}
  ${staffFragments.shift}
`;

export const frontListSubscription = gql`
  subscription ($date: Date!, $physician: Long, $operationRoomId: Long, $isCanceled: Boolean) {
    unregisteredPatients: procedures(
      date: $date
      physician: $physician
      operationRoomId: $operationRoomId
      isCanceled: $isCanceled
    ) {
      ...ProcedureBase
      physician {
        name
      }
      events {
        id
        type
        timestamp
        channel
        content
        text
        status
      }
    }
  }
  ${fragments.base}
`;

export const scheduleProcedure = gql`
  mutation scheduleProcedure(
    $operationRoomId: Long!
    $date: String!
    $startTime: String!
    $duration: Int!
    $physicianId: Long!
    $anesthesiologistId: Long
    $anesthesiaType: String!
    $patientProviderId: Long!
    $patientName: String!
    $patientDOB: String
    $patientSex: String
    $procedureTypeId: Long!
    $procedureType: String!
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $vendorId: Long
    $implant: String
    $price: String
    $representative: RepresentativeInput
    $diagnosis: String
    $equipment: String
    $cptCodes: String
    $bilaterally: String
    $visitType: String
    $room: String
    $insuranceName: String
    $policyName: String
    $caretakerCellPhone: String
    $familyCellPhone: String
    $patientEmailAddress: String
  ) {
    scheduleProcedure(
      operationRoomId: $operationRoomId
      date: $date
      startTime: $startTime
      duration: $duration
      physicianId: $physicianId
      anesthesiologistId: $anesthesiologistId
      anesthesiaType: $anesthesiaType
      patientProviderId: $patientProviderId
      patientName: $patientName
      patientDOB: $patientDOB
      patientSex: $patientSex
      procedureTypeId: $procedureTypeId
      procedureType: $procedureType
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      vendorId: $vendorId
      implant: $implant
      price: $price
      representative: $representative
      diagnosis: $diagnosis
      equipment: $equipment
      cptCodes: $cptCodes
      bilaterally: $bilaterally
      visitType: $visitType
      room: $room
      insuranceName: $insuranceName
      policyName: $policyName
      caretakerCellPhone: $caretakerCellPhone
      familyCellPhone: $familyCellPhone
      patientEmailAddress: $patientEmailAddress
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editScheduledProcedure = gql`
  mutation editScheduledProcedure(
    $procedureId: Long!
    $operationRoomId: Long!
    $date: String!
    $startTime: String!
    $duration: Int!
    $physicianId: Long!
    $anesthesiologistId: Long
    $anesthesiaType: String!
    $patientProviderId: Long!
    $patientName: String!
    $patientDOB: String
    $patientSex: String
    $procedureTypeId: Long!
    $procedureType: String!
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $vendorId: Long
    $implant: String
    $price: String
    $representative: RepresentativeInput
    $diagnosis: String
    $equipment: String
    $cptCodes: String
    $bilaterally: String
    $visitType: String
    $room: String
    $insuranceName: String
    $policyName: String
    $caretakerCellPhone: String
    $familyCellPhone: String
    $patientEmailAddress: String
  ) {
    editScheduledProcedure(
      procedureId: $procedureId
      operationRoomId: $operationRoomId
      date: $date
      startTime: $startTime
      duration: $duration
      physicianId: $physicianId
      anesthesiologistId: $anesthesiologistId
      anesthesiaType: $anesthesiaType
      patientProviderId: $patientProviderId
      patientName: $patientName
      patientDOB: $patientDOB
      patientSex: $patientSex
      procedureTypeId: $procedureTypeId
      procedureType: $procedureType
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      vendorId: $vendorId
      implant: $implant
      price: $price
      representative: $representative
      diagnosis: $diagnosis
      equipment: $equipment
      cptCodes: $cptCodes
      bilaterally: $bilaterally
      visitType: $visitType
      room: $room
      insuranceName: $insuranceName
      policyName: $policyName
      caretakerCellPhone: $caretakerCellPhone
      familyCellPhone: $familyCellPhone
      patientEmailAddress: $patientEmailAddress
    )
  }
`;

export const editProcedure = gql`
  mutation (
    $id: Long!
    $operationRoom: String!
    $date: String!
    $startTime: String!
    $duration: Int!
    $physicianId: Long!
    $anesthesiologistId: Long
    $anesthesiaType: String!
    $patientName: String!
    $patientAge: Age
    $patientSex: String
    $procedureType: String!
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
  ) {
    editProcedure(
      id: $id
      operationRoom: $operationRoom
      date: $date
      startTime: $startTime
      duration: $duration
      physicianId: $physicianId
      anesthesiologistId: $anesthesiologistId
      anesthesiaType: $anesthesiaType
      patientName: $patientName
      patientAge: $patientAge
      patientSex: $patientSex
      procedureType: $procedureType
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editProcedureAnesthesiologist = gql`
  mutation ($id: Long!, $anesthesiologistId: Long) {
    editProcedureAnesthesiologist(id: $id, anesthesiologistId: $anesthesiologistId) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const editProcedureAnesthesiaType = gql`
  mutation ($id: Long!, $anesthesiaType: String!) {
    editProcedureAnesthesiaType(id: $id, anesthesiaType: $anesthesiaType) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

const update = gql`
  mutation (
    $id: Long!
    $patientName: String
    $notes: String
    $allergy: String
    $patientHomePhone: String
    $patientCellPhone: String
    $patientEmailAddress: String
  ) {
    editProcedureEntry(
      id: $id
      patientName: $patientName
      notes: $notes
      allergy: $allergy
      patientHomePhone: $patientHomePhone
      patientCellPhone: $patientCellPhone
      patientEmailAddress: $patientEmailAddress
    ) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

const updateMobileNumber = gql`
  mutation ($id: Long!, $patientCellPhone: String) {
    editProcedureEntry(id: $id, patientCellPhone: $patientCellPhone) {
      ...ProcedureData
    }
  }
  ${fragments.all}
`;

export const cancelProcedure = gql`
  mutation ($id: Long!) {
    cancelProcedure(id: $id)
  }
`;

export const reactivateProcedure = gql`
  mutation ($id: Long!) {
    reactivateProcedure(id: $id)
  }
`;

export const item = gql`
  query procedure($id: Long!) {
    scheduledProcedure: procedure(id: $id) {
      ...ProcedureBase
      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        exchangeId
      }

      patient {
        id
        notificationNumbers
      }

      entryQuestionnaire {
        ...Questionnaire
      }

      procedureInstructions
      instructionsAccepted
      anesthesiaReviewed
    }
  }
  ${fragments.base}
  ${fragments.questionnaire}
`;

export const list = gql`
  query procedures($filter: Filter) {
    scheduledProcedures: procedures(filter: $filter) {
      ...ProcedureBase
      entryQuestionnaire {
        id
        progress
        submittedAt
      }
      events {
        id
        type
        timestamp
        patientPin
        channel
        text
        status
        content
        exchangeId
      }
      instructionsAccepted
    }
  }
  ${fragments.base}
`;

export const scheduledProceduresSubscription = gql`
  subscription scheduledProcedures($filter: Filter) {
    scheduledProcedures(filter: $filter) {
      ...ProcedureBase
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
      instructionsAccepted
      questionnaireInviteSMSCount
      questionnaireInviteEmailCount
      physician {
        id
        name
      }
      lastQuestionnaireInviteSMS {
        id
        type
        timestamp
        patientPin
        channel
        text
        status
        content
        exchangeId
      }
      events {
        id
        type
        timestamp
        patientPin
        channel
        content
        user {
          id
          name
        }
        text
        status
        anesthesiaReviewed
        exchangeId
      }
      anesthesiaReviewed
    }
  }
  ${fragments.base}
`;

export const procedureStatistics = gql`
  subscription procedureStatistics($filter: Filter) {
    procedureStatistics(filter: $filter) {
      scheduledProcedures
      formsFilled
      formsNotFilled
      totalForms
      pendingInfo
      preOpCompleted
      instructionsAccepted
    }
  }
`;

export const scheduledProceduresViews = gql`
  subscription scheduledProceduresViews($filter: Filter) {
    scheduledProceduresViews(filter: $filter) {
      questionnairesNotSent
      incompleteQuestionnaires
    }
  }
`;

export const resendQuestionnaireInvitationSMS = gql`
  mutation resendQuestionnaireInvitationSMS($procedureId: Long!) {
    resendQuestionnaireInvitationSMS(procedureId: $procedureId)
  }
`;

export const resendRegisterReadySMS = gql`
  mutation resendRegisterReadySMS($procedureId: Long!) {
    resendRegisterReadySMS(procedureId: $procedureId)
  }
`;

export const resendQuestionnaireInvitationEmail = gql`
  mutation resendQuestionnaireInvitationEmail($procedureId: Long!) {
    resendQuestionnaireInvitationEmail(procedureId: $procedureId)
  }
`;

export const resendInstructionInvitationSMS = gql`
  mutation resendInstructionInvitationSMS($procedureId: Long!, $templateType: InstructionTemplateType!) {
    resendInstructionInvitationSMS(procedureId: $procedureId, templateType: $templateType)
  }
`;

export const resendInstructionInvitationEmail = gql`
  mutation resendInstructionInvitationEmail($procedureId: Long!, $templateType: InstructionTemplateType!) {
    resendInstructionInvitationEmail(procedureId: $procedureId, templateType: $templateType)
  }
`;

export const setStatus = gql`
  mutation setProcedureStatus($id: Long!, $status: String) {
    setProcedureStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const setAnesthesiaReviewed = gql`
  mutation setAnesthesiaReviewed($id: Long!, $anesthesiaReviewed: Boolean!) {
    setAnesthesiaReviewed(id: $id, anesthesiaReviewed: $anesthesiaReviewed) {
      id
      anesthesiaReviewed
    }
  }
`;

export const postPreopNote = gql`
  mutation postPreopNote($id: Long!, $preopNote: String!) {
    postPreopNote(id: $id, preopNote: $preopNote) {
      id
      events {
        id
        type
        timestamp
        patientPin
        channel
        user {
          id
          name
        }
        text
        status
        exchangeId
      }
    }
  }
`;

export const deletePreopNote = gql`
  mutation deletePreOpNote($id: Long!) {
    deletePreOpNote(id: $id)
  }
`;

export const getSurgeryInstructionsTemplates = gql`
  query surgeryInstructionsTemplates {
    surgeryInstructionsTemplates {
      id
      type
      content
    }
  }
`;

export const updateSurgeryInstructionsTemplate = gql`
  mutation updateSurgeryInstructionsTemplate($templateType: InstructionTemplateType!, $content: String!) {
    updateSurgeryInstructionsTemplate(templateType: $templateType, content: $content)
  }
`;

export const updateProcedureSurgeryInstructions = gql`
  mutation updateProcedureSurgeryInstructions($procedureId: Long!, $content: String!) {
    updateProcedureSurgeryInstructions(procedureId: $procedureId, content: $content)
  }
`;

export const resetProcedureInstructions = gql`
  mutation resetProcedureInstructions($procedureId: Long!) {
    resetProcedureInstructions(procedureId: $procedureId)
  }
`;

export default {
  item,
  list,
  scheduledProceduresSubscription,
  update,
  itemSubscription,
  listSubscription,
  dailyProcedures,
  frontListSubscription,
  updateMobileNumber,
};

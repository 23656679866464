const styles = {
  header: {
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  underline: {
    textDecoration: 'underline',
  },
  inputField: {
    border: 0,
    outline: 0,
    backgroundColor: 'lightgrey',
    width: 'calc(100% - 1.5rem)',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  div: {
    lineHeight: '1.5em',
  },
  medicationsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  medicationsInnerDiv: {
    border: '1px solid black',
    padding: '0.1rem',
    borderRight: 0,
  },
  reviewsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
  },
  greyBackground: {
    backgroundColor: 'lightgrey',
    border: '1px solid black',
    fontWeight: 'bold',
    padding: '0.1rem',
    textAlign: 'center',
  },
  textareaField: {
    border: 0,
    outline: 0,
    backgroundColor: 'lightgrey',
    width: 'calc(100% - 2rem)',
    height: '250px',
    padding: '0.5rem',
    marginLeft: '0.5rem',
    resize: 'none',
  },
  twoColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    alignContent: 'start',
  },
  fiveColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '20% 20% 20% 20% 20%',
    textAlign: 'center',
  },
  twoNotEqualColumns: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '60% 40%',
    alignContent: 'start',
  },
  twoNotEqualColumns1: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    alignContent: 'start',
  },
  twoNotEqualColumns2: {
    lineHeight: '1.6em',
    display: 'grid',
    gridTemplateColumns: '50% 25% 25%',
    alignContent: 'start',
  },
  border: {
    border: '1px solid black',
  },
  leftDownCorner: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '.25em',
  },
  rightTopCorner: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '.25em',
  },
  allergiesSection: {
    lineHeight: '1rem',
    border: '1px solid black',
  },
  tab: {
    textAlign: 'center',
  },
  activeTab: {
    backgroundColor: 'lightgrey',
    border: '1px solid black',
    textAlign: 'center',
    width: '15%',
  },
  sectionHeader: {
    border: '1px solid black',
    backgroundColor: 'lightgrey',
    textAlign: 'center',
  },
  sectionRow: {
    border: '1px solid black',
    textAlign: 'center',
  },
  sectionEmptyRow: {
    border: '1px solid black',
    textAlign: 'center',
    padding: '10px',
  },
  inlineLeft: {
    display: 'inline',
    textAlign: 'left',
  },
  inlineRight: {
    display: 'inline',
    textAlign: 'left',
  },
  signatureBox1: {
    display: 'flex',
    flexDirection: 'rows',
    textAlign: 'center',
  },
  examLabGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    height: '80px',
  },
  examLabInnerGridDiv: {
    lineHeight: '150%',
    borderLeft: '1px solid black',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  oneColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'center',
    padding: '0.5rem',
    border: '1px solid black',
    borderBottom: 0,
  },
  threeNotEqualColumns3: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    border: '1px solid black',
    borderBottom: 0,
  },
  twoNotEqualColumns4: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    border: '1px solid black',
  },
  twoEqualColumns3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    border: '1px solid black',
    borderBottom: 0,
  },
};

export default styles;

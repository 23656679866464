import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { RouterlessModal } from '../../../se/components/Modal';
import PasswordInput from '../../../se/components/inputs/PasswordInput';
import LinkButton from '../../../se/components/LinkButton';
import Form from '../../../se/components/Form';
import { compose, withProps } from 'recompose';
import { withDescription } from '../../../se/components/Description';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

export const changePassword = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password)
  }
`;

const ChangePasswordInput = withProps({
  schema: {
    password: compose(
      withDescription(
        'Password must be at least 8 characters long and contain numbers, lowercase and uppercase letters'
      )
    )(PasswordInput),
  },
})(ObjectInput);

const ChangePasswordForm = ({ onClose }) => {
  const [change] = useMutation(changePassword);

  const onChangePassword = async ({ password }) => {
    try {
      await change({
        variables: {
          password,
        },
      });
      onClose({ success: true });
    } catch (e) {
      throw e;
    }
  };

  return <Form autoFocus input={ChangePasswordInput} label="Change" onSubmit={onChangePassword} />;
};

export default class ChangePassword extends React.Component {
  state = {
    success: false,
  };

  handleClose = e => {
    if (e?.success) {
      this.setState({ success: true });
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <div>
        {this.state.success ? (
          <RouterlessModal title="Change Password" onClose={this.props.onClose}>
            <p style={{ marginBottom: '1em' }}>Password changed. Use your new password on your next login.</p>
            <LinkButton onClick={this.handleClose}>Okay</LinkButton>
          </RouterlessModal>
        ) : (
          <RouterlessModal title="Change Password" onClose={this.handleClose}>
            <ChangePasswordForm onClose={this.handleClose} />
          </RouterlessModal>
        )}
      </div>
    );
  }
}

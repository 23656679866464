import { gql } from '@apollo/client';

export const fragments = {
  list: gql`
    fragment FormList on Form {
      id
      order
      name
      type
      saveType
      isSigned
    }
  `,
  view: gql`
    fragment FormView on Form {
      id
      order
      name
      type
      saveType
      content
      value
      realValue
      isSigned
      signature
      mainCategory
      defaultValue
    }
  `,
  formBase: gql`
    fragment FormBase on FormSigning {
      id
      order
      name
      formType
      content
      shouldSign
      isSigned
    }
  `,
};

export const procedureForms = gql`
  query procedureForms($procedureId: Long) {
    procedureForms(procedureId: $procedureId) {
      ...FormBase
    }
  }
  ${fragments.formBase}
`;

export const uploadFormTypes = gql`
  query uploadFormTypes {
    uploadFormTypes {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const list = gql`
  query forms {
    forms {
      ...FormList
    }
  }
  ${fragments.list}
`;

export const get = gql`
  query form($id: Long!, $procedureId: Long!) {
    form(id: $id, procedureId: $procedureId) {
      ...FormView
      entryQuestionnaire(procedureId: $procedureId) {
        id
        questions
        answers
      }
    }
  }
  ${fragments.view}
`;

export const getSubscription = gql`
  subscription form($id: Long!, $procedureId: Long!) {
    form(id: $id, procedureId: $procedureId) {
      ...FormView
      entryQuestionnaire(procedureId: $procedureId) {
        id
        questions
        answers
      }
    }
  }
  ${fragments.view}
`;

export const patientChartForm = gql`
  subscription patientChartForm($id: Long!, $procedureId: Long!) {
    patientChartForm(id: $id, procedureId: $procedureId) {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const update = gql`
  mutation submitForm($id: Long!, $procedureId: Long!, $value: Json!) {
    submitForm(id: $id, procedureId: $procedureId, value: $value) {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const uploadRequest = gql`
  query uploadRequest($id: Long!, $procedureId: Long, $fileName: String!, $contentType: String!) {
    uploadRequest(id: $id, procedureId: $procedureId, fileName: $fileName, contentType: $contentType)
  }
`;

export const uploadedForms = gql`
  query uploadedForms($id: Long!, $procedureId: Long) {
    uploadedForms(id: $id, procedureId: $procedureId)
  }
`;

export const uploadedFormsSubscription = gql`
  subscription uploadedForms($id: Long!, $procedureId: Long) {
    uploadedForms(id: $id, procedureId: $procedureId)
  }
`;

export const patientFile = gql`
  query patientFile($ids: [Long!]!, $procedureId: Long!, $mdSignature: Boolean!) {
    patientFile(ids: $ids, procedureId: $procedureId, mdSignature: $mdSignature)
  }
`;

export const confirmFormUpload = gql`
  mutation confirmFormUpload($id: Long!, $procedureId: Long, $fileName: String!, $value: Json!) {
    confirmFormUpload(id: $id, procedureId: $procedureId, fileName: $fileName, value: $value)
  }
`;

export const removeUploadedForm = gql`
  mutation removeUploadedForm($id: Long!, $procedureId: Long, $fileName: String!, $value: Json!) {
    removeUploadedForm(id: $id, procedureId: $procedureId, fileName: $fileName, value: $value)
  }
`;

export default {
  list,
  get,
  update,
  getSubscription,
  uploadRequest,
  confirmFormUpload,
  uploadedForms,
  uploadedFormsSubscription,
  uploadFormTypes,
  removeUploadedForm,
  patientFile,
};

import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInput from '../../../se/components/inputs/TextInput';
import { NotificationNumbersInput } from '../NotificationNumbersInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import CheckboxInput from '../CheckboxInput';

export default withProps({
  schema: {
    name: compose(
      withLabel('Name'),
      withProps({
        placeholder: 'e.g., Mark Nordstrom',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
    phoneNumber: NotificationNumbersInput,
    email: compose(
      withLabel('Email'),
      withProps({
        placeholder: 'e.g., john@clinic.edu',
        autoComplete: 'off',
        required: false,
      })
    )(TextInput),
    hstId: compose(
      withLabel('Provider Id'),
      withProps({
        placeholder: 'e.g., 192',
        autoComplete: 'off',
        required: false,
      })
    )(NumberInput),
    shouldSendNotification: compose(withProps({ label: 'Send Notification' }))(CheckboxInput),
  },
})(ObjectInput);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import pluralize from 'pluralize';
import titlecase from 'stringcase/lib/titlecase';
import styled from 'styled-components';
import { pure } from 'recompose';

import Row from './Row';
import { H2 } from '../typography';
import StackView from '../StackView';
import TitleAction from '../TitleAction';
import EntityLoadingState from '../entity-states/EntityLoadingState';
import EntityErrorState from '../entity-states/EntityErrorState';
import EntityEmptyState from '../entity-states/EntityEmptyState';

import { isArray } from '../../utilities/check';
import responsive from '../../utilities/responsive';
import Button from '@material-ui/core/Button';
import CollectionListOnly from './CollectionListOnly';

export const DefaultTitle = styled(H2)`
  margin: 0.5em 0;
  ${responsive.md.andSmaller`
    font-size: 1.25rem;
  `}
`;

const DefaultTitleAndActions = ({
  Title,
  Actions,
  canCreate,
  entityName,
  createUrlProvider,
  createActionTitle,
  isEmpty,
}) => (
  <TitleAction
    Title={
      Title
        ? () => <Title entityName={entityName} isEmpty={isEmpty} />
        : () => <DefaultTitle>{titlecase(pluralize(entityName))}</DefaultTitle>
    }
  >
    {Actions ? <Actions entityName={entityName} /> : null}
    {canCreate && createUrlProvider ? (
      <Button
        variant="contained"
        component={Link}
        to={{ pathname: createUrlProvider(), search: window.location.search }}
      >
        {createActionTitle || `Create ${titlecase(entityName)}`}
      </Button>
    ) : null}
  </TitleAction>
);

const CollectionList = ({
  match,
  location,
  history,
  data,
  getList,
  entityName,
  columns,
  hideColumns,
  TitleAndActions,
  Create,
  Title,
  Actions,
  View,
  onViewClick,
  createBackUrlProvider,
  createUrlProvider,
  createPathProvider,
  showUrlProvider,
  idProvider,
  Loading,
  Error,
  Empty,
  FilterComponent,
  FooterComponent,
  filter,
  setFilter,
  additionalRoutes,
  highlightedProvider,
  highlightedRowStyles,
  highlightedRowStyleProvider,
  AdditionalBlock,
  containsSeparator,
  useColumnSelection,
  defaultDisplayColumns,
  useCSVExport,
  MobileItemComponent,
  tableKey,
  simpleFilter,
  createActionTitle,
  defaultSort,
  searchSource,
}) => {
  const canCreate = Create && createUrlProvider;
  return (
    <StackView>
      <Fragment>
        {TitleAndActions ? (
          <TitleAndActions entityName={entityName} />
        ) : (
          <DefaultTitleAndActions
            entityName={entityName}
            canCreate={canCreate}
            createUrlProvider={createUrlProvider}
            createActionTitle={createActionTitle}
            Title={Title}
            Actions={Actions}
            isEmpty={!isArray(getList(data)) || !getList(data).length}
            searchSource={searchSource}
          />
        )}

        <CollectionListOnly
          match={match}
          location={location}
          history={history}
          data={data}
          getList={getList}
          columns={columns}
          hideColumns={hideColumns}
          View={View}
          onViewClick={onViewClick}
          showUrlProvider={showUrlProvider}
          idProvider={idProvider}
          Loading={Loading}
          Error={Error}
          Empty={Empty}
          FilterComponent={FilterComponent}
          FooterComponent={FooterComponent}
          filter={filter}
          setFilter={setFilter}
          highlightedProvider={highlightedProvider}
          highlightedRowStyles={highlightedRowStyles}
          highlightedRowStyleProvider={highlightedRowStyleProvider}
          AdditionalBlock={AdditionalBlock}
          containsSeparator={containsSeparator}
          useColumnSelection={useColumnSelection}
          defaultDisplayColumns={defaultDisplayColumns}
          useCSVExport={useCSVExport}
          MobileItemComponent={MobileItemComponent}
          tableKey={tableKey}
          simpleFilter={simpleFilter}
          defaultSort={defaultSort}
          searchSource={searchSource}
        />
      </Fragment>

      <Switch>
        {(additionalRoutes || []).map(({ key, path, Component, ...rest }) => (
          <Route
            {...rest}
            key={key}
            path={match.path + path}
            render={props => <Component {...props} listData={data} baseUrl={match.url} />}
          />
        ))}
        {Create ? (
          <Route
            path={createPathProvider()}
            render={props => <Create {...props} backUrlProvider={createBackUrlProvider} />}
          />
        ) : null}
      </Switch>
    </StackView>
  );
};

CollectionList.defaultProps = {
  data: {},
  View: Row,
  Loading: EntityLoadingState,
  Error: EntityErrorState,
  Empty: EntityEmptyState,
  containsSeparator: false,
  useColumnSelection: false,
  useCSVExport: false,
};

CollectionList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      lens: PropTypes.func.isRequired,
      Component: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  Create: PropTypes.func,
  TitleAndActions: PropTypes.func,
  Title: PropTypes.func,
  Actions: PropTypes.func,
  View: PropTypes.func,
  onViewClick: PropTypes.func,
  createUrlProvider: PropTypes.func,
  createPathProvider: PropTypes.func,
  showUrlProvider: PropTypes.func,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  highlightedProvider: PropTypes.func,
  highlightedRowStyles: PropTypes.object,
  highlightedRowStyleProvider: PropTypes.func,
  AdditionalBlock: PropTypes.func,
  containsSeparator: PropTypes.bool,
  tableKey: PropTypes.string,
  useColumnSelection: PropTypes.bool,
  simpleFilter: PropTypes.bool,
  defaultSort: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.oneOf(['ASC', 'DESC']),
  }),
  searchSource: PropTypes.object,
};

export default pure(CollectionList);

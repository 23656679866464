import React, { useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import get from 'lodash/get';
import has from 'lodash/has';
import isAfter from 'date-fns/is_after';
import addMinutes from 'date-fns/add_minutes';

import { Icon } from '../../Schedule';
import Time from '../Time';
import { Anesthesiologist, Personnel } from '../anesthesiologist/Personnel';
import responsive from '../../../../../se/utilities/responsive';
import Patient from '../Patient';
import { ROOM_TYPES } from '../../../../entities/room/enums';
import Appendix from './Appendix';
import { getInOrStatusLabel } from '../statusUtils';
import Notes from '../Notes';
import localHospital from '../../../../../assets/images/icons/local_hospital.png';
import {
  formatDuration,
  fullNameToDrLastName,
  fullNameToDrLastNameMiddleEast,
  fullNameToLastNameRest,
  getDelayInMinutes,
  getOrLateEntryInMinutes,
  isCompleted,
  isDelayed,
  isDimmed,
  isNoShow,
  NormalizedStatus,
  normalizeStatus,
} from '../shared/procedureUtils';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import StaffShifts from '../staff/StaffShifts';
import { Box } from '@material-ui/core';
import { StaffShiftProcedureContext } from '../staff/StaffShiftContext';
import { StaffSlotProcedureContext } from '../staff/StaffSlotContext';
import { format } from 'date-fns';
import { getPatientNameAsInitials } from '../../../../entities/patient/transducers';
import MultipleStaffShiftSelectionCheckbox from '../staff/MultipleStaffShiftSelectionCheckbox';
import { VendorProcedureProvider } from '../vendor/context/VendorProcedureContext';
import Vendors from '../vendor/Vendors';
import VendorMenu from '../vendor/VendorMenu';
import isMiddleEast from '../../../../../util/isMiddleEast';
import { getLogEntries, isPreOpAsDPU } from '../../tablet/utils';

const pulsate = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: .75;
  }
`;

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 0.16666em;
  overflow: hidden;
  border: ${props => (props.isDpuFromOr ? '1px solid purple' : 'none')};

  box-sizing: border-box;

  opacity: ${props => (props.completed ? '.3' : 'initial')};
  animation: ${props =>
    props.hasError && !props.completed ? css`0.75s ${pulsate} ease-in-out alternate infinite` : 'none'};
  margin-bottom: 0.5em;

  ${responsive.md.andSmaller`
    margin-left: .5em;
    margin-right: .5em;
  `};

  ${props =>
    props.onClick &&
    !props.disabled &&
    css`
      cursor: pointer;
      user-select: none;
      transition: transform 100ms ease-in-out, background-color 150ms ease-in-out;

      :hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      :active {
        background-color: rgba(255, 255, 255, 0.075);
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
      border: none;
      animation: none;
    `}

  ${props =>
    props.cancelled &&
    css`
      opacity: 0.3;
      border: none;
      animation: none;
    `}
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 3em;
`;

const Body = styled.div``;

const PatientAndPersonnel = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: hidden;
`;

const Description = styled.div`
  font-family: 'Roboto', 'Roboto Condensed', sans-serif;
  margin: 0.66666em 0.25em;
  display: flex;
  line-height: 1.16;
`;

export const RegularText = styled.span`
  opacity: ${props => (props.highContrast || props.warning || props.panic ? 1 : '.7')};
  color: ${props => (props.panic ? '#FF5ABB' : props.warning ? '#F55323' : 'inherit')};
  line-height: 1.2;
`;

export const EmphasizedText = styled(RegularText)`
  opacity: 1;
`;

export const ImageIcon = styled.img`
  width: 1em;
  height: 1em;
  margin-right: 0.333em;
`;

export function formatPatientName(format, name, initials) {
  if (format === 'FullName') {
    return name;
  }

  if (format === 'LastNameInitial') {
    return getPatientNameAsInitials(name, 1, true).initials;
  }

  if (format === 'FirstNameInitial') {
    console.log(format, name, initials, getPatientNameAsInitials(name, 1, false, true).initials);
    return getPatientNameAsInitials(name, 1, false, true).initials;
  }

  return initials;
}

const Procedure = ({
  hospitalId,
  date,
  procedure,
  isUnassigned,
  isPatientIconVisible,
  onClick,
  highContrast,
  isKiosk,
  editableStaff,
  editableAnesthesiologist,
  showBedNumber,
  condensView,
}) => {
  const canAddStaff = !isKiosk && editableStaff;
  const physicianId = get(procedure, 'physician.id');
  const startTime = get(procedure, 'startTime');
  const duration = get(procedure, 'duration');
  const endTime = addMinutes(startTime, duration);
  const normalizedStatus = normalizeStatus(
    procedure?.patient?.status,
    procedure?.patient?.room,
    isMiddleEast(hospitalId)
  );
  const status = has(procedure, 'patient.id') ? normalizedStatus : 'PreAdm';
  const admittedAt = get(procedure, 'patient.events.admittedAt');
  const preOpAt = get(procedure, 'patient.events.preOpAt');
  const orAt = get(procedure, 'patient.events.orAt');
  const recoveryAt = get(procedure, 'patient.events.recoveryAt');
  const isCanceled = get(procedure, 'isCanceled');

  const physicianName = isMiddleEast(hospitalId)
    ? fullNameToDrLastNameMiddleEast(procedure?.physician?.name)
    : fullNameToDrLastName(procedure?.physician?.name);
  const anesthesiologistName = isMiddleEast(hospitalId)
    ? fullNameToDrLastName(procedure?.anesthesiologist?.name)
    : fullNameToLastNameRest(procedure?.anesthesiologist?.name);
  const anesthesiaType = procedure?.anesthesiaType;

  const isHoldProcedure = get(procedure, 'patient.events.holdProcedureAt');
  const readyForSurgeon = get(procedure, 'patient.events.readyForSurgeonAt');
  const readyForOr = get(procedure, 'patient.events.readyForOrAt');

  const waitingRoomTime = admittedAt
    ? formatDuration(admittedAt, preOpAt || orAt || recoveryAt || new Date(), !(preOpAt || orAt || recoveryAt))
    : '-';

  const preOpTime = preOpAt ? formatDuration(preOpAt, orAt || new Date(), !orAt) : '-';
  const orTime = orAt ? formatDuration(orAt, recoveryAt || new Date(), !recoveryAt) : '-';

  const toleranceInMinutes = 10;
  const breaksSchedule =
    status !== 'Completed' &&
    status !== 'Canceled' &&
    status !== NormalizedStatus.In_OR &&
    status !== NormalizedStatus.In_PACU &&
    status !== NormalizedStatus.Discharged &&
    status !== NormalizedStatus.Completed &&
    status !== NormalizedStatus.Canceled &&
    isAfter(new Date(), addMinutes(endTime, toleranceInMinutes));

  const orLateInMinutes = getOrLateEntryInMinutes(procedure, isMiddleEast(hospitalId));
  const noShow = isNoShow(procedure);

  const ref = useRef();

  const isWaiting = [ROOM_TYPES.WAITING_ROOM, ROOM_TYPES.PRE_OP].includes(get(procedure, 'patient.room.type'));
  const isDPU = isPreOpAsDPU(procedure?.patient?.room?.type, getLogEntries(procedure?.patient));

  return (
    <StaffShiftProcedureContext
      hospitalId={hospitalId}
      procedureId={procedure.id}
      date={format(date, 'YYYY-MM-DD')}
      staffShifts={procedure.staffShifts || []}
    >
      <Wrapper
        ref={ref}
        onClick={onClick}
        isDpuFromOr={isDPU}
        completed={isCompleted(procedure)}
        hasError={isUnassigned || breaksSchedule}
        disabled={[NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus)}
        cancelled={isCanceled || noShow}
      >
        <Header>
          {canAddStaff && (
            <Box style={{ backgroundColor: 'rgba(255,255,255,0.1)' }} mr={0.16}>
              <MultipleStaffShiftSelectionCheckbox procedureId={procedure.id} />
            </Box>
          )}
          <Time
            canAddStaff={canAddStaff}
            startTime={procedure.startTimeText}
            endTime={procedure.endTimeText}
            duration={duration}
            late={orLateInMinutes && isWaiting}
          />
          <PatientAndPersonnel>
            <Personnel
              date={date}
              procedureId={procedure.id}
              color={get(procedure, 'physician.color') || get(procedure, 'physicianColor') || '#888888'}
              physicianId={physicianId}
              physicianName={physicianName}
              editableAnesthesiologist={editableAnesthesiologist}
              anesthesiologistName={anesthesiologistName}
              anesthesiaType={anesthesiaType}
              condensView={condensView}
            />
            {condensView && (
              <Anesthesiologist
                date={date}
                procedureId={procedure.id}
                condensView={condensView}
                editableAnesthesiologist={editableAnesthesiologist}
                anesthesiologistName={anesthesiologistName}
                anesthesiaType={anesthesiaType}
              />
            )}
            {!condensView && (
              <Patient
                name={procedure?.patient?.name || procedure?.patientName}
                age={get(procedure, 'patientAge')}
                sex={get(procedure, 'patientSex')}
                waitingRoomTime={waitingRoomTime}
                preOpTime={preOpTime}
                orTime={orTime}
                isPatientIconVisible={isPatientIconVisible}
                status={isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
                inOrStatus={
                  normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(get(procedure, 'patient.status'))
                }
                bed={showBedNumber && !!procedure?.patient?.bed ? procedure?.patient?.bed : false}
                condensView={condensView}
              />
            )}
          </PatientAndPersonnel>

          {condensView && (
            <Box flex={1} flexBasis="100%" mt="1px">
              <Patient
                name={procedure?.patient?.name || procedure?.patientName}
                age={get(procedure, 'patientAge')}
                sex={get(procedure, 'patientSex')}
                waitingRoomTime={waitingRoomTime}
                preOpTime={preOpTime}
                orTime={orTime}
                isPatientIconVisible={isPatientIconVisible}
                status={isCanceled ? 'Canceled' : noShow ? 'NoShow' : status}
                inOrStatus={
                  normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(get(procedure, 'patient.status'))
                }
                bed={showBedNumber && !!procedure?.patient?.bed ? procedure?.patient?.bed : false}
                condensView={condensView}
              />
            </Box>
          )}
        </Header>
        <Appendix
          isDPU={isDPU}
          isDimmed={isDimmed(procedure)}
          late={orLateInMinutes}
          isDelayed={isDelayed(procedure, isMiddleEast(hospitalId)) && !noShow}
          delay={getDelayInMinutes(procedure)}
          isWaiting={isWaiting}
          readyForSurgeon={get(procedure, 'patient.room.type') === ROOM_TYPES.PRE_OP && !!readyForSurgeon}
          readyForOr={get(procedure, 'patient.room.type') === ROOM_TYPES.PRE_OP && !!readyForOr}
          readyForSurgery={get(procedure, 'patient.status') === NormalizedStatus.Ready}
          isHoldProcedure={get(procedure, 'patient.room.type') === ROOM_TYPES.PRE_OP && !!isHoldProcedure}
        />
        <Body>
          {procedure.procedureType && (
            <Description>
              <ImageIcon src={localHospital} style={{ opacity: 0.2, marginTop: '.125em' }} />
              <Notes highContrast={highContrast}>
                {procedure.procedureType + (procedure.procedureMod ? ` (${procedure.procedureMod})` : '')}
              </Notes>
            </Description>
          )}
          {procedure.notes && (
            <Description>
              <Icon style={{ color: '#ABE3FF', opacity: 0.2, marginTop: '.125em' }}>assignment</Icon>{' '}
              <Notes highContrast={highContrast}>{procedure.notes}</Notes>
            </Description>
          )}
          {procedure.allergy && (
            <Description>
              <Icon style={{ color: '#ABE3FF', opacity: 0.2, marginTop: '.125em' }}>warning</Icon>
              <Notes highContrast={highContrast}>{procedure.allergy}</Notes>
            </Description>
          )}
          <Box px={0.5} pb={0.5}>
            <StaffSlotProcedureContext
              hospitalId={hospitalId}
              procedureId={procedure.id}
              date={format(date, 'YYYY-MM-DD')}
            >
              <StaffShifts
                staffShifts={procedure.staffShifts || []}
                procedure={procedure}
                isKiosk={isKiosk}
                editableStaff={editableStaff}
              >
                {canAddStaff && <StaffShiftMenu procedure={procedure} />}
              </StaffShifts>
            </StaffSlotProcedureContext>
          </Box>
          <Box px={0.5} pb={0.5}>
            <VendorProcedureProvider procedureId={procedure.id} date={format(date, 'YYYY-MM-DD')}>
              <Vendors procedure={procedure} editableStaff={editableStaff}>
                {canAddStaff && <VendorMenu procedure={procedure} />}
              </Vendors>
            </VendorProcedureProvider>
          </Box>
        </Body>
      </Wrapper>
    </StaffShiftProcedureContext>
  );
};

export default Procedure;

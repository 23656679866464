import React from 'react';
import format from 'date-fns/format';
import { TruncatedText } from '../../../se/components/typography';
import { userRoleLabels } from '../role/labels';
import { getNestedValue } from '../../../se/utilities/data/object';
import { options } from '../../inputs/PatientNameFormatInput';
import { sortDate } from '../../../util/sort';
import { smsNotificationColumn } from '../physician/columns';
import { AdminNotificationsButton } from '../../pages/user/admin/NotificationsButton';

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => item.name,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const baseColumns = [
  {
    title: 'Email',
    key: 'email',
    lens: item => item.email,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  smsNotificationColumn,
  {
    title: 'Role',
    key: 'role',
    lens: item => item.role,
    Component: props => <TruncatedText>{getNestedValue(props.data, userRoleLabels)}</TruncatedText>,
  },
  {
    title: 'Patient Name Format',
    lens: item => item.patientNameFormat,
    Component: props => <TruncatedText>{options[props.data] || props.data}</TruncatedText>,
  },
  {
    title: 'Created At',
    lens: item => item.dateCreated,
    sortFunction: (l, r) => sortDate(l.dateCreated, r.dateCreated),
    Component: props => <TruncatedText>{format(props.data, 'MM/DD/YYYY HH:mm:ss')}</TruncatedText>,
  },
  {
    title: 'Last Login',
    lens: item => item.lastLogin,
    sortFunction: (l, r) => sortDate(l.lastLogin, r.lastLogin),
    Component: props => (
      <TruncatedText>{props.data ? format(props.data, 'MM/DD/YYYY HH:mm:ss') : 'Never'}</TruncatedText>
    ),
  },
  {
    title: 'Receive Open Position Notifications',
    lens: item => item,
    Component: props => <AdminNotificationsButton {...props} />,
  },
];

import React, { Fragment, useEffect, useState } from 'react';
import { compose, withState } from 'recompose';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { Switch } from 'react-router-dom';

import Authorize from './Authorize';
import GridFader from '../../core/GridFader';
import { clearSession, fetchSession, persistSession, verifySession } from './kioskSession';
import EnterFullScreen from './EnterFullScreen';
import ScreenRouter from './ScreenRouter';
import ServerEventClient from '../../../essentials/ServerEventClient';

const COMMANDS = {
  DISCONNECT: 'DISCONNECT',
};

class KioskMachine extends React.Component {
  constructor(props) {
    super(props);
    this.source = new ServerEventClient(this.handleServerCommand);
  }

  async componentDidMount() {
    const result = await verifySession(this.props.session);
    if (!result) {
      clearSession();
      this.props.setSession({ valid: false });
    } else {
      this.handleAuthorizeSession({ ...this.props.session, token: result.token });
      this.source.open({ token: result.token, organization: this.props.organizationId });
    }
  }

  componentWillUnmount() {
    this.source.close();
  }

  disconnect = () => {
    this.source.close();
    clearSession();
    this.props.setSession({ valid: false });
  };

  handleAuthorizeSession = ({ token, path }) => {
    const sessionData = { token, path, valid: true };
    persistSession(sessionData);
    this.props.setSession(sessionData);
  };

  handleServerCommand = data => {
    const { directive } = data || {};
    if (COMMANDS[directive] === COMMANDS.DISCONNECT) {
      this.disconnect();
    }
  };

  render() {
    const { match, session: kioskSession, organizationId } = this.props;
    const { token: accessToken, valid: loggedIn, path } = kioskSession || {};
    if (!loggedIn) {
      return (
        <Switch component={GridFader}>
          <Route
            exact
            path={`${match.url}/`}
            render={() => <Authorize organizationId={organizationId} setSession={this.handleAuthorizeSession} />}
          />
          <Redirect to={`${match.url}/`} />
        </Switch>
      );
    }

    return (
      <Fragment>
        <FullscreenManager />
        <ScreenRouter
          accessToken={accessToken}
          organizationId={organizationId}
          onUnauthorized={this.disconnect}
          path={path}
        />
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withState('session', 'setSession', () => fetchSession())
)(KioskMachine);

const FullscreenManager = () => {
  const windowHeight = useWindowHeight();

  const [isInFullScreen, setIsInFullScreen] = useState(getIsInFullscreen(windowHeight));
  const [forceIsInFullScreen, setForceIsInFullScreen] = useState(false);

  useEffect(() => {
    setIsInFullScreen(getIsInFullscreen());
  }, [windowHeight]);

  return (
    <EnterFullScreen
      isInFullScreen={isInFullScreen || forceIsInFullScreen}
      onFullScreenEnter={() => setForceIsInFullScreen(true)}
    />
  );
};

function useWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowHeight;
}

function getIsInFullscreen() {
  return (
    window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: standalone)').matches
  );
}

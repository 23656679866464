import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js'; // bez js
import ospitekFonts from './vfs_fonts';

pdfMake.vfs = { ...pdfFonts.pdfMake.vfs, ...ospitekFonts.ospitek.vfs };

pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
  Glyphter: {
    normal: 'glyphter.ttf',
  },
  Fontello: {
    normal: 'fontello.ttf',
  },
};

export const createPdf = docDefinition =>
  pdfMake.createPdf(docDefinition, undefined, undefined, { ...pdfFonts.pdfMake.vfs, ...ospitekFonts.ospitek.vfs });

export default pdfMake;

import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const DataItem = ({
  name,
  value,
  unit,
  icon,
}: {
  name: string | undefined;
  value: string | undefined;
  unit: string | null;
  icon?: ReactNode | null;
}) => {
  const classes = useStyles();

  return (
    <Box fontSize="2rem">
      <Typography variant="h5" color="textSecondary">
        <Box display="flex" alignItems="center">
          {name}
          {icon && (
            <Box display="flex" ml={0.5}>
              {icon}
            </Box>
          )}
        </Box>
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="flex-end">
        <Typography variant="h4" className={classes.procedureText}>
          {value || '-'}
        </Typography>
        {!!(value && unit) && (
          <Typography variant="h5" className={classes.procedureText}>
            {unit}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  procedureText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
});

export default DataItem;

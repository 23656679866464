import React from 'react';
import styles from '../../styles';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportAllergiesRow from '../../components/MedicalPassportAllergiesRow';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toHeight from '../../../../../../questionnaire/toHeight';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import toWeightKgLbs from '../../../../../../questionnaire/toWeightKgLbs';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import { testReducer } from '../../../../../../questionnaire/fallRiskReducers';

const activityAssessment = [
  {
    label: 'Ambulatory',
    name: 'ambulatory',
  },
  {
    label: 'Ambulatory w/ assistance',
    name: 'ambulatoryWithAssistance',
  },
];

const assistiveDevice = [
  {
    label: 'Crutches',
    name: 'crutches',
  },
  {
    label: 'Walker',
    name: 'walker',
  },
  {
    label: 'Wheelchair',
    name: 'wheelchair',
  },
  {
    label: 'Cane (See Fall Assessment Record)',
    name: 'cane',
  },
];

const checkboxesPage5 = [
  {
    text: 'Level of Consciousness',
    checkboxes: [
      {
        label: 'Alert/Oriented',
        name: 'alertOriented',
      },
      {
        label: 'Confused',
        name: 'confused',
      },
      {
        label: 'Drowsy',
        name: 'drowsy',
      },
      {
        label: 'Sedated',
        name: 'sedated',
      },
    ],
  },
  {
    text: 'Emotional Status',
    checkboxes: [
      {
        label: 'Calm',
        name: 'calm',
      },
      {
        label: 'Anxious',
        name: 'anxious',
      },
      {
        label: 'Agitated',
        name: 'agitated',
      },
      {
        label: 'Fearful',
        name: 'fearful',
      },
    ],
  },
  {
    text: 'Respiratory Status',
    checkboxes: [
      {
        label: 'Spontaneous',
        name: 'spontaneous',
      },
      {
        label: 'Labored',
        name: 'labored',
      },
      {
        label: 'Nasal/Mask O2',
        name: 'nasalMaskO2',
      },
      {
        label: 'Sleep apnea',
        name: 'sleepApnea',
      },
      {
        label: 'CPAP',
        name: 'cpap',
      },
      {
        label: 'BPAP',
        name: 'bpap',
      },
    ],
  },
  {
    text: 'Skin Condition',
    checkboxes: [
      {
        label: 'Warm/dry/intact',
        name: 'warmDryIntact',
      },
      {
        label: 'Other:',
        name: 'other',
      },
    ],
  },
];

const Page5 = ({ showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const weightLbs = parseInt(defaultValue?.demographicInformation?.bmi?.weightLb);
  return (
    <div id="page5" className="page" style={{ fontSize: '0.8em' }}>
      <div style={styles.twoColumns}>
        <div>
          <div>{hospitalName || ''}</div>
          <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
          <MedicalPassportField name="procedure.serviceTime" label="Procedure Date" />
          <MedicalPassportTextArea name="procedure.name" label="Procedure" />
          <h3>Pre-Procedure Nursing Assessment</h3>
        </div>

        <div style={{ ...styles.border, position: 'relative' }}>
          <div style={styles.rightTopCorner}>
            {showQRCode && (
              <img
                alt="Barcoded value"
                src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
              />
            )}
          </div>
          <div style={styles.leftDownCorner}>
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
            <div style={{ marginBottom: '0.2rem' }}>
              <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            </div>
          </div>
        </div>
      </div>
      <div style={styles.allergiesSection}>
        <div style={styles.activeTab}>ALLERGIES</div>
        <MedicalPassportTextArea name="allergies2" path={['allergies']} reducer={toHighlightsString} />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ lineHeight: '1.6em' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr 1fr 4fr',
              fontWeight: 'bold',
              border: '1px solid black',
              borderTop: 0,
              borderBottom: 0,
              lineHeight: '1.6em',
              padding: '0 0.1rem',
            }}
          >
            <div style={{ borderRight: '1px solid black' }}>&nbsp;</div>
            <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>Yes</div>
            <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>No</div>
            <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>N/A</div>
            <div style={{ padding: '0 0.1rem' }}>Comments:</div>
          </div>
          <MedicalPassportAllergiesRow text="Caps/Crowns" name="page5.capsCrowns" />
          <MedicalPassportAllergiesRow text="Loose Teeth" name="page5.looseTeeth" />
          <MedicalPassportAllergiesRow text="Dentures" name="page5.dentures" />
          <MedicalPassportAllergiesRow text="Glasses" name="page5.glasses" />
          <MedicalPassportAllergiesRow text="Contact Lenses" name="page5.contactLenses" />
          <MedicalPassportAllergiesRow text="Hearing Aids" name="page5.hearingAids" />
          <MedicalPassportAllergiesRow text="Jewelry" name="page5.jewelry" />
          <MedicalPassportAllergiesRow text="Clippers" name="page5.clippers" />
          <MedicalPassportAllergiesRow text="Teds" name="page5.teds" />
          <MedicalPassportAllergiesRow text="Piercings" name="page5.piercings" />
          <div style={{ border: '1px solid black', padding: '0 0.1rem' }}>
            <MedicalPassportField name="npoTime" label="NPO Time" />
          </div>
          <div style={{ borderLeft: '1px solid black', paddingLeft: '3px' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>Advance Directive:</div>
              <MedicalPassportCheckbox name="page5.advanceDirective" />
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>Primary Language is:</div>
              <MedicalPassportCheckbox name="page5.english" label="English" />
              <MedicalPassportCheckbox name="page5.spanish" label="Spanish" />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <MedicalPassportCheckbox name="page5.other" label="Other: " />
                <MedicalPassportField name="page5.otherText" />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div>Interpreter needed?</div>
              <MedicalPassportCheckbox name="page5.interpreterNeededYes" label="Yes" />
              <MedicalPassportCheckbox name="page5.interpreterNeededNo" label="No" />
            </div>
          </div>
        </div>
        <div
          style={{
            lineHeight: '1.8em',
            padding: '0 0.1rem 0 0.2rem',
            borderRight: '1px solid black',
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <MedicalPassportField
              name="ht"
              label="Height"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
            />
            <MedicalPassportField
              name="wt"
              label="Weight"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightKgLbs}
              warning={weightLbs > 500}
            />
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <MedicalPassportField name="age" label="Age" path={['demographicInformation', 'dob']} reducer={toAge} />
            <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" />
          </div>
          <MedicalPassportField name="page5.timeIn" label="TimeIn" />
          <div style={{ display: 'flex', gap: '8px' }}>
            <MedicalPassportField name="page5.voided" label="Voided" />
            <MedicalPassportField name="page5.lmp" label="LMP" />
          </div>
          <div>Is there any chance you could be pregnant?</div>
          <div>
            Hand-Off PAMS(Procedure/Allergies/Medications/Special Meds):
            <MedicalPassportField name="page5.handoff" />
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <div>Allergy to Latex:</div>
            <MedicalPassportCheckbox name="page5.allergyToLatex.yes" label="Yes" />
            <MedicalPassportCheckbox name="page5.allergyToLatex.no" label="No" />
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <MedicalPassportField name="page5.painScale" label="Pain Scale" />
            <MedicalPassportField name="page5.location" label="Location" />
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <div>IV:</div>
            <MedicalPassportCheckbox name="page5.lr" label="LR @ TKO Rate" />
            <MedicalPassportCheckbox name="page5.ns" label="NS @ TKO Rate" />
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <MedicalPassportCheckbox name="other" label="Other: " />
              <div>
                <input type="text" name="handoff" style={{ ...styles.inputField, width: '35px' }} />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <div>_Cath:</div>
            <MedicalPassportCheckbox name="page5.22g" label="22G" />
            <MedicalPassportCheckbox name="page5.20g" label="20G" />
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <MedicalPassportCheckbox name="page5.otherCath" label="Other: " />
              <MedicalPassportField name="page5.otherCathText" />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div>Size:</div>
            <MedicalPassportCheckbox name="page5.right" label="Right" />
            <MedicalPassportCheckbox name="page5.left" label="Left" />
            <MedicalPassportCheckbox name="page5.hand" label="Hand" />
            <MedicalPassportCheckbox name="page5.forearm" label="Forearm" />
            <MedicalPassportCheckbox name="page5.anticubital" label="Anticubital" />
            <MedicalPassportCheckbox name="page5.other" label="Other" />
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            <MedicalPassportField name="page5.insertedBy" label="Inserted By" />
            <MedicalPassportCheckbox name="page5.ivOrdered" label="NO IV ordered" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page5.ivStarted" label="IV started by Anesthesia" />
            <div style={{ display: 'flex', gap: '4px' }}>
              <MedicalPassportCheckbox name="page5.metalOnTheBody" label="Metal in the body" />
              <MedicalPassportCheckbox name="page5.glucose" label="Glucose" />
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>BP ______ , P ______ , R ______ , 02 Sat _________ %</div>
          </div>
        </div>
      </div>
      <div style={styles.border}>
        {checkboxesPage5.map((e, i) => (
          <div style={{ borderBottom: '1px solid black' }} key={'allergies' + i}>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '1.5rem',
                marginLeft: '0.2rem',
                fontWeight: 'bold',
              }}
            >
              {e.text}
            </div>
            {e.checkboxes.map(e => (
              <MedicalPassportCheckbox
                name={e.name}
                label={e.label}
                key={e.name}
                style={{ display: 'inline-flex', marginRight: '1.5rem' }}
              />
            ))}
          </div>
        ))}
      </div>
      <div style={styles.sectionHeader}>ACTIVITY ASSESSMENT</div>
      <div style={styles.border}>
        {activityAssessment.map(e => (
          <MedicalPassportCheckbox
            name={e.name}
            label={e.label}
            key={'assessment' + e.name}
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
          />
        ))}
        {assistiveDevice.map(e => (
          <MedicalPassportCheckbox
            name={e.name}
            label={e.label}
            key={'assessment' + e.name}
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
            path={['basicscreening', 'device', 'yes', 'list']}
            reducer={testReducer(e.name.toLowerCase())}
          />
        ))}
        <MedicalPassportCheckbox name={''} label={''} style={{ display: 'inline-block', marginRight: '0.5rem' }} />
        <MedicalPassportCheckbox
          name="page5.fallPreventionGuidelines"
          label="Verbal and written Fall Prevention Guidelines given to Patient & Family"
        />
        <MedicalPassportCheckbox
          name="page5.DVTPreventionInstructions"
          label="Verbal and written DVT Prevention instructions given to patient"
        />
      </div>
      <div style={styles.sectionHeader}>PREOPERATIVE MEDICATIONS</div>
      <div style={styles.border}>
        <div style={styles.fiveColumns}>
          <div>Medication</div>
          <div>Dose</div>
          <div>Route</div>
          <div>Time</div>
          <div>Initials</div>
        </div>
        {[...Array(4)].map((e, i) => (
          <div style={styles.fiveColumns} key={'medication' + i}>
            <MedicalPassportField name="voided" label={`${i + 1}`} />
            <MedicalPassportField name="voided" />
            <MedicalPassportField name="voided" />
            <MedicalPassportField name="voided" />
            <MedicalPassportField name="voided" />
          </div>
        ))}
      </div>

      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={styles.sectionHeader}>NURSING NOTES</div>
          {[...Array(5)].map((e, index) => (
            <div style={styles.sectionEmptyRow} key={'note' + index}>
              {''}
            </div>
          ))}
          <div style={styles.twoNotEqualColumns1}>
            <div style={styles.sectionRow}>INITIALS</div>
            <div style={styles.sectionRow}>SIGNATURE</div>
            {[...Array(4)].map((e, index) => (
              <div style={styles.sectionEmptyRow} key={'signature' + index}>
                {''}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div style={styles.sectionHeader}>ADDITIONAL VITAL SIGNS</div>
          {[...Array(4)].map((e, index) => (
            <div style={styles.twoNotEqualColumns2} key={'vitalSign' + index}>
              <div style={{ ...styles.sectionRow, ...styles.twoColumns }}>
                <div>Time:</div>
                <div>BP</div>
              </div>
              <div style={styles.sectionRow}>P</div>
              <div style={styles.sectionRow}>SaO2</div>
            </div>
          ))}
        </div>
      </div>
      <div style={styles.signatureBox1}>
        <div>
          <div style={{ paddingTop: '1.5rem' }}>______________________________</div>
          <div>Nurse Signature</div>
        </div>
        <div>
          <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>_______________________</div>
          <div>Date</div>
        </div>
      </div>
    </div>
  );
};

export default Page5;

import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment, useEffect } from 'react';
import TitleAction from '../../se/components/TitleAction';
import Filters from './analytics/Filters';
import { compose, mapProps, withProps } from 'recompose';
import GraphLayout from './analytics/GraphLayout';
import { breakpoint } from '../../se/utilities/responsive';
import {
  analyticsConfigMutation,
  analyticsConfigQuery,
  analyticsReportQuery,
  dashboardQuery,
  sendAnalyticsReport,
} from '../../graph/dashboard';
import withFilterQueryString from '../../se/hocs/withFilterQueryString';
import HospitalRating from './analytics/HospitalRating';
import PWAInstallBanner from '../../se/components/PWAInstallBanner';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from './unpackSessionObject';
import { CenteredSpinner } from '../../se/components/Spinner';
import { RouterlessModal } from '../../se/components/Modal';
import Form from '../../se/components/Form';
import get from 'lodash/get';
import useModal from '../../hooks/useModal';
import { withLabel } from '../../se/components/Label';
import ReportRecipients from './sensor/ReportRecipients';
import ObjectInput from '../../se/components/inputs/ObjectInput';
import Box from '@material-ui/core/Box';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { NamedRange } from '../core/DatePicker';
import { YearMonth } from '@js-joda/core';
import TextInput from '../../se/components/inputs/TextInput';
import toArray from 'lodash/toArray';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import pick from 'lodash/fp/pick';
import objectHash from 'object-hash/dist/object_hash';

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    margin: 'auto',
  },
});

const Stats = withStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr`,
    gridGap: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr auto',
    },
  },
}))(Box);

export const roundValue = (value, digits = 1) => (value ? (Math.round(value * 100) / 100).toFixed(digits) : 0);
const AnalyticsConfigInput = withProps({
  schema: {
    monthlyReportRecipients: compose(
      withLabel('Monthly report recipients'),
      withProps({ name: 'monthlyReportRecipients' })
    )(ReportRecipients),
    weeklyReportRecipients: compose(
      withLabel('Weekly report recipients'),
      withProps({ name: 'weeklyReportRecipients' })
    )(ReportRecipients),
  },
})(ObjectInput);

const DetailedReportButton = () => {
  const { open, openModal, closeModal } = useModal();
  const [mutate] = useMutation(analyticsConfigMutation);
  const { data, loading } = useQuery(analyticsConfigQuery);

  const handleSubmit = async values => {
    const monthlyReportRecipients = toArray(get(values, 'monthlyReportRecipients'));
    const weeklyReportRecipients = toArray(get(values, 'weeklyReportRecipients'));

    const result = await mutate({
      variables: {
        monthlyReportRecipients,
        weeklyReportRecipients,
      },
      refetchQueries: [{ query: analyticsConfigQuery }],
    });

    if (get(result, 'data.updateAnalyticsConfiguration')) {
      closeModal();
    }
  };
  return (
    <div>
      <IconButton className="material-icons" onClick={openModal}>
        <SettingsIcon />
      </IconButton>
      {open && (
        <RouterlessModal
          title={'Analytics Global Configuration'}
          onClose={closeModal}
          fullWindow={window.innerWidth < breakpoint.md}
        >
          {!loading ? (
            <Form
              autoFocus
              initialValue={get(data, 'analyticsConfiguration')}
              input={AnalyticsConfigInput}
              label={'Save'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          ) : (
            <CenteredSpinner />
          )}
        </RouterlessModal>
      )}
    </div>
  );
};

const MonthYearInput = withProps({
  schema: {
    monthYear: withLabel('Enter month')(TextInput),
  },
})(ObjectInput);

const Title = ({ filter }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [reportQueryWorking, setReportQueryWorking] = React.useState(false);
  const [lastDetailedReport, setLastDetailedReport] = React.useState(null);
  const { open, openModal, closeModal } = useModal();
  const [sendReport] = useMutation(sendAnalyticsReport);

  useEffect(() => {
    if (lastDetailedReport !== null) {
      const timeout = setTimeout(() => {
        setLastDetailedReport(null);
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [lastDetailedReport]);

  const handleSubmit = async values => {
    await sendReport({
      variables: {
        monthYear: values.monthYear,
      },
    });
    closeModal();
  };

  return (
    <Fragment>
      <Typography variant="h2">Analytics</Typography>
      <Box display="flex" justifyContent="center">
        <Button
          onClick={async event => {
            if (event.altKey) {
              openModal();
            } else {
              if (reportQueryWorking) {
                return;
              }

              const filterHash = objectHash(JSON.parse(JSON.stringify(filter)));

              if (lastDetailedReport !== null && lastDetailedReport[0] === filterHash) {
                window.open(lastDetailedReport[1], '_blank');
              } else {
                setReportQueryWorking(true);

                try {
                  const result = await client.query({
                    query: analyticsReportQuery,
                    variables: { filter },
                  });

                  setLastDetailedReport([filterHash, result.data.detailedReport]);
                  window.open(result.data.detailedReport, '_blank');
                } finally {
                  setReportQueryWorking(false);
                }
              }

              closeModal();
            }
          }}
          startIcon={<PictureAsPdfIcon style={{ opacity: reportQueryWorking ? 0 : 1 }} />}
          color="primary"
          disabled={false}
        >
          <span style={{ opacity: reportQueryWorking ? 0 : 1 }}>Detailed Report</span>
          {reportQueryWorking && <CircularProgress size={20} className={classes.loading} />}
        </Button>
        <DetailedReportButton loading={false} />
        {open && (
          <RouterlessModal
            title={'Send Analytics Report'}
            onClose={closeModal}
            fullWindow={window.innerWidth < breakpoint.md}
          >
            <Form
              autoFocus
              initialValue={{ monthYear: YearMonth.now().toString() }}
              input={MonthYearInput}
              label={'Send'}
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          </RouterlessModal>
        )}
      </Box>
    </Fragment>
  );
};

export const AnalyticsLayout = ({ loading, children }) =>
  !loading ? (
    <Box mt={2}>
      <Stats>{children}</Stats>
    </Box>
  ) : (
    <CenteredSpinner style={{ height: '30em' }} />
  );

const Analytics = ({ filter, setFilter, statistics = [], isBusinessManager, loading }) => (
  <Fragment>
    <TitleAction
      Title={() => <Title filter={filter} />}
      actionStyle={{
        style: { gridTemplateColumns: '1fr', alignItems: 'start' },
      }}
    >
      <Filters onChange={setFilter} value={filter} />
    </TitleAction>

    <PWAInstallBanner />

    {!isBusinessManager && <HospitalRating filter={filter} />}
    <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
      {statistics.map((stat, i) => (
        <GraphLayout key={`${stat.id}-${i}`} {...stat} />
      ))}
    </AnalyticsLayout>
  </Fragment>
);

const pickFilter = pick([
  'name',
  'physician',
  'procedureType',
  'hospital',
  'status',
  'dateRange',
  'category',
  'speciality',
]);

export const options = ({ filter }) => {
  const { dateRange, ...rest } = filter || {};
  return { variables: { filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) } }, fetchPolicy: 'network-only' };
};

export default compose(
  withSession(unpackSessionObject),
  withFilterQueryString({
    dateRange: NamedRange.lastSevenDays(),
  }),
  graphql(dashboardQuery, { options }),
  mapProps(({ data, ...rest }) => ({ statistics: data.dashboard, loading: data.loading, ...rest }))
)(Analytics);

import {
  ADMIN,
  FRONT_DESK,
  SENSOR_SUPERVISOR,
  MONITORING,
  NURSING,
  OPERATIONS_MANAGER,
  BUSINESS_MANAGER,
  SUPER_ADMIN,
  GROUP_ADMIN,
  SCHEDULE,
  PREOP_NURSE,
  SCHEDULER,
  NURSE,
} from '../entities/role/enums';
import { getNestedValue } from '../../se/utilities/data/object';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import contains from 'lodash/fp/contains';
import set from 'lodash/fp/set';

export const unpackSessionObject = flow(
  ({ session, destroy, destroyPartially }) => {
    if (session) {
      if (
        !(
          flow(get('user.metadata'), contains(GROUP_ADMIN))(session) ||
          flow(get('user.metadata'), contains(MONITORING))(session)
        ) &&
        session.membership
      ) {
        // Look for one role, first check for powerful role
        const acceptableRoles = [
          ADMIN,
          FRONT_DESK,
          NURSING,
          NURSE,
          OPERATIONS_MANAGER,
          BUSINESS_MANAGER,
          PREOP_NURSE,
          MONITORING,
          SENSOR_SUPERVISOR,
          SCHEDULER,
        ];
        const roles = session.membership.metadata;
        const role = (roles || []).find(role => acceptableRoles.includes(role));
        return {
          session: { ...session, destroy, destroyPartially },
          accessToken: session.token,
          organization: getNestedValue('membership.organization.id', session),
          organizationName: getNestedValue('membership.organization.name', session),
          role: role,
          isAdmin: role === ADMIN,
          isMonitoringEngineer: role === MONITORING,
          isFrontDesk: (roles || []).indexOf(FRONT_DESK) >= 0,
          isScheduleUser: (session.user.metadata || []).indexOf(SCHEDULE) >= 0,
        };
      } else {
        // Look for one role, first check for powerful role
        const acceptableRoles = [SUPER_ADMIN, GROUP_ADMIN, MONITORING];
        const roles = session.user.metadata;
        const role = (roles || []).find(role => acceptableRoles.includes(role));
        return {
          session: { ...session, destroy, destroyPartially },
          accessToken: session.token,
          role: role,
          isSuperAdmin: role === SUPER_ADMIN,
          isGroupAdmin: role === GROUP_ADMIN,
          isMonitoringEngineer: role === MONITORING,
          isScheduleUser: (session.user.metadata || []).indexOf(SCHEDULE) >= 0,
        };
      }
    } else {
      return { session: null };
    }
  },
  props => {
    const hasRole = role => flow(get('session.membership.metadata'), contains(role))(props);

    return flow(
      set('isOperationsManager', hasRole('OperationsManager')),
      set('isBusinessManager', hasRole('BusinessManager')),
      set('isPreOPNurse', hasRole('PreOPNurse')),
      set('isNurse', hasRole('Nurse'))
    )(props);
  }
);

import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { disable, enable } from '../../../../graph/notificationTemplates';
import TextAreaInput from '../../../../se/components/inputs/TextAreaInput';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import TriggerInput from './TriggerInput';
import { useMutation } from '@apollo/client';
import { Channel } from '../NotificationTemplates';
import TextInput from '../../../../se/components/inputs/TextInput';
import { withLabel } from '../../../../se/components/Label';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmailIcon from '@material-ui/icons/Email';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import AdditionalInfoTextArea from './AdditionalInfoTextArea';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
}));

const NotificationTemplateInput = withProps({
  schema: {
    content: withProps({ rows: 3, placeholder: '' })(TextAreaInput),
    additionalInfo: withProps({ rows: 3 })(AdditionalInfoTextArea),
    trigger: TriggerInput,
  },
})(ObjectInput);

const EmailNotificationTemplateInput = withProps({
  schema: {
    subject: compose(
      withLabel(undefined, { section: true }, 'Email Subject'),
      withProps({ style: { marginBottom: 0 } })
    )(TextInput),
    content: compose(
      withLabel(undefined, undefined, 'Email Body'),
      withProps({ rows: 3, placeholder: '' })
    )(TextAreaInput),
  },
})(ObjectInput);

export default ({ value, onChange }) => {
  // eslint-disable-next-line
  const [busy, setBusy] = useState(false);
  const classes = useStyles();

  const [toggle] = useMutation(value.enabled ? disable : enable, {
    variables: {
      id: value.id,
    },
  });

  const handleToggle = async () => {
    setBusy(true);
    try {
      await toggle();
    } finally {
      setBusy(false);
    }
  };

  const Input = value.channel === Channel.Email ? EmailNotificationTemplateInput : NotificationTemplateInput;

  // TODO Update switch styles. See docs at https://www.npmjs.com/package/react-switch
  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {value.channel === Channel.Email ? <EmailIcon /> : <TextsmsIcon />}
          </Avatar>
        }
        action={
          <Box mr={1} mt={1}>
            <Button
              value="check"
              color={value.enabled ? 'primary' : 'default'}
              variant={value.enabled ? 'contained' : 'outlined'}
              onClick={handleToggle}
            >
              {value.enabled ? 'On' : 'Off'}
            </Button>
          </Box>
        }
        title={value.name}
        subheader={value.description}
      />
      <CardContent>
        <Input
          formId={`notificationTemplates${value.channel}`}
          value={value}
          style={{ width: '100%', marginBottom: '1em' }}
          onChange={onChange}
        />
      </CardContent>
    </Card>
  );
};

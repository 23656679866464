import { createContext } from 'react';
import Config from '../types/Config';

export const defaultConfig = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:8080',
};

const ConfigContext = createContext<Config>(defaultConfig);

export default ConfigContext;

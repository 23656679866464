import React from 'react';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import TextInput from '../../../se/components/inputs/TextInput';
import TextAreaInput from '../../../se/components/inputs/TextAreaInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { FieldRow } from '../../pages/kiosk/schedule/edit/ProcedureEditor';
import PhoneInput from '../../../se/components/inputs/PhoneInput';
import { EmailInput } from '../../../se/components/inputs/EmailInput';
import PhysicianSelectInput from '../../inputs/physician/PhysicianSelectInput';
import { withScope } from '../../HospitalInfo';
import { GraphqlSelectInput } from '../../inputs/graphqlSelectInput';
import { list } from '../../../graph/locations';
import Box from '@material-ui/core/Box';

const PatientInput = withProps({
  schema: {
    name: compose(withLabel('Patient Name'), withProps({ placeholder: 'e.g., Mark Nordstrom' }))(TextInput),
    sex: compose(
      withLabel('Sex'),
      withProps({ maxLength: 1, pattern: 'm|f|M|F', title: '[M]ale or [F]emale', placeholder: 'F' })
    )(TextInput),
    homePhone: withProps({ placeholder: 'e.g., (619) 412-9512' })(withLabel('Home Phone Number')(PhoneInput)),
    cellPhone: withProps({ placeholder: 'e.g., (619) 412-9512' })(withLabel('Mobile Phone Number')(PhoneInput)),
    emailAddress: withProps({ placeholder: 'john@xyz.com' })(withLabel('Email Address')(EmailInput)),
    physician: PhysicianSelectInput,
  },
  render: props => {
    const { name, sex, emailAddress, homePhone, cellPhone, physician } = props;
    return (
      <div>
        <FieldRow>
          <div style={{ flex: '0 1 calc(60% - 1rem)' }}>{name}</div>
          <div>{sex}</div>
        </FieldRow>
        <div>{emailAddress}</div>
        <FieldRow>
          <div>{homePhone}</div>
          <div>{cellPhone}</div>
        </FieldRow>
        <FieldRow>
          <div>{physician}</div>
        </FieldRow>
      </div>
    );
  },
})(ObjectInput);

const EditProcedureInput = withProps({
  schema: {
    patient: PatientInput,
    procedureType: compose(
      withLabel('Procedure Type'),
      withProps({ placeholder: 'e.g., Neuroimaging' })
    )(TextAreaInput),
    locationId: withScope(({ scope, ...rest }) => (
      <GraphqlSelectInput
        label={'The location of the surgical office'}
        entityName={'Location'}
        placeholder={'Location'}
        graphqlList={list}
        variables={{ organizationId: scope?.hospital?.id }}
        getOptionValue={option => (option ? parseInt(option.value, 10) : undefined)}
        isClearable={false}
        sortOptions={true}
        hideComponentIf={list => (list?.length ? list?.length < 2 : true)}
        {...rest}
      />
    )),
  },
  render: props => {
    const { procedureType, patient, locationId } = props;
    return (
      <Box mb={6}>
        <div>{patient}</div>
        <div>{procedureType}</div>
        <div>{locationId}</div>
      </Box>
    );
  },
})(ObjectInput);

export default EditProcedureInput;

import React, { FC } from 'react';
import { Chip, createStyles, makeStyles, MenuItem, MenuList, Popover } from '@material-ui/core';
import { Procedure } from '../../../../../types/Procedure';
import Vendor from '../../../../../types/Vendor';
import { useVendorProcedureContext } from './context/VendorProcedureContext';
import { RemoveCircleOutline } from '@material-ui/icons';
import { useStyles as useStylesSlotMenu } from '../staff/StaffSlotMenu';

interface VendorProps {
  vendor: Vendor;
  procedure?: Procedure;
  onClick: (vendorId: number) => Promise<void>;
  editableStaff?: boolean;
}

const VendorMenu = ({ vendor, editableStaff, onClick }: VendorProps) => {
  const classes = useStylesSlotMenu();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeSlot = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    await onClick(vendor.id);
    handleClose();
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Chip variant="outlined" color={'primary'} label={vendor?.name} onClick={handleButtonClick} size="small" />
      {open && editableStaff && (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          onClose={handleClose}
          getContentAnchorEl={null}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.popover,
          }}
        >
          <MenuList className={classes.menu} onClick={e => e.stopPropagation()}>
            <MenuItem onClick={removeSlot}>
              <Chip
                icon={<RemoveCircleOutline className={classes.iconRemove} />}
                label={'Remove Slot'}
                size="small"
                className={classes.chip}
              />
            </MenuItem>
          </MenuList>
        </Popover>
      )}
    </>
  );
};

interface VendorsProps {
  procedure?: Procedure;
  editableStaff?: boolean;
}

const Vendors: FC<VendorsProps> = ({ procedure, editableStaff, children }) => {
  const classes = useStyles();
  const vendorContext = useVendorProcedureContext();
  return (
    <div className={classes.vendors}>
      {children}
      {(procedure?.vendors || []).map(vendor => (
        <VendorMenu
          editableStaff={editableStaff}
          onClick={vendorContext.remove}
          key={vendor.id}
          procedure={procedure}
          vendor={vendor}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    vendors: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      margin: -2,

      '& > *': {
        margin: 2,
      },
    },
  })
);

export default Vendors;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import PatientSlotContainer from './PatientSlotContainer';
import PatientInfo from './PatientInfo';
import BedInfo from './BedInfo';
import { INTERNAL_MONITOR, PACU_TABLET, POST_OP_TABLET, PREP_TABLET } from '../../entities/screens/enums';
import ChatButton from '../../chat/ChatButton';
import { CaretakerChatDialog } from '../../chat/ChatDialog';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core';
import CaretakerMessage from './CaretakerMessage';

const emphasize = keyframes`
  0% {
    border-color: rgba(255, 172, 0, 0);
    box-shadow: 0 0 4px rgba(255, 172, 0, 0);
  }
  100% {
    border-color: rgba(255, 172, 0, 1);
    box-shadow: 0 0 8px rgba(255, 172, 0, 1);
  }
`;

const Container = styled(PatientSlotContainer)`
  // animation: 1s ${emphasize} ease-in alternate infinite;
  // animation-delay: 250ms;

  border-color: ${props => props.border};
`;

const useStyles = makeStyles(theme =>
  createStyles({
    chatButton: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const PatientSlot = ({
  caretakerMessage,
  physician,
  procedure,
  patient,
  patientType,
  bed,
  hideBedInfo,
  showPriority,
  priority,
  timeEntered,
  poseRef,
  isFamilyReadyPreOp,
  ready,
  isHoldProcedure,
  readyForSurgeon,
  readyForOr,
  familyReady,
  frameColor,
  expectedExitTime,
  screenType,
  patientData,
  showPreOpNote,
  preOpNote,
  showPacuNote,
  pacuNote,
  hideChart,
  isDPU,
}) => {
  const classes = useStyles();
  const isInternalScreen = screenType === INTERNAL_MONITOR && caretakerMessage;
  const isPreOpScreen = screenType === PREP_TABLET;
  const isPacuScreen = screenType === PACU_TABLET;
  const isPostOpScreen = screenType === POST_OP_TABLET;
  const showChatIcon =
    !!patientData?.notificationNumbers &&
    !!patientData?.caretakerConsent &&
    (isPacuScreen || isPreOpScreen || isPostOpScreen);

  return (
    <Container ref={poseRef} border={(ready || isFamilyReadyPreOp) && !isDPU ? '#00B63E' : frameColor}>
      <PatientInfo
        physician={physician}
        patient={patient}
        procedure={procedure}
        timeEntered={timeEntered}
        patientType={patientType}
        status={'alert'}
        ready={ready}
        readyForSurgeon={readyForSurgeon}
        readyForOr={readyForOr}
        familyReady={familyReady}
        isHoldProcedure={isHoldProcedure}
        expectedExitTime={expectedExitTime}
        bed={bed}
        showPreOpNote={showPreOpNote}
        showPacuNote={showPacuNote}
        preOpNote={preOpNote}
        pacuNote={pacuNote}
        hideChart={hideChart}
        isDPU={isDPU}
      />
      <Box className={classes.chatButton}>
        {showChatIcon && (
          <ChatButton
            hasUnreadMessages={patientData?.caretakerThread?.hasUnreadMessages}
            chatComponent={CaretakerChatDialog}
            chatProps={{
              patient: patientData,
            }}
          />
        )}
      </Box>
      {isInternalScreen && (
        <CaretakerMessage phoneNumber={caretakerMessage?.phoneNumber} message={caretakerMessage?.content} />
      )}
      {!hideBedInfo && <BedInfo bed={bed} showPriority={showPriority} priority={priority} />}
    </Container>
  );
};

export default PatientSlot;

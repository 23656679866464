import React, { Fragment } from 'react';
import GraphLayout from '../analytics/GraphLayout';
import BarLayout, { barColors } from '../analytics/BarLayout';
import PieChart from '../analytics/PieChart';
import { AnalyticsLayout, roundValue } from '../Analytics';
import { defaultTo, find, zip } from 'lodash';
import { breakpoint } from '../../../se/utilities/responsive';
import get from 'lodash/get';

const utilizationBreakdown = ({ operationRoomBookings, scheduledPatients }) => {
  const utilization = defaultTo(scheduledPatients.value, 0);
  const bookings = defaultTo(operationRoomBookings.value, 0);

  const unutilizedBlocks = Math.max(bookings - utilization, 0);
  const vacantBlocks = 100 - utilization - unutilizedBlocks;

  return { utilization, unutilizedBlocks, vacantBlocks };
};

const calculateBookingDataPoint = point => {
  const utilization = defaultTo(point['bar-0'], 0);
  const bookings = defaultTo(point['bar-1'], 0);

  const unutilizedBlocks = Math.max(bookings - utilization, 0);
  const vacantBlocks = 100 - utilization - unutilizedBlocks;

  return {
    ...point,
    'bar-0': roundValue(utilization),
    'bar-1': roundValue(unutilizedBlocks),
    'bar-2': roundValue(vacantBlocks),
  };
};

const BookingAnalytics = ({ statistics = [], loading }) => {
  const operationRoomBookings = defaultTo(
    find(statistics, stat => stat.id === 'operationRoomBookings'),
    {}
  );
  const scheduledPatients = defaultTo(
    find(statistics, stat => stat.id === 'scheduledPatients'),
    {}
  );
  const showBookingStats = operationRoomBookings && scheduledPatients;
  const { utilization, unutilizedBlocks, vacantBlocks } = utilizationBreakdown({
    operationRoomBookings,
    scheduledPatients,
  });
  const isMobile = window.innerWidth < breakpoint.md;

  const bars = [
    {
      ...scheduledPatients,
      value: utilization,
      title: 'Utilization',
    },
    { ...operationRoomBookings, title: 'Unutilized Bookings' },
  ];

  const distribution = zip(...bars.map(b => get(b, 'distribution')))
    .map(bars =>
      bars.reduce((acc, curr, i) => ({ ...acc, [`bar-${i}`]: curr.value, date: curr.date, name: curr.name }), {})
    )
    .map(calculateBookingDataPoint);

  const barsData = [
    ...bars.map((_, i) => ({ ...bars[i], id: `bar-${i}`, color: barColors[i] })),
    { id: `bar-2`, title: 'Vacancy', unit: '%', color: barColors[2] },
  ];

  return (
    <AnalyticsLayout loading={loading}>
      {showBookingStats && (
        <Fragment>
          <BarLayout
            key={`block-schedule-dist`}
            barsData={barsData}
            distribution={distribution}
            title={'OR Block Allocation Utilization'}
            ratio={isMobile ? 0.6 : 0.3}
            style={{ gridColumn: !isMobile ? '1 / span 2' : undefined }}
          />
          <GraphLayout key={`block-schedule-util`} title={'Block Utilization'} compact noPadding noValue>
            <PieChart
              data={[
                { name: 'Utilization', value: utilization },
                { name: 'Unutilized Bookings', value: unutilizedBlocks },
                { name: 'Vacancy', value: vacantBlocks, legendColor: 'white' },
              ]}
              colors={barColors}
            />
          </GraphLayout>
        </Fragment>
      )}
    </AnalyticsLayout>
  );
};

export default BookingAnalytics;

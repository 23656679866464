import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { compose, withProps } from 'recompose';
import { getNestedValue } from '../../se/utilities/data/object';
import { withRouter } from 'react-router';

import { dark as darkTheme } from '../../theme';
import ScreenRouter from './kiosk/ScreenRouter';
import KioskMachine from './kiosk/KioskMachine';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from './unpackSessionObject';
import { muiThemeDark } from '../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

// FIXME: This transition causes Firefox css rendering issues, making the browser unusable
// FIXME: Maybe just disable this animation for firefox
const GlobalCss = createGlobalStyle`

  // @keyframes preventBurnIn {
  //   0% {
  //     filter: hue-rotate(0);
  //   }
  //
  //   2%, 100% {
  //     filter: hue-rotate(2deg);
  //   }
  // }
  //
  // body {
  //   animation: 1800s infinite alternate preventBurnIn;
  // }
`;

const Kiosk = ({ accessToken, session, unpackedSession, isHuman, isSuperAdmin, organizationId }) => (
  <Fragment>
    <ThemeProvider theme={darkTheme}>
      <MUIThemeProvider theme={muiThemeDark}>
        <CssBaseline />
        {isHuman ? (
          <ScreenRouter
            accessToken={accessToken}
            organizationId={organizationId}
            isHuman={true}
            isSuperAdmin={isSuperAdmin}
          />
        ) : (
          <KioskMachine organizationId={organizationId} />
        )}
      </MUIThemeProvider>
    </ThemeProvider>
    <GlobalCss />
  </Fragment>
);

export default compose(
  withRouter,
  withProps(({ match }) => ({ organizationId: getNestedValue('params.organizationId', match) })),
  withSession(({ session }) => ({
    isHuman: session && session.account && session.user,
    accessToken: getNestedValue('token', session),
    session,
    ...unpackSessionObject({ session }),
  }))
)(Kiosk);

import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment StatisticsData on Statistics {
      id
      title
      unit
      value
      distribution {
        name
        value
        date
      }
    }
  `,
};

export const dashboardQuery = gql`
  query dashboard($filter: Filter) {
    dashboard(filter: $filter) {
      ...StatisticsData
    }
  }
  ${fragments.all}
`;

export const analyticsConfigQuery = gql`
  query analyticsConfiguration {
    analyticsConfiguration {
      monthlyReportRecipients
      weeklyReportRecipients
    }
  }
`;

export const analyticsReportQuery = gql`
  query detailedReport($filter: Filter) {
    detailedReport(filter: $filter)
  }
`;

export const sendAnalyticsReport = gql`
  mutation sendDetailedReport($monthYear: String!) {
    sendDetailedReport(monthYear: $monthYear)
  }
`;

export const analyticsConfigMutation = gql`
  mutation updateAnalyticsConfiguration($monthlyReportRecipients: [Long!]!, $weeklyReportRecipients: [Long!]!) {
    updateAnalyticsConfiguration(
      monthlyReportRecipients: $monthlyReportRecipients
      weeklyReportRecipients: $weeklyReportRecipients
    )
  }
`;

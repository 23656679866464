import { ProcedureForm, ScheduleProcedureVars } from '../types/types';
import set from 'lodash/set';

import get from 'lodash/fp/get';

import flow from 'lodash/fp/flow';
import { set as setFp } from 'lodash/fp';
import { Representative } from '../../../../../../types/Vendor';
import { Room } from '../../../../../../types/Room';
import { format } from 'date-fns';
import { Procedure } from '../../../../../../types/Procedure';
import { PatientType } from '../../../../../../types/Patient';

export const sort = (array: string[]) => array.sort((a, b) => b.toLowerCase().localeCompare(a.toLowerCase()));

export const validateForm = (procedureForm: ProcedureForm): ProcedureForm =>
  flow(validateMail, validateRequired)(procedureForm);

const setMailErrors = (emailsPath: string[], procedureForm: ProcedureForm): ProcedureForm => {
  const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  let emailErrors = { ...procedureForm };
  for (let i = 0; i < emailsPath.length; i++) {
    const emailPath = emailsPath[i];
    emailErrors = setFp(
      `${emailPath}.error`,
      !filter.test(get(`${emailPath}.value`)(procedureForm) || '')
        ? !!get(`${emailPath}.value`)(procedureForm)
          ? 'Not valid email pattern.'
          : undefined
        : undefined
    )(emailErrors);
  }
  return emailErrors;
};

const validateMail = (procedureForm: ProcedureForm): ProcedureForm =>
  setMailErrors(
    ['staff.anesthesiologist.email', 'staff.physician.email', 'vendor.representative.email'],
    procedureForm
  );

const setValidateRequiredError = (value: ProcedureForm, path: string) =>
  !get(`${path}.value`)(value)
    ? set(value, `${path}.error`, 'This field is required.')
    : set(value, `${path}.error`, undefined);

export const validateRequired = (procedureForm: ProcedureForm): ProcedureForm => {
  const procedureFormCopy = { ...procedureForm };

  // Has side effects -> try to do without side effects

  setValidateRequiredError(procedureFormCopy, 'patient.basicInfo.firstName');
  setValidateRequiredError(procedureFormCopy, 'patient.basicInfo.lastName');
  setValidateRequiredError(procedureFormCopy, 'patient.basicInfo.medicalRecord');

  setValidateRequiredError(procedureFormCopy, 'staff.anesthesiologist.name');
  setValidateRequiredError(procedureFormCopy, 'staff.physician.name');

  setValidateRequiredError(procedureFormCopy, 'procedure.procedureType.name');
  setValidateRequiredError(procedureFormCopy, 'procedure.room.name');
  setValidateRequiredError(procedureFormCopy, 'procedure.anesthesiaType');
  setValidateRequiredError(procedureFormCopy, 'procedure.timeInfo.timeRange.startTime');
  setValidateRequiredError(procedureFormCopy, 'procedure.timeInfo.timeRange.duration');
  setValidateRequiredError(procedureFormCopy, 'procedure.timeInfo.dateOfService');

  return procedureFormCopy;
};

export const getCreateState = (
  date: Date,
  operationRoom: Room,
  timeRange?: { startTime: string; duration: number }
): ProcedureForm => ({
  equipment: undefined,
  insurance: undefined,
  patient: undefined,
  // @ts-ignore
  procedure: {
    // Remove this @ts-ignore
    room: {
      name: {
        value: operationRoom?.name,
        error: !!operationRoom?.name ? undefined : 'This field is required.',
      },
      type: {
        value: operationRoom?.type,
      },
      id: {
        value: operationRoom?.id,
      },
    },
    timeInfo: {
      dateOfService: {
        value: format(date, 'YYYY-MM-DD'),
        error: !!format(date, 'YYYY-MM-DD') ? undefined : 'This field is required.',
      },
      timeRange: {
        startTime: {
          // @ts-ignore
          value: timeRange?.startTime,
          error: undefined,
        },
        duration: {
          // @ts-ignore
          value: timeRange?.duration,
          error: undefined,
        },
      },
    },
  },
  staff: undefined,
  vendor: undefined,
});

export const getEditState = (procedure: Procedure, operationRoom: Room, date: Date): ProcedureForm => ({
  equipment: procedure?.equipment?.split(','),
  insurance: {
    insuranceName: { value: procedure?.insuranceName || '' },
    policyName: { value: procedure?.policyName || '' },
  },
  patient: {
    basicInfo: {
      firstName: {
        value: procedure?.patientNameUnformatted?.split(' ')?.[0] || '',
        error: undefined,
      },
      lastName: {
        value: procedure?.patientNameUnformatted?.split(' ')?.[1] || '',
        error: undefined,
      },
      medicalRecord: {
        value: `${procedure?.patientHstId}` || '',
        error: undefined,
      },
      dateOfBirth: {
        // @ts-ignore
        value: procedure?.patientDateOfBirthISO || null,
      },
      sex: {
        value: procedure?.patientSex || null,
      },
      diagnosis: {
        value: procedure?.diagnosis || null,
      },
      allergies: {
        value: procedure?.allergy || null,
      },
      note: {
        value: procedure?.notes || null,
      },
    },
    contactInfo: {
      familyPhoneNumber: {
        value: procedure?.familyCellPhone || null,
      },
      patientPhoneNumber: {
        value: procedure?.patientCellPhone || null,
      },
      careTakerPhoneNumber: {
        value: procedure?.caretakerCellPhone || null,
      },
      homePhoneNumber: {
        value: procedure?.patientHomePhone || null,
      },
      patientEmail: {
        value: procedure?.patientEmailAddress || null,
      },
    },
  },
  procedure: {
    room: {
      name: {
        value: operationRoom?.name,
        error: !!operationRoom?.name ? undefined : 'This field is required.',
      },
      type: {
        value: operationRoom?.type,
      },
      id: {
        value: operationRoom?.id,
      },
    },
    procedureType: {
      name: {
        value: procedure?.procedureType,
        error: undefined,
      },
      id: {
        value: procedure?.procedureTypeId,
      },
    },
    anesthesiaType: {
      value: procedure?.anesthesiaType,
      error: undefined,
    },
    cptCodes: {
      value: procedure?.cptCodes || null,
    },
    bilaterally: {
      value: procedure?.bilaterally || null,
    },
    timeInfo: {
      dateOfService: {
        value: format(date, 'YYYY-MM-DD'),
        error: !!format(date, 'YYYY-MM-DD') ? undefined : 'This field is required.',
      },
      timeRange: {
        startTime: {
          value: procedure?.startTimeText || '',
          error: undefined,
        },
        duration: {
          value: procedure?.duration || 0,
          error: undefined,
        },
      },
    },
    visit: {
      visitType: {
        value: (procedure?.visitType as PatientType) || null,
      },
      room: {
        value: procedure?.room,
      },
    },
  },
  staff: {
    physician: {
      name: {
        value: procedure?.physician?.name,
        error: undefined,
      },
      notificationNumbers: {
        value: procedure?.physician?.notificationNumbers,
      },
      email: {
        value: procedure?.physician?.email,
        error: undefined,
      },
      id: {
        value: procedure?.physician?.id,
      },
    },
    anesthesiologist: {
      name: {
        value: procedure?.anesthesiologist?.name,
        error: undefined,
      },
      phoneNumber: {
        value: procedure?.anesthesiologist?.phoneNumber,
      },
      email: {
        value: procedure?.anesthesiologist?.email,
        error: undefined,
      },
      id: {
        value: procedure?.anesthesiologist?.id,
      },
    },
  },
  vendor: {
    vendorInfo: {
      name: {
        value: procedure?.vendors[0]?.name,
        error: undefined,
      },
      id: {
        value: procedure?.vendors[0]?.id,
      },
      representatives: {
        value: procedure?.vendorRepresentative?.vendor?.representatives,
      },
    },
    implant: {
      value: procedure?.vendorRepresentative?.implant || null,
    },
    price: {
      value: `${procedure?.vendorRepresentative?.price}` || null,
    },
    representative: {
      name: {
        value: procedure?.vendorRepresentative?.representative?.name,
        error: undefined,
      },
      email: {
        value: procedure?.vendorRepresentative?.representative?.email,
        error: undefined,
      },
      phoneNumber: {
        value: procedure?.vendorRepresentative?.representative?.phoneNumber,
      },
      id: {
        value: procedure?.vendorRepresentative?.representative?.id,
      },
    },
  },
});

// fix this once you have more time
export const transform = (procedureForm: ProcedureForm): ScheduleProcedureVars => ({
  operationRoomId: procedureForm?.procedure?.room?.id?.value as number,
  date: procedureForm?.procedure?.timeInfo?.dateOfService?.value as string,
  startTime: procedureForm?.procedure?.timeInfo?.timeRange?.startTime?.value as string,
  duration: procedureForm?.procedure?.timeInfo?.timeRange?.duration?.value || 0,
  physicianId: procedureForm?.staff?.physician?.id?.value as number,
  anesthesiologistId: procedureForm?.staff?.anesthesiologist?.id?.value as unknown as number,
  anesthesiaType: procedureForm?.procedure?.anesthesiaType?.value as string,
  patientName: `${procedureForm?.patient?.basicInfo?.firstName?.value?.trim()} ${procedureForm?.patient?.basicInfo?.lastName?.value?.trim()}`,
  patientDOB: procedureForm?.patient?.basicInfo?.dateOfBirth?.value,
  patientSex: procedureForm?.patient?.basicInfo?.sex?.value,
  procedureTypeId: procedureForm?.procedure?.procedureType?.id?.value as number,
  procedureType: procedureForm?.procedure?.procedureType?.name?.value as string,
  notes: procedureForm?.patient?.basicInfo?.note?.value,
  allergy: procedureForm?.patient?.basicInfo?.allergies?.value,
  patientHomePhone: procedureForm?.patient?.contactInfo?.homePhoneNumber?.value,
  patientCellPhone: procedureForm?.patient?.contactInfo?.patientPhoneNumber?.value,
  vendorId: procedureForm?.vendor?.vendorInfo?.id?.value,
  implant: procedureForm?.vendor?.implant?.value,
  price: procedureForm?.vendor?.price?.value?.replace('$', ''),
  representative:
    procedureForm?.vendor?.vendorInfo?.id?.value && procedureForm?.vendor?.representative?.name?.value
      ? ({
          name: procedureForm?.vendor?.representative?.name?.value,
          phoneNumber: procedureForm?.vendor?.representative?.phoneNumber?.value,
          email: procedureForm?.vendor?.representative?.email?.value,
        } as Representative)
      : undefined,
  diagnosis: procedureForm?.patient?.basicInfo?.diagnosis?.value,
  equipment: procedureForm?.equipment?.join(','),
  cptCodes: procedureForm?.procedure?.cptCodes?.value,
  bilaterally: procedureForm?.procedure?.bilaterally?.value,
  visitType: procedureForm?.procedure?.visit?.visitType?.value,
  room: procedureForm?.procedure?.visit?.room?.value,
  insuranceName: procedureForm?.insurance?.insuranceName?.value,
  policyName: procedureForm?.insurance?.policyName?.value,
  caretakerCellPhone: procedureForm?.patient?.contactInfo?.careTakerPhoneNumber?.value,
  familyCellPhone: procedureForm?.patient?.contactInfo?.familyPhoneNumber?.value,
  patientEmailAddress: procedureForm?.patient?.contactInfo?.patientEmail?.value,
  patientProviderId: parseInt(procedureForm?.patient?.basicInfo?.medicalRecord?.value || '0', 10),
});

export const transformValue = <A extends {}, B extends {}>(valueObject: A, keyObject: B) => {
  const mergedObject = {};
  Object.keys(keyObject).forEach(key => {
    if (valueObject.hasOwnProperty(key)) {
      //@ts-ignore
      mergedObject[key] = { value: valueObject[key] };
    }
  });
  return mergedObject;
};

export const checkErrors = (obj: {}, error: { found: boolean }) => {
  for (const key in obj) {
    if (typeof get(key)(obj) == 'object' && !!get(key)(obj)) checkErrors(get(key)(obj), error);
    else {
      if (key === 'error' && !!get(key)(obj)) {
        error.found = true;
        return;
      }
    }
  }
};

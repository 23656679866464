import React, { Dispatch, FC, SetStateAction } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { mdiCard, mdiCardOff } from '@mdi/js';
import Icon from '@mdi/react';

const ToggleOverlay: FC<{
  showOverlay: boolean;
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
}> = ({ showOverlay, setShowOverlay }) => (
  <>
    {showOverlay ? (
      <IconButton onClick={() => setShowOverlay(!showOverlay)} color="primary">
        <Icon path={mdiCardOff} title="Hide Block Schedule" size={1} />
      </IconButton>
    ) : (
      <IconButton onClick={() => setShowOverlay(!showOverlay)} color="primary">
        <Icon path={mdiCard} title="Show Block Schedule" size={1} />
      </IconButton>
    )}
  </>
);

export default ToggleOverlay;

import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment AnesthesiologistData on Anesthesiologist {
      id
      name
      email
      hstId
      phoneNumber
      hasScheduleAccess
      scheduleAccessAll
      shouldSendNotification
    }
  `,
};

export const list = gql`
  query anesthesiologists {
    anesthesiologists {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query anesthesiologist($id: Long!) {
    anesthesiologist(id: $id) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createAnesthesiologist(
    $name: String!
    $hstId: Long
    $phoneNumber: String
    $email: String
    $shouldSendNotification: Boolean
  ) {
    createAnesthesiologist(
      name: $name
      hstId: $hstId
      phoneNumber: $phoneNumber
      email: $email
      shouldSendNotification: $shouldSendNotification
    ) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editAnesthesiologist(
    $id: Long!
    $hstId: Long
    $name: String!
    $phoneNumber: String
    $email: String
    $shouldSendNotification: Boolean
  ) {
    editAnesthesiologist(
      id: $id
      name: $name
      hstId: $hstId
      phoneNumber: $phoneNumber
      email: $email
      shouldSendNotification: $shouldSendNotification
    ) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteAnesthesiologist($id: Long!) {
    deleteAnesthesiologist(id: $id)
  }
`;

export const grantScheduleAccess = gql`
  mutation grantScheduleAccessToAnesthesiologist($id: Long!) {
    grantScheduleAccessToAnesthesiologist(id: $id) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const resendScheduleAccessLink = gql`
  mutation resendScheduleAccessLinkToAnesthesiologist($id: Long!) {
    resendScheduleAccessLinkToAnesthesiologist(id: $id) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const setScheduleAccessAllAnesthesiologist = gql`
  mutation setScheduleAccessAllAnesthesiologist($id: Long!, $scheduleAccessAll: Boolean!) {
    setScheduleAccessAllAnesthesiologist(id: $id, scheduleAccessAll: $scheduleAccessAll)
  }
`;

export const getScheduleAccessAllAnesthesiologist = gql`
  query getScheduleAccessAllAnesthesiologist($id: Long) {
    getScheduleAccessAllAnesthesiologist(id: $id)
  }
`;

export const revokeScheduleAccess = gql`
  mutation revokeScheduleAccessToAnesthesiologist($id: Long!) {
    revokeScheduleAccessToAnesthesiologist(id: $id) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const listAssignedAnesthesiologists = gql`
  query listAssignedAnesthesiologists($date: Date!) {
    listAssignedAnesthesiologists(date: $date) {
      ...AnesthesiologistData
    }
  }
  ${fragments.all}
`;

export const sendNewScheduleNotification = gql`
  mutation sendNewScheduleNotificationAnesthesiologist($contacts: [ContactInput!]!, $message: String!, $date: Date!) {
    sendNewScheduleNotificationAnesthesiologist(contacts: $contacts, message: $message, date: $date)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAllAnesthesiologist,
};

import React, { useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Frame from 'react-frame-component';
import get from 'lodash/get';
import set from 'lodash/set';
import { int as randomInt } from '../../../se/utilities/random/index.js';
import Handlebars from 'handlebars';
import { LocalDate, Period } from '@js-joda/core';
import safeEval from 'safe-eval';
import flow from 'lodash/fp/flow';
import update from 'lodash/fp/update';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import clsx from 'clsx';
import NoPermissionText from '../../NoPermissionText';
import { useTheme } from '@material-ui/core/styles';

const renderAge = dob => {
  try {
    const [monthStr, dayStr, yearStr] = dob.split('/');
    const month = parseInt(monthStr, 10);
    const day = parseInt(dayStr, 10);
    const year = parseInt(yearStr, 10);
    const date = LocalDate.of(year, month, day);
    return Period.between(date, LocalDate.now()).years();
  } catch (e) {
    return undefined;
  }
};

Handlebars.registerHelper('json', function () {
  return JSON.stringify(this, undefined, 2);
});

Handlebars.registerHelper('eval', function (code) {
  return safeEval(code, this);
});

const addAge = flow(
  update('demographicInformation', demographicInformation => ({
    ...demographicInformation,
    age: renderAge(demographicInformation?.dob),
  }))
);

const pageSize = {
  width: 215.9,
  height: 279.4,
};

const scale = 0.8;

const StyledFrame = styled(Frame)`
  width: ${pageSize.width + 40}mm; // add 20mm to left and right spacing
  font-size: ${scale}rem;
`;

export const RootFrame = React.forwardRef(({ children, allowPageBreaks }, ref) => {
  useEffect(() => {
    const curr = get(ref, 'current.node');

    if (curr) {
      setTimeout(() => {
        curr.style.height = curr?.contentWindow?.document?.documentElement?.scrollHeight + 'px';
        curr.style.width = curr?.contentWindow?.document?.documentElement?.scrollWidth + 'px';
      }, 50);
    }
  }, [ref]);

  const theme = useTheme();
  const backgroundColor = theme.palette.background.paper;
  const head = useMemo(
    () =>
      ReactHtmlParser(`
    <style>
      html {
        background-color: ${backgroundColor};
      }
      body {
        margin: 0;
      }
      .page {
        min-height: ${pageSize.height}mm;
        margin: 0;
        font-size: 1em;
        background: white;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 2px 2px 7px 4px rgba(0, 0, 0, 0.15);
        padding: 2rem;
        overflow: hidden;
      }

      .page + .page {
        margin-top: 1em;
      }

      @media screen {
        .page {
          width: ${pageSize.width}mm;
        }
      }

      @media print {
        @page {
          width: ${pageSize.width}mm;
          height: ${pageSize.height}mm;
          ${
            allowPageBreaks
              ? `
              margin-top: 2rem;
              margin-bottom: 2rem;
            `
              : `
              margin: 0;
            `
          }
        }

        .page {
          width: ${pageSize.width}mm;
          ${
            allowPageBreaks
              ? `
              padding-top: 0;
              padding-bottom: 0;
              page-break-after: always;
            `
              : `
              height: ${pageSize.height}mm;
              margin: 0;
            `
          }
          font-size: 1em;
          box-shadow: none;
        }
      }
    </style>
  `),
    [allowPageBreaks, backgroundColor]
  );

  return (
    <StyledFrame ref={ref} head={head} frameBorder="0" scrolling="no">
      {children}
    </StyledFrame>
  );
});

const expandType = value => {
  if (value === 'false') {
    return false;
  } else if (value === 'true') {
    return true;
  } else {
    return value;
  }
};

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'center',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

const DatabaseHtmlForm = React.forwardRef(({ content, value, setValue, signature }, ref) => {
  const classes = useStyles();
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  const onChange = id => e => {
    if (e.target.type === 'checkbox') {
      const newVal = { ...value };
      set(newVal, id, e.target.checked);
      setValue(newVal);
    } else if (e.target.type === 'radio') {
      const newVal = { ...value };
      set(newVal, id, expandType(e.target.value));
      setValue(newVal);
    } else {
      const newVal = { ...value };
      set(newVal, id, e.target.value);
      setValue(newVal);
    }
  };

  const memoizedOnChange = useCallback(onChange, [onChange]);

  const transform = useCallback(
    node => {
      const { type, name } = node;
      if (type === 'tag' && name === 'input') {
        const { id, type } = node.attribs;

        if (type === 'radio') {
          const fieldVal = get(value, node.attribs.name);
          const attrs = { checked: String(fieldVal) === node.attribs.value };
          return (
            <input
              {...node.attribs}
              onChange={memoizedOnChange(node.attribs.name)}
              key={`${node.attribs.name}-${id}`}
              {...attrs}
            />
          );
        } else if (type === 'checkbox') {
          const fieldVal = get(value, id);
          const attrs = { checked: !!fieldVal };
          return <input {...node.attribs} onChange={memoizedOnChange(id)} key={id} {...attrs} />;
        } else {
          const fieldVal = get(value, id);
          const attrs = { value: fieldVal || '' };
          return <input {...node.attribs} onChange={memoizedOnChange(id)} key={id} {...attrs} />;
        }
      } else if (type === 'tag' && name === 'textarea') {
        const { id } = node.attribs;
        return <textarea {...node.attribs} onChange={memoizedOnChange(id)} value={get(value, id)} key={id} />;
      } else if (type === 'tag' && name === 'img' && 'data-signature' in node.attribs) {
        const signatureKey = node.attribs['data-signature'];
        const src = signatureKey ? value[signatureKey] : signature;
        const alt = signatureKey ? signatureKey : 'patient signature';
        if (src) {
          return <img src={src} {...node.attribs} alt={alt} key={`signature-${randomInt()()}`} />;
        }
      }
    },
    [value, memoizedOnChange, signature]
  );

  const renderedContent = useMemo(() => {
    const template = Handlebars.compile(content || '');
    return template(addAge(value));
  }, [content, value]);

  const node = useMemo(() => ReactHtmlParser(renderedContent, { transform }), [renderedContent, transform]);

  return (
    <Box className={clsx({ [classes.content]: !isAllowedToView })}>
      {!isAllowedToView && (
        <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
          <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
        </Box>
      )}
      <RootFrame ref={ref}>{node}</RootFrame>
    </Box>
  );
});

export default DatabaseHtmlForm;

import React from 'react';
import TextAnswer from './TextAnswer';
import { DateAnswer } from '../../types/Answer';

interface Props {
  label: string;
  answer?: DateAnswer;
}

const DateTextAnswer = ({ label, answer }: Props) => <TextAnswer question={label} answer={answer} />;

export default DateTextAnswer;

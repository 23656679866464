import React, { forwardRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Value } from './ValueContext';
import { RootFrame } from '../DatabaseHtmlForm';
import Page1V2 from './v2/pages/Page1';
import Page3V2 from './v2/pages/Page3';
import Page4V2 from './v2/pages/Page4';
import Page5V2 from './v2/pages/Page5';
import Page6V2 from './v2/pages/Page6';
import Page7V2 from './v2/pages/Page7';
import Page1V1 from './v1/pages/Page1';
import Page3V1 from './v1/pages/Page3';
import Page4V1 from './v1/pages/Page4';
import Page5V1 from './v1/pages/Page5';
import Page6V1 from './v1/pages/Page6';
import Page7V1 from './v1/pages/Page7';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../../NoPermissionText';
import useHasAccessRight from '../../../../hooks/useHasAccessRight';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Page8V2 from './v2/pages/Page8';
import hasDataMatrixQRCode from '../../../../util/hasDataMatrixQRCode';

const ErrorPage = () => (
  <div
    className="page"
    style={{
      display: 'grid',
      alignContent: 'center',
      justifyItems: 'center',
    }}
  >
    <h2>Unable to render this page.</h2>
  </div>
);

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

const PredefinedForms = pages =>
  forwardRef(
    (
      {
        hospitalId,
        hospitalName,
        defaultValue,
        value,
        setValue,
        signature,
        questionnaire,
        questionnaireAnswers,
        lastPreOpCompletedEvent,
        procedureId,
      },
      ref
    ) => {
      const classes = useStyles();
      const hasAccessRight = useHasAccessRight();
      const isAllowedToView = hasAccessRight('patient.view');
      const showQRCode = hasDataMatrixQRCode(hospitalId);

      return (
        <Box className={clsx({ [classes.content]: !isAllowedToView })}>
          {!isAllowedToView && (
            <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
              <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
            </Box>
          )}
          <RootFrame ref={ref} allowPageBreaks>
            <Value.Provider value={{ defaultValue, value, setValue, questionnaire, questionnaireAnswers }}>
              <style>
                {`
          html {
            font-family: sans-serif;
          }
          p {
            margin: 0;
          }
          @media print {
            ::-webkit-scrollbar {
              display: none;
            }

            html, body {
              background: none;
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
            }

            .page {
              border: none;
            }

            textarea {
              resize: none;
            }
          `}
              </style>
              {pages.map((Page, i) => (
                <ErrorBoundary key={i} FallbackComponent={ErrorPage}>
                  <Page
                    {...{
                      showQRCode,
                      hospitalName,
                      defaultValue,
                      value,
                      setValue,
                      questionnaire,
                      questionnaireAnswers,
                      lastPreOpCompletedEvent,
                      procedureId,
                    }}
                  />
                </ErrorBoundary>
              ))}
            </Value.Provider>
          </RootFrame>
        </Box>
      );
    }
  );

const predefinedForms = {
  medicalPassport: PredefinedForms([Page1V1, Page3V1, Page4V1, Page5V1, Page6V1, Page7V1]),
  medicalPassport1: PredefinedForms([Page1V1, Page3V1, Page4V1, Page5V1, Page6V1, Page7V1]),
  medicalPassport2: PredefinedForms([Page1V2, Page3V2, Page4V2, Page5V2, Page6V2, Page7V2, Page8V2]),
  fallRiskAssessment: PredefinedForms([Page7V2]),
  vteAssessment: PredefinedForms([Page6V2]),
};

export default predefinedForms;

import { CustomModal } from '../../../pages/kiosk/tablet/Tablet';
import { RouterlessModal } from '../../../../se/components/Modal';
import React, { useMemo, useState } from 'react';
import { getRoom } from '../../common/transducers';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { useMutation, useSubscription } from '@apollo/client';
import { listNursing } from '../../../../graph/rooms';
import { discharge, setRoom } from '../../../../graph/patients';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { OR, PACU, PRE_OP } from '../../room/enums';
import { RoomPicker } from '../RoomPicker';
import Button from '@material-ui/core/Button';
import Icon from '@mdi/react';
import { mdiAccountArrowRightOutline, mdiAccountHeartOutline } from '@mdi/js';
import withStyles from '@material-ui/core/styles/withStyles';

const stopPropagation = fn => e => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (fn) {
    fn(e);
  }
};

const filterOR = rooms => filter(rooms, ({ type }) => type === OR);

const RoomConfirmModal = ({ handleConfirm, handleCancel, patientName, roomType }) => {
  const { data } = useSubscription(listNursing);
  const room = useMemo(() => find(get(data, 'rooms'), room => get(room, 'type') === roomType), [data, roomType]);
  const roomName = get(room, 'name');

  const ReadyPrompt = useMemo(
    () => CustomModal(`Are you sure you want to move ${patientName} to ${roomName}?`, true),
    [patientName, roomName]
  );

  const onConfirm = () => handleConfirm(get(room, 'id'));

  return (
    <RouterlessModal onClose={stopPropagation(handleCancel)}>
      {roomType === OR ? (
        <RoomPicker
          rooms={filterOR(get(data, 'rooms', []))}
          onCancel={stopPropagation(handleCancel)}
          onChoose={handleConfirm}
        />
      ) : (
        <ReadyPrompt handleConfirm={stopPropagation(onConfirm)} handleCancel={stopPropagation(handleCancel)} />
      )}
    </RouterlessModal>
  );
};

export const ConfirmDischargeModal = ({ handleConfirm, handleCancel, patientName }) => {
  const Prompt = useMemo(
    () => CustomModal(`Are you sure you want to discharge patient ${patientName}?`, true),
    [patientName]
  );
  return (
    <RouterlessModal onClose={stopPropagation(handleCancel)}>
      <Prompt handleConfirm={stopPropagation(handleConfirm)} handleCancel={stopPropagation(handleCancel)} />
    </RouterlessModal>
  );
};

const flow = {
  WAITING_ROOM: PRE_OP,
  PRE_OP: OR,
  OR: PACU,
};

const roomTypeLabels = {
  PRE_OP: 'PreOp',
  OR: 'OR',
  PACU: 'Recovery',
};

const MoveButton = withStyles(theme => ({
  root: {
    justifyContent: 'end',
    backgroundColor: theme.palette.background.default,
  },
}))(Button);

const LocationChangeWidget = ({ data: { id, room, patientName } }) => {
  const [busy, setBusy] = useState(null);
  const [modal, setModal] = useState(null);
  const [doSetRoom] = useMutation(setRoom);
  const [doSetDischarge] = useMutation(discharge);
  const nextRoom = flow[get(room, 'type')];

  const withBusy = operation => async roomId => {
    setModal(false);
    setBusy(true);

    try {
      await operation({ variables: { id, roomId: roomId } });
    } finally {
      setBusy(false);
    }
  };

  const setHere = withBusy(doSetRoom);
  const setDischarge = withBusy(doSetDischarge);

  const closeModal = stopPropagation(() => setModal(null));

  const openMoveModal = stopPropagation(() =>
    setModal(
      <RoomConfirmModal
        roomType={nextRoom}
        handleConfirm={setHere}
        handleCancel={closeModal}
        patientName={patientName}
      />
    )
  );

  const openDischargeModal = stopPropagation(() =>
    setModal(<ConfirmDischargeModal handleConfirm={setDischarge} handleCancel={closeModal} patientName={patientName} />)
  );

  if (!nextRoom && !modal) {
    return (
      <MoveButton
        startIcon={<Icon path={mdiAccountHeartOutline} size={1} style={{ opacity: 0.5 }} />}
        color="secondary"
        fullWidth
        disableElevation
        variant="outlined"
        disabled={busy}
        onClick={openDischargeModal}
      >
        {busy ? 'Discharging...' : 'Discharge'}
      </MoveButton>
    );
  }

  return modal ? (
    modal
  ) : (
    <MoveButton
      startIcon={<Icon path={mdiAccountArrowRightOutline} size={1} style={{ opacity: 0.5 }} />}
      color="primary"
      fullWidth
      disableElevation
      variant="outlined"
      disabled={busy}
      onClick={openMoveModal}
    >
      {busy ? 'Moving...' : `Move To ${roomTypeLabels[nextRoom]}`}
    </MoveButton>
  );
};

export const actionColumn = {
  title: 'Action',
  style: {
    position: 'sticky',
    right: 0,
  },
  lens: data => ({
    id: data.id,
    room: getRoom(data.room),
    patientName: getNestedValue('name', data) || '',
  }),
  Component: LocationChangeWidget,
};

import React, { Ref, useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Box } from '@material-ui/core';
import { SignatureAnswer } from '../../types/Answer';
import { SignatureQuestion } from '../../types/Question';
import { Paragraph } from '../../se/components/typography';
import Button from '@material-ui/core/Button';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { useSlideIn } from '../Form';
import { useTheme } from '@material-ui/core/styles';

type SignatureInputProps = SignatureQuestion['props'] & {
  name?: string;
  answer?: SignatureAnswer;
  onChange?: (newAnswer: SignatureAnswer) => void;
};

const SignatureInput = ({ label, answer, onChange }: SignatureInputProps) => {
  const canvasRef = useRef<SignaturePad>();
  const theme = useTheme();

  const handleClear = () => {
    canvasRef.current?.clear();
  };

  useEffect(() => {
    answer && canvasRef.current?.fromDataURL(answer, { ratio: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  const [invalid, setInvalid] = useState(false);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, slideIn]);

  return (
    <Box my="auto" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
        <Paragraph style={{ color: invalid ? theme.palette.error.main : 'inherit' }}>
          {label ?? 'Draw your signature here'}
        </Paragraph>
        <Button
          variant="contained"
          size="small"
          onClick={handleClear}
          style={{
            textTransform: 'none',
            backgroundColor: '#D3D3D3',
            color: '#000c3f',
            fontSize: '1em',
            border: 'none',
            marginLeft: '2rem',
          }}
        >
          X Clear
        </Button>
      </Box>
      <div
        onTouchCancel={stopPropagation}
        onTouchCancelCapture={stopPropagation}
        onTouchEnd={stopPropagation}
        onTouchEndCapture={stopPropagation}
        onTouchMove={stopPropagation}
        onTouchMoveCapture={stopPropagation}
        onTouchStart={stopPropagation}
        onTouchStartCapture={stopPropagation}
      >
        <SignaturePad
          ref={canvasRef as Ref<SignaturePad>}
          clearOnResize={false}
          onEnd={() => onChange?.(canvasRef.current!.toDataURL('image/png'))}
          canvasProps={{
            width: 600,
            height: 300,
            style: {
              width: 600,
              height: 300,
              background: 'white',
              border: invalid ? `2px solid ${theme.palette.error.main}` : 'none',
            },
          }}
        />
      </div>
    </Box>
  );
};

export default SignatureInput;

import React, { Fragment, useMemo, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import { RouterlessModal } from '../../../../se/components/Modal';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import TextAreaInput from '../../../../se/components/inputs/TextAreaInput';
import { withLabel } from '../../../../se/components/Label';
import Form from '../../../../se/components/Form';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import fromPairs from 'lodash/fromPairs';
import SelectInput from '../../../../se/components/inputs/SelectInput';
import { create, remove, update } from '../../../../graph/bookings';
import { addMinutes, addWeeks, format, isBefore, setHours, setMinutes } from 'date-fns';

import NumberInput from '../../../../se/components/inputs/NumberInput';
import pluralize from 'pluralize';
import Button from '../../../../se/components/Button';
import { CustomModal } from '../../kiosk/tablet/Tablet';
import { getMinutesOfDay } from '../../../../util/dateTime';
import ProceduresPreview from './ProceduresPreview';
import { fragments as physicianFragments } from '../../../../graph/physicians';
import { fragments as partnerFragments } from '../../../../graph/partners';
import Grid from '@material-ui/core/Grid';
import LinkButton from '../../../../se/components/LinkButton';

const notF = fn => value => !fn(value);

const Wrapper = styled.div`
  display: flex;
  // margin-bottom: 2em;

  > * {
    flex: 1;
  }

  > * + * {
    margin-left: 0.666666em;
  }
`;

const BlockDateAndTime = styled.div`
  font-size: 1.333em;
`;

const BlockInfo = styled.div`
  margin-bottom: 1em;
  height: 2.5em;
`;

const HalfColumn = styled(Wrapper)`
  margin-bottom: 0;
`;

const ForInput = compose(withLabel('For'), withProps({ required: true }))(SelectInput);

const constructTimeOptions = (from, to) => {
  const label = format(from, 'h:mm A');
  const option = { [getMinutesOfDay(from)]: label === '12:00 PM' ? 'Noon' : label };
  return isBefore(from, to) ? { ...option, ...constructTimeOptions(addMinutes(from, 15), to) } : option;
};

const timeOptions = constructTimeOptions(
  setMinutes(setHours(new Date(), 6), 0),
  setMinutes(setHours(new Date(), 18), 0)
);

const FromInput = compose(
  withLabel('From'),
  withProps({
    placeholder: 'From',
    options: timeOptions,
    required: true,
  })
)(SelectInput);

const ToInput = compose(
  withLabel('To'),
  withProps({
    placeholder: 'To',
    options: timeOptions,
    required: true,
  })
)(SelectInput);

const repeatOptions = {
  0: `Don't Repeat`,
  1: 'Every Day',
  7: 'Every Week',
  14: 'Every 2 Weeks',
  28: 'Every 4 Weeks',
};

const RepeatInput = compose(
  withLabel('Repeat'),
  withProps({
    placeholder: 'Is it a repeating booking ?',
    options: repeatOptions,
  })
)(SelectInput);

const EndsAfterInput = compose(
  withLabel('Ends after'),
  withProps({
    min: 1,
    unitForValue: value => pluralize('Week', value),
    allowEmpty: true,
  })
)(NumberInput);

const BookerInput = ({ value, data, loading, onChange }) => {
  const options = useMemo(() => {
    if (!data || data.loading || data.error) {
      return {};
    } else {
      return pickBy(
        {
          'Open Blocks': { open: 'Anyone' },
          'Surgeon Offices': fromPairs((data.partners || []).map(partner => [`partner-${partner.id}`, partner.name])),
          Physicians: fromPairs(
            (data.physicians || []).map(physician => [`physician-${physician.id}`, physician.name])
          ),
        },
        notF(isEmpty)
      );
    }
  }, [data]);

  return (
    <Wrapper>
      <ForInput onChange={onChange} value={value} options={options} loading={loading} />
    </Wrapper>
  );
};

const RepeatPreview = styled.div`
  margin-top: 0.4em;
  b {
    font-weight: 500;
  }
`;

const BlockInput = ({ value, onChange }) => {
  const onChangeFactory = field => val => {
    onChange({ ...value, [field]: val });
  };
  const repeats = get(value, 'repeat') > 0;
  const repeatWeeks = get(value, 'endsAfterWeeks', 1);
  const disabled = get(value, 'disabled', false);

  return (
    <Fragment>
      <BlockInfo>
        <BlockDateAndTime>{format(get(value, 'date'), 'ddd, MMM Do, YYYY')}</BlockDateAndTime>
        {repeats ? (
          <RepeatPreview>
            Repeats&nbsp;
            <b>{repeatOptions[get(value, 'repeat')]}</b>
            &nbsp;for&nbsp;
            <b>
              {repeatWeeks}&nbsp;{pluralize('Week', repeatWeeks)}
            </b>
            &nbsp;( {format(addWeeks(get(value, 'date'), repeatWeeks), 'ddd, MMM Do, YYYY')} )
          </RepeatPreview>
        ) : null}
      </BlockInfo>
      <Wrapper>
        <HalfColumn>
          <FromInput
            onChange={onChangeFactory('from')}
            value={get(value, 'from')}
            menuWidthFollowContent
            // isDisabled={disabled}
          />
          <ToInput
            onChange={onChangeFactory('to')}
            value={get(value, 'to')}
            menuWidthFollowContent
            // isDisabled={disabled}
          />
        </HalfColumn>
      </Wrapper>
      <Wrapper style={{ marginBottom: '2em' }}>
        <RepeatInput
          onChange={onChangeFactory('repeat')}
          value={get(value, 'repeat')}
          menuWidthFollowContent
          isDisabled={disabled}
        />
        {repeats && (
          <EndsAfterInput
            onChange={onChangeFactory('endsAfterWeeks')}
            value={repeatWeeks}
            menuWidthFollowContent
            disabled={disabled}
          />
        )}
      </Wrapper>
    </Fragment>
  );
};

const BookingInput = withProps({
  schema: {
    booker: compose(
      graphql(gql`
        query bookers {
          physicians {
            ...PhysicianData
          }
          partners(partnershipRole: PROCEDURE_TRANSFERS) {
            ...PartnerData
          }
        }
        ${physicianFragments.all}
        ${partnerFragments.all}
      `)
    )(BookerInput),
    block: BlockInput,
    notes: compose(
      withLabel('Notes'),
      withProps({
        placeholder: 'Add notes here...',
        rows: 6,
        style: { resize: 'none' },
      })
    )(TextAreaInput),
  },
})(ObjectInput);

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MultipleBookingRemoval = CustomModal(
  'This is a repeat booking. Do you want to delete only this event or all occurrences after it as well? ',
  false,
  'This one only',
  'This and all events after it',
  'Cancel'
);

const OneBookingRemoval = CustomModal('The booking will be removed permanently. Are you sure?');

const Footer = ({ busy, onCancel, initialValue, setScheduledProcedures, closeModal, setSecondDialogOpen }) => {
  const {
    id,
    masterBookingId,
    block: { repeat },
  } = initialValue;
  const [prompting, setPrompting] = useState(false);

  const [removeBooking, { loading: loadingRemove }] = useMutation(remove);
  const [errorMessage, setErrorMessage] = useState(null);

  const onRemove = removeRest => async () => {
    try {
      await removeBooking({ variables: { id: id, removeRest: removeRest } });
      setPrompting(false);
      onCancel();
      closeModal && closeModal();
      setSecondDialogOpen(false);
    } catch (e) {
      const BookingHasScheduledProceduresErrorMessage =
        e?.graphQLErrors?.[0].message === 'BookingHasScheduledProceduresError'
          ? 'This block already has scheduled procedures.'
          : null;
      setErrorMessage(BookingHasScheduledProceduresErrorMessage);

      // eslint-disable-next-line no-throw-literal
      throw { message: BookingHasScheduledProceduresErrorMessage };
    }
  };
  const Prompt = masterBookingId || repeat > 0 ? MultipleBookingRemoval : OneBookingRemoval;

  return (
    <Actions>
      {prompting && (
        <Prompt
          working={loadingRemove}
          handleConfirm={onRemove(false)}
          handleSecondaryConfirm={masterBookingId || repeat > 0 ? onRemove(true) : undefined}
          handleCancel={() => {
            setErrorMessage(null);
            setPrompting(false);
            setSecondDialogOpen(false);
          }}
          errorMessage={errorMessage}
        />
      )}
      <Button disabled={busy}>{id ? 'Update' : 'Create'}</Button>
      {id && (
        <LinkButton
          danger
          disabled={busy}
          onClick={() => {
            setPrompting(true);
            setSecondDialogOpen(true);
          }}
          type={'button'}
        >
          Delete
        </LinkButton>
      )}
    </Actions>
  );
};

const getId = name => value => {
  if (value && value.startsWith && value.startsWith(`${name}-`)) {
    return parseInt(value.substring(name.length + 1), 10);
  } else {
    return null;
  }
};

const getPhysicianId = getId('physician');
const getPartnerId = getId('partner');

const ContextForm = ({ date, booker, room, from, to, booking, onClose }) => {
  const isEdit = !!get(booking, 'id');

  const [secondDialogOpen, setSecondDialogOpen] = useState(false);

  const [createBooking, { loading: loadingCreate }] = useMutation(create);
  const [updateBooking, { loading: loadingUpdate }] = useMutation(update);

  const onSubmit = async value => {
    const repeat = parseInt(get(value, 'block.repeat', 0), 10);
    const physician = getPhysicianId(get(value, 'booker'));
    const organization = getPartnerId(get(value, 'booker'));

    try {
      if (isEdit) {
        await updateBooking({
          variables: {
            id: get(booking, 'id'),
            masterBookingId: get(booking, 'masterBookingId'),
            physician,
            organization,
            roomId: get(room, 'id'),
            fromMinute: parseInt(get(value, 'block.from'), 10),
            toMinute: parseInt(get(value, 'block.to'), 10),
            repeat: repeat,
            endsAfterWeeks: parseInt(get(value, 'block.endsAfterWeeks', repeat > 0 ? 1 : 0), 10),
            date: format(date, 'YYYY-MM-DD'),
            notes: get(value, 'notes'),
            speciality: get(value, 'booker.physician.speciality.value') || get(value, 'physician.speciality'),
            updateRest: false,
          },
        });
        onClose(null);
      } else {
        await createBooking({
          variables: {
            id: get(booking, 'id'),
            masterBookingId: get(booking, 'masterBookingId'),
            physician,
            organization,
            roomId: get(room, 'id'),
            fromMinute: parseInt(get(value, 'block.from'), 10),
            toMinute: parseInt(get(value, 'block.to'), 10),
            repeat: repeat,
            endsAfterWeeks: parseInt(get(value, 'block.endsAfterWeeks', repeat > 0 ? 1 : 0), 10),
            date: format(date, 'YYYY-MM-DD'),
            notes: get(value, 'notes'),
            speciality: get(value, 'booker.physician.speciality.value') || get(value, 'physician.speciality'),
          },
        });
        onClose(null);
      }
    } catch (e) {
      const BookingHasScheduledProceduresErrorMessage =
        e?.graphQLErrors?.[0].message === 'BookingHasScheduledProceduresError'
          ? 'This block already has scheduled procedures.'
          : null;
      const OverlappingBookingErrorMessage =
        e?.graphQLErrors?.[0].message === 'OverlappingBookingError' ? 'This block overlaps with other blocks. ' : null;

      // eslint-disable-next-line no-throw-literal
      throw { message: BookingHasScheduledProceduresErrorMessage || OverlappingBookingErrorMessage };
    }
  };
  const closeModal = () => {
    onClose(null);
  };

  return (
    <RouterlessModal
      title={`Book ${get(room, 'name')}`}
      onClose={closeModal}
      width={booking ? '70rem' : '45rem'}
      style={{ overflowY: secondDialogOpen ? 'unset' : 'auto' }}
    >
      <Grid container spacing={2}>
        <Grid item md>
          <Form
            autoFocus
            input={BookingInput}
            onSubmit={onSubmit}
            busy={loadingCreate || loadingUpdate}
            initialValue={{
              id: get(booking, 'id'),
              masterBookingId: get(booking, 'masterBookingId'),
              block: {
                from,
                to,
                date,
                repeat: get(booking, 'repeat'),
                endsAfterWeeks: get(booking, 'endsAfterWeeks'),
                disabled: isEdit,
              },
              booker,
              notes: get(booking, 'notes'),
            }}
            closeModal={closeModal}
            setSecondDialogOpen={setSecondDialogOpen}
            Footer={Footer}
          />
        </Grid>

        {booking && (
          <Grid item md>
            <ProceduresPreview date={date} room={room} showOverlay={false} />
          </Grid>
        )}
      </Grid>
    </RouterlessModal>
  );
};

export default ContextForm;

import React, { useContext } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import get from 'lodash/get';
import { withTheme } from '../se/theme';
import ospitek_logo from '../assets/images/ospitek_logo.png';
import { scope } from '../graph/scope';
import { useQuery } from '@apollo/client';
import responsive from '../se/utilities/responsive';
import { translateToTz } from '../util/dateTime';

const HospitalInfoRoot = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${withTheme(theme => theme.textColor.string())};
  font-size: 1.25rem;
  font-weight: ${withTheme(theme => theme.fontWeight.medium)};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0 2.5rem 0.5rem;

  ${responsive.md.andSmaller`
    font-size: .75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;
  `};
`;

const HospitalName = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  line-height: 1.25;
  font-size: 1.75rem;

  ${responsive.md.andSmaller`
    flex: 2;
    text-align: right;
  `};
`;

const HospitalDateAndTime = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
  text-align: right;
  font-size: 1.75rem;

  ${responsive.md.andSmaller`
    display: none;
  `};
`;

const LogoHolder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 6em;
`;

export const withDate = updateInterval => Component =>
  class WithDate extends React.Component {
    componentDidMount() {
      this.updateInterval = setInterval(() => this.forceUpdate(), updateInterval);
    }

    componentWillUnmount() {
      clearInterval(this.updateInterval);
    }

    render() {
      return <Component {...this.props} date={new Date()} />;
    }
  };

const WallClock = withDate(10 * 1000)(({ date, translateToHospitalZone }) => (
  <HospitalDateAndTime>{format(translateToHospitalZone(date), 'MMMM D • HH:mm')}</HospitalDateAndTime>
));

export const Scope = React.createContext();

export const ScopeProvider = ({ children }) => {
  const { data } = useQuery(scope);

  return <Scope.Provider value={get(data, 'scope')}>{children}</Scope.Provider>;
};

export const useScope = () => useContext(Scope);

export const withScope = Component => props =>
  <Scope.Consumer>{scope => <Component {...props} scope={scope} />}</Scope.Consumer>;

export const HospitalInfo = ({ scope }) => (
  <HospitalInfoRoot>
    <LogoHolder>
      <Logo src={ospitek_logo} />
    </LogoHolder>
    <HospitalName>{get(scope, 'hospital.name')}</HospitalName>
    <WallClock translateToHospitalZone={translateToTz(get(scope, 'hospital.timezone.id'))} />
  </HospitalInfoRoot>
);

export default withScope(HospitalInfo);

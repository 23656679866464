import React from 'react';
import styled, { css } from 'styled-components';
import airlineSeatFlat from '../../../../assets/images/icons/airline_seat_flat.png';
import person from '../../../../assets/images/icons/person.png';
import airlineSeatIndividualSuite from '../../../../assets/images/icons/airline_seat_individual_suite.png';
import { PatientStat } from './shared/PatientStat';
import { Box, Chip } from '@material-ui/core';
import responsiveStyles from '../../../../se/utilities/responsive';

const responsive = responsiveStyles as any;

const PatientWrapper = styled.div<any>`
  display: flex;
  flex: ${props => (props.condensView ? 1 : 'auto')};
`;

const PatientNameWrapper = styled(PatientStat)<any>`
  font-size: ${props => (props.inline ? 'inherit' : '1.16666em')};
  width: ${props => (props.condensView ? '8em' : undefined)};
  ${props =>
    props.inline
      ? css`
          padding: 0;
          background-color: transparent;
          flex: 0 1 auto;
        `
      : null}

  ${props =>
    props.condensView
      ? css`
          flex: 3;
        `
      : null}

  ${responsive.sm.andSmaller`
    > i {
      display: none;
    }
  `};
`;

const getBackgroundColor = (status: any) => '#00A7F7';

export const PatientName = ({ name, age, sex, isPatientIconVisible, condensView, inline }: any) => (
  <PatientNameWrapper condensView={condensView} inline={inline}>
    {isPatientIconVisible && (
      <img src={person} alt={'icon'} style={{ opacity: 0.5, width: '1em', marginRight: '.3333em' }} />
    )}
    {name}
    &nbsp;
    {age}
    {sex}
  </PatientNameWrapper>
);

const Label = ({ text, backgroundColor }: { text: string; backgroundColor: string }) => (
  <Box borderRadius={4} px={1} my={-1} bgcolor={backgroundColor} style={{ fontWeight: 'bold' }}>
    {text}
  </Box>
);

const Patient = ({
  name,
  age,
  sex,
  preOpTime,
  orTime,
  isPatientIconVisible,
  status,
  inOrStatus,
  bed,
  condensView,
}: any) => {
  const color = getBackgroundColor(status);

  return (
    <PatientWrapper condensView={condensView}>
      <PatientName
        condensView={condensView}
        name={name}
        age={age}
        sex={sex}
        isPatientIconVisible={isPatientIconVisible}
      />
      {!condensView && (
        <>
          <PatientStat title="Time in PRE-OP">
            {preOpTime && (
              <img
                src={airlineSeatIndividualSuite}
                alt={'icon'}
                style={{ opacity: 0.5, width: '1em', marginRight: '.3333em' }}
              />
            )}
            {preOpTime}
          </PatientStat>
          <PatientStat title="Time in OR">
            {orTime && (
              <img src={airlineSeatFlat} alt={'icon'} style={{ opacity: 0.5, width: '1em', marginRight: '.3333em' }} />
            )}
            {orTime}
          </PatientStat>
        </>
      )}

      <PatientStat left={bed && !condensView} center={!(bed && !condensView)}>
        <Label backgroundColor={color} text={inOrStatus ? inOrStatus : status} />
        {bed && !condensView && <Bed bed={bed} />}
      </PatientStat>
    </PatientWrapper>
  );
};

export const Bed = ({ bed }: any) => (
  <Chip
    size="small"
    variant="outlined"
    icon={
      <div
        className="material-icons"
        style={{
          color: 'rgba(255, 255, 255, 0.33)',
          fontSize: '1.3em',
          marginLeft: '0.4em',
        }}
      >
        hotel
      </div>
    }
    label={bed}
    style={{
      marginLeft: '0.5em',
    }}
  />
);

export default Patient;

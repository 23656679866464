import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { withTheme } from '../../se/theme';

const Content = styled.div`
  color: ${withTheme((theme, props) => theme.toaster[props.variant].textColor.string())};
  background-color: ${withTheme((theme, props) => theme.toaster[props.variant].backgroundColor.string())};
  font-size: ${withTheme(theme => theme.textSize.m)};
  line-height: ${withTheme(theme => theme.lineHeight.m)};
  padding: 1.25em;
  border-radius: ${withTheme(theme => theme.border.radius.default)};

  opacity: 0.95;

  display: flex;
  justify-content: center;
  align-items: center;

  > strong {
    font-weight: 500;
  }

  pointer-events: initial;
`;

const Bar = styled.div`
  position: fixed;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;

  pointer-events: none;
`;

const AnimatedBar = posed(Bar)({
  preEnter: {
    opacity: 0,
    transition: { type: 'spring' },
  },
  enter: {
    position: 'fixed',
    opacity: 0.95,
    y: 20,
    transition: {
      duration: 300,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    position: 'fixed',
    opacity: 0,
    y: -20,
    transition: {
      duration: 300,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const ToastBar = ({ id, children, ...props }) => (
  <PoseGroup animateOnMount>
    <AnimatedBar key={id}>
      <Content {...props}>{children}</Content>
    </AnimatedBar>
  </PoseGroup>
);

ToastBar.propTypes = {
  id: PropTypes.any.isRequired,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
};

export default ToastBar;

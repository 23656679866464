import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import responsive from '../../../../se/utilities/responsive';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { Box, CircularProgress, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { makeStyles } from '@material-ui/core/styles';
import posed, { PoseGroup } from 'react-pose';
import StarIcon from '@material-ui/icons/Star';
import { format, isAfter, subDays } from 'date-fns';
import classNames from 'clsx';
import useNow from '../../../../hooks/useNow';
import { useScope } from '../../../HospitalInfo';
import ospitek_logo from '../../../../assets/images/ospitek_logo.png';

const Page = styled.div`
  color: ${props => props.theme.textColor.string()};
  padding: 2em 3em;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${responsive.md.andSmaller`
    padding: 1em;
  `}
`;

const Label = ({ style, children, ...props }) => (
  <Box
    {...props}
    style={{
      background: 'rgb(105, 186, 99)',
      color: 'rgb(255 57 176)',
      fontSize: '1.25rem',
      letterSpacing: '.5px',
      textTransform: 'uppercase',
      borderRadius: '0.25em',
      display: 'flex',
      alignItems: 'center',
      padding: '0.25em 0.5em',
      // gap: '0.25em',
      ...style,
    }}
  >
    {children}
  </Box>
);

const Logo = styled.img`
  width: 9em;
`;

const LabelBadge = ({ isCaretaker, style }) =>
  isCaretaker ? (
    <Label style={{ background: 'rgba(106, 17, 71, 0.51)', ...style }}>Caretaker Review</Label>
  ) : (
    <Label style={{ background: 'rgba(255, 233, 45, 0.18)', color: 'rgb(255, 213, 0)', ...style }}>
      Patient Review
    </Label>
  );

const Feedback = ({ patientName, physician, submittedAt, rating, suggestion, isCaretaker }) => {
  const now = useNow();

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Box
        display="flex"
        style={{
          // gap: '1rem',
          marginBottom: '2rem',
        }}
      >
        {isAfter(submittedAt, subDays(now, 1)) && (
          <Label>
            New
            <StarIcon />
          </Label>
        )}

        <LabelBadge isCaretaker={isCaretaker} />
      </Box>
      <Box
        style={{
          fontSize: '2rem',
          marginBottom: '0.5rem',
        }}
      >
        {physician ? `Dr. ${physician.name}` : ''}
      </Box>
      <Box
        style={{
          fontSize: '1.5rem',
          opacity: 0.5,
          marginBottom: '2rem',
        }}
      >
        {format(submittedAt, 'd MMMM YYYY')}
        {physician ? ' • Patient ' + patientName : ''}
      </Box>
      <Stars
        score={rating}
        style={{
          marginBottom: '2rem',
        }}
        starStyle={{
          fontSize: '4rem',
        }}
      />
      <Box
        style={{
          fontSize: '1.5rem',
        }}
      >
        {suggestion}
      </Box>
    </Box>
  );
};

// star
// fullStar
// emptyStar

const Stars = ({
  score,
  style = {},
  starStyle = {},
  fullStarStyle = {},
  emptyStarStyle = {},
  className = {},
  classes = {},
  ...props
}) => {
  const localClasses = useStarsStyles();

  const clampedScore = Math.min(5, Math.max(0, score));

  return (
    <Box
      {...props}
      display="flex"
      style={{
        color: 'rgb(244, 174, 61)',
        ...style,
      }}
      className={className}
    >
      {[1, 2, 3, 4, 5].map(star =>
        clampedScore >= star - 0.25 ? (
          <StarIcon
            key={star}
            style={{
              color: 'rgb(244, 174, 61)',
              ...starStyle,
              ...fullStarStyle,
            }}
            className={classNames(localClasses.star, classes.star, classes.fullStar)}
          />
        ) : clampedScore >= star - 0.75 ? (
          <div className={localClasses.halfStar}>
            <div className={localClasses.halfStarFull}>
              <StarIcon
                key={star}
                style={{
                  color: 'rgb(244, 174, 61)',
                  ...starStyle,
                  ...fullStarStyle,
                }}
                className={classNames(localClasses.star, classes.star, classes.fullStar)}
              />
            </div>
            <div className={localClasses.halfStarEmpty}>
              <StarIcon
                key={star}
                style={{
                  color: 'rgb(50, 44, 50)',
                  ...starStyle,
                  ...emptyStarStyle,
                }}
                className={classNames(localClasses.star, classes.star, classes.emptyStar)}
              />
            </div>
          </div>
        ) : (
          <StarIcon
            key={star}
            style={{
              color: 'rgb(50, 44, 50)',
              ...starStyle,
              ...emptyStarStyle,
            }}
            className={classNames(localClasses.star, classes.star, classes.emptyStar)}
          />
        )
      )}
    </Box>
  );
};

const useStarsStyles = makeStyles({
  star: {
    fontSize: '4rem',
  },
  halfStar: {
    position: 'relative',
  },
  halfStarFull: {
    clipPath: 'inset(0 50% 0 0)',
  },
  halfStarEmpty: {
    clipPath: 'inset(0 0 0 50%)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const Controls = ({ duration = 10000, onPrev, onNext }) => {
  const classes = useControlsStyles();

  const [timeLeft, setTimeLeft] = useState(duration);
  const [playing, setPlaying] = useState(true);

  const progress = (1 - timeLeft / duration) * 100;

  useEffect(() => {
    if (!playing) {
      const timeout = setTimeout(() => setPlaying(true), 60 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [playing]);

  useEffect(() => {
    if (playing) {
      let prevTime = Date.now();
      let finished = false;
      function track() {
        if (finished) {
          return;
        }
        const currentTime = Date.now();
        const deltaTime = currentTime - prevTime;
        setTimeLeft(prevTimeLeft => Math.max(0, prevTimeLeft - deltaTime));
        prevTime = currentTime;
        window.requestAnimationFrame(track);
      }
      window.requestAnimationFrame(track);
      return () => (finished = true);
    }
  }, [playing]);

  useEffect(() => {
    if (playing && timeLeft === 0) {
      onNext();
    }
  }, [playing, timeLeft, onNext]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box>
        <IconButton aria-label="previous" onClick={onPrev}>
          <SkipPreviousIcon />
        </IconButton>
      </Box>
      <div className={classes.playPause}>
        <div className={classes.playPauseProgress}>
          <CircularProgress
            variant="determinate"
            value={progress}
            style={{ width: '4em', height: '4em' }}
            classes={{ circle: classes.circle }}
          />
        </div>
        <div className={classes.playPauseButton}>
          <IconButton aria-label={playing ? 'pause' : 'play'} onClick={() => setPlaying(prev => !prev)}>
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
        </div>
      </div>
      <Box>
        <IconButton aria-label="next" onClick={onNext}>
          <SkipNextIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const useControlsStyles = makeStyles({
  playPause: {
    position: 'relative',
  },
  playPauseProgress: {
    position: 'absolute',
    width: 0,
    height: 0,
    top: '-0.25rem',
    left: '-0.25rem',
  },
  playPauseButton: {},
  circle: {
    transition: 'none',
  },
});

const Summary = ({ averageRating, ratingDistribution, isCaretaker }) => {
  const classes = useSummaryStyles();

  const minimumRatings = Math.min(
    ratingDistribution.oneStar,
    ratingDistribution.twoStar,
    ratingDistribution.threeStar,
    ratingDistribution.fourStar,
    ratingDistribution.fiveStar
  );
  const maximumRatings = Math.max(
    ratingDistribution.oneStar,
    ratingDistribution.twoStar,
    ratingDistribution.threeStar,
    ratingDistribution.fourStar,
    ratingDistribution.fiveStar
  );

  const totalRatings =
    ratingDistribution.oneStar +
    ratingDistribution.twoStar +
    ratingDistribution.threeStar +
    ratingDistribution.fourStar +
    ratingDistribution.fiveStar;

  const lowerBound = Math.sqrt(minimumRatings);

  return (
    <Box display="flex" flexDirection="column">
      <LabelBadge style={{ alignSelf: 'flex-start', marginBottom: '1rem' }} isCaretaker={isCaretaker} />
      <Box
        display="flex"
        alignItems="center"
        mb={1}
        style={
          {
            /*gap: '1.5rem'*/
          }
        }
      >
        <Stars
          score={totalRatings > 0 ? averageRating : 0}
          classes={{
            star: classNames(classes.star1, classes.star2, classes.star3),
          }}
          starStyle={{
            marginBottom: '0.5rem',
          }}
        />
        <Typography className={classNames(classes.rating1, classes.rating2, classes.rating3)}>
          {totalRatings > 0 ? (Math.round(averageRating * 10) / 10).toFixed(1) : 'n/a'}
        </Typography>
      </Box>
      <Box
        style={{
          fontSize: '1.5rem',
          marginBottom: '2rem',
        }}
      >
        {totalRatings === 0 ? 'No reviews' : totalRatings === 1 ? 'One review' : `${totalRatings} reviews`}
      </Box>
      <table>
        <tbody>
          {['one', 'two', 'three', 'four', 'five']
            .map((star, i) => (
              <tr key={i + 1}>
                <td className={classes.ratingLabel}>
                  <Typography
                    style={{
                      opacity: 0.5,
                    }}
                    className={classNames(classes.ratingLine1, classes.ratingLine2, classes.ratingLine3)}
                  >
                    {i + 1}
                  </Typography>
                </td>
                <td className={classes.ratingBar}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (((ratingDistribution[star + 'Star'] || 0) - lowerBound) / (maximumRatings - lowerBound)) * 100
                    }
                    style={{
                      height: '0.5rem',
                      borderRadius: '0.25rem',
                    }}
                  />
                </td>
                <td className={classes.ratingCount}>
                  <Typography
                    style={{
                      opacity: 0.5,
                    }}
                    className={classNames(classes.ratingLine1, classes.ratingLine2, classes.ratingLine3)}
                  >
                    {ratingDistribution[star + 'Star'] || 0}
                  </Typography>
                </td>
              </tr>
            ))
            .reverse()}
        </tbody>
      </table>
    </Box>
  );
};

const useSummaryStyles = makeStyles({
  ratingLabel: {
    width: '0.1%',
    whiteSpace: 'nowrap',
  },
  ratingBar: {
    padding: '0 1rem',
    verticalAlign: 'middle',
  },
  ratingCount: {
    width: '0.1%',
    whiteSpace: 'nowrap',
  },
  star1: {
    fontSize: '4vw',
  },
  star2: {
    fontSize: 'min(max(2rem, 4vw), 4.375rem)',
  },
  star3: {
    fontSize: 'clamp(2rem, 4vw, 4.375rem)',
  },
  rating1: {
    fontSize: '3vw',
    marginLeft: '1rem',
  },
  rating2: {
    fontSize: 'min(max(2rem, 3vw), 4rem)',
  },
  rating3: {
    fontSize: 'clamp(2rem, 3vw, 4rem)',
  },
  ratingLine1: {
    fontSize: '2vw',
  },
  ratingLine2: {
    fontSize: 'min(max(1rem, 2vw), 2.25rem)',
  },
  ratingLine3: {
    fontSize: 'clamp(1rem, 2vw, 2.25rem)',
  },
});

const Slides = styled.div`
  display: flex;
  flex: 1;
  > div {
    flex: 1;
    > div {
      flex: 1;
    }
  }
`;

const Slide = posed(styled.div`
  opacity: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
`)({
  enter: {
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
      delay: 1000,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const CombinedSummary = ({ summary, caretakerSummary }) => {
  const classes = useCombinedSummaryStyles();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography align="center" className={classNames(classes.title1, classes.title3, classes.title3)}>
            Average Rating
          </Typography>
          <Typography
            align="center"
            style={{ opacity: 0.5 }}
            className={classNames(classes.subtitle1, classes.subtitle3, classes.subtitle3)}
          >
            All time
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Summary {...summary} />
      </Grid>
      <Grid item xs={6}>
        <Summary {...caretakerSummary} isCaretaker />
      </Grid>
    </Grid>
  );
};

const useCombinedSummaryStyles = makeStyles({
  title1: {
    fontSize: '3vw',
  },
  title2: {
    fontSize: 'min(max(2rem, 3vw), 4rem)',
  },
  title3: {
    fontSize: 'clamp(2rem, 3vw, 4rem)',
  },
  subtitle1: {
    fontSize: '2vw',
  },
  subtitle2: {
    fontSize: 'min(max(1.5rem, 2vw), 2.25rem)',
  },
  subtitle3: {
    fontSize: 'clamp(1.5rem, 2vw, 2.25rem)',
  },
});

const ReviewsPage = () => {
  const feedbackSummary = useQuery(gql`
    query feedbackSummary {
      feedbackSummary {
        averageRating
        ratingDistribution {
          oneStar
          twoStar
          threeStar
          fourStar
          fiveStar
        }
      }
    }
  `);

  const caretakerFeedbackSummary = useQuery(gql`
    query caretakerFeedbackSummary {
      caretakerFeedbackSummary {
        averageRating
        ratingDistribution {
          oneStar
          twoStar
          threeStar
          fourStar
          fiveStar
        }
      }
    }
  `);

  const latestFeedback = useSubscription(gql`
    subscription latestFeedback {
      latestFeedback {
        patientName
        physician {
          id
          name
        }
        submittedAt
        rating
        suggestion
        isCaretaker
      }
    }
  `);

  const [index, setIndex] = useState(0);

  useEffect(() => setIndex(0), [latestFeedback.data]);
  const scope = useScope();
  const hospitalName = scope?.hospital?.name;
  if (feedbackSummary.loading || caretakerFeedbackSummary.loading || latestFeedback.loading) {
    return null;
  }

  if (feedbackSummary.error || caretakerFeedbackSummary.error || latestFeedback.error) {
    return <pre>{(feedbackSummary.error || latestFeedback.error).toString()}</pre>;
  }

  const summary = feedbackSummary.data.feedbackSummary || {};
  const caretakerSummary = caretakerFeedbackSummary.data.caretakerFeedbackSummary || {};
  const feedback = latestFeedback.data.latestFeedback || [];

  return (
    <Page>
      <Box pt={5}>
        {hospitalName && (
          <Typography variant="h2" align="center">
            {hospitalName}
          </Typography>
        )}
      </Box>
      <Slides>
        <PoseGroup>
          <Slide key={index}>
            {index === 0 ? (
              <CombinedSummary summary={summary} caretakerSummary={caretakerSummary} />
            ) : (
              <Feedback {...feedback[(index - 1) % feedback.length]} />
            )}
          </Slide>
        </PoseGroup>
      </Slides>
      <Controls
        key={index}
        duration={10000 + 250 * ((feedback[(index - 1) % feedback.length] || {}).suggestion || '').split(' ').length}
        onPrev={() => setIndex(i => (i + feedback.length + 1 - 1) % (feedback.length + 1))}
        onNext={() => setIndex(i => (i + 1) % (feedback.length + 1))}
      />
      <Box position={'absolute'} right={50} bottom={20} mt={5}>
        <Logo src={ospitek_logo} />
      </Box>
    </Page>
  );
};

export default ReviewsPage;

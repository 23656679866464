import { gql, makeVar } from '@apollo/client';
import { int as randomInt } from '../../se/utilities/random/index.js';

export const scheduleRequestsVar = makeVar({
  110: {
    id: 110,
    patient: {
      age: null,
      cellPhone: '+381 644200399',
      dateOfBirth: null,
      emailAddress: 'antic.filip996@gmail.com',
      homePhone: null,
      id: 110,
      initials: 'F.A.',
      name: 'Filip Antic',
      ratingInviteSent: false,
      ratingInviteSentAt: null,
      sex: 'M',
    },
    date: new Date(),
    time: '12:00',
    duration: '30',
    surgicalCenter: 'Test',
    __typename: 'ScheduleRequest',
  },
});

export const scheduleRequestedDocumentsVar = makeVar({
  396: {
    id: 396,
    patientId: 110,
    text: 'sadasd',
    resolved: false,
    __typename: 'RequestedDocument',
  },
});

const resolvers = {
  Query: {
    // Context has cache in in, we can use that to access Apollo Client cache directly and use it for persistence
    scheduleRequests() {
      return Object.values(scheduleRequestsVar());
    },
    scheduleRequest(parent, args) {
      return scheduleRequestsVar()[args.id];
    },
    requestedDocuments(parent, args) {
      return Object.values(scheduleRequestedDocumentsVar()).filter(r => r.patientId === args.id);
    },
    procedureRequestsStatistics() {
      const requested = Math.round(randomInt(0, 100)());
      const scheduled = Math.round(randomInt(0, 75)());
      return {
        totalRequested: requested,
        totalScheduled: scheduled,
        missingInformation: requested - scheduled,
      };
    },
  },
  Mutation: {
    updateScheduleRequest(parent, { id, ...data }) {
      const items = scheduleRequestsVar();
      const updatedItem = { ...items[id], ...data, __typename: 'ScheduleRequest' };
      scheduleRequestsVar({ ...items, [id]: updatedItem });
      return updatedItem;
    },
    createScheduleRequest(parent, variables) {
      const items = scheduleRequestsVar();
      const newItem = { ...variables, __typename: 'ScheduleRequest' };
      scheduleRequestsVar({ ...items, [newItem.id]: newItem });
      return newItem;
    },
    removeScheduleRequest(parent, { id }) {
      const items = scheduleRequestsVar();
      delete items[id];
      scheduleRequestsVar(items);
      return true;
    },
    createRequestedDocument(parent, variables) {
      const items = scheduleRequestedDocumentsVar();
      const id = Math.round(randomInt(0, 10000)());
      const newItem = { ...variables, resolved: false, id, __typename: 'RequestedDocument' };
      scheduleRequestedDocumentsVar({ ...items, [id]: newItem });
      return newItem;
    },
    markDocumentAsResolved(parent, { id }) {
      const items = scheduleRequestedDocumentsVar();
      scheduleRequestedDocumentsVar({ ...items, [id]: { ...items[id], resolved: true } });
      return true;
    },
  },
  Subscription: {
    subscribe(parent, { id, ...data }, context) {},
  },
};

export const typeDefs = gql`
  extend type Query {
    scheduleRequests: [ScheduleRequest]!
    scheduleRequest: ScheduleRequest!
    procedureRequestsStatistics: ProcedureRequestStatistics!
  }
  extend type Mutation {
    updateScheduleRequest: ScheduleRequest!
    createScheduleRequest: ScheduleRequest!
    removeScheduleRequest: Boolean
    createRequestedDocument: RequestedDocument
    markDocumentAsResolved: Boolean
  }
  type ProcedureRequestStatistics {
    totalRequested: Int!
    totalScheduled: Int!
    missingInformation: Int!
  }
  extend type Subscription {
    subscribe: ScheduleRequest
  }
  type ScheduleRequest {
    id: Long!
    patient: Patient!
    time: String!
    date: Date
    duration: String
    surgicalCenter: String!
  }
  type RequestedDocument {
    id: Long!
    text: String!
    patientId: Long!
    resolved: Boolean
  }
`;

export default {
  resolvers: resolvers,
  typeDefs: typeDefs,
};

import { CarouselInjectedProps, WithStore } from 'pure-react-carousel';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';

interface SliderContext {
  slideTo: (index: number) => void;
}

const sliderContext = createContext<SliderContext | null>(null);

export const useSlider = (): SliderContext | null => useContext(sliderContext);

interface SliderProviderProps extends CarouselInjectedProps {
  children: ReactNode;
}

const SliderProvider = ({ carouselStore, children }: SliderProviderProps) => {
  const [slide, setSlide] = useState<number | undefined>();

  useEffect(() => {
    if (slide === undefined) {
      return;
    }

    const timeout = setTimeout(() => {
      carouselStore.setStoreState({ currentSlide: slide });
      setSlide(undefined);
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [carouselStore, slide]);

  const slideTo = useCallback((index: number) => {
    setSlide(prev => (prev === undefined ? index : Math.min(prev, index)));
  }, []);

  const slider = useMemo(() => ({ slideTo }), [slideTo]);

  return <sliderContext.Provider value={slider}>{children}</sliderContext.Provider>;
};

export default WithStore(SliderProvider as any);

import { Mutation, Query } from '@apollo/client/react/components';
import React, { useState } from 'react';
import Button from '../../../se/components/Button';
import ErrorFactory from '../../../se/components/errors/Error';
import NotificationTemplateInput from './components/NotificationTemplateInput';
import { list, update } from '../../../graph/notificationTemplates';
import { SectionTitle } from '../../pages/Configuration';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const Error = ErrorFactory();

const isDirty = (state, template) =>
  state[template.id] &&
  (state[template.id].content !== template.content ||
    state[template.id].trigger !== template.trigger ||
    state[template.id].subject !== template.subject ||
    state[template.id].additionalInfo !== template.additionalInfo);

export const Channel = {
  SMS: 'SMS',
  Email: 'Email',
};

export default ({ destinations }) => {
  const [state, setState] = useState({});

  const handleChange = value => setState({ [value.id]: value });

  const onComplete = () => console.log('completed');

  const handleSubmit = (mutation, id) => e => {
    e.preventDefault();

    mutation({
      variables: {
        id: id,
        name: state[id].name,
        content: state[id].content,
        description: state[id].description,
        trigger: state[id].trigger,
        destination: state[id].destination,
        subject: state[id].subject,
        additionalInfo: state[id].additionalInfo,
      },
      refetchQueries: [{ query: list, variables: { id } }],
    });
  };

  const sort = items => items.sort((a, b) => a.id - b.id);
  const groupNotifications = data => {
    const group = groupBy(data, 'destination');
    return destinations.map(({ title, keys }) => ({
      title,
      items: flatten(keys.map(key => sort(get(group, key, [])))),
    }));
  };

  return (
    <Query query={list}>
      {({ loading, error, data = {} }) => (
        <Grid container spacing={8}>
          <Mutation mutation={update} onComplete={onComplete}>
            {(mutation, { loading, error }) =>
              groupNotifications(get(data, 'notificationTemplates', [])).map(({ title, items }, i) =>
                items.length > 0 ? (
                  <Grid item md={6} key={i}>
                    <Box mb={4}>
                      <SectionTitle>{title}</SectionTitle>
                    </Box>
                    <Grid container spacing={4}>
                      {items.map(notificationTemplate => (
                        <Grid item xs={12} key={notificationTemplate.id}>
                          <form onSubmit={handleSubmit(mutation, notificationTemplate.id)}>
                            <NotificationTemplateInput
                              value={state[notificationTemplate.id] || notificationTemplate}
                              onChange={handleChange}
                            />
                            {isDirty(state, notificationTemplate) && <Button disabled={loading}>Save</Button>}
                            <Error
                              isVisible={error !== undefined}
                              id={`NotificationTemplates-${notificationTemplate.id}`}
                            >
                              <p>An unexpected error has occurred. Please, try again or contact our support.</p>
                            </Error>
                          </form>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : null
              )
            }
          </Mutation>
        </Grid>
      )}
    </Query>
  );
};

import React from 'react';
import styled from 'styled-components';
import { optionalText } from '../tablet/utils';

const GridBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 0.5em;
`;

const Row1 = styled.div`
  font-size: 1.5em;
  line-height: 1.4em;
`;

const Row2 = styled.div`
  font-size: 1.125rem;
  line-height: 1.1em;
`;

const PhysicianBox = styled(Row1)`
  text-align: left;
  color: white;
`;

const TextEllipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const ProcedureBox = styled(Row2)`
  color: #91c4ff;
`;

const AnesthesiologistBox = styled(Row1)`
  text-align: center;
  color: white;
`;

const AnesthesiaBox = styled(Row2)`
  text-align: center;
  color: #91c4ff;
  margin: auto 0;
`;

const TimeBox = styled(Row2)`
  text-align: right;
  opacity: 0.5;
  margin: auto 0;
`;

const PatientBox = styled(Row1)`
  text-align: right;
  color: white;
`;

const PatientInfo = ({
  patientName,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  procedureTypeName,
  enteredAt,
}: {
  patientName?: string;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  procedureTypeName?: string;
  enteredAt?: string;
}) => (
  <GridBox>
    <PhysicianBox>{optionalText(physicianName)}</PhysicianBox>
    <AnesthesiologistBox>{optionalText(anesthesiologistName)}</AnesthesiologistBox>
    <PatientBox>{optionalText(patientName)}</PatientBox>
    <ProcedureBox>
      <TextEllipsis>{optionalText(procedureTypeName)}</TextEllipsis>
    </ProcedureBox>
    <AnesthesiaBox>{`Anesthesia type: ${optionalText(anesthesiaType)}`}</AnesthesiaBox>
    <TimeBox>{optionalText(`Entered at ${enteredAt}`)}</TimeBox>
  </GridBox>
);

export default PatientInfo;

import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../ScheduleProcedureForm';
import { AutocompleteInputSetter } from '../components/AutocompleteInputSetter';
import { list as vendors } from '../../../../../../graph/vendors';
import { Autocomplete } from '@material-ui/lab';
import { FormParams, RepresentativePicked, VendorDetails } from '../types/types';
import set from 'lodash/fp/set';
import { phoneNumberFormatPipeline } from '../../../../../../se/components/inputs/PhoneInput';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { EmailInput } from '../components/EmailInput';
import { representativePickedInitial, vendorPickedInitial } from '../utils/values';
import { transformValue } from '../utils/functions';
import flow from 'lodash/fp/flow';
import InputAdornment from '@material-ui/core/InputAdornment';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const formatPrice = (value: string | null | undefined) => {
  if (!value) return value;
  if (value === '0') return '';

  return parseFloat(value).toFixed(2);
};

const VendorForm: FC<FormParams<VendorDetails>> = ({ value, setValue }) => {
  const classes = useStyles();

  const isRepresentativeDisabled = !value?.vendorInfo?.representatives;

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Vendor
      </Typography>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <AutocompleteInputSetter
              list={vendors}
              label={'Vendor Name'}
              variant="filled"
              margin="dense"
              fullWidth
              id="filled-required"
              onChange={event => {
                if (event?.constructor?.name === 'SyntheticEvent') {
                  setValue(set('vendorInfo.name.value', event?.target?.value)(value));
                } else {
                  setValue(
                    flow(
                      set('vendorInfo', transformValue(event || {}, vendorPickedInitial)),
                      set('representative', !!event ? value?.representative : undefined)
                    )(value)
                  );
                }
              }}
              clearInput={() => setValue(flow(set('vendorInfo', undefined), set('representative', undefined))(value))}
              value={value?.vendorInfo?.name?.value}
            />
            <TextField
              id="filled-required"
              label="Implant"
              variant="filled"
              margin="dense"
              fullWidth
              value={value?.implant?.value || ''}
              onChange={event => setValue(set('implant.value', event.target.value)(value))}
            />
            <TextField
              id="filled-required"
              label="Price"
              InputProps={{
                inputProps: {
                  min: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon style={{ marginRight: '-0.5em', fontSize: 20 }} />
                  </InputAdornment>
                ),
              }}
              variant="filled"
              margin="dense"
              type={'number'}
              onBlur={() => setValue(set('price.value', formatPrice(value?.price?.value))(value))}
              fullWidth
              value={value?.price?.value}
              onChange={event => setValue(set('price.value', event.target.value)(value))}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              id="vendor-rep-name"
              options={value?.vendorInfo?.representatives?.value || []}
              getOptionLabel={(option: RepresentativePicked) => option?.name || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  id="filled-required"
                  label="Vendor Representative Name"
                  onChange={event => setValue(set('representative.name.value', event.target.value)(value))}
                  variant="filled"
                  margin="dense"
                  value={value?.representative?.name?.value || ''}
                  fullWidth
                  disabled={isRepresentativeDisabled}
                />
              )}
              disabled={isRepresentativeDisabled}
              //@ts-ignore
              value={value?.representative}
              onChange={(event, representative) =>
                setValue(
                  set('representative', transformValue(representative || {}, representativePickedInitial))(value)
                )
              }
              inputValue={value?.representative?.name?.value ?? ''}
            />
            <PhoneNumberInput
              label="Vendor Representative Phone Number"
              value={value?.representative?.phoneNumber?.value || ''}
              onChange={event =>
                setValue(set('representative.phoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value))
              }
              disabled={isRepresentativeDisabled}
            />
            <EmailInput
              error={!!value?.representative?.email?.error}
              helperText={value?.representative?.email?.error}
              label="Vendor Representative Email"
              value={value?.representative?.email?.value || ''}
              onChange={event => setValue(set('representative.email.value', event.target.value)(value))}
              disabled={isRepresentativeDisabled}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VendorForm;

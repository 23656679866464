import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
  color: ${props => props.theme.textColor.string()};
  margin: auto;
  text-align: center;
  padding: 1em;
  max-width: 22em;
`;

const Image = styled.img`
  max-width: 12em;
`;

const Text = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.4;
  opacity: 0.5;
`;

const Children = styled.div``;

export default ({ image, text, children, ...props }) => (
  <Body {...props}>
    {image && <Image src={image} />}
    {text && <Text>{text}</Text>}
    {children && <Children>{children}</Children>}
  </Body>
);

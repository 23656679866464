import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { PACU, POST_OP, PRE_OP, UTILITY } from '../../../entities/room/enums';
import responsiveStyles, { breakpoint } from '../../../../se/utilities/responsive';
import { Icon } from '../Schedule';
import { withTheme } from '../../../../se/theme';
import StaffShifts from './staff/StaffShifts';
import StaffShiftMenu from './staff/StaffShiftMenu';
import { StaffShiftRoomContext } from './staff/StaffShiftContext';
import { StaffSlotRoomContext } from './staff/StaffSlotContext';
import { Room } from '../../../../types/Room';
import MultipleStaffShiftSelectionCheckbox from './staff/MultipleStaffShiftSelectionCheckbox';
import { Box } from '@material-ui/core';

const responsive = responsiveStyles as any;

const Staff = styled.div<{ expand: boolean; isFooter: boolean }>`
  display: flex;
  flex: 0 0 auto;
  padding: 0;
  background-color: #40496f;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;

  ${(props: any) =>
    props.isFooter
      ? `max-height: 70%;`
      : `
             max-height: 100%
             padding-left: 0.25em;
             padding-right: 0.25em;
             ;
          `}
  transition: all 0.3s ease;
  z-index: 3;

  ${responsive.md.andSmaller`
    flex-direction: column;
    padding: 0;
    line-height: 1.5;
    overflow-y: auto;
    ${(props: any) =>
      props.expand
        ? css`
            height: 60%;
          `
        : css`
            height: 5em;
          `};
  `}
`;

const Rooms = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

  margin-left: 0.25rem;
  margin-right: 0.25rem;

  > div {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  ${responsive.md.andSmaller`
    display: block;

    > div:first-of-type {
      border: none;
    }
  `}
`;

const RoomRow = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.2em 0.25em;
  max-width: 100%;
  margin-left: -0.5em;
  margin-right: -0.5em;

  > div:first-of-type {
    font-weight: bold;
  }

  ${responsive.md.andSmaller`
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding: .75em 1em;
  `}

  ${responsive.md.andSmaller`
    ${(props: any) =>
      props.visible
        ? null
        : css`
            display: none;
          `}
  `}
`;

const StaffMobile = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 1em;
  margin: 1.375em 0;

  > i:first-of-type {
    background-color: ${withTheme((theme: any) => theme.button.primary.backgroundColor.string())};
    border-radius: 5em;
    padding: 0.25em;
  }

  > span {
    font-size: 1.125em;
  }

  ${responsive.lg.andLarger`
    display: none;
  `}
  ${(props: any) =>
    props.visible
      ? null
      : css`
          display: none;
        `}
`;

const Curtain = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: #000c3f;
  opacity: 0.65;
  transition: opacity 0.3s ease;
  ${(props: any) =>
    props.visible
      ? null
      : css`
          display: none;
          opacity: 0;
        `}
`;

interface ScheduleFooterProps {
  isPowerUser: boolean;
  isFrontDesk: boolean;
  hospitalId: number;
  date: string;
  rooms: Array<Room>;
  staffId?: number;
  myProceduresOnly: boolean;
  editableStaff: boolean;
  isKiosk: boolean;
  isFooter: boolean;
}

const getSortedRooms = (rooms: Array<Room>, myProceduresOnly: boolean, staffId?: number): Array<Room> =>
  staffId && myProceduresOnly
    ? [
        ...rooms
          .filter(({ type }) => type === PRE_OP)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
        ...rooms
          .filter(({ type }) => type === PACU)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
        ...rooms
          .filter(({ type }) => type === POST_OP)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
        ...rooms
          .filter(({ type }) => type === UTILITY)
          .filter(room => room?.staffShifts.filter(s => s?.staff?.staffId === staffId).length > 0),
      ]
    : [
        ...rooms.filter(({ type }) => type === PRE_OP),
        ...rooms.filter(({ type }) => type === PACU),
        ...rooms.filter(({ type }) => type === POST_OP),
        ...rooms.filter(({ type }) => type === UTILITY),
      ];

const ScheduleFooter: FC<ScheduleFooterProps> = ({
  isFrontDesk,
  hospitalId,
  date,
  rooms,
  staffId,
  myProceduresOnly,
  editableStaff,
  isKiosk,
  isFooter,
}) => {
  const isClickable = window.innerWidth < breakpoint.md;
  const canAddStaff = !isKiosk && !isFrontDesk && editableStaff;
  const sortedRooms = getSortedRooms(rooms, myProceduresOnly, staffId);
  const showFooter = sortedRooms?.length > 0;

  const [expanded, setExpand] = useState(false);

  const onClickFooter = () => {
    isClickable && setExpand(!expanded);
  };

  if (!showFooter) return null;

  return (
    <>
      <Curtain onClick={onClickFooter} visible={expanded} />
      <Staff
        style={{ cursor: isClickable ? 'pointer' : 'normal', flexDirection: isFooter ? 'row' : 'column' }}
        isFooter={isFooter}
        expand={expanded}
      >
        <Rooms style={{ flexDirection: isFooter ? 'row' : 'column' }}>
          <StaffMobile visible={expanded} onClick={onClickFooter}>
            <Icon style={{ color: '#fff', fontSize: '1.5em', marginRight: '.5em' }}>people</Icon>
            <span style={{ fontWeight: 500 }}>
              {editableStaff ? 'Tap to view and edit staff schedule.' : 'Tap to close staff schedule'}
            </span>
            <Icon style={{ color: '#fff', fontSize: '1.5em', marginLeft: 'auto' }}>keyboard_arrow_down</Icon>
          </StaffMobile>
          {sortedRooms.map(room => (
            <RoomRow style={{ flexDirection: isFooter ? 'row' : 'column' }} key={room.id} visible={expanded}>
              <StaffShiftRoomContext hospitalId={hospitalId} roomId={room.id} date={date} staffShifts={[]}>
                {canAddStaff && (
                  <Box>
                    <MultipleStaffShiftSelectionCheckbox roomId={room.id} />
                  </Box>
                )}
                <Box mr={1}>{room.name}</Box>
                <StaffSlotRoomContext hospitalId={hospitalId} roomId={room.id} date={date}>
                  <Box mr={1}>{canAddStaff && <StaffShiftMenu />}</Box>
                  <StaffShifts
                    isFooter={isFooter}
                    staffShifts={room.staffShifts || []}
                    isKiosk={isKiosk}
                    editableStaff={editableStaff}
                  />
                </StaffSlotRoomContext>
              </StaffShiftRoomContext>
            </RoomRow>
          ))}
          <StaffMobile visible={!expanded} onClick={onClickFooter}>
            <Icon style={{ color: '#fff', fontSize: '1.5em', marginRight: '.5em' }}>people</Icon>
            <span style={{ fontWeight: 500 }}>
              {editableStaff ? 'Tap to view and edit staff schedule.' : 'Tap to see staff schedule'}
            </span>
            <Icon style={{ color: '#fff', fontSize: '1.5em', marginLeft: 'auto' }}>keyboard_arrow_up</Icon>
          </StaffMobile>
        </Rooms>
      </Staff>
    </>
  );
};

export default ScheduleFooter;

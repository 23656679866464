import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { mapProps } from 'recompose';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';
import Header from '../admin/Header';
import Footer from '../admin/Footer';
import { dark as darkTheme } from '../../../../theme';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import { withSession } from '../../../../state/Session';
import get from 'lodash/fp/get';
import { muiThemeDark } from '../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import ScheduledRequests from '../../../entities/scheduleRequests/ScheduledRequests';
import BlockSchedulePage from '../../blockSchedule/BlockSchedulePage';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import ScheduledProcedures from '../../../entities/procedures/ScheduledProcedures';
import { useScope } from '../../../HospitalInfo';
import ChangePassword from '../ChangePassword';
import { Event, Schedule, Today } from '@material-ui/icons';

const getRoutes = modules =>
  [
    {
      path: `/block-schedule`,
      to: `/block-schedule`,
      label: 'Block Schedule',
      icon: <Event />,
      component: BlockSchedulePage,
    },
    {
      path: `/scheduled-requests`,
      to: `/scheduled-requests`,
      label: 'Schedule requests',
      icon: <Today />,
      component: ScheduledRequests,
    },
    {
      path: '/schedule',
      to: '/schedule',
      label: 'OR Schedule Screen',
      icon: <Event />,
    },
    modules.patientRegistration
      ? {
          path: `/scheduled-patients`,
          to: `/scheduled-patients`,
          label: 'PreOp Registration',
          icon: <Schedule />,
          component: ScheduledProcedures,
        }
      : undefined,
  ].filter(_ => !!_);

const FrontSidebar = mapProps(() => {
  const scope = useScope();
  return {
    Header,
    Footer,
    links: getRoutes(scope?.hospital?.modules || {}),
  };
})(Sidebar);

const Homepage = ({ patientNameFormat }) => {
  const scope = useScope();
  return (
    <ThemeProvider theme={darkTheme}>
      <MUIThemeProvider theme={muiThemeDark}>
        <style>
          {`body {
            background: ${muiThemeDark.palette.background.default};
          }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider value={patientNameFormat}>
          <OmniSearchProvider>
            <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
            <Page Sidebar={FrontSidebar}>
              <MainContainer switchControl>
                <Switch>
                  {getRoutes(scope?.hospital?.modules || {}).map(({ path, component }) => (
                    <Route key={path} path={path} component={component} />
                  ))}
                  <Route path="/change-password" component={ChangePassword} />
                  <Route render={() => <Redirect to="/scheduled-requests" />} />
                </Switch>
              </MainContainer>
            </Page>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default withSession(get('session.account.metadata'))(Homepage);

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../se/components/Button';
import styled, { css, ThemeProvider } from 'styled-components';
import { light as lightTheme } from '../../../theme';
import LinkButton from '../../../se/components/LinkButton';
import { EditorActions, InstructionBody, TemplateEditor } from '../procedures/instructions/SurgeryInstructions';
import get from 'lodash/get';
import form, { procedureForms } from '../../../graph/surgeon/forms';
import { useMutation } from '@apollo/client';
import compact from 'lodash/compact';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import { PrintLink } from '../procedures/PrintLink';
import { sanitize } from '../../../util/sanitize';
import getObjectDeepKeys from '../procedures/utils/getObjectDeepKeys';
import { difference, omit } from 'lodash';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import Tooltip from '../../Tooltip';
import Box from '@material-ui/core/Box';
import predefinedForms from './predefinedForms';
import { useScope } from '../../HospitalInfo';
import isEqual from 'lodash/fp/isEqual';
import DatabaseHtmlForm from './DatabaseHtmlForm';

const removeNullAndUndefined = (obj: any) => {
  const isArray = Array.isArray(obj);
  for (const k of Object.keys(obj)) {
    if (obj[k] === null || obj[k] === undefined) {
      if (isArray) {
        obj.splice(k, 1);
      } else {
        delete obj[k];
      }
    } else if (typeof obj[k] === 'object') {
      removeNullAndUndefined(obj[k]);
    }
    if (isArray && obj.length === k) {
      removeNullAndUndefined(obj);
    }
  }
  return obj;
};

const EditorPreview = styled.div<any>`
  width: 100%;
  overflow: hidden;
  ${props =>
    props.working &&
    css`
      opacity: 0.7;
      pointer-events: none;
      cursor: progress;
    `}
  transition: all 0.1s ease;
`;

export const isMedicalPassport = (content: string) =>
  content === 'medicalPassport' || content === 'medicalPassport1' || content === 'medicalPassport2';

const FormContent = ({
  id,
  content,
  defaultValue: defaultValueProp,
  value: valueProp,
  realValue: realValueProp,
  procedureId,
  signature,
  entryQuestionnaire,
  lastPreOpCompletedEvent,
  withActions,
}: any) => {
  const scope = useScope();
  const hospitalName = scope?.hospital?.name;
  const hospitalId = scope?.hospital?.id;
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');
  const isAllowedToEdit = hasAccessRight('patient.edit');

  const questionnaire = get(entryQuestionnaire, 'questions');
  const questionnaireAnswers = get(entryQuestionnaire, 'answers');
  const defaultValue = useMemo(() => JSON.parse(defaultValueProp || '{}'), [defaultValueProp]);
  const value = useMemo(() => JSON.parse(valueProp || '{}'), [valueProp]);
  const realValue = useMemo(() => JSON.parse(realValueProp || '{}') || {}, [realValueProp]);
  const remoteValue = useMemo(() => merge({}, defaultValue, value), [defaultValue, value]);
  const actualValue = useMemo(() => (isMedicalPassport(content) ? realValue : value), [content, realValue, value]);
  const [localValue, setLocalValue] = useState(actualValue);

  useEffect(() => {
    setLocalValue((lv: any) => merge({}, lv, actualValue));
  }, [actualValue, questionnaireAnswers]);

  const combinedValue = useMemo(() => {
    const keys = difference(getObjectDeepKeys(remoteValue), getObjectDeepKeys(localValue));
    /* @ts-ignore */
    return omit(merge({}, remoteValue, localValue), keys);
  }, [remoteValue, localValue]);
  const isDirty = useMemo(
    () =>
      isMedicalPassport(content)
        ? !isEqual(removeNullAndUndefined(localValue), removeNullAndUndefined(actualValue))
        : !isEqual(removeNullAndUndefined(combinedValue), removeNullAndUndefined(remoteValue)),
    [content, localValue, actualValue, combinedValue, remoteValue]
  );

  const contentRef = useRef();
  const [updateFormMutation] = useMutation(form.update);
  const [working, setWorking] = useState(false);

  const updateForm = async () => {
    setWorking(true);

    try {
      if (isMedicalPassport(content)) {
        await updateFormMutation({
          variables: { id, procedureId, value: JSON.stringify(combinedValue) },
          refetchQueries: [{ query: procedureForms, variables: { procedureId } }],
        });
      } else {
        const curr = get(contentRef, 'current.node');
        const inputs = curr.contentDocument.querySelectorAll('textarea, input');
        const keys = compact([...inputs].map(input => (!!input.name ? input.name : input.id)));
        await updateFormMutation({
          variables: { id, procedureId, value: JSON.stringify(pick(combinedValue, keys)) },
          refetchQueries: [{ query: procedureForms, variables: { procedureId } }],
        });
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setWorking(false);
    }
  };

  const promptUpdateForm = () => {
    if (signature) {
      if (
        window.confirm(
          'Patient signed this form already, changing it will require them to sign again! Are you sure you want to save changes?'
        )
      ) {
        return updateForm();
      }
    } else {
      return updateForm();
    }
  };

  const cancelEditing = () => {
    setLocalValue(actualValue);
  };

  const printForm = () => {
    const curr = get(contentRef, 'current.node');
    if (curr) {
      curr.contentWindow.focus();
      curr.contentWindow.print();
    }
  };

  /* @ts-ignore */
  const Form = predefinedForms[content] || DatabaseHtmlForm;

  const handleSetValue = useCallback(arg => {
    if (isFunction(arg)) {
      /* @ts-ignore */
      setLocalValue(prev => sanitize(arg(prev)) || {});
    } else {
      setLocalValue(sanitize(arg) || {});
    }
  }, []);

  return (
    <InstructionBody>
      <TemplateEditor>
        <ThemeProvider theme={lightTheme}>
          {withActions && (
            <EditorActions>
              {/* @ts-ignore */}
              <Tooltip
                content={isAllowedToEdit ? null : 'You don’t have sufficient permissions to edit this document.'}
              >
                <Box>
                  {/* @ts-ignore */}
                  <Button onClick={promptUpdateForm} disabled={!isDirty || working || !isAllowedToEdit}>
                    Save Changes
                  </Button>
                  {isDirty && (
                    <>
                      {/* @ts-ignore */}
                      <LinkButton warning onClick={cancelEditing} style={{ marginLeft: '0.5em' }} disabled={working}>
                        Cancel
                      </LinkButton>
                    </>
                  )}
                </Box>
              </Tooltip>
              {/* @ts-ignore */}
              <Tooltip
                content={isAllowedToView ? null : 'You don’t have sufficient permissions to view this document.'}
              >
                <Box>
                  {/* @ts-ignore */}
                  <PrintLink onPrint={printForm} title="Print Form" disabled={!isAllowedToView} />
                </Box>
              </Tooltip>
            </EditorActions>
          )}
          {/* @ts-ignore */}
          <EditorPreview working={working}>
            <Form
              procedureId={procedureId}
              hospitalId={hospitalId}
              hospitalName={hospitalName}
              ref={contentRef}
              content={content}
              defaultValue={remoteValue}
              value={isMedicalPassport(content) ? localValue : combinedValue}
              setValue={handleSetValue}
              signature={signature}
              questionnaire={questionnaire}
              questionnaireAnswers={questionnaireAnswers}
              lastPreOpCompletedEvent={lastPreOpCompletedEvent}
            />
          </EditorPreview>
        </ThemeProvider>
      </TemplateEditor>
    </InstructionBody>
  );
};

export default FormContent;

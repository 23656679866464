export const InstructionTemplates = {
  HandAndFootSurgeryInstructions: 'HandAndFootSurgeryInstructions',
  GynecologySurgeryInstructions: 'GynecologySurgeryInstructions',
  ArthroscopicSurgeryInstructions: 'ArthroscopicSurgeryInstructions',
  PainManagementSurgeryInstructions: 'PainManagementSurgeryInstructions',
  PediatricSurgeryInstructions: 'PediatricSurgeryInstructions',
  GeneralSurgeryInstructions: 'GeneralSurgeryInstructions',
  CovidTestSurgeryInstructions: 'CovidTestSurgeryInstructions',
};

export const instructionTemplateLabels = {
  [InstructionTemplates.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
  [InstructionTemplates.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
  [InstructionTemplates.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
  [InstructionTemplates.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
  [InstructionTemplates.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
  [InstructionTemplates.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
  [InstructionTemplates.CovidTestSurgeryInstructions]: 'Covid Test Appointment Instructions',
};

export const InstructionTemplatesView2Hospital15 = {
  PreOpAbdominoplastySurgeryInstructions: 'PreOpAbdominoplastySurgeryInstructions',
  PostOpAbdominoplastySurgeryInstructions: 'PostOpAbdominoplastySurgeryInstructions',
  BlepharoplastySurgeryInstructions: 'BlepharoplastySurgeryInstructions',
  BreastAugmentationSurgeryInstructions: 'BreastAugmentationSurgeryInstructions',
  BreastLiftSurgeryInstructions: 'BreastLiftSurgeryInstructions',
  BreastReductionSurgeryInstructions: 'BreastReductionSurgeryInstructions',
  CalfImplantSurgeryInstructions: 'CalfImplantSurgeryInstructions',
  ChinOrJawAugmentationSurgeryInstructions: 'ChinOrJawAugmentationSurgeryInstructions',
  FaceLiftSurgeryInstructions: 'FaceLiftSurgeryInstructions',
  FacialContourSurgeryInstructions: 'FacialContourSurgeryInstructions',
  PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions: 'PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions',
  PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions: 'PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions',
};

export const instructionTemplateLabelsView2Hospital15 = {
  [InstructionTemplatesView2Hospital15.PreOpAbdominoplastySurgeryInstructions]:
    'Tummy Tuck (Abdominoplasty) Pre-op Instructions',
  [InstructionTemplatesView2Hospital15.PostOpAbdominoplastySurgeryInstructions]:
    'Tummy Tuck (Abdominoplasty) Post-op Instructions',
  [InstructionTemplatesView2Hospital15.BlepharoplastySurgeryInstructions]:
    'Eyelid Surgery (Blepharoplasty) Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.BreastAugmentationSurgeryInstructions]:
    'Breast Augmentation Post-op Instructions',
  [InstructionTemplatesView2Hospital15.BreastLiftSurgeryInstructions]: 'Breast Lift (Mastopexy) Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.BreastReductionSurgeryInstructions]: 'Breast Reduction Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.CalfImplantSurgeryInstructions]: 'Calf Implant Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.ChinOrJawAugmentationSurgeryInstructions]:
    'Chin or Jaw Augmentation Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.FaceLiftSurgeryInstructions]: 'Face & Neck Lift Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.FacialContourSurgeryInstructions]: 'Facial Contour Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions]:
    'Facial Laser Resurfacing (CO2 laser) Pre-Op Instructions',
  [InstructionTemplatesView2Hospital15.PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions]:
    'Facial Laser Resurfacing (CO2 laser) Post-Op Instructions',
};

export const formSaveTypeLabels = {
  Filled: 'Patient Forms',
  Uploaded: 'Uploaded Photos',
};

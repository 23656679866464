import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, FormGroup, FormLabel, Radio } from '@material-ui/core';
import { SelectAnswer } from '../../types/Answer';
import { SelectQuestion } from '../../types/Question';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { useSlideIn } from '../Form';

type RadioGroupInputProps = SelectQuestion['props'] & {
  name?: string;
  answer?: SelectAnswer;
  onChange?: (newAnswer: SelectAnswer) => void;
};

const RadioGroupInput = ({ optional, label, options, answer, onChange }: RadioGroupInputProps) => {
  const [invalid, setInvalid] = useState(false);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, slideIn]);

  return (
    <Box my={3}>
      <FormControl component="fieldset" error={invalid}>
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <FormGroup>
          <Box display="flex" flexDirection="row">
            {options.map(option => {
              const { name, label } = option;
              return (
                <FormControlLabel
                  key={name}
                  control={
                    <Radio
                      color="primary"
                      checked={answer?.name === option.name}
                      onChange={() => {
                        onChange?.(option);
                        setInvalid(false);
                      }}
                      name={name}
                    />
                  }
                  label={label}
                />
              );
            })}
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default RadioGroupInput;

import React from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireValue } from '../hooks';
import toString from '../../../../../questionnaire/toString';

export const fieldStyle = {
  formField: {
    display: 'flex',
    padding: '0 .25em',
    alignItems: 'center',
  },
  formFieldLabel: {
    flex: '0 0 auto',
  },
  formFieldValue: {
    flex: '1 1 auto',
  },
  inputField: {
    border: 0,
    outline: 0,
    background: 'lightgrey',
    width: 'calc(100% - 1.5rem)',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  inputFieldOdd: {
    background: 'transparent',
  },
  todo: {
    background: 'darksalmon',
  },
};

const UnnamedInput = () => <input type="text" style={{ ...fieldStyle.inputField, ...fieldStyle.todo }} />;

const NamedInput = ({ name, path, paths = [], reducer, warning, fontWeight, fontSize, underline }) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValue(reducer || toString, ...[path, ...paths].filter(path => path));

  return (
    <input
      type="text"
      name={name}
      style={{
        ...fieldStyle.inputField,
        color: warning ? 'red' : 'inherit',
        fontWeight: fontWeight,
        fontSize: fontSize,
        borderBottom: underline ? '1px solid black' : 0,
      }}
      value={value || questionnaireValue || defaultValue || ''}
      onChange={e => setValue(e.target.value || ' ')} // had to write ' '(blank charter) so we could delete value of input
    />
  );
};

const MedicalPassportField = ({
  label,
  name,
  path,
  paths,
  reducer,
  warning = false,
  fontWeight = undefined,
  fontSize = undefined,
  visibility,
  underline,
}) => (
  <div
    style={{
      ...fieldStyle.formField,
      visibility,
    }}
  >
    {label && <div style={fieldStyle.formFieldLabel}>{label}:</div>}
    <div style={fieldStyle.formFieldValue}>
      {name ? (
        <NamedInput
          name={name}
          path={path}
          paths={paths}
          reducer={reducer}
          warning={warning}
          fontWeight={fontWeight}
          fontSize={fontSize}
          underline={underline}
        />
      ) : (
        <UnnamedInput />
      )}
    </div>
  </div>
);

export default MedicalPassportField;

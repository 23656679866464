import { workingHours } from './dummyData';
import { DateTimeFormatter } from '@js-joda/core';
import { toLocalTime } from '../../../pages/kiosk/schedule/staff/StaffMember';

/**
 * Time is sting in format: hh:mm e.g. "8:30"
 */
export type Time = string | undefined | null;

/**
 * Hour - if we have time like this 1:30 then hour is 1 + 0.5 = 1.5
 */
export type Hour = number | undefined | null;

/**
 * if we have time like this 1:30 then hour is 1 + 0.5 = 1.5
 */
export const getHour = (top?: number, height?: number): number | null => {
  if (!height) return null;
  const percent = ((top || 0) / height) * 100;
  const hour = ((workingHours.end - workingHours.start) * percent) / 100 + 6;
  const minutes = Math.round((hour - Math.floor(hour)) * 60);
  const quarter = minutes < 10 ? 0 : minutes < 20 ? 1 : minutes < 40 ? 2 : minutes < 50 ? 3 : 4;
  return Math.floor(hour) + (quarter * 15) / 60;
};

/**
 * Gets duration in minutes. hourBegin, hourEnd - number, e. g. if time is 1:30 then hour is 1 + 0.5 = 1.5
 */
export const getDurationFrom = (hourBegin: Hour, hourEnd: Hour): number | null => {
  if (!hourBegin || !hourEnd) {
    return null;
  }

  return (hourEnd - hourBegin) * 60;
};

/**
 * Gets duration in minutes. timeBegin, timeEnd - string such as “06:00”
 */
export const getDurationFromTime = (timeBegin: Time, timeEnd: Time): number | null => {
  if (!timeBegin || !timeEnd) {
    return null;
  }

  return getDurationFrom(timeToHour(timeBegin), timeToHour(timeEnd));
};

/**
 * Gets timeEnd - string such as “06:00”
 */
export const getEndTime = (timeBegin: Time, duration?: number | null): string | null => {
  if (!timeBegin || !duration) {
    return null;
  }

  return timeBegin && duration
    ? toLocalTime(timeBegin)?.plusMinutes(duration).format(DateTimeFormatter.ISO_LOCAL_TIME) || null
    : null;
};

/**
 * Converts number representing hours to string such as “06:00”.
 */
export const hourToTime = (hour: Hour): Time => {
  if (!hour) {
    return null;
  }

  const hours = Math.floor(hour);
  const minutes = Math.floor((hour - hours) * 60);

  const minutesString = '0' + minutes;

  return `${hours}:${minutesString.substr(minutesString.length - 2)}`;
};

/**
 * Converts string such as “06:00” to number representing hours.
 */
export const timeToHour = (time: Time): Hour => {
  if (!time) {
    return null;
  }

  const match = time.match(/(\d+):(\d\d)/);

  if (!match) {
    return null;
  }

  const hours = parseInt(match[1], 10);
  const minutes = parseInt(match[2], 10);

  return hours + minutes / 60;
};

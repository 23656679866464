import 'core-js';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { unregister } from './serviceWorker';
import Entrypoint from './components/Entrypoint';

if (process.env.NODE_ENV === 'production') {
  const environments = {
    'https://testing.ospitek.com': ['Testing', 1.0],
    'https://view1.ospitek.com': ['View1', 0.1],
    'https://view.ospitek.com': ['View', 0.1],
  };

  const [environment, tracesSampleRate] = environments[process.env.PUBLIC_URL] || [];

  if (environment) {
    Sentry.init({
      dsn: 'https://21a8677175f341aba65c1c6c4a26f128@o1065753.ingest.sentry.io/6057895',
      environment,
      tracesSampleRate,
      integrations: [new Integrations.BrowserTracing()],
    });
  }
}

if (process.env.NODE_ENV === 'development') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

if (document.getElementById('root')) {
  ReactDOM.render(<Entrypoint />, document.getElementById('root'));

  window.formatDuration = require('./util/duration').formatDuration;

  // Unregister service worker
  unregister();

  // Make sure application is refreshed on tablet screens.
  if (window.navigator.standalone === true) {
    const reloadApp = () => {
      if (document.visibilityState === 'hidden') {
        if (
          window.location.pathname.startsWith('/kiosk/') &&
          !window.location.pathname.match(/^\/kiosk\/\d+\/charting\/\d+$/)
        ) {
          ReactDOM.unmountComponentAtNode(document.getElementById('root'));
        }
      } else {
        ReactDOM.render(<Entrypoint />, document.getElementById('root'));
      }
    };
    document.addEventListener('visibilitychange', reloadApp);
  }
}

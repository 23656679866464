import React from 'react';
import { withRouter } from 'react-router';
import { TruncatedText } from '../../../se/components/typography';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import RouterLink from '../../../se/components/RouterLink';
import { sortNumber, sortString } from '../../../util/sort';
import { Check } from '@material-ui/icons';

export const nameColumn = {
  title: 'Name',
  lens: item => item.name,
  sortFunction: (l, r) => sortString(l.name, r.name),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const emailColumn = {
  title: 'Email',
  lens: item => item.email,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const hstIdColumn = {
  title: 'Provider Id',
  lens: item => item.hstId,
  sortFunction: (l, r) => sortNumber(l.hstId, r.hstId),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const smsNotificationColumn = {
  title: 'SMS Notification Numbers',
  lens: data =>
    data.notificationNumbers !== null && data.notificationNumbers !== undefined
      ? formatPhoneNumber(processPhoneNumbersString(data.notificationNumbers).nationalNumbers)
      : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const scheduleAccessColumn = {
  title: 'Schedule Access',
  lens: data => data,
  Component: withRouter(props => (
    <RouterLink onClick={e => e.stopPropagation()} to={`${props.match.url}/schedule-access/${props.data.id}`}>
      {props.data.hasScheduleAccess ? <Check fontSize={'small'} style={{ marginRight: '.25em' }} /> : null}
      <TruncatedText>{props.data.hasScheduleAccess ? 'Yes' : 'Manage'}</TruncatedText>
    </RouterLink>
  )),
};

export const sendNotificationColumn = {
  title: 'Notify',
  lens: item => item.shouldSendNotification,
  Component: props => <TruncatedText>{props.data ? 'Yes' : 'No'}</TruncatedText>,
};

export const specialityColumn = {
  title: 'Speciality',
  lens: item => item.speciality,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const categoryColumn = {
  title: 'Category',
  lens: item => item.category,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const baseColumns = [
  nameColumn,
  hstIdColumn,
  smsNotificationColumn,
  scheduleAccessColumn,
  specialityColumn,
  sendNotificationColumn,
  categoryColumn,
];

export const listColumns = [...baseColumns];

export const viewColumns = [
  specialityColumn,
  hstIdColumn,
  sendNotificationColumn,
  smsNotificationColumn,
  emailColumn,
  categoryColumn,
];

import LinkButton from '../../../se/components/LinkButton';
import React from 'react';
import isString from 'lodash/isString';
import Tooltip from '../../Tooltip';

export const PrintLink = ({ onPrint, title, style, disabled }) => (
  <Tooltip content={isString(disabled) ? disabled : null}>
    <LinkButton onClick={onPrint} style={{ marginRight: '1em', ...style }} disabled={disabled}>
      <i className="material-icons" style={{ fontSize: '1.2em', marginRight: '.25em' }}>
        picture_as_pdf
      </i>
      <span>{title}</span>
    </LinkButton>
  </Tooltip>
);

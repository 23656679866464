import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import NoteAdd from '@material-ui/icons/NoteAdd';
import useClickAway from '../../../../../hooks/useClickAway';
import { useStaffSlotContext } from './StaffSlotContext';
import { useStyles } from './StaffShiftTime';

interface StaffMemberProps {
  assignedShiftId?: number;
  additionalText?: string;
  editText: boolean;
  setEditText?: Dispatch<SetStateAction<boolean>>;
  hideText?: boolean;
  editableStaff?: boolean;
  inputRef: any;
}

const StaffShiftText: FC<StaffMemberProps> = ({
  inputRef,
  hideText,
  editText,
  setEditText,
  assignedShiftId,
  additionalText,
  editableStaff,
}) => {
  const classes = useStyles();
  const defaultValue = additionalText ? additionalText : '';

  const onTextClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    editableStaff && setEditText?.(true);
  };

  const onTimeTextClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    editableStaff && setEditText?.(true);
  };

  const onClickAway = () => {
    setEditText?.(false);
  };

  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, onClickAway);

  const showTextIcon = !hideText && !editText && assignedShiftId && !additionalText;
  const showText = !editText && additionalText;

  const staffSlotContext = useStaffSlotContext();

  const saveAdditionalText = async (additionalText: string | undefined) => {
    assignedShiftId && (await staffSlotContext.setAdditionalText(assignedShiftId, additionalText));
  };

  const onInputChange = async (additionalText: string) => {
    if (additionalText) {
      await saveAdditionalText(additionalText);
      setEditText?.(false);
    }
  };

  return (
    <>
      {showTextIcon && (
        <IconButton size="small" className={classes.noteIcon} onClick={onTextClick}>
          <NoteAdd fontSize="small" />
        </IconButton>
      )}

      {editText && (
        <div ref={wrapperRef}>
          <input
            defaultValue={defaultValue}
            ref={inputRef}
            maxLength={15}
            onClick={e => e.stopPropagation()}
            onKeyPress={async (e: React.KeyboardEvent<HTMLInputElement>) => {
              const key = e.key;
              if (key === 'Enter') {
                //@ts-ignore
                await onInputChange(e.target?.value);
              }
            }}
            autoFocus={true}
            className={classes.noteInput}
          />
        </div>
      )}

      {showText && (
        <Typography className={classes.noteText} variant="caption" onClick={onTimeTextClick}>
          {additionalText}
        </Typography>
      )}
    </>
  );
};

export default StaffShiftText;

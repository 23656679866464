import { ApolloProvider } from '@apollo/client';
import React, { Children, Fragment } from 'react';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';

import { createApolloClient } from '../../../essentials/apolloClient';
import { OfflineIndicator } from '../../core/OfflineIndicator';
import delay from 'lodash/delay';

class ConnectionProvider extends React.PureComponent {
  state = { apolloClient: undefined, subscriptionClient: undefined, lastDisconnectedAt: null };

  constructor(props) {
    super(props);
    this.timer = null;
  }

  UNSAFE_componentWillMount() {
    const { accessToken, organizationId, onUnauthorized } = this.props;
    this.createApolloClientFactory(accessToken, organizationId, onUnauthorized);
    this._mounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { accessToken, organizationId, onUnauthorized } = this.props;

    if (prevProps.accessToken !== accessToken || prevProps.organizationId !== organizationId) {
      this.cleanupApollo(prevState);
      this.createApolloClientFactory(accessToken, organizationId, onUnauthorized);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this.cleanupApollo(this.state);
  }

  cleanupApollo = ({ apolloClient, subscriptionClient }) => {
    if (apolloClient) {
      apolloClient.stop();
    }

    if (subscriptionClient) {
      subscriptionClient.close();
    }
  };

  createApolloClientFactory = (accessToken, organizationId, onUnauthorized) => {
    const [apolloClient, subscriptionClient] = createApolloClient(
      accessToken,
      parseInt(organizationId, 10),
      onUnauthorized,
      this.onConnected,
      this.onDisconnected
    );

    this.setState({ apolloClient, subscriptionClient });
  };

  setLastDisconnected = value => {
    if (this._mounted) {
      this.setState({ lastDisconnectedAt: value });
    }
    this.timer = null;
  };

  scheduleSwitch = (after, value) => {
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
    this.timer = delay(this.setLastDisconnected, after, value);
  };

  onConnected = () => {
    this.scheduleSwitch(100, null);
  };

  onDisconnected = () => {
    if (this.state.lastDisconnectedAt == null) {
      this.scheduleSwitch(10000, new Date());
    }
  };

  render(props) {
    const { apolloClient: client, lastDisconnectedAt } = this.state;
    return client ? (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Fragment>
            {lastDisconnectedAt && <OfflineIndicator changedAt={lastDisconnectedAt} />}
            {Children.only(this.props.children)}
          </Fragment>
        </ApolloHooksProvider>
      </ApolloProvider>
    ) : null;
  }
}

ConnectionProvider.displayName = 'ConnectionProvider';

export default ConnectionProvider;

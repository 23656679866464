import { Box, Button, createStyles, Dialog, makeStyles, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Chat from './Chat';
import { gql, useMutation } from '@apollo/client';
import React, { FC, useState } from 'react';
import { Patient } from '../../types/Patient';
import ThreadInfo from '../../types/ThreadInfo';

export interface ChatDialogProps {
  thread?: ThreadInfo;
  createThread: () => Promise<string>;
  markAsRead: () => Promise<void>;
  dialogTitle: string;
  dialogSubtitle: string;
  open: boolean;
  handleClose: () => void;
}

export const ChatDialog = ({
  thread,
  createThread,
  markAsRead,
  dialogTitle,
  dialogSubtitle,
  open,
  handleClose,
}: ChatDialogProps) => {
  const classes = useStyles();

  const [markingAsRead, setMarkingAsRead] = useState(false);

  const markAsReadAndClose = async () => {
    try {
      setMarkingAsRead(true);

      await markAsRead();

      handleClose?.();
    } finally {
      setMarkingAsRead(false);
    }
  };

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={true}
        classes={{
          root: classes.dialogRoot,
          container: classes.dialogContainer,
          paper: classes.dialogPaper,
        }}
      >
        <div className={classes.dialogHeader}>
          <div>
            <Typography className={classes.dialogTitle} variant="h4">
              {dialogTitle}
            </Typography>
            <Typography className={classes.dialogSubtitle}>{dialogSubtitle}</Typography>
          </div>
          {handleClose && (
            <div>
              {thread?.hasUnreadMessages && (
                <Button aria-label="close" onClick={markAsReadAndClose} disabled={markingAsRead}>
                  {markingAsRead ? 'Marking as Read…' : 'Mark as Read and Close'}
                </Button>
              )}
              <IconButton aria-label="close" onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          )}
        </div>
        <Chat threadId={thread?.id} createThread={createThread} />
      </Dialog>
    </Box>
  );
};

function useMarkAsRead(threadId?: string) {
  const [markMessagesAsRead] = useMutation(gql`
    mutation markMessagesAsRead($threadId: String!) {
      markMessagesAsRead(threadId: $threadId)
    }
  `);

  return async () => {
    if (!threadId) {
      return;
    }

    await markMessagesAsRead({ variables: { threadId } });
  };
}

interface CaretakerChatDialogProps {
  patient: Patient;
  open: boolean;
  handleClose: () => void;
}

export const CaretakerChatDialog: FC<CaretakerChatDialogProps> = ({ patient, open, handleClose }) => {
  const [createCaretakerThread] = useMutation(gql`
    mutation createCaretakerThread($patientId: Long!) {
      createCaretakerThread(patientId: $patientId)
    }
  `);

  const createThread = async () => {
    const result = await createCaretakerThread({ variables: { patientId: patient.id } });

    return result.data.createCaretakerThread;
  };

  const markAsRead = useMarkAsRead(patient?.caretakerThread?.id);

  return (
    <>
      <ChatDialog
        thread={patient?.caretakerThread}
        markAsRead={markAsRead}
        createThread={createThread}
        dialogTitle={`Send Message to Caretaker`}
        dialogSubtitle={`Patient ${patient?.name} • Caretaker Phone Number ${patient?.notificationNumbers}`}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

interface PatientChatDialogProps {
  patient: Patient;
  open: boolean;
  handleClose: () => void;
}

export const PatientChatDialog: FC<PatientChatDialogProps> = ({ patient, open, handleClose }) => {
  const [createPatientThread] = useMutation(gql`
    mutation createPatientThread($patientId: Long!) {
      createPatientThread(patientId: $patientId)
    }
  `);

  const createThread = async () => {
    const result = await createPatientThread({ variables: { patientId: patient?.id } });

    return result.data.createPatientThread;
  };

  const markAsRead = useMarkAsRead(patient?.patientThread?.id);

  return (
    <ChatDialog
      thread={patient?.patientThread}
      markAsRead={markAsRead}
      createThread={createThread}
      dialogTitle={`Send Message to Patient`}
      dialogSubtitle={`Patient ${patient?.name} • Patient Phone Number ${patient?.phoneNumber}`}
      open={open}
      handleClose={handleClose}
    />
  );
};

interface PreOpPatientChatDialogProps {
  patient: Patient;
  open: boolean;
  handleClose: () => void;
}

export const PreOpPatientChatDialog: FC<PreOpPatientChatDialogProps> = ({ patient, open, handleClose }) => {
  const [createPatientThreadForProcedure] = useMutation(gql`
    mutation createPatientThreadForProcedure($procedureId: Long!) {
      createPatientThreadForProcedure(procedureId: $procedureId)
    }
  `);

  const createThread = async () => {
    const result = await createPatientThreadForProcedure({ variables: { procedureId: patient?.procedureId } });

    return result.data.createPatientThreadForProcedure;
  };

  const markAsRead = useMarkAsRead(patient?.patientThread?.id);

  return (
    <ChatDialog
      thread={patient?.patientThread}
      markAsRead={markAsRead}
      createThread={createThread}
      dialogTitle={`Send Message to Patient`}
      dialogSubtitle={`Patient ${patient?.name} • Patient Phone Number ${patient?.phoneNumber}`}
      open={open}
      handleClose={handleClose}
    />
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    dialogHeader: {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dialogTitle: {
      marginLeft: '1rem',
    },
    dialogSubtitle: {
      marginLeft: '1rem',
      opacity: '0.5',
    },
    dialogRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dialogContainer: {
      flex: 1,
      width: '50%',
      maxWidth: '50em',
    },
    dialogPaper: {},
  })
);

import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { a11yProps, TabPanel } from './TabPanel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SurgeonFormPage from '../surgeonProcedures/PreOpForm';
import { ProcedureEvent } from '../../../types/ProcedureEvent';
import { CheckCircleTwoTone } from '@material-ui/icons';

interface Form {
  id: number;
  name: string;
  isSigned: boolean;
}

interface FormsProps {
  procedureId: number;
  forms: Array<Form>;
  lastPreOpCompletedEvent: ProcedureEvent;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Forms = ({ procedureId, forms, lastPreOpCompletedEvent }: FormsProps) => {
  const classes = useStyles();

  const [value, setValue] = useState<number>(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Patient Tabs"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="on"
      >
        {forms.map(({ id, name, isSigned }: Form, i: number) => (
          <Tab
            key={id}
            label={
              <Box display="flex" alignItems="center" lineHeight={1.25}>
                {name}
                {isSigned && (
                  <Box ml={1}>
                    <Chip size="small" color="primary" icon={<CheckCircleTwoTone />} label="Signed" />
                  </Box>
                )}
              </Box>
            }
            {...a11yProps(i)}
          />
        ))}
      </Tabs>
      {forms.map((form: Form, i: number) => (
        <TabPanel key={i} index={i} value={value}>
          <SurgeonFormPage id={form.id} procedureId={procedureId} lastPreOpCompletedEvent={lastPreOpCompletedEvent} />
        </TabPanel>
      ))}
    </Box>
  );
};

export default Forms;

import React, { useState } from 'react';
import { Label } from './common';
import styled, { css, keyframes } from 'styled-components';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { eventTime } from './utils';
import { PROCEDURE_STATUSES } from '../../../entities/patient/enums';
import HelperAction from './HelperAction';
import responsive from '../../../../se/utilities/responsive';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, fade, Link } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NotificationButton from '../NotificationButton';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../graph/notificationTemplates';
import { PatientTypeIconSwitch } from '../../../inputs/PatientTypeButtonGroup';
import { Textsms } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import { CaretakerChatDialog } from '../../../chat/ChatDialog';
import makeStyles from '@material-ui/core/styles/makeStyles';

const pulse = keyframes`
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-5px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(5px, 0, 0);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  > div + div {
    margin-left: 1rem;
  }

  ${responsive.sm.andSmaller`
    flex-flow: column;

    > div + div {
      margin-left: 0;
    }

    ${props =>
      props.hiddenSm
        ? css`
            display: none;
          `
        : null}
  `};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding: 2rem 2.5rem 2rem;
  position: relative;

  ${Row} + ${Row} {
    margin-top: 1.5rem;
  }

  ${responsive.sm.andSmaller`
    padding: 1rem 1rem 0;
    min-height: initial;

    ${Row} + ${Row} {
      margin-top: 0;
    }
  `};
`;

const Value = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;

  ${responsive.sm.andSmaller`
    font-size: 1rem;
  `};
`;

const ProcedureValue = styled(Value)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const Data = styled.div`
  text-align: ${props => (props.right ? 'right' : 'left')};

  ${responsive.sm.andSmaller`
    text-align: left;
    margin-bottom: .75rem;

    ${props =>
      props.hiddenSm
        ? css`
            display: none;
          `
        : null}
  `};
`;

export const NotifyButton = styled.button`
  color: ${props => props.theme.button.primary.backgroundColor.string()};
  font-size: 1rem;
  padding: 0;
  background: transparent;
  border: none;
  opacity: ${props => (props.disabled ? '0.35' : '1')};
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  animation: ${props =>
    props.disabled
      ? 'none'
      : css`
          ${pulse}, ${pulse}, ${pulse}, ${pulse}, ${pulse}
        `};
  animation-delay: 5s, 10s, 15s, 20s, 25s;
  animation-duration: 1s;

  i {
    margin-right: 0.5rem;
  }

  ${responsive.sm.andSmaller`
    display: none;
  `};
`;

const LabelWithAction = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#fff', 0.05),
    color: fade('#fff', 1),
    marginTop: 10,
  },
  icon: {
    color: fade('#fff', 1),
  },
  label: {
    lineHeight: 1.2,
  },
}));

const CurrentStatusHeader = ({ physician, patient, patientType, procedureType, action }) => {
  const [working, setWorking] = useState(false);
  const notifyPhysician = async () => {
    setWorking(true);
    await action.notificationAction.action();
    setWorking(false);
  };
  const classes = useStyles();
  const disableReason = !patient.caretakerConsent
    ? 'No consent'
    : !patient.notificationNumbers
    ? 'No phone number'
    : undefined;

  const [open, setOpen] = useState(false);
  const [openMessaging, setMessagingOpen] = useState(false);
  const { data: patientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'PatientReady' },
  });
  const patientReadyNotificationData = patientReadyNotification?.getNotificationTemplate[0] || null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Header>
      <Row>
        <Data>
          <LabelWithAction>
            <Label>Physician</Label>
            {action.notificationAction &&
              (!eventTime(PROCEDURE_STATUSES.READY, patient) ? (
                <NotificationButton
                  physician={physician}
                  action={action}
                  notifyPhysician={notifyPhysician}
                  working={working}
                  notificationEnabled={patientReadyNotificationData?.enabled}
                />
              ) : (
                <Label style={{ marginLeft: '.5rem' }}>(Notified)</Label>
              ))}
            <Box ml={2}>
              <Button
                startIcon={<AssignmentIcon />}
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClickOpen}
              >
                Preference Card
              </Button>
            </Box>
          </LabelWithAction>
          <Box display="flex">
            <Value>{physician.name}</Value>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Preference Card</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please contact Ospitek via email{' '}
                  <Link href="mailto:sales@ospitek.com" color="textPrimary">
                    sales@ospitek.com
                  </Link>{' '}
                  or phone{' '}
                  <Typography display="inline" color="textPrimary">
                    1-833-OSPITEK
                  </Typography>{' '}
                  to learn more about the preference card.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Data>
        <Data right>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <Label>Patient</Label>
              <Value>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Value>{patient?.name}</Value>
                  <Box position={'relative'} top={5}>
                    <PatientTypeIconSwitch patientType={patientType} />
                  </Box>
                </div>
              </Value>
            </div>
            <Button
              onClick={() => setMessagingOpen(true)}
              disabled={!!disableReason}
              style={{
                backgroundColor: 'transparent',
                opacity: !!disableReason ? 0.5 : undefined,
              }}
              startIcon={
                <Chip
                  size="small"
                  icon={<Textsms />}
                  label={!!disableReason ? disableReason : 'Send Message'}
                  classes={{
                    root: classes.root,
                    icon: classes.icon,
                    label: classes.label,
                  }}
                />
              }
            />
            <div hidden={true}>
              <CaretakerChatDialog
                patient={patient}
                open={openMessaging}
                handleClose={() => setMessagingOpen(false)}
                withButton={false}
              />
            </div>
          </div>
        </Data>
      </Row>
      <Row hiddenSm style={{ minHeight: 0 }}>
        <Data style={{ display: 'flex', flexDirection: 'column' }}>
          <Label>Procedure</Label>
          <ProcedureValue>{getNestedValue('name', procedureType) || '-'}</ProcedureValue>
        </Data>
        <Data right hiddenSm>
          <Label>Duration</Label>
          <Value>{getNestedValue('duration', patient) || '-'}</Value>
        </Data>
      </Row>
      <HelperAction />
    </Header>
  );
};

export default CurrentStatusHeader;

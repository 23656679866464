import React, { useState, useMemo, Fragment } from 'react';
import styled from 'styled-components';
import BookSlotModal from './booking/BookSlotModal';
import { list } from '../../../graph/bookings';
import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import rooms from '../../../graph/rooms';
import { ROOM_TYPES } from '../../entities/room/enums';
import {
  format,
  addMonths,
  subMonths,
  getDay,
  subDays,
  addDays,
  getMonth,
  getYear,
  getDaysInMonth,
  isWeekend,
} from 'date-fns';
import chunk from 'lodash/chunk';
import Week from './Week';
import { Subscription } from '@apollo/client/react/components';
import { withQuery } from '@apollo/client/react/hoc';

const Schedule = styled.div`
  font-size: 0.75em;
`;

export const padWeeks = days => {
  const differenceBefore = Math.max(getDay(first(days)) - 1);
  const differenceAfter = Math.max(5 - getDay(last(days)));
  const padLeft = [...new Array(differenceBefore)].map((_, i) => subDays(first(days), differenceBefore - i));
  const padRight = [...new Array(differenceAfter)].map((_, i) => addDays(last(days), i + 1));
  return [...padLeft, ...days, ...padRight];
};

export const thisMonthWorkdays = date => {
  const month = getMonth(date);
  const year = getYear(date);
  return [...new Array(getDaysInMonth(date))].map((_, i) => new Date(year, month, i + 1)).filter(_ => !isWeekend(_));
};

const BlockScheduleCalendar = ({ data, modal, setModal, weeks, rooms, date, physician, colorMode }) => {
  const bookings = groupBy(get(data, 'bookings', []), ({ roomId, date }) => `${roomId}_${date}`);
  return (
    <Schedule>
      {modal && <BookSlotModal {...(modal || {})} onClose={setModal} />}
      {weeks.map((dates, i) => (
        <Week
          key={i}
          colorMode={colorMode}
          rooms={rooms}
          dates={dates}
          bookings={bookings}
          dateSelection={date}
          onCellClick={val => setModal(val)}
          showDayNames={i === 0}
          physicianSelection={physician}
          firstWeek={i === 0}
        />
      ))}
    </Schedule>
  );
};

const BlockSchedule = ({ data, date, physician, colorMode }) => {
  const [modalData, setModalData] = useState(null);

  const rooms = get(data, 'rooms', []).filter(_ => _.type === ROOM_TYPES.OR);

  const weeks = useMemo(() => chunk(padWeeks(thisMonthWorkdays(date)), 5), [date]);

  return (
    <Fragment>
      <Subscription
        subscription={list}
        variables={{
          from: format(subMonths(first(first(weeks)), 1), 'YYYY-MM-DD'),
          to: format(addMonths(last(last(weeks)), 1), 'YYYY-MM-DD'),
        }}
      >
        {({ data }) => (
          <Fragment>
            <BlockScheduleCalendar
              colorMode={colorMode}
              weeks={weeks}
              rooms={rooms}
              data={data}
              modal={modalData}
              setModal={setModalData}
              date={date}
              physician={physician}
            />
          </Fragment>
        )}
      </Subscription>
    </Fragment>
  );
};

export default withQuery(rooms.list)(BlockSchedule);

import React from 'react';
import { format } from 'date-fns';
import {
  generateDetailedPdf,
  generateMedicationPdf,
  generateMedicationPdf3,
  generateMedicationPdf68,
  generateMedicationPdfHospital17,
  generateMedicationPdfHospital_31_1_2,
} from './pdf/PdfGenerator';
import { withScope } from '../../HospitalInfo';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import QuestionnaireInvite from './questionnaire/QuestionnaireInvite';
import { PrintLink } from './PrintLink';
import QuestionnaireFulfilment from './questionnaire/QuestionnaireFulfilment';
import {
  generateFullPdf,
  generateFullPdf1,
  generateFullPdfHospital17,
  generateFullPdfHospital2,
  generateFullPdfHospital3,
} from './pdf/FullPdfGenerator';
import QuestionnaireActions from './questionnaire/QuestionnaireActions';
import QuestionnaireAnswersPreview from './questionnaire/QuestionnaireAnswersPreview';
import parseJson from './utils/parseJson';
import { generateSummaryPdf } from './pdf/SummaryPdfGenerator';
import { Label, QuestionnaireWrapper, Wrapper } from './questionnaire/Common';
import { generateFullPdfMedicalPassport } from './pdf/MedicalPassportPdf';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import { ProcedureStatus } from '../../../types/ProcedureEvent';
import { getLastProcedureEvent } from '../../../util/procedureEvents';

const QuestionnaireAnswers = ({ onPrint, ...rest }) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  return (
    <QuestionnaireAnswersPreview {...rest}>
      <PrintLink
        onPrint={onPrint(generateDetailedPdf)}
        title="Detailed Questionnaire"
        disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
      />
      <PrintLink
        onPrint={onPrint(generateSummaryPdf)}
        title="Summary Questionnaire"
        disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
      />
    </QuestionnaireAnswersPreview>
  );
};

const getFullPdfGenerator = scope =>
  get(scope, 'hospital.id') === 3
    ? generateFullPdfHospital3
    : (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 4) ||
      (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 17)
    ? generateFullPdfHospital17
    : (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 59) ||
      (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 31) ||
      (window.location.hostname === 'view1.ospitek.com' && get(scope, 'hospital.id') === 10) ||
      (window.location.hostname === 'localhost' && get(scope, 'hospital.id') === 59) ||
      (window.location.hostname === 'localhost' && get(scope, 'hospital.id') === 7)
    ? generateFullPdfMedicalPassport
    : (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 2) ||
      (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 67) ||
      (window.location.hostname === 'localhost' && get(scope, 'hospital.id') === 1)
    ? generateFullPdfHospital2
    : (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 1) ||
      (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 68)
    ? generateFullPdf1
    : generateFullPdf;

const getMedPdfGenerator = scope =>
  (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 4) ||
  (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 17)
    ? generateMedicationPdfHospital17
    : (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 59) ||
      (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 2) ||
      (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 31)
    ? generateMedicationPdfHospital_31_1_2
    : (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 68) ||
      (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 1)
    ? generateMedicationPdf68
    : (window.location.hostname === 'testing.ospitek.com' && get(scope, 'hospital.id') === 65) ||
      (window.location.hostname === 'view.ospitek.com' && get(scope, 'hospital.id') === 3)
    ? generateMedicationPdf3
    : generateMedicationPdf;

const getPrintFn = (entryQuestionnaire, procedure, scope) => fn => e => {
  e.preventDefault();
  e.stopPropagation();

  const event = getLastProcedureEvent(procedure?.events || [], ProcedureStatus.PreOpCompleted);

  fn({
    questions: parseJson(entryQuestionnaire.questions),
    answers: parseJson(entryQuestionnaire.answers),
    procedure,
    hospitalName: get(scope, 'hospital.name'),
    preOpBy: get(event, 'user.name'),
    preOpAt: get(event, 'timestamp') ? format(get(event, 'timestamp'), 'MM/DD/YYYY HH:mm') : undefined,
    scheduleProvider: get(scope, 'hospital.scheduleProvider'),
    notes: sortBy(
      get(procedure, 'events', []).filter(_ => _.type === 'Note'),
      'timestamp'
    ),
  });
};

export const PrintFormButtons = withScope(({ entryQuestionnaire, procedure, scope }) => {
  const onPrint = getPrintFn(entryQuestionnaire, procedure, scope);
  const fullPdfGenerator = getFullPdfGenerator(scope);
  const medicationPdf = getMedPdfGenerator(scope);

  return (
    <>
      <PrintLink onPrint={onPrint(fullPdfGenerator)} title="Full Form" disabled={false} />
      <PrintLink onPrint={onPrint(medicationPdf)} title="Medication Rec. Form" disabled={false} />
    </>
  );
});

export default withScope(({ entryQuestionnaire, procedure, scope }) => {
  const onPrint = getPrintFn(entryQuestionnaire, procedure, scope);

  const fullPdfGenerator = getFullPdfGenerator(scope);
  const medicationPdf = getMedPdfGenerator(scope);

  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  return (
    <Wrapper>
      <div>
        <QuestionnaireWrapper>
          <QuestionnaireFulfilment formProgress={get(procedure, 'entryQuestionnaire.progress')} />
          <QuestionnaireInvite procedure={procedure} />
        </QuestionnaireWrapper>
        <QuestionnaireActions entryQuestionnaire={entryQuestionnaire}>
          <PrintLink
            onPrint={onPrint(fullPdfGenerator)}
            title="Full Form"
            disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
          />
          <PrintLink
            onPrint={onPrint(medicationPdf)}
            title="Medication Rec. Form"
            disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
          />
        </QuestionnaireActions>
        {!entryQuestionnaire && <Label>Entry form is not filled yet</Label>}
        {entryQuestionnaire && <QuestionnaireAnswers {...entryQuestionnaire} onPrint={onPrint} />}
      </div>
    </Wrapper>
  );
});

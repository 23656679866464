import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { sendNewScheduleNotification } from '../../../../../graph/staff';
import { format } from 'date-fns';
import StaffMember from '../../../../../types/StaffMember';
import { useScope } from '../../../../HospitalInfo';
import { Destination } from '../../../../../types/NotificationTemplate';
import SendNotificationDialog, { Contact } from '../SendNotificationDialog';

type StaffMemberContact = {
  staffId: number;
  staffHospitalId: number;
  phoneNumber: string;
};

const SendNotification = ({
  open,
  handleClose,
  date,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  date: Date;
  data: any;
}) => {
  const recipients = useMemo(
    () => (data?.listAssignedStaffMembers || []).map((e: StaffMember) => ({ ...e, id: e.staffId })),
    [data]
  );

  const [doSend] = useMutation(sendNewScheduleNotification);

  const scope = useScope();

  const sendNotifications = async (recipients: Contact[], message: String) => {
    const staffContacts: StaffMemberContact[] = recipients.map(e => ({
      staffId: e.id,
      staffHospitalId: scope?.hospital?.id,
      phoneNumber: e.phoneNumber,
    }));

    await doSend({
      variables: {
        staffContacts,
        message,
        date: format(date, 'YYYY-MM-DD'),
      },
    });
  };

  return (
    <SendNotificationDialog
      date={date}
      open={open}
      recipients={recipients}
      sendNotifications={sendNotifications}
      destination={Destination.StaffMember}
      handleClose={handleClose}
    />
  );
};

export default SendNotification;

import get from 'lodash/get';
import { format, isSameDay, parse } from 'date-fns';
import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { openPdf } from '../../entities/procedures/pdf/shared';
import { isDefinedNotNullAndFinite } from '../../../se/utilities/check';
import { roundFixed } from './SensorList';
import { compact, head } from 'lodash/array';
import find from 'lodash/find';
import { hourOptions } from './SensorGlobalConfig';
import isFinite from 'lodash/isFinite';
import { createPdf } from '../../../vendor/pdfMake';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const colorForValue = (value, min, max) => {
  if (!value || value === '-') {
    return 'black';
  } else if ((value < min && isFinite(min)) || (value > max && isFinite(max))) {
    return 'red';
  } else {
    return 'black';
  }
};

const formatTemperature = temp =>
  isDefinedNotNullAndFinite(get(temp, 'value')) ? `${roundFixed(temp.value)}${temp.unit}` : '-';

const formatHumidity = (hum, min, max) => (isDefinedNotNullAndFinite(hum) ? `${roundFixed(hum)}%` : '-');

const tempAndHum = (temp, hum, sensor) => {
  const tmin = get(sensor, 'temperatureMin');
  const tmax = get(sensor, 'temperatureMax');
  const hmin = get(sensor, 'humidityMin');
  const hmax = get(sensor, 'humidityMax');

  return {
    text: [
      {
        text: formatTemperature(temp),
        color: colorForValue(get(temp, 'value'), get(tmin, 'value'), get(tmax, 'value')),
      },
      ' | ',
      { text: formatHumidity(hum), color: colorForValue(hum, hmin, hmax) },
    ],
  };
};

const unpackMeasurement = (optMes, sensor) => tempAndHum(get(optMes, 'temperature'), get(optMes, 'humidity'), sensor);

const makeMeasurements = (columns, measurements = [], sensor) =>
  columns.map(col => ({
    text: unpackMeasurement(
      find(measurements, _ => _.hour === col),
      sensor
    ),
    alignment: 'center',
  }));

const formatMeasurementHeader = hour => ({ text: hourOptions[hour], alignment: 'center' });

const extremesHeader = show =>
  show
    ? [
        { alignment: 'center', text: 'Min' },
        { alignment: 'center', text: 'Max' },
      ]
    : [];

const makeExtremesRow = show => (row, sensor) =>
  show
    ? [
        { text: tempAndHum(row.minTemperature, row.minHumidity, sensor), alignment: 'center' },
        { text: tempAndHum(row.maxTemperature, row.maxHumidity, sensor), alignment: 'center' },
      ]
    : [];

const issueEntryFor =
  date =>
  ({ issue }) => {
    if (issue && issue.isResolved) {
      const { id, action, createdAt } = issue;
      const sameDay = isSameDay(parse(date), createdAt);
      return `#${id} (${format(createdAt, sameDay ? 'HH:mm' : 'MM-DD/HH:mm')}) ${action}`;
    }
  };

const makeReport = (report = [], sensor, hideExtremes, allAlerts) => {
  const measurementColumns = get(head(report), 'measurements', []).map(_ => _.hour);
  const alertsForRow = row => {
    if (allAlerts) {
      return compact(
        get(row, 'issues', [])
          .map(issue => ({ issue }))
          .map(issueEntryFor(row.date))
      ).join('; ');
    } else {
      return compact(get(row, 'measurements', []).map(issueEntryFor(row.date))).join('; ');
    }
  };
  const table = [
    measurementColumns.length <= 13
      ? {
          layout: {
            hLineWidth: i => (i === 1 ? 1 : 0.6),
            vLineWidth: (i, node) => ([1, 3, node.table.widths.length - 1].includes(i) ? 0.8 : 0.6),
          },
          table: {
            headerRows: 1,
            widths: [
              'auto',
              ...extremesHeader(!hideExtremes).map(_ => 'auto'),
              ...measurementColumns.map(_ => 'auto'),
              '*',
            ],
            body: [
              [
                { alignment: 'center', text: 'Date' },
                ...extremesHeader(!hideExtremes),
                ...measurementColumns.map(formatMeasurementHeader),
                { alignment: 'center', text: 'Action/Comments' },
              ],
              ...report.map(row => [
                { text: row.date, alignment: 'center' },
                ...makeExtremesRow(!hideExtremes)(row, sensor),
                ...makeMeasurements(measurementColumns, get(row, 'measurements'), sensor),
                alertsForRow(row),
              ]),
            ],
          },
        }
      : [
          {
            layout: {
              hLineWidth: i => (i === 1 ? 1 : 0.6),
              vLineWidth: (i, node) => ([1, 3, node.table.widths.length - 1].includes(i) ? 0.8 : 0.6),
            },
            table: {
              headerRows: 1,
              widths: [
                'auto',
                ...extremesHeader(!hideExtremes).map(_ => 'auto'),
                ...measurementColumns.slice(0, Math.ceil(measurementColumns.length / 2)).map(_ => 'auto'),
                '*',
              ],
              body: [
                [
                  { alignment: 'center', text: 'Date' },
                  ...extremesHeader(!hideExtremes),
                  ...measurementColumns.slice(0, Math.ceil(measurementColumns.length / 2)).map(formatMeasurementHeader),
                  { alignment: 'center', text: 'Action/Comments' },
                ],
                ...report.map(row => [
                  { text: row.date, alignment: 'center' },
                  ...makeExtremesRow(!hideExtremes)(row, sensor),
                  ...makeMeasurements(
                    measurementColumns.slice(0, Math.ceil(measurementColumns.length / 2)),
                    get(row, 'measurements'),
                    sensor
                  ),
                  alertsForRow(row),
                ]),
              ],
            },
            margin: [0, 0, 0, 20],
          },
          {
            layout: {
              hLineWidth: i => (i === 1 ? 1 : 0.6),
              vLineWidth: (i, node) => ([1, 3, node.table.widths.length - 1].includes(i) ? 0.8 : 0.6),
            },
            table: {
              headerRows: 1,
              widths: [
                'auto',
                ...extremesHeader(!hideExtremes).map(_ => 'auto'),
                ...measurementColumns
                  .slice(Math.ceil(measurementColumns.length / 2), measurementColumns.length)
                  .map(_ => 'auto'),
                '*',
              ],
              body: [
                [
                  { alignment: 'center', text: 'Date' },
                  ...extremesHeader(!hideExtremes),
                  ...measurementColumns
                    .slice(Math.ceil(measurementColumns.length / 2), measurementColumns.length)
                    .map(formatMeasurementHeader),
                  { alignment: 'center', text: 'Action/Comments' },
                ],
                ...report.map(row => [
                  { text: row.date, alignment: 'center' },
                  ...makeExtremesRow(!hideExtremes)(row, sensor),
                  ...makeMeasurements(
                    measurementColumns.slice(Math.ceil(measurementColumns.length / 2), measurementColumns.length),
                    get(row, 'measurements'),
                    sensor
                  ),
                  alertsForRow(row),
                ]),
              ],
            },
          },
        ],
  ];
  return table;
};

const minMax = (min, max) => `From ${roundFixed(min)} to ${roundFixed(max)}`;

const makeBody = (reports = [], hospitalName, hideExtremes, allAlerts) =>
  reports.map((report, i) => ({
    stack: [
      {
        columns: [
          {
            stack: [
              {
                text: [`ID: ${report.sensor.id}`, hideExtremes ? '\n' : ' / ', `Location: ${report.sensor.location}`],
                alignment: 'left',
              },
              !hideExtremes
                ? {
                    layout: 'noBorders',
                    headerRows: 0,
                    fontSize: 9,
                    table: {
                      body: [
                        [
                          `Temperature Range:`,
                          minMax(
                            get(report, 'sensor.temperatureMin.value'),
                            get(report, 'sensor.temperatureMax.value')
                          ),
                          get(report, 'sensor.temperatureMin.unit', ''),
                        ],
                        [
                          'Humidity Range:',
                          minMax(get(report, 'sensor.humidityMin'), get(report, 'sensor.humidityMax')),
                          get(report, 'sensor.humidityMin') || get(report, 'sensor.humidityMax') ? '%' : '',
                        ],
                      ],
                    },
                  }
                : undefined,
            ],
          },
          {
            stack: [
              { text: hospitalName, opacity: 0.8, fontSize: 14, alignment: 'right', width: '*' },
              { text: 'Temperature And Humidity Report', opacity: 0.8, fontSize: 11, alignment: 'right' },
            ],
          },
        ],
        marginBottom: 20,
      },
      makeReport(report.report, report.sensor, hideExtremes, allAlerts),
    ],
    pageBreak: reports.length - 1 > i ? 'after' : undefined,
  }));

export const generateSensorDetailedAlertReport = ({ sensors, hospitalName }) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Sensor Audit Report` },
    content: [makeBody(sensors, hospitalName, true, true)],
  };

  openPdf(createPdf(docDefinition), `Sensor_Audit_Report_${format(new Date(), 'MM_DD_YYYY_HH_mm_ss')}`);
};

export const generateSensorReportFull = ({ sensors, hospitalName }) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Sensor Full Report` },
    content: [makeBody(sensors, hospitalName)],
  };

  openPdf(createPdf(docDefinition), `Sensor_Full_Report_${format(new Date(), 'MM_DD_YYYY_HH_mm_ss')}`);
};

export const generateSensorReportAudit = ({ sensors, hospitalName }) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Detailed Sensor Report` },
    content: [makeBody(sensors, hospitalName, true)],
  };

  openPdf(createPdf(docDefinition), `Detailed_Sensor_Report_${format(new Date(), 'MM_DD_YYYY_HH_mm_ss')}`);
};

import React from 'react';
import { branch, compose, renderComponent, withState } from 'recompose';
import EntityView from '../../../../se/components/entity/EntityView';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { withRouter } from 'react-router';
import withDebugKey from '../../../../hocs/withDebugKey';
import { withSession } from '../../../../state/Session';
import get from 'lodash/get';
import { NamedRange } from '../../../core/DatePicker';
import { convertHoursAndMinutesForSlider, getRoomEntry, procedureAccuracy } from '../../../../util/procedureAccuracy';
import Box from '@material-ui/core/Box';
import FileUploader from '../../../FileUploader';
import { withScope } from '../../../HospitalInfo';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, Typography } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import forms from '../../../../graph/surgeon/forms';
import { FormType } from '../../../../types/Form';
import PatientChartForm from '../../../entities/surgeonProcedures/PatientChartForm';
import { STATUSES } from '../../../entities/patient/enums';
import { unpackSessionObject } from '../../unpackSessionObject';
import { getRoom } from '../../../entities/common/transducers';
import { TrackingPanel } from '../../../entities/patient/views/dataFields';
import ChangeLocation from '../../../entities/patient/views/ChangeLocationForm';
import { PrintFormButtons } from '../../../entities/procedures/QuestionnaireAnswers';
import { ChartingButton } from './ChartingPatients';

export const withOptionalDebug = Component =>
  compose(
    withSession(unpackSessionObject),
    branch(props => props.isSuperAdmin || props.isMonitoringEngineer, renderComponent(withDebugKey(Component)))
  )(Component);

const PatientViewContent = props => {
  const { scope } = props;
  const timeZone = get(scope, 'hospital.timezone.id');
  const { id, dischargedAt, voidedAt, room, braceletId, status } = props.data || {};

  const theme = useTheme();
  const orRoomTimes = get(props.data, 'orRoomTimes', []);
  const startTime = get(props.data, 'procedure.startTime', false);
  const duration = get(props.data, 'procedure.duration', false);
  const [enteredAt, exitedAt] = orRoomTimes.length > 0 ? getRoomEntry(orRoomTimes) : [0, 0];
  const calculate = startTime && duration && enteredAt && exitedAt;
  const procedureSchedulingAccuracy = calculate
    ? procedureAccuracy(startTime, duration, enteredAt, exitedAt, timeZone)
    : false;
  const [scheduledRange, actualRange, sliderRange] = calculate
    ? convertHoursAndMinutesForSlider(startTime, duration, enteredAt, exitedAt, timeZone)
    : [
        [0, 0],
        [0, 0],
        [6, 18],
      ];
  const isActivePatient = !(dischargedAt || voidedAt);

  const procedure = props?.data?.procedure;
  const procedureId = procedure?.id;

  const { data: forsData } = useQuery(forms.list);
  const patientChartForm = (forsData?.forms || [])?.find(e => e.type === FormType.PatientChartForm);

  return (
    <>
      <EntityView {...props} />

      <Box mt={2}>
        <TrackingPanel
          isActive={isActivePatient}
          isDischarged={!!dischargedAt}
          isCanceled={!!dischargedAt && status === STATUSES.CANCELED}
          patientId={id}
          room={getRoom(room)}
          braceletId={braceletId}
          setEditModalOpen={props.setEditModalOpen}
          procedureSchedulingAccuracy={procedureSchedulingAccuracy}
          scheduledRange={scheduledRange}
          actualRange={actualRange}
          sliderRange={sliderRange}
        />
      </Box>

      {!!procedureId && <FileUploader procedureId={procedureId} />}

      {!!procedureId && !!procedure?.entryQuestionnaire && (
        <Box mt={2}>
          <PrintFormButtons procedure={procedure} entryQuestionnaire={procedure?.entryQuestionnaire} />
        </Box>
      )}

      {patientChartForm?.id && procedureId && (
        <Box mt={2}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography style={{ fontSize: theme.typography.h3.fontSize }}>PreOp Chart Form</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PatientChartForm
                id={patientChartForm?.id}
                procedureId={procedureId}
                lastPreOpCompletedEvent={null}
                withActions={true}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {props.editModalOpen.room && (
        <ChangeLocation
          patientId={props.data.id}
          initialValue={getNestedValue('id', room)}
          setEditModalOpen={props.setEditModalOpen}
        />
      )}
    </>
  );
};

export const PatientChartButton = withRouter(props => {
  const patient = props?.data;
  const handleClick = e => {
    e.stopPropagation();
    props.history.push(props.location.pathname.replace(`/${patient?.id}`, `/patient-chart/${patient?.id}`));
  };
  return <ChartingButton patient={patient} handleClick={handleClick} />;
});

export default compose(
  withScope,
  withRouter,
  withState('filter', 'setFilter', {
    dateRange: NamedRange.lastSevenDays(),
  }),
  withState('signalLogShown', 'setSignalLogShown', false),
  withState('editModalOpen', 'setEditModalOpen', {
    room: false,
    braceletId: false,
  }),
  withOptionalDebug
)(PatientViewContent);

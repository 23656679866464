import { Mutation, Query } from '@apollo/client/react/components';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import gatewaysScheme, { updateLocation } from '../../../graph/gateways';
import { Text, TruncatedText } from '../../../se/components/typography';
import { addSeconds, distanceInWordsStrict } from 'date-fns';
import TitleAction from '../../../se/components/TitleAction';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import graphqlSelectInput from '../../inputs/graphqlSelectInput';
import { list as braceletList } from '../../../graph/bracelets';
import ThresholdInput from './ThresholdInput';
import RoomSelectUpdateInput from './RoomSelectUpdateInput';
import { getNestedValue } from '../../../se/utilities/data/object';
import ConfigurationEdit from './ConfigurationEdit';
import CollectionList from '../../../se/components/collection/CollectionList';
import LinkButton from '../../../se/components/LinkButton';
import DelayedTextInput from './DelayedTextInput';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import GatewayMobileListItem from './GatewayMobileListItem';
import PortSwitchInput from './PortSwitchInput';
import Box from '@material-ui/core/Box';
import { sortDate, sortString } from '../../../util/sort';

const getBraceletSignal = bracelet => (bracelet ? bracelet.signalStrength : '-');
const getBraceletAverage = bracelet => (bracelet ? bracelet.signalAverage : '-');

export const distanceAgo = (now, time) => distanceInWordsStrict(time, now, { includeSeconds: true });

export const distanceFromSeconds = seconds => distanceAgo(new Date(0), addSeconds(new Date(0), seconds));

const getBraceletSync = (bracelet, serverTime) =>
  bracelet && bracelet.updatedAt ? `${distanceAgo(serverTime, bracelet.updatedAt)} ago` : '-';

const colorForStrength = (strength, threshold) => {
  if (!strength || strength === '-') {
    return undefined;
  } else if (strength > threshold) {
    return 'lime';
  } else {
    return 'red';
  }
};

const BraceletSelectInput = graphqlSelectInput({
  entityName: 'Bracelet',
  placeholder: 'Bracelet',
  graphqlList: braceletList,
});

const RemoveGatewayButton = ({ id, bracelet }) => (
  <Mutation
    mutation={gatewaysScheme.remove}
    update={proxy => {
      try {
        const { gateways } = proxy.readQuery({ query: gatewaysScheme.list, variables: { braceletId: bracelet } }) || {};
        proxy.writeQuery({
          query: gatewaysScheme.list,
          variables: { braceletId: bracelet },
          data: { gateways: gateways.filter(_ => _.id !== id) },
        });
      } catch (e) {
        console.warn(e);
      }
    }}
  >
    {(mutate, { loading }) => (
      <LinkButton danger onClick={() => mutate({ variables: { id } })} disabled={!!loading}>
        Remove
      </LinkButton>
    )}
  </Mutation>
);

const SignalStrength = ({ signalAverage, signalStrength, threshold }) => (
  <Fragment>
    <Text style={{ color: colorForStrength(signalStrength, threshold) }}>{signalStrength}</Text>
    <Text>{` / `}</Text>
    <Text style={{ color: colorForStrength(signalAverage, threshold) }}>{signalAverage}</Text>
  </Fragment>
);

export const getColumns = isSuperAdmin =>
  columns.filter(column =>
    isSuperAdmin ? column.superAdmin || column.bothAdminSuperAdmin : !column.superAdmin || column.bothAdminSuperAdmin
  );

// This should be cleaned...
const columns = [
  {
    title: 'ID',
    lens: data => data.id,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
    bothAdminSuperAdmin: true,
  },
  {
    title: 'GW Last Seen',
    lens: data => data,
    sortFunction: (l, r) => sortDate(l.lastSeenAt, r.lastSeenAt),
    Component: ({ data }) => (
      <TruncatedText style={{ width: '9em' }}>
        {data ? `${distanceAgo(data.serverTime, data.lastSeenAt)} ago` : 'Never'}
      </TruncatedText>
    ),
    bothAdminSuperAdmin: true,
  },
  {
    title: 'Room',
    lens: (data, context) => ({
      hospitalId: data?.hospital?.id,
      gateway: data.id,
      type: data.type,
      room: data.room && data.room.id,
      gateways: getNestedValue('data.gateways', context) || [],
    }),
    Component: ({ data }) => (
      <RoomSelectUpdateInput
        disabled={true}
        hospitalId={data.hospitalId}
        room={data.room}
        gateway={data.gateway}
        type={data.type}
        gateways={data.gateways}
      />
    ),
    superAdmin: true,
  },
  {
    title: 'Room',
    lens: (data, context) => ({
      hospitalId: data?.hospital?.id,
      gateway: data.id,
      type: data.type,
      room: data.room && data.room.id,
      gateways: getNestedValue('data.gateways', context) || [],
    }),
    Component: ({ data }) => (
      <RoomSelectUpdateInput
        hospitalId={data.hospitalId}
        room={data.room}
        gateway={data.gateway}
        type={data.type}
        gateways={data.gateways}
      />
    ),
  },
  {
    title: 'Location in Room',
    lens: data => ({ gateway: data.id, location: data.location }),
    Component: ({ data }) => (
      <DelayedTextInput
        disabled={true}
        value={data.location}
        variables={{ id: data.gateway }}
        mutation={updateLocation}
        path={'location'}
      />
    ),
    superAdmin: true,
  },
  {
    title: 'Location in Room',
    lens: data => ({ gateway: data.id, location: data.location }),
    Component: ({ data }) => (
      <DelayedTextInput
        value={data.location}
        variables={{ id: data.gateway }}
        mutation={updateLocation}
        path={'location'}
      />
    ),
  },
  {
    title: 'Port Switch',
    lens: data => ({ gateway: data.id, portSwitch: data.portSwitch }),
    Component: ({ data }) => <PortSwitchInput disabled={true} portSwitch={data.portSwitch} gateway={data.gateway} />,
    superAdmin: true,
  },
  {
    title: 'Port Switch',
    lens: data => ({ gateway: data.id, portSwitch: data.portSwitch }),
    Component: ({ data }) => <PortSwitchInput portSwitch={data.portSwitch} gateway={data.gateway} />,
  },
  {
    title: 'Strength / Average',
    lens: data => data,
    Component: ({ data }) => (
      <SignalStrength
        signalAverage={getBraceletAverage(getNestedValue('signal', data))}
        signalStrength={getBraceletSignal(getNestedValue('signal', data))}
        threshold={data.threshold}
      />
    ),
    superAdmin: false,
  },
  {
    title: 'Threshold',
    lens: data => ({ gateway: data.id, threshold: data.threshold }),
    Component: ({ data }) => <ThresholdInput disabled={true} threshold={data.threshold} gateway={data.gateway} />,
    superAdmin: true,
  },
  {
    title: 'Threshold',
    lens: data => ({ gateway: data.id, threshold: data.threshold }),
    Component: ({ data }) => <ThresholdInput threshold={data.threshold} gateway={data.gateway} />,
  },
  {
    title: 'Last Sync',
    lens: data => data,
    sortFunction: (l, r) => sortString(distanceAgo(l.signal, l.serverTime), distanceAgo(r.signal, r.serverTime)),
    Component: ({ data }) => (
      <TruncatedText style={{ width: '9em' }}>{getBraceletSync(data.signal, data.serverTime)}</TruncatedText>
    ),
    superAdmin: false,
  },
  {
    title: '',
    lens: data => data,
    Component: ({ data }) => <RemoveGatewayButton id={data.id} bracelet={undefined} />,
    superAdmin: false,
  },
];

const isMainGateway = data => data.id === getNestedValue('signal.mainGateway', data);

const isInactive = data => getNestedValue('isInactive', data);

const isMainGatewayOrInactive = data => isMainGateway(data) || isInactive(data);

const Container = styled.div`
  ${responsive.md.andSmaller`
    margin-bottom: 7em;
  `}
`;

const highlightedStyleProvider = data => ({
  ...(isMainGatewayOrInactive(data)
    ? {
        border: '1px solid darkorange',
        borderTopWidth: '2px',
        borderLeftWidth: '3px',
        borderRightWidth: '3px',
        borderBottomWidth: '2px',
      }
    : {}),
  filter: isInactive(data) ? 'grayscale(20%)' : 'contrast(70%)',
});

export default () => {
  const [bracelet, setBracelet] = useState(undefined);
  const isMobile = window.innerWidth < breakpoint.md;
  return (
    <Container>
      <TitleAction Title={() => <DefaultTitle>Gateways</DefaultTitle>}>
        <Box minWidth={'25em'}>
          <BraceletSelectInput onChange={setBracelet} value={bracelet} />
        </Box>
      </TitleAction>
      <ConfigurationEdit withActionButton={true} style={{ marginBottom: '1em' }} />
      {!bracelet && !isMobile && <p>Choose a bracelet from the dropdown menu to start the live data feed</p>}
      <Query
        query={gatewaysScheme.list}
        fetchPolicy={'network-only'}
        pollInterval={5000}
        variables={{ braceletId: bracelet }}
      >
        {({ data }) => (
          <CollectionList
            idProvider={item => item.id}
            entityName={'Gateway'}
            columns={getColumns(false)}
            data={data}
            getList={data => data['gateways']}
            TitleAndActions={() => null}
            highlightedProvider={isMainGatewayOrInactive}
            highlightedRowStyleProvider={highlightedStyleProvider}
            MobileItemComponent={GatewayMobileListItem}
            history={{}}
            location={{}}
          />
        )}
      </Query>
    </Container>
  );
};

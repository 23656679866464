import React, { FC, useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SendNotificationStaffMember from './staff/SendNotification';
import SendNotificationAnesthesiologist from './anesthesiologist/SendNotification';
import SendNotificationVendor from './vendor/SendNotification';
import { useQuery } from '@apollo/client';
import { listAssignedStaffMembers } from '../../../../graph/staff';
import { format } from 'date-fns';
import { listAssignedAnesthesiologists } from '../../../../graph/anesthesiologists';
import { listAssignedVendors } from '../../../../graph/vendors';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

interface SendNotificationDropdownProps {
  date: Date;
}

type NotificationDisabled = {
  isStaffMemberDisabled: boolean;
  isAnesthesiologistDisabled: boolean;
  isVendorDisabled: boolean;
};
const emptyString = ''; //had to do it like this as it had some bug when we put ''

const TooltipWrapper: FC<{ title: string; isDisabled: boolean; children?: React.ReactNode }> = ({
  title,
  children,
  isDisabled,
}) => (
  <Tooltip title={isDisabled ? <Typography style={{ fontSize: 'small' }}>{title}</Typography> : emptyString}>
    <span>{children}</span>
  </Tooltip>
);

const SendNotificationDropdown: FC<SendNotificationDropdownProps> = ({ date }) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | (EventTarget & HTMLAnchorElement) | null
  >(null);

  const [isDisabledNotify, setIsDisabledNotify] = useState<NotificationDisabled>({
    isStaffMemberDisabled: true,
    isAnesthesiologistDisabled: true,
    isVendorDisabled: true,
  });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [localDate, setLocalDate] = useState(new Date());
  useEffect(() => {
    !isNaN(date.getTime()) && setLocalDate(date);
  }, [date]);

  const { data: staffData } = useQuery(listAssignedStaffMembers, {
    variables: { date: format(localDate, 'YYYY-MM-DD') },
  });
  const { data: anesthesiologistData } = useQuery(listAssignedAnesthesiologists, {
    variables: { date: format(localDate, 'YYYY-MM-DD') },
  });
  const { data: vendorData } = useQuery(listAssignedVendors, { variables: { date: format(localDate, 'YYYY-MM-DD') } });

  useEffect(() => {
    let isDisabled: NotificationDisabled = {
      isStaffMemberDisabled: staffData?.listAssignedStaffMembers?.length === 0,
      isAnesthesiologistDisabled: anesthesiologistData?.listAssignedAnesthesiologists?.length === 0,
      isVendorDisabled: vendorData?.listAssignedVendors?.length === 0,
    };
    setIsDisabledNotify(isDisabled);
  }, [
    staffData?.listAssignedStaffMembers,
    anesthesiologistData?.listAssignedAnesthesiologists,
    vendorData?.listAssignedVendors,
  ]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState<number | null>(null);

  const handleClickOpen = (menuItem: number) => {
    setOpen(menuItem);
    handleClose();
  };

  const handleClickClose = () => {
    setOpen(null);
  };

  return (
    <>
      <TooltipWrapper
        title={'Nobody to notify'}
        isDisabled={
          isDisabledNotify.isStaffMemberDisabled &&
          isDisabledNotify.isAnesthesiologistDisabled &&
          isDisabledNotify.isVendorDisabled
        }
      >
        <IconButton
          disabled={
            isDisabledNotify.isStaffMemberDisabled &&
            isDisabledNotify.isAnesthesiologistDisabled &&
            isDisabledNotify.isVendorDisabled
          }
          onClick={handleClick}
          color="primary"
        >
          <NotificationsActiveIcon />
        </IconButton>
      </TooltipWrapper>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <TooltipWrapper title={'No staff to notify'} isDisabled={isDisabledNotify.isStaffMemberDisabled}>
          <MenuItem disabled={isDisabledNotify.isStaffMemberDisabled} onClick={() => handleClickOpen(1)}>
            Staff Members
          </MenuItem>
        </TooltipWrapper>
        <TooltipWrapper
          title={'No anesthesiologist to notify'}
          isDisabled={isDisabledNotify.isAnesthesiologistDisabled}
        >
          <MenuItem disabled={isDisabledNotify.isAnesthesiologistDisabled} onClick={() => handleClickOpen(2)}>
            Anesthesiologists
          </MenuItem>
        </TooltipWrapper>
        <TooltipWrapper title={'No vendor to notify'} isDisabled={isDisabledNotify.isVendorDisabled}>
          <MenuItem disabled={isDisabledNotify.isVendorDisabled} onClick={() => handleClickOpen(3)}>
            Vendors
          </MenuItem>
        </TooltipWrapper>
      </Menu>
      {open === 1 && (
        <SendNotificationStaffMember data={staffData} date={localDate} open={true} handleClose={handleClickClose} />
      )}
      {open === 2 && (
        <SendNotificationAnesthesiologist
          data={anesthesiologistData}
          date={localDate}
          open={true}
          handleClose={handleClickClose}
        />
      )}
      {open === 3 && (
        <SendNotificationVendor data={vendorData} date={localDate} open={true} handleClose={handleClickClose} />
      )}
    </>
  );
};

export default SendNotificationDropdown;

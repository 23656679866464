import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../../../se/components/LinkButton';
import responsive from '../../../../se/utilities/responsive';

const HelperActionWrapper = styled.div`
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;

  span {
    background: ${props => props.theme.backgroundColor.string()};
    line-height: 2;
    padding: 0 1rem;

    small {
      opacity: 0.5;
    }
  }

  ${responsive.sm.andSmaller`
    display: none;
  `};
`;

const ButtonIcon = styled.i`
  font-size: 1rem;
`;

const refreshTablet = () => {
  document.location.reload(true);
};

const HelperAction = () => (
  <HelperActionWrapper>
    <span>
      <small>Experiencing problems with the app?</small>{' '}
      <LinkButton onClick={refreshTablet}>
        Click to refresh <ButtonIcon className="material-icons">refresh</ButtonIcon>
      </LinkButton>
    </span>
  </HelperActionWrapper>
);

export default HelperAction;

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import Page from '../../../../../se/components/Page';
import Sidebar from '../../../../../se/components/Sidebar';
import ChangePassword from '../../ChangePassword';
import Header from '../../admin/Header';
import Footer from '../../admin/Footer';
import { light as lightTheme } from '../../../../../theme';
import { Context as PatientNameFormatContext } from '../../../../../contexts/PatientNameFormat';
import { withSession } from '../../../../../state/Session';
import get from 'lodash/fp/get';
import { muiThemeLight } from '../../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../../OmniSearch';
import Consultations from '../../../../entities/surgeonOffice/consultations/Consultations';
import Surgeries from '../../../../entities/surgeonOffice/surgeries/Surgeries';
import proceduresSource from '../../../../../omnisearch/sources/surgeon/procedures';
import Schedule from '../../../../entities/schedule/Schedule';
import Feedback from '../../../../entities/surgeonFeedback/Feedback';
import { CovidScreeningSO } from '../../../../entities/covidScreening/CovidScreeningSubmissions';
import { unpackSessionObject } from '../../../unpackSessionObject';
import { useScope, withScope } from '../../../../HospitalInfo';
import { getBasePathAndUrl } from '../admin/Homepage';
import { AirlineSeatFlat, Contacts, Event, ThumbUp } from '@material-ui/icons';

const getSwitchRoutes = (basePath, baseUrl, modules) =>
  [
    modules.exchangeOnly
      ? undefined
      : {
          path: `${basePath}/consultations`,
          to: `${baseUrl}/consultations`,
          label: 'Consultations',
          icon: <Contacts />,
          component: Consultations,
        },
    {
      path: `${basePath}/surgeries`,
      to: `${baseUrl}/surgeries`,
      label: 'Surgeries',
      icon: <AirlineSeatFlat />,
      component: Surgeries,
    },
    {
      path: `${basePath}/schedule-procedure`,
      to: `${baseUrl}/schedule-procedure`,
      label: 'Surgery Schedule',
      icon: <Event />,
      component: Schedule,
    },
    {
      separator: true,
    },
    {
      path: `${basePath}/feedback`,
      to: `${baseUrl}/feedback`,
      label: 'Feedback',
      icon: <ThumbUp />,
      component: Feedback,
    },
    modules.covidScreening
      ? {
          path: `${basePath}/covid-screening`,
          to: `${baseUrl}/covid-screening`,
          label: 'Covid Screening',
          icon: 'coronavirus',
          component: CovidScreeningSO,
        }
      : undefined,
  ].filter(_ => !!_);

const NurseSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  mapProps(props => {
    const { match } = props;
    const [basePath, baseUrl] = getBasePathAndUrl(match);
    const modules = {
      exchangeOnly: props.scope.hospital.exchangeOnly,
      covidScreening: !!props.scope?.hospital?.covidScreening,
    };

    return {
      ...props,
      links: getSwitchRoutes(basePath, baseUrl, modules),
    };
  })
)(Sidebar);

const Homepage = ({ patientNameFormat, ...props }) => {
  const scope = useScope();
  const [basePath, baseUrl] = getBasePathAndUrl(props.match);
  const modules = {
    exchangeOnly: !!scope.hospital.exchangeOnly,
    covidScreening: !!scope?.hospital?.covidScreening,
  };
  const routes = getSwitchRoutes(basePath, baseUrl, modules).filter(_ => !_.separator);

  return (
    <ThemeProvider theme={lightTheme}>
      <MUIThemeProvider theme={muiThemeLight}>
        <style>
          {`body {
          background: ${muiThemeLight.palette.background.default};
        }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider value={patientNameFormat}>
          <OmniSearchProvider>
            <OmniSearchSources sources={[proceduresSource]} />
            <Page Sidebar={NurseSidebar}>
              <MainContainer switchControl>
                <Switch>
                  {routes.map(({ path, component }, index) => (
                    <Route key={path || index} path={path} component={component} />
                  ))}
                  <Route path="/change-password" component={ChangePassword} />
                  <Route render={() => <Redirect to="/consultations" />} />
                </Switch>
              </MainContainer>
            </Page>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default withSession(get('session.account.metadata'))(Homepage);

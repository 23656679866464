import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportRow from '../../components/MedicalPassportRow';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
} from '../paths';
import toAge, { getAge } from '../../../../../../questionnaire/toAge';
import toBMI, { calculateBMI } from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import toSex from '../../../../../../questionnaire/toSex';
import toUsageString from '../../../../../../questionnaire/toUsageString';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import { parse } from 'date-fns';

const Page4 = ({ showQRCode, hospitalName, value, defaultValue }) => {
  const age = getAge(parse(defaultValue?.demographicInformation?.dob, 'MM/DD/YYYY'));
  const bmi = calculateBMI(
    parseInt(defaultValue?.demographicInformation?.bmi?.weightLb, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightFt, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightIn, 10)
  );

  const providerId = defaultValue?.procedure?.patient?.providerId;

  return (
    <div id="page4" className="page" style={{ fontSize: '0.8em' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <h3>{hospitalName || ''}</h3>
          <h3>ANESTHESIA ASSESSMENT</h3>
        </div>
        <div>
          {showQRCode && (
            <img
              alt="Barcoded value"
              src={`http://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${providerId}&scale=2`}
            />
          )}
        </div>
      </div>
      <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '55% 45%',
          }}
        >
          <div>
            <div
              style={{
                border: '1px solid black',
                borderTop: 0,
                minHeight: '60px',
                height: '100%',
                borderBottom: 0,
                padding: '0.1rem 0.2rem 0',
              }}
            >
              <MedicalPassportTextArea name="procedure.name" label="Planned Procedure" />
            </div>
          </div>
          <div>
            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
              <div style={{ marginBottom: '0.2rem' }}>
                <div style={{ marginBottom: '0.2rem' }}>
                  <MedicalPassportField
                    name="procedure.patient.name"
                    label="Patient"
                    fontWeight={'bold'}
                    fontSize={16}
                  />
                </div>
                <MedicalPassportField label="Surgical Date" name="procedure.serviceTime" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" />
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                />
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportField
                  name="ht"
                  label="Ht"
                  path={['demographicInformation', 'bmi']}
                  reducer={toHeight}
                />
                <MedicalPassportField
                  name="wt"
                  label="Wt"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeight}
                />
                <MedicalPassportField
                  name="bmi"
                  label="BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                  warning={bmi !== '-' ? bmi > 40 : false}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '55% 45%' }}>
          <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
            <div
              style={{
                border: '1px solid black',
                padding: '0.2rem 0.1rem',
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
              }}
            >
              <MedicalPassportField name="page4.activityLevel" label="Activity Level" />
            </div>
            <div
              style={{
                border: '1px solid black',
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
                lineHeight: '1.6em',
                padding: '0 0.1rem',
              }}
            >
              <MedicalPassportTextArea
                name="smokingPage4"
                label="Smoking"
                paths={[
                  ['basicscreening', 'smoking'],
                  ['basicscreening', 'smoking status'],
                ]}
                reducer={toUsageString}
              />
              <MedicalPassportTextArea
                name="etohPage4"
                label="ETOH"
                paths={[
                  ['basicscreening', 'drinking'],
                  ['basicscreening', 'alcohol abuse'],
                ]}
                reducer={toUsageString}
              />
              <MedicalPassportTextArea
                name="drugHxPage4"
                label="Drug Hx"
                path={['basicscreening', 'drugs']}
                reducer={toUsageString}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={styles.medicationsGrid}>
        <div style={styles.medicationsInnerDiv}>
          <MedicalPassportTextArea
            label={'Med Allergies/Sensitivities'}
            name="allergies1"
            path={['allergies']}
            reducer={toHighlightsString}
          />
        </div>
        <div style={styles.medicationsInnerDiv}>
          <MedicalPassportTextArea
            label={'Current Medications'}
            name="medications1"
            path={['medications']}
            reducer={toHighlightsString}
          />
        </div>
        <div style={{ ...styles.medicationsInnerDiv, borderRight: '1px solid black' }}>
          <MedicalPassportTextArea
            label="Surgical/Anesthesia Hx"
            name="anesthesia1"
            path={['priorSurgeries']}
            reducer={toHighlightsString}
          />
        </div>
      </div>
      <div
        style={{
          border: '1px solid black',
          borderTop: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 .25em',
        }}
      >
        Cleared for Sugery:
        <MedicalPassportCheckbox name="page4.clearedForSurgeryYes" label="Yes" style={{ marginRight: '0.2rem' }} />
        <MedicalPassportCheckbox name="page4.clearedForSurgeryNo" label="No" style={{ marginRight: '0.2rem' }} />
        <MedicalPassportCheckbox name="page4.clearedForSurgeryNA" label="N/A" style={{ marginRight: '0.5rem' }} />
        <MedicalPassportField name="page4.clearedForSurgeryDate" label="Date of clearance and obtained" />
      </div>
      <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
        <MedicalPassportTextArea
          label={'Hx of Anesthesia Complications'}
          name="page4.hxAnesthesiaComplications"
          path={['anesthesia complications']}
        />
      </div>

      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>
          <div>Review of Systems</div>
        </div>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '1.8em',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow
            label="Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            style={{ borderTop: 0 }}
            index={0}
          />
          <MedicalPassportRow label="Pulmonary" name="systems.pulmonary" paths={pulmonary} index={1} />
          <MedicalPassportRow label="Renal" name="systems.renal" paths={renal} index={2} />
          <MedicalPassportRow label="Hepatic" name="systems.hepatic" paths={hepatic} index={3} />
          <MedicalPassportRow label="Neurological" name="systems.neurological" paths={neurological} index={4} />
          <MedicalPassportRow
            label="Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            index={5}
          />
          <MedicalPassportRow label="Hematological" name="systems.hematological" paths={hematological} index={6} />
          <MedicalPassportRow label="Endocrine/Metabolic" name="systems.endocrine2" paths={endocrine} index={7} />
          <MedicalPassportRow
            label="Musculoskeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            index={8}
          />
          <MedicalPassportRow label="Psychiatric" name="systems.psychiatric" paths={psychiatric} index={9} />
          <MedicalPassportRow label="OB/Gyn" name="systems.gyn" paths={gynaecological} index={10} />
          <MedicalPassportRow label="Other" name="systems.other" paths={other} index={11} />
        </div>
      </section>

      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>Physical Exam/Labs/Anesthesia Plan</div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr 2fr' }}>
          <div
            style={{
              border: '1px solid black',
              lineHeight: '1.6em',
              borderRight: 0,
              borderBottom: 0,
              padding: '0.25em',
            }}
          >
            <div style={{ paddingLeft: '.25em' }}>Vital Signs</div>
            <MedicalPassportField name="page4.temp" label="Temp" />
            <MedicalPassportField name="page4.bp" label="BP" />
            <MedicalPassportField name="page4.pulse" label="Pulse" />
            <MedicalPassportField name="page4.resp" label="Resp" />
            <MedicalPassportField name="page4.o2Sat" label="O2Sat" />
          </div>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', height: '80px' }}>
              <div style={{ border: '1px solid black', borderRight: 0 }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}
                >
                  <div>General</div>
                  <MedicalPassportCheckbox
                    name="page4.generalWnl"
                    label="WNL"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
              </div>
              <div style={{ border: '1px solid black', borderRight: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '15px',
                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}
                  >
                    <div>Heart</div>
                    <MedicalPassportCheckbox
                      name="page4.heartWnl"
                      label="WNL"
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MedicalPassportCheckbox
                      name="page4.heartRegularRateRhythm"
                      label="Regular Rate + Rhythm"
                      style={{ display: 'flex' }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ border: '1px solid black', borderRight: 0 }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '.25em' }}
                >
                  <div>Lungs</div>
                  <div>
                    <MedicalPassportCheckbox
                      name="page4.lungsWnl"
                      label="WNL"
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                    <MedicalPassportCheckbox
                      name="page4.lungsCta"
                      label="CTA"
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ border: '1px solid black', borderRight: 0 }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}
                >
                  <div>Teeth</div>
                  <MedicalPassportCheckbox
                    name="page4.teethWnl"
                    label="WNL"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
              </div>
            </div>
            <div style={styles.examLabGrid}>
              <div style={styles.examLabInnerGridDiv}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '0 .25em' }}>
                  <div>EKG/ECHO reviewed</div>
                  <MedicalPassportCheckbox
                    name="page4.ekgNa"
                    label="Y/N"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                  <MedicalPassportCheckbox
                    name="page4.ekgYn"
                    label="N/A"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', padding: '0 .25em' }}>
                  <div>Labs reviewed</div>
                  <MedicalPassportCheckbox
                    name="page4.labNa"
                    label="Y/N"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                  <MedicalPassportCheckbox
                    name="page4.labYn"
                    label="N/A"
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ height: '67px', border: '1px solid black', borderBottom: 0, padding: '.4em .25em' }}>
              Airway Assessment:
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                border: '1px solid black',
                borderBottom: 0,
                height: '73px',
                padding: '.25em',
              }}
            >
              <div>Other</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <div style={{ border: '1px solid black', padding: '.5em', borderRight: 0 }}>
            <div style={{ display: 'flex', gap: '40px' }}>
              <div>ASA Class:</div>
              <MedicalPassportCheckbox label="1" name="page4.asaClass1" />
              <MedicalPassportCheckbox label="2" name="page4.asaClass2" />
              <MedicalPassportCheckbox label="3" name="page4.asaClass3" />
              <MedicalPassportCheckbox label="4" name="page4.asaClass4" />
            </div>
            <div style={{ lineHeight: '1.6em' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
                <div>NPO Status Verified?</div>
                <div>
                  <MedicalPassportCheckbox name="page4.NPOStatusVerifiedYes" label="Yes" />
                </div>
                <div>
                  <MedicalPassportCheckbox name="page4.NPOStatusVerifiedNo" label="No" />
                </div>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
                <div>Pt. Reassessed Day of Surgery?</div>
                <div>
                  <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryYes" label="Yes" />
                </div>
                <div>
                  <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryNo" label="No" />
                </div>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
                <div>Postoperative Plan:</div>
                <div>
                  <MedicalPassportCheckbox name="page4.pacu" label="PACU/Home" />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              border: '1px solid black',
              padding: '.25em',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Assessment:</div>
              <div style={{ lineHeight: '1.6em' }}>
                <MedicalPassportCheckbox
                  name="page4.noContradictionAnesthesia"
                  label="No contradiction to anesthesia"
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MedicalPassportCheckbox name="page4.assessment1.other" />
                  <MedicalPassportField name="page4.assessment1.otherText" label="Other" />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Anesthetic Plan:</div>
              <div style={{ lineHeight: '1.6em' }}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '6px' }}>
                  <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
                  <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
                  <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
                  <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
                  <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
                  <MedicalPassportCheckbox name="page4.assessment2.other" />
                  <MedicalPassportField name="page4.assessment2.otherText" label="Other" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '30px', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ textAlign: 'center' }}>
            <div>________________________________</div>
            <div>Anesthesia</div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>________________________________</div>
            <div>Date/Time</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page4;

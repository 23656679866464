import React, { useRef } from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import getColumns from './columns';
import pluralize from 'pluralize';
import config from '../../../config';
import {
  item,
  listSubscription,
  listSubscriptionSubmissionsForPatientsSC,
  listSubscriptionSubmissionsForPatientsSO,
  patientSubmission,
} from '../../../graph/covidScreening';
import { graphql } from '@apollo/client/react/hoc';
import CovidScreeningSubmission from './CovidScreeningSubmission';
import get from 'lodash/get';
import { tryParseJson } from '../../../util/parseJson';
import TabRoutes from '../../core/TabRoutes';
import { withProps } from 'recompose';
import { NamedRange } from '../../core/DatePicker';
import Filters from '../../pages/analytics/Filters';
import camelCase from 'lodash/camelCase';
import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import { DefaultTitle } from '../../../se/components/collection/CollectionList';
import { titlecase } from 'stringcase';
import queryString from 'query-string';
import compactQueryParams from '../../../util/compactQueryParams';
import { withScope } from '../../HospitalInfo';
import TitleAction from '../../../se/components/TitleAction';
import Typography from '@material-ui/core/Typography';
import QRCode from 'react-qr-code';
import ReactToPrint from 'react-to-print';
import { PersonAddTwoTone, PrintTwoTone } from '@material-ui/icons';
import { muiThemePrint } from '../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { H2 } from '../../../se/components/typography';
import PatientCovidScreeningSubmission from './PatientCovidScreeningSubmission';

const useStyles = makeStyles(theme =>
  createStyles({
    actions: {
      margin: theme.spacing(0, -1),

      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    hidden: {
      display: 'none',
    },
  })
);

const Empty = {
  title: 'There is no submissions at the moment',
  hint: 'Submissions will appear here when patients, staff or others complete the covid form',
  illustration: theme => theme.illustrations.waiting,
};

class Instructions extends React.Component {
  render() {
    return (
      <MUIThemeProvider theme={muiThemePrint}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          width="100vw"
          color="text.primary"
          style={{ backgroundColor: 'white' }}
        >
          {/* TODO instead of tailoring the entityName to not include “Submissions”, it would be better entityName came without it in the first place */}
          <Typography variant="h2" align="center" gutterBottom>
            For {titlecase(pluralize(this.props.entityName.replace('Submission', '')))}
          </Typography>
          <Typography variant="h1" align="center" gutterBottom>
            Scan this code and fill in your <br />
            COVID screening questionnaire here
          </Typography>

          <Box my={4}>
            <QRCode value={this.props.url} />
          </Box>

          <Typography>OR</Typography>

          <Box mt={4}>
            <Typography align="center" gutterBottom>
              Type this URL in your browser
            </Typography>
            <Box border={1} px={2} py={1}>
              <Typography variant="h3" align="center">
                {this.props.url}
              </Typography>
            </Box>
          </Box>
        </Box>
      </MUIThemeProvider>
    );
  }
}

const Title = ({ entityName }) => <H2>{titlecase(pluralize(entityName))}</H2>;

const TitleAndActions = category => {
  const TitleAndActionsComponent = ({ scope, entityName }) => {
    const classes = useStyles();
    const componentRef = useRef();
    const organizationId = scope?.hospital?.covidScreening?.id;

    const search = queryString.stringify(compactQueryParams({ category }));
    const url = `${config.covidScreeningURL}/${organizationId}?${search}`;

    const openSubmissionWindow = e => {
      e.preventDefault();

      if (!organizationId) {
        return null;
      }

      window.open(url, 'submission', `width=1024,height=750`);
    };

    return (
      <TitleAction Title={() => <Title entityName={entityName} />}>
        {organizationId && (
          <div className={classes.actions}>
            <ReactToPrint
              trigger={() => (
                <Button variant="outlined" startIcon={<PrintTwoTone />}>
                  Print QR Code
                </Button>
              )}
              content={() => componentRef.current}
            />
            <Button
              color="primary"
              variant="contained"
              component="a"
              href={url}
              startIcon={<PersonAddTwoTone />}
              onClick={openSubmissionWindow}
            >
              Create {titlecase(entityName)}
            </Button>
          </div>
        )}

        <div className={classes.hidden}>
          <Instructions entityName={entityName} ref={componentRef} url={url} />
        </div>
      </TitleAction>
    );
  };
  return withScope(TitleAndActionsComponent);
};

const Filter = props => (
  <Filters
    {...props}
    showNameSearchInput
    hidePhysicianSelectInput
    hideProcedureTypeSelectInput
    hideHospitalSelectInput
    hideProcedureStatusSelectInput
    hideCategorySelectInput
    hideSpecialitySelectInput
    hideConsultationStatusSelectInput
    hideSurgeryStatusHospitalSelectInput
    hideSurgeryStatusSurgeonOfficeSelectInput
    reload={true}
    dontReloadOn={['name']}
  />
);

const SubmissionTitle = data => <DefaultTitle>{titlecase(get(tryParseJson(data?.answer), 'name', ''))}</DefaultTitle>;
const PatientSubmissionTitle = withScope(({ scope, ...data }) => {
  const organizationId = scope?.hospital?.covidScreening?.id;

  const search = queryString.stringify(compactQueryParams({ category: 'other', parent: data?.id }));
  const url = `${config.covidScreeningURL}/${organizationId}?${search}`;

  const openSubmissionWindow = e => {
    e.preventDefault();

    if (!organizationId) {
      return null;
    }

    window.open(url, 'submission', `width=1024,height=750`);
  };

  return (
    <TitleAction Title={() => <SubmissionTitle {...data} />}>
      {organizationId && (
        <Box ml={2}>
          <Button component="a" href={url} variant="contained" onClick={openSubmissionWindow}>
            Create Caretaker Submission
          </Button>
        </Box>
      )}
    </TitleAction>
  );
});

const CovidScreening = (category, entityName) =>
  GraphQLCRUD({
    entityName,
    idType: 'string',
    scheme: {
      list: listSubscription(category, camelCase(pluralize(entityName))),
    },
    List: {
      tableKey: 'CovidScreening',
      useCSVExport: true,
      columns: getColumns(category),
      Title,
      TitleAndActions: TitleAndActions(category),
      FilterComponent: Filter,
      defaultFilterValues: {
        dateRange: NamedRange.today(),
      },
      Empty,
    },
    Show: {
      columns: getColumns(category),
      View: props => <CovidScreeningSubmission category={category} {...props} />,
      titleProvider: category === 'patient' ? PatientSubmissionTitle : SubmissionTitle,
      itemProvider: graphql(item(camelCase(entityName)), {
        options: ({ idProvider, ...rest }) => ({
          variables: {
            id: idProvider(rest),
            fetchPolicy: 'cache-and-network',
          },
        }),
      }),
    },
  });

const CovidScreeningForPatientsSC = GraphQLCRUD({
  entityName: 'PatientSubmission',
  idType: 'string',
  scheme: {
    list: listSubscriptionSubmissionsForPatientsSC,
  },
  List: {
    tableKey: 'CovidScreening',
    useCSVExport: true,
    columns: getColumns('patient', 'SC'),
    Title,
    TitleAndActions: TitleAndActions('patient'),
    FilterComponent: Filter,
    defaultFilterValues: {
      dateRange: NamedRange.today(),
    },
    Empty,
  },
  Show: {
    columns: getColumns('patient', 'SC'),
    View: PatientCovidScreeningSubmission,
    titleProvider: PatientSubmissionTitle,
    itemProvider: graphql(patientSubmission, {
      options: ({ idProvider, ...rest }) => ({
        variables: {
          id: idProvider(rest),
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
});

const CovidScreeningForPatientsSO = GraphQLCRUD({
  entityName: 'PatientSubmission',
  idType: 'string',
  scheme: {
    list: listSubscriptionSubmissionsForPatientsSO,
  },
  List: {
    tableKey: 'CovidScreening',
    useCSVExport: true,
    columns: getColumns('patient', 'SO'),
    Title,
    TitleAndActions: TitleAndActions('patient'),
    FilterComponent: Filter,
    defaultFilterValues: {
      dateRange: NamedRange.today(),
    },
    Empty,
  },
  Show: {
    columns: getColumns('patient', 'SO'),
    View: PatientCovidScreeningSubmission,
    titleProvider: PatientSubmissionTitle,
    itemProvider: graphql(patientSubmission, {
      options: ({ idProvider, ...rest }) => ({
        variables: {
          id: idProvider(rest),
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
});

export const CovidScreeningSC = withProps({
  tabs: [
    {
      title: 'Patients',
      path: '/patients',
      component: CovidScreeningForPatientsSC,
    },
    {
      title: 'Staff',
      path: '/staff',
      component: CovidScreening('staff', 'StaffSubmission'),
    },
    {
      title: 'Vendor & Others',
      path: '/other',
      component: CovidScreening('other', 'OtherSubmission'),
    },
  ],
})(TabRoutes);

export const CovidScreeningSO = withProps({
  tabs: [
    {
      title: 'Patients',
      path: '/patients',
      component: CovidScreeningForPatientsSO,
    },
    {
      title: 'Staff',
      path: '/staff',
      component: CovidScreening('staff', 'StaffSubmission'),
    },
    {
      title: 'Vendor & Others',
      path: '/other',
      component: CovidScreening('other', 'OtherSubmission'),
    },
  ],
})(TabRoutes);

import { Subscription } from '@apollo/client/react/components';
import React from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Redirect, Route, Switch } from 'react-router-dom';
import GridFader from '../../core/GridFader';
import Monitor from './Monitor';
import WaitingRoomMonitor from './WaitingRoomMonitor';
import RoomTabletAppFactory from './RoomTabletApp';
import Schedule from './Schedule';
import Tablet from './tablet/Tablet';
import { screenConfiguration } from '../../../graph/screens';
import { getNestedValue } from '../../../se/utilities/data/object';
import ConnectionProvider from './ConnectionProvider';
import { ScopeProvider } from '../../HospitalInfo';
import BlockSchedulePage from '../blockSchedule/BlockSchedulePage';
import ReviewsPage from './reviews/ReviewsPage';
import { ROOM_TYPES } from '../../entities/room/enums';
import MultipleCalendarPage from '../calendar/MultipleCalendarPage';
import PatientChart from './charting/PatientChart';
import ChartingPage from './charting/ChartingPage';
import { get } from 'lodash/fp';

const defaultConfig = {
  showProcedure: true,
  dischargeSound: true,
  orReadySound: true,
  waitingReadySound: true,
  pacuReadySound: true,
  prepReadySound: true,
  readyForSurgeonSound: true,
  readyToPickupSound: true,
  holdProcedureSound: true,
  useCssGridLayout: false,
  showPostOp: true,
};

export const PrepTabletApp = RoomTabletAppFactory(ROOM_TYPES.PRE_OP);
export const PacuTabletApp = RoomTabletAppFactory(ROOM_TYPES.PACU);
export const PostOpTabletApp = RoomTabletAppFactory(ROOM_TYPES.POST_OP);

const ScreenRouter = ({ match, location, accessToken, organizationId, onUnauthorized, path, isHuman }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <ConnectionProvider accessToken={accessToken} organizationId={organizationId} onUnauthorized={onUnauthorized}>
      <ScopeProvider>
        <Subscription subscription={screenConfiguration}>
          {({ data }) => (
            <Switch component={GridFader}>
              <Route
                path={`${match.path}/monitor`}
                render={() => (
                  <Monitor
                    accessToken={accessToken}
                    version={queryParams.version}
                    configuration={isHuman ? defaultConfig : getNestedValue('screenConfiguration', data)}
                  />
                )}
              />
              <Route
                path={`${match.path}/waiting-room`}
                render={() => (
                  <WaitingRoomMonitor
                    version={queryParams.version}
                    configuration={isHuman ? defaultConfig : getNestedValue('screenConfiguration', data)}
                  />
                )}
              />
              <Route path={`${match.path}/nursing/prep/:roomId`} component={PrepTabletApp} />
              <Route path={`${match.path}/nursing/pacu/:roomId`} component={PacuTabletApp} />
              <Route path={`${match.path}/nursing/post-op/:roomId`} component={PostOpTabletApp} />
              <Route path={`${match.path}/tablet/:operationRoomId`} component={Tablet} />
              <Route
                path={`${match.path}/block-schedule`}
                render={props => <BlockSchedulePage {...props} organizationId={organizationId} />}
              />
              <Route path={`${match.path}/calendar`} component={MultipleCalendarPage} />
              <Route
                path={`${match.path}/schedule`}
                render={() => <Schedule isKiosk={!isHuman} config={getNestedValue('screenConfiguration', data)} />}
              />
              <Route
                path={`${match.path}/charting/patient-chart/:id`}
                component={props => {
                  const patientId = parseInt(get('params.id')(props.match), 10);

                  return <PatientChart {...props} patientId={patientId} />;
                }}
              />
              <Route path={`${match.path}/charting`} component={ChartingPage} />
              <Route path={`${match.path}/reviews`} component={ReviewsPage} />
              <Route render={() => <Redirect to={`${match.url}${path || '/monitor'}`} />} />
            </Switch>
          )}
        </Subscription>
      </ScopeProvider>
    </ConnectionProvider>
  );
};

export default withRouter(ScreenRouter);

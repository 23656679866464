import React, { Fragment } from 'react';
import { branch, compose, mapProps, renderComponent, renderNothing, withState } from 'recompose';
import { format, isAfter, isBefore } from 'date-fns';
import parse from 'date-fns/parse';
import flatMap from 'lodash/fp/flatMap';
import { H4 } from '../../../../se/components/typography';
import { EventLog, EventLogEntry, EventLogEntryDebug, EventLogItem, EventLogTime, IconWrapper } from './components';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { isArray } from '../../../../se/utilities/check';
import { groupBy } from 'lodash';
import { optionalFormat, takeFirst } from '../../../pages/kiosk/tablet/utils';
import { withRouter } from 'react-router';
import RouterLink from '../../../../se/components/RouterLink';
import get from 'lodash/get';
import { errorMessage } from '../../../../vendor/twillio';
import { ZonedDateTime } from '@js-joda/core';
import {
  AirlineSeatFlat,
  ArrowForward,
  Chat,
  DirectionsWalk,
  Gesture,
  Help,
  HowToReg,
  Note,
  PersonAdd,
  RemoveCircle,
  TimeToLeave,
  Undo,
} from '@material-ui/icons';

const absolutePath = (location, user) => {
  const to = `users/${user.id}`;
  if (user.superRole) {
    return `/${to}`;
  } else {
    const basePath = location.pathname.match('/su/[^/]/') || '';
    return basePath + to;
  }
};

const ManualEntryByLink = ({ user, rootPath, location }) =>
  user && (
    <EventLogTime>
      <RouterLink to={{ pathname: absolutePath(location, user), search: location.search }}>{user.name}</RouterLink>
    </EventLogTime>
  );

const toBool = val => val === 'true';

const getFragment = (type, value) => {
  switch (type) {
    case 'NoteChange':
      return `Note: ${value}` || 'Deleted Note';
    case 'CaretakerConsentChange':
      return `Caretaker consent ${toBool(value) ? 'granted' : 'denied'}`;
    default:
      return type;
  }
};

export const PlainPatientLogs = ({
  timeEditModal,
  setTimeEditModal,
  isSuperAdmin,
  match,
  location,
  debug,
  ...props
}) => (
  <EventLog>
    <H4>Patient Journey</H4>
    {(props.data.log || []).map(
      (entryRoot, id) =>
        entryRoot.entries &&
        entryRoot.entries.length > 0 && (
          <EventLogItem key={id}>
            {entryRoot.entries.map((entry, index) => (
              <Fragment key={index}>
                <EventLogTime>
                  {format(ZonedDateTime.parse(entryRoot.timestamp).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss')}
                </EventLogTime>
                <EventLogEntry>
                  <IconWrapper>
                    {entry.type === 'Admitted' && <PersonAdd style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Marked as Ready For Surgeon' && <AirlineSeatFlat style={{ fontSize: '1.1em' }} />}
                    {(entry.type === 'Became Ready' ||
                      entry.type === 'Marked as Ready in PACU' ||
                      entry.type === 'Marked as Ready For OR' ||
                      entry.type === 'Marked as Family Ready') && <HowToReg style={{ fontSize: '1.1em' }} />}
                    {(entry.type === 'Marked as not Ready For OR' ||
                      entry.type === 'Marked as not Ready in PACU' ||
                      entry.type === 'Marked as not Ready For Surgeon') && <Undo style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Entered' && <ArrowForward style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Procedure' && <ArrowForward style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Discharged' && <DirectionsWalk style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Unknown' && <Help fontSize={'small'} />}
                    {entry.type === 'Family Ready For Visit' && <TimeToLeave style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Family Sms' && <Chat style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Physician Notified' && <Chat style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'Anesthesiologist Notified' && <Chat style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'NoteChange' && <Note style={{ fontSize: '1.1em' }} />}
                    {entry.type === 'CaretakerConsentChange' && !toBool(entry.value) && (
                      <RemoveCircle style={{ fontSize: '1.1em' }} />
                    )}
                    {entry.type === 'CaretakerConsentChange' && !!toBool(entry.value) && (
                      <Gesture style={{ fontSize: '1.1em' }} />
                    )}
                  </IconWrapper>
                  {getFragment(entry.type, entry.value)}&nbsp;
                  {entry.room && <span>{entry.room.name}</span>}
                  {entry.status && <span>{entry.status}</span>}
                  {entry.delivery && entry.delivery.errorCode && (
                    <span>&nbsp;-&nbsp;{errorMessage(entry.delivery.errorCode)}</span>
                  )}
                  {entry.delivery && entry.delivery.sendingError && (
                    <span>&nbsp;-&nbsp;{entry.delivery.sendingError}</span>
                  )}
                </EventLogEntry>
                <EventLogEntryDebug>
                  <EventLogTime>
                    {debug ? optionalFormat(entry.manualTimestamp, 'MM/DD/YYYY HH:mm:ss', '') : ''}
                  </EventLogTime>
                  {debug && <ManualEntryByLink user={getNestedValue('manualEntryBy', entry)} location={location} />}
                  {getNestedValue('performedBy', entry) && (
                    <ManualEntryByLink user={getNestedValue('performedBy', entry)} location={location} />
                  )}
                </EventLogEntryDebug>
              </Fragment>
            ))}
          </EventLogItem>
        )
    )}
  </EventLog>
);

const filterDuplicates = (curr, idx, array) =>
  !(idx < array.length && JSON.stringify(curr.entries) === JSON.stringify(getNestedValue('entries', array[idx + 1])));

const uniqKey = entry =>
  `${getNestedValue('type', entry)}_${getNestedValue('status', entry)}_${getNestedValue('room.id', entry)}`;

const resolveLogEntries = entries => {
  const shouldOmit = ['NoteChange', 'CaretakerConsentChange'].includes(get(entries, '[0].entries[0].type'));
  return shouldOmit
    ? entries
    : takeFirst(entries.sort((l, r) => (isAfter(parse(l.timestamp), parse(r.timestamp)) ? -1 : 1)));
};

const cleanLogs = logs => {
  // Group by kind of event
  const relevant = (logs || []).filter(
    log => !['MessageDelivery@FamilyTrackingInvite'].includes(get(log, 'entries[0].type'))
  );
  const grouped = groupBy(relevant, _ => uniqKey(takeFirst(getNestedValue('entries', _))));

  // Resolve the LAST actual action that happened
  const deduped = flatMap(key => resolveLogEntries(grouped[key]))(Object.keys(grouped));

  // Resolve manual timestamps and sort by physical timestamp not the event occurrence
  return deduped
    .map(row => ({
      ...row,
      eventTimestamp: row.timestamp,
      timestamp: getNestedValue('manualTimestamp', takeFirst(row.entries)) || row.timestamp,
    }))
    .sort((l, r) => (isBefore(parse(l.timestamp), parse(r.timestamp)) ? -1 : 1));
};

export const PatientLogs = compose(
  withRouter,
  withState('timeEditModal', 'setTimeEditModal', null),
  mapProps(props => {
    const logs = props.data.log.filter(filterDuplicates).filter(entry => getNestedValue('entries.length', entry));
    const cleanedLogs = props.debug ? logs : cleanLogs(logs);

    return {
      ...props,
      data: {
        ...props.data,
        log: cleanedLogs.map(entry => ({
          ...entry,
          entries: entry.entries
            ? entry.entries.reduce((acc, item) => {
                if (item.type === 'Exited') {
                  return acc;
                } else if (item.type === 'ReadyForSurgeonNotify') {
                  return [...acc, { ...item, type: 'Physician Notified' }];
                } else if (item.type === 'ReadyForAnesthesiologistNotify') {
                  return [...acc, { ...item, type: 'Anesthesiologist Notified' }];
                } else if (item.type === 'Created') {
                  return [...acc, { ...item, type: 'Admitted' }];
                } else if (item.type === 'BecameReady') {
                  return [...acc, { ...item, type: 'Became Ready' }];
                } else if (item.type === 'BecameReadyInPACU') {
                  return [...acc, { ...item, type: 'Marked as Ready in PACU' }];
                } else if (item.type === 'BecameReadyInPREP') {
                  return [...acc, { ...item, type: 'Marked as Ready For OR' }];
                } else if (item.type === 'NotReadyInPREP') {
                  return [...acc, { ...item, type: 'Marked as not Ready For OR' }];
                } else if (item.type === 'FamilyReady') {
                  return [...acc, { ...item, type: 'Marked as Family Ready' }];
                } else if (item.type === 'NotReadyInPACU') {
                  return [...acc, { ...item, type: 'Marked as not Ready in PACU' }];
                } else if (item.type === 'ReadyForSurgeon') {
                  return [...acc, { ...item, type: 'Marked as Ready For Surgeon' }];
                } else if (item.type === 'NotReadyForSurgeon') {
                  return [...acc, { ...item, type: 'Marked as not Ready For Surgeon' }];
                } else if (item.type === 'ProcedureStatusSet') {
                  return [...acc, { ...item, type: 'Procedure' }];
                } else if (item.type === 'FamilyReadyPACU') {
                  return [...acc, { ...item, type: 'Family Ready For Visit' }];
                } else if (item.type === 'MessageDelivery@FamilyTrackingInvite') {
                  return [...acc, { ...item, type: 'Family Sms', delivery: JSON.parse(get(item, 'delivery', '{}')) }];
                }
                return [...acc, item];
              }, [])
            : [],
        })),
      },
    };
  })
)(PlainPatientLogs);

export const OptionalPatientLogs = branch(
  props => isArray(getNestedValue('data.log', props)) && props.data.log.length,
  renderComponent(PatientLogs),
  renderNothing
)();

export enum ProcedureStatus {
  PreOpCompleted = 'preopCompleted',
  InfoPending = 'infoPending',
  PreOpApptMade = 'PreOpApptMade',
  PreOpApptNeeded = 'PreOpApptNeeded',
  Cancelled = 'Cancelled',
  PhonePreOpNeeded = 'PhonePreOpNeeded',
  SpanishSpeaker = 'SpanishSpeaker',
  EkgOnFile = 'EkgOnFile',
  SeePreOpNotes = 'SeePreOpNotes',
}

export interface ProcedureEvent {
  id: number;
  type: string;
  timestamp: string;
  user?: {
    id: number;
    name: string;
  };
  channel: string;
  text?: string;
  status?: ProcedureStatus | string;
  anesthesiaReviewed?: boolean;
  content?: string;
  cancelReason?: string | null;
}

import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@material-ui/core';
import { CheckboxesAnswer, YesCheckboxAnswerWithChildren } from '../../types/Answer';
import { CheckboxesQuestion } from '../../types/Question';
import { isBoolean } from '../../se/utilities/check';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import isArray from 'lodash/isArray';
import { useSlideIn } from '../Form';

type CheckboxesInputProps = CheckboxesQuestion['props'] & {
  name?: string;
  answer?: CheckboxesAnswer;
  onChange?: (newAnswer: CheckboxesAnswer) => void;
};

const CheckboxesInput = ({
  label,
  checkboxes,
  omitNone,
  optional,
  horizontal,
  horizontalChild = false,
  answer,
  onChange,
}: CheckboxesInputProps) => {
  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  const [invalid, setInvalid] = useState(false);

  const completed = count(Object.entries(answer ?? {}), ([, value]) => (isArray(value) ? value[0] : value));

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer || !(completed > 0)) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, completed, slideIn]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changed = !omitNone
      ? { [event.target.name]: event.target.checked, none: false }
      : { [event.target.name]: event.target.checked };
    onChange?.(answer ? { ...answer, ...changed } : changed);
    setInvalid(false);
  };

  const clickNone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changed = { [event.target.name]: event.target.checked };
    onChange?.(changed);
    setInvalid(false);
  };

  return (
    <Box my={3} display="flex" textAlign="start">
      <FormControl component="fieldset" error={invalid}>
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <FormHelperText>Check all that apply</FormHelperText>
        <FormGroup>
          <Box display="flex" flexDirection={horizontal ? 'row' : 'column'} flexWrap="wrap">
            {checkboxes.map(checkbox => {
              const { name, label } = checkbox;
              const checkboxAnswer: boolean | YesCheckboxAnswerWithChildren | undefined = answer?.[name];
              const checkboxValue = isBoolean(checkboxAnswer)
                ? !!checkboxAnswer
                : !!(checkboxAnswer as YesCheckboxAnswerWithChildren)?.value;
              return !!checkbox?.child ? (
                horizontalChild ? (
                  <Box display="flex" flexDirection="row" key={name}>
                    <FormControlLabel
                      style={{ whiteSpace: horizontal ? 'nowrap' : 'normal' }}
                      control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                      label={label}
                    />
                    {checkboxValue && <Box mr={3}>{checkbox?.child}</Box>}
                  </Box>
                ) : (
                  <Box key={name}>
                    <FormControlLabel
                      style={{ whiteSpace: horizontal ? 'nowrap' : 'normal' }}
                      control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                      label={label}
                    />
                    {checkboxValue && (
                      <Box mx={2} mt={-2}>
                        {checkbox?.child}
                      </Box>
                    )}
                  </Box>
                )
              ) : (
                <FormControlLabel
                  key={name}
                  control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                  label={label}
                />
              );
            })}
            {!omitNone && (
              <FormControlLabel
                key="none"
                control={
                  <Checkbox color="primary" checked={!!answer?.['none'] || false} onChange={clickNone} name="none" />
                }
                label="None"
              />
            )}
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  );
};

const count = <T,>(arr: T[], predicate: (item: T) => boolean) =>
  arr.reduce((acc, item) => (predicate(item) ? acc + 1 : acc), 0);

export default CheckboxesInput;

import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, lifecycle, mapProps, withProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import merge from 'lodash/merge';
import update from 'lodash/update';
import { isDefinedAndNotNull, isFunction, isObject } from '../../../../se/utilities/check';
import LinkButton from '../../../../se/components/LinkButton';
import Button from '../../../../se/components/Button';
import waiting from '../../../../assets/images/waiting.svg';
import { PROCEDURE_STATUSES, STATUSES as PATIENT_STATUSES } from '../../../entities/patient/enums';
import { ROOM_TYPES, STATUSES as ROOM_STATUSES } from '../../../entities/room/enums';
import { getPatientProcedureDuration, transformORPatients } from '../../../entities/patient/transducers';
import { mapTabletOperationRoom } from '../../../entities/room/transducers';
import Mousetrap from 'mousetrap';
import { get, throttle } from 'lodash';
import { format } from 'date-fns';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  completeCleaning,
  markAsComplete,
  opreationRoomSubscription,
  patientReadyInOR,
  startClosing,
  startProcedure,
  startTimeout,
} from '../../../../graph/rooms';
import {
  exitProcedure,
  resetProcedure,
  setRoom as setRoomForPatient,
  startAdditionalTimeout,
} from '../../../../graph/patients';

import ProcedureProgress from './ProcedureProgress';
import CurrentStatusHeader from './CurrentStatusHeader';
import { ButtonLabel, Label, MainButton, TimeOut2Button, TimeOutButton } from './common';
import {
  eventTime,
  isOrTimeActive,
  optionalFormat,
  patientEnteredRoomTypeTime,
  patientEnteredTime,
  showValueIf,
} from './utils';
import CleaningClock from './CleaningClock';
import { H3 } from '../../../../se/components/typography';
import WallClock from './WallClock';
import ClientUpdater from '../../../ClientUpdater';
import TimeEditModal, { OR_TIMES, OR_TIMES_S } from '../../../inputs/timeEdit/TimeEditModal';
import EditTimeIcon from '../../../inputs/timeEdit/EditTimeIcon';
import HelperAction from './HelperAction';
import { CenteredSpinner } from '../../../../se/components/Spinner';
import responsive from '../../../../se/utilities/responsive';
import { soundAlert } from '../Monitor';

import prevSound from '../../../../assets/sound/discharge.mp3';
import nextSound from '../../../../assets/sound/orReady.mp3';
import AirFiltrationControl from '../widgets/AirFiltratrationControl';
import { AirFiltrationIndicatorTimer } from '../widgets/AirFiltrationIndicator';
import { Exit, Modal, ModalAction, Subtitle, Title } from './Modal';
import PatientSelection from '../nursingApp/PatientSelection';
import { getProcedures as getProcedureCards } from '../schedule/transform';
import { useSubscription } from '@apollo/client';
import { listSubscription as listProceduresSubscription } from '../../../../graph/procedures';
import Procedure from '../schedule/overview/Procedure';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import { getCurrentProcedureAndNextProcedures } from './procedures';
import { Alert } from '@material-ui/lab';
import { RingVolume } from '@material-ui/icons';

const MessageToSend = styled.p`
  position: relative;
  padding: 1em 1.5em;
  border-radius: .25em;
  margin-bottom: 4rem;
  font-size: 1.75rem;
  line-height: 1.5;
  background-color: ${props => props.theme.textColor.alpha(0.2).string()};
  max-width: 30em;
  text-align: left;

  :before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid ${props => props.theme.textColor.alpha(0.2).string()};
    border-top: 10px solid ${props => props.theme.textColor.alpha(0.2).string()};
    border-bottom: 10px solid transparent;
    right: 19px;
    bottom: -20px;
}
  }
`;

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  grid-template-columns: minmax(min-content, 0) auto;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';

  ${responsive.sm.andSmaller`
    display: flex;
    overflow: hidden;
  `};
`;

export const Idle = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  grid-column: 1 / span 2;
`;

export const Illustration = styled.img`
  max-width: 6rem;
`;

export const Supertitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1.5rem;
  opacity: 0.5;

  @media (min-width: 50rem) {
    font-size: 1.25rem;
  }
`;

const SubtleLink = styled(LinkButton)`
  color: inherit;
  opacity: 0.5;
  margin-bottom: 0.5rem;
`;

const RoomName = styled.span`
  margin-top: 0;
  text-align: right;
  opacity: 0.5;
  font-size: 0.875rem;
`;

const Actions = styled.div`
  display: flex;
  padding: 0 1.75rem;

  ${responsive.sm.andSmaller`
    flex-flow: column-reverse;
    padding: 0 .25rem;
  `};
`;

const ActionsAlt = styled(Actions)`
  grid-column: 1 / span 2;
  padding-bottom: 2.5rem;
`;

export const CustomModal = (message, yesPrimary = false, yesLabel, yesSecondaryLabel, noLabel) => ({
  handleConfirm,
  handleSecondaryConfirm,
  handleCancel,
  working,
  sendTo,
  messageToSend,
  errorMessage,
}) => (
  <Modal
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
    style={{ overflowY: 'unset' }}
  >
    <Title>Are you sure?</Title>
    <Subtitle>
      {message}{' '}
      {!!sendTo && (
        <span>
          <br />
          {sendTo}
        </span>
      )}
    </Subtitle>
    {!!messageToSend && <MessageToSend>{messageToSend}</MessageToSend>}
    <ModalAction>
      <Button xl primary={yesPrimary} onClick={handleConfirm} disabled={working}>
        <WithWorking working={working}>{yesLabel || 'Yes'}</WithWorking>
      </Button>
      {handleSecondaryConfirm && (
        <Button xl primary={yesPrimary} onClick={handleSecondaryConfirm} disabled={working}>
          <WithWorking working={working}>{yesSecondaryLabel || 'Yes'}</WithWorking>
        </Button>
      )}
      <Button xl primary={!yesPrimary} onClick={handleCancel} disabled={working}>
        {noLabel || 'No'}
      </Button>
    </ModalAction>
    {!!errorMessage && (
      <Alert severity="error" style={{ marginTop: 40 }}>
        {errorMessage}
      </Alert>
    )}
  </Modal>
);

const ExitModal = CustomModal('Current procedure progress will be lost. This action cannot be reversed.');
const ClosingModal = CustomModal(
  "This action will start procedure closing and send SMS to the patient's caretaker.",
  true
);

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.5rem;
  align-items: center;
  line-height: 1.25rem;

  > * {
    flex: 1;
  }

  ${responsive.sm.andSmaller`
    padding: 1rem 1.5rem;
  `};
`;

const SubtleActionWrapper = styled.div`
  background-color: transparent;
  flex: 1 0 calc(50% - 1.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 0 0.75rem 0;
  outline: none;
`;

const SubtleAction = styled(Exit)`
  display: ${props => (props.visible ? 'inline-block' : 'none')};
`;

const StatusWrapper = styled.div`
  margin: auto 0;
`;

const StatusWrapperAlt = styled(StatusWrapper)`
  position: relative;
  grid-column: 1 / span 2;
  margin: 0;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClockWrapper = styled(StatusWrapper)`
  grid-column: 1 / span 2;
  margin: 0;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
`;

const Text = styled.p`
  text-align: center;
  line-height: 1.4;
  opacity: 0.5;
  margin-bottom: 1rem;
`;

const useStyles = makeStyles(theme => ({
  dialogContent: {
    background: theme.palette.background.default,
    fontSize: '1.5em',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const WithWorking = ({ working, children, size }) => (working ? <CenteredSpinner size={size} /> : children);

const CurrentAction = ({
  nextProceduresInOperationRoom,
  action,
  status,
  patient,
  patientType,
  physician,
  procedureType,
  handleExitProcedureButtonClick,
  room,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [working, setWorking] = useState({ back: false, forward: false });
  const { SubAction } = action;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const withWorking = (fn, key) => async args => {
    if (fn) {
      setWorking({ [key]: true });
      await fn(args);
      setWorking({ [key]: false });
    }
  };

  return (
    <Fragment>
      {!action.isHeaderHidden ? (
        <CurrentStatusHeader
          physician={physician}
          room={room}
          patient={patient}
          patientType={patientType}
          procedureType={procedureType}
          handleExitProcedureButtonClick={handleExitProcedureButtonClick}
          action={action}
        />
      ) : null}

      <Box display="flex" flex={1} flexDirection="column" id="drawer-container">
        <StatusWrapper>
          <ProcedureProgress status={status} room={room} action={action} />
        </StatusWrapper>

        <Actions>
          {!action.isSubtleAction && (
            <Fragment>
              <TimeOutButton onClick={withWorking(action.prev, 'back')} disabled={!action.prev || working.back}>
                <WithWorking working={working.back} size={'4rem'}>
                  <Box fontSize={'6.5em'}>
                    <ChevronLeftIcon fontSize={'inherit'} />
                  </Box>
                </WithWorking>
                <ButtonLabel>{'Undo'}</ButtonLabel>
              </TimeOutButton>

              <MainButton onClick={withWorking(action.next, 'forward')} disabled={working.forward}>
                <WithWorking working={working.forward} size={'4rem'}>
                  <Box fontSize={'6.5em'}>
                    <ChevronRightIcon fontSize={'inherit'} />
                  </Box>
                </WithWorking>
                <ButtonLabel>{action.nextLabel}</ButtonLabel>
              </MainButton>
            </Fragment>
          )}

          {action.isSubtleAction && (
            <SubtleActionWrapper>
              <H3 style={{ opacity: '.5', marginTop: 0 }}>No further action needed</H3>
              <Text>
                This procedure will be automatically marked as complete <br /> as soon as the patient leaves the room.
              </Text>
              <Exit style={{ minHeight: '1em' }} onClick={withWorking(action.prev, 'back')} disabled={working.back}>
                <WithWorking working={working.back}>Undo</WithWorking>
              </Exit>
              <SubtleAction
                onClick={withWorking(action.next, 'forward')}
                visible={action.isSubtleAction}
                disabled={working.forward}
              >
                <WithWorking working={working.forward}>{action.nextLabel}</WithWorking>
              </SubtleAction>
            </SubtleActionWrapper>
          )}
        </Actions>

        <Footer>
          {nextProceduresInOperationRoom && nextProceduresInOperationRoom.length > 0 ? (
            <Fragment>
              <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Next Procedures
              </Button>
              <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar color="inherit" className={classes.appBar}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                      Next Procedures
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                      Back to the current procedure
                    </Button>
                  </Toolbar>
                </AppBar>
                <Box p={4} className={classes.dialogContent}>
                  {nextProceduresInOperationRoom.map((p, index) => (
                    <Box mb={2} key={index}>
                      <Procedure
                        onClick={undefined}
                        procedure={p}
                        isUnassigned={false}
                        date={new Date()}
                        isPatientIconVisible={true}
                        highContrast={true}
                      />
                    </Box>
                  ))}
                </Box>
              </Dialog>
            </Fragment>
          ) : (
            <div />
          )}
          <RoomName style={{ textAlign: 'center' }}>{room.name}</RoomName>
          {SubAction ? <SubAction /> : <span style={{ textAlign: 'right' }}>–</span>}
        </Footer>
      </Box>
    </Fragment>
  );
};

const TimeOutAction = ({ isTimeOutActive, mutate, patient }) => {
  const [working, setWorking] = useState(false);

  const handleButtonClick = async () => {
    try {
      if (isFunction(mutate) && isObject(patient)) {
        setWorking(true);
        await mutate({ variables: { id: patient.id } });
      }
    } catch (error) {
      console.error(error);
    }
    setWorking(false);
  };

  return (
    <TimeOut2Button onClick={handleButtonClick} disabled={working || isTimeOutActive}>
      <WithWorking working={working}> {isTimeOutActive ? 'Time Out 2 Started' : 'Start Time Out 2'} </WithWorking>
    </TimeOut2Button>
  );
};

const Times = styled.div`
  grid-column: 1 / 1;
  display: flex;
  flex-flow: column;
  border-right: 1px solid rgba(255, 255, 255, 0.25);

  > * + * {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
  }

  ${responsive.sm.andSmaller`
    display: none;
  `};
`;

const Controls = styled.div`
  grid-column: 2 / 2;
  display: flex;
  flex-flow: column;
  height: 100vh;
  flex: 1;

  ${responsive.sm.andSmaller`
    height: 100%;
  `};
`;

const Entry = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 2.5rem;
  padding-right: 1rem;
`;

const Value = styled.div`
  font-size: 4.5rem;
  font-weight: 600;
  font-family: monospace;
  line-height: 1.2;

  > span {
    font-size: 1.2rem;
  }
`;

const LabelLarge = styled(Label)`
  font-size: 1.5rem;
  margin-bottom: 0;
`;

const TimeHeader = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 2rem;
`;

const Tablet = ({
  modal,
  setModal,
  closingModal,
  setClosingModal,
  patientsModal,
  setPatientsModal,
  room,
  roomId,
  setRoomForPatient,
  resetProcedure,
  startTimeout,
  patientReady,
  startProcedure,
  startClosing,
  completeCleaning,
  startAdditionalTimeout,
  exitProcedure,
  setTimeEditModal,
  timeEditModal,
}) => {
  const proceduresQuery = useSubscription(listProceduresSubscription, {
    variables: {
      date: format(new Date(), 'YYYY-MM-DD'),
      operationRoomId: get(room, 'id', ''),
      isCanceled: false,
    },
  });
  const [currentProcedure, nextProceduresInOperationRoom] = getCurrentProcedureAndNextProcedures(
    getProcedureCards(proceduresQuery) || []
  );

  const [working, setWorking] = useState(false);

  const withWorking = fn => async args => {
    if (fn) {
      setWorking(true);
      await fn(args);
      setWorking(false);
    }
  };

  const { patient = {}, status } = room;
  const { physician = {}, procedureType = {}, type: patientType } = patient;
  const showModal = isShown => () => setModal(isShown);
  const showClosingModal = isShown => () => setClosingModal(isShown);

  const showPatientsModal = isShown => () => setPatientsModal(isShown);
  const createUpdatePatientStatus = id => mutate => () => mutate({ variables: { id, patientId: id } });
  const updatePatientStatus = createUpdatePatientStatus(patient.id);
  const movePatientToOR = roomId => patientId => () => setRoomForPatient({ variables: { id: patientId, roomId } });
  const setPatientInOr = () => resetProcedure({ variables: { id: patient.id } });
  const updateRoomStatus = id => completeCleaning({ variables: { id } });

  const call = fn => (typeof fn === 'function' ? fn() : undefined);
  const handlerFor = direction =>
    throttle(() => {
      call(get(actions[status], direction));
    }, 1000);

  // forward button handler
  useEffect(() => {
    Mousetrap.bind('l', handlerFor('next'));
    return () => Mousetrap.unbind('l');
  });

  // back button handler
  useEffect(() => {
    Mousetrap.bind('k', handlerFor('prev'));
    return () => Mousetrap.unbind('k');
  });

  const manuallyExitProcedure = id => async () => {
    try {
      await exitProcedure({ variables: { id } });
    } catch (error) {
      console.error(error);
    } finally {
      showModal(false)();
    }
  };

  const startProcedureClosing = async () => {
    try {
      await updatePatientStatus(startClosing)();
    } catch (error) {
      console.error(error);
    } finally {
      showClosingModal(false)();
    }
  };

  const withAlertSound = sound => fn => async () => {
    try {
      // we need to play it right away, as Safari prevents autoplay
      soundAlert(sound, true);
      await fn();
    } catch (e) {
      console.warn('Operation failed. Not playing sound notification');
    }
  };

  const withAlertSoundPrev = withAlertSound(prevSound);
  const withAlertSoundNext = withAlertSound(nextSound);

  /**
   * Here we treat TIME_OUT_2 status as ongoing,
   * because it is not relevant to the physician.
   * It's purpose is to record second time out.
   */
  const actions = {
    [PATIENT_STATUSES.IN_OR]: {
      nextLabel: 'Start Time Out',
      next: withAlertSoundNext(updatePatientStatus(startTimeout)),
      notificationAction: {
        action: updatePatientStatus(patientReady),
        label: 'Notify Physician',
        icon: <RingVolume />,
      },
    },
    [PATIENT_STATUSES.READY]: {
      next: withAlertSoundNext(updatePatientStatus(startTimeout)),
      nextLabel: 'Start Time Out',
      notificationAction: {},
    },
    [PATIENT_STATUSES.TIME_OUT]: {
      next: withAlertSoundNext(updatePatientStatus(startProcedure)),
      prev: withAlertSoundPrev(updatePatientStatus(setPatientInOr)),
      nextLabel: 'Start Procedure',
    },
    [PATIENT_STATUSES.ONGOING]: {
      next: withAlertSoundNext(updatePatientStatus(startClosing)),
      nextLabel: 'Start Closing',
      prev: withAlertSoundPrev(updatePatientStatus(startTimeout)),
      SubAction: withProps({
        isTimeOutActive: false,
        mutate: startAdditionalTimeout,
        patient,
      })(TimeOutAction),
    },
    [PATIENT_STATUSES.TIME_OUT_2]: {
      next: withAlertSoundNext(updatePatientStatus(startClosing)),
      nextLabel: 'Start Closing',
      prev: withAlertSoundPrev(updatePatientStatus(startTimeout)),
      SubAction: withProps({
        isTimeOutActive: true,
        mutate: startAdditionalTimeout,
        patient,
      })(TimeOutAction),
    },
    [PATIENT_STATUSES.CLOSING]: {
      next: () => {},
      fallbackButton: true,
      nextLabel: '',
      isSubtleAction: true,
      prev: withAlertSoundPrev(updatePatientStatus(startProcedure)),
    },
    [ROOM_STATUSES.CLEANING]: {
      next: () => updateRoomStatus(roomId),
      nextLabel: 'Cleaning Completed',
      isHeaderHidden: true,
      showCleaningClock: true,
    },
  };

  const classes = useStyles();
  const enteredAtTime = patientEnteredTime(patient, room);
  const timeoutTime = eventTime(PROCEDURE_STATUSES.TIME_OUT, patient);
  const procedureStartTime = eventTime(PROCEDURE_STATUSES.ONGOING, patient);
  const closingTime = eventTime(PROCEDURE_STATUSES.CLOSING, patient);

  const enteredPrepAt = patientEnteredRoomTypeTime(patient, ROOM_TYPES.PRE_OP);

  const openTimeEditModal = focusedInput => () => setTimeEditModal(focusedInput);
  const closeTimeEditModal = () => setTimeEditModal(null);

  return (
    <Fragment>
      <Root>
        {patientsModal ? (
          <PatientSelection
            handleCancel={showPatientsModal(false)}
            handlePatientClick={movePatientToOR(roomId)}
            style={{ overflow: 'auto' }}
            withRoom={true}
          />
        ) : !status ? (
          <Idle>
            <AirFiltrationIndicatorTimer roomId={roomId} style={{ position: 'absolute', left: '3rem', top: '2rem' }} />
            <ActionWrapper>
              <HelperAction />
            </ActionWrapper>
            <Illustration src={waiting} />
            <Supertitle>{room.name}</Supertitle>
            <Title style={{ margin: 0 }}>Waiting for patient</Title>
            <Subtitle style={{ marginBottom: '1em' }}>
              Once the patient enters the room, this screen will automatically show the controls.
            </Subtitle>

            {currentProcedure && (
              <Fragment>
                <Divider />

                <Box display="flex" flexDirection="column" justifyContent="center" p={2} width="100%">
                  <Fragment>
                    <Box mb={2}>
                      <Typography align="center" variant="h6" gutterBottom>
                        Upcoming Procedure
                      </Typography>
                    </Box>
                    <Box display="block" className={classes.dialogContent} width="100%">
                      <Procedure
                        onClick={undefined}
                        procedure={currentProcedure}
                        isUnassigned={false}
                        date={new Date()}
                        isPatientIconVisible={true}
                      />
                    </Box>
                  </Fragment>
                </Box>
              </Fragment>
            )}

            <SubtleLink onClick={showPatientsModal(true)}>Patient not Detected? Tap Here.</SubtleLink>
          </Idle>
        ) : modal ? (
          <ExitModal
            handleConfirm={withWorking(manuallyExitProcedure(patient.id))}
            handleCancel={showModal(false)}
            working={working}
          />
        ) : closingModal ? (
          <ClosingModal
            handleConfirm={startProcedureClosing}
            handleCancel={showClosingModal(false)}
            working={working}
          />
        ) : isObject(actions[status]) ? (
          <Fragment>
            {actions[status].isHeaderHidden ? (
              <Box width="100vw" display="flex" flexDirection="column" justifyContent="center">
                <StatusWrapperAlt>
                  <AirFiltrationIndicatorTimer
                    roomId={roomId}
                    style={{ position: 'absolute', left: '3rem', top: '2rem' }}
                  />
                  <ProcedureProgress status={status} room={room} action={actions[status]} noicon />
                </StatusWrapperAlt>

                {actions[status].showCleaningClock && (
                  <ClockWrapper>
                    <CleaningClock />
                  </ClockWrapper>
                )}

                <ActionsAlt>
                  <MainButton onClick={actions[status].next}>
                    <Box fontSize={'6.5em'}>
                      <ChevronRightIcon fontSize={'inherit'} />
                    </Box>
                    <ButtonLabel>{actions[status].nextLabel}</ButtonLabel>
                  </MainButton>
                </ActionsAlt>

                {currentProcedure && (
                  <Fragment>
                    <Divider />

                    <Box width="100%" p={2} flexDirection="flex" direction="column">
                      <Box mb={2}>
                        <Typography align="center" variant="h6" gutterBottom>
                          Upcoming Procedure
                        </Typography>
                      </Box>
                      <Box display="block" className={classes.dialogContent}>
                        <Procedure
                          onClick={undefined}
                          procedure={currentProcedure}
                          isUnassigned={false}
                          date={new Date()}
                          isPatientIconVisible={true}
                        />
                      </Box>
                    </Box>
                  </Fragment>
                )}
              </Box>
            ) : (
              <Fragment>
                <Times>
                  <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(25vh - 1px)' }}>
                    <AirFiltrationControl roomId={roomId} />
                    <Entry
                      style={{ background: 'rgba(255,255,255,.15)', flex: '1 0 auto', minHeight: 'calc(20vh - 1px)' }}
                    >
                      <LabelLarge>Time</LabelLarge>
                      <WallClock />
                    </Entry>
                  </div>
                  <Entry>
                    <TimeHeader>
                      <LabelLarge>Entered at</LabelLarge>
                      {isOrTimeActive(status, OR_TIMES_S.ENTERED_AT) && (
                        <EditTimeIcon l onClick={openTimeEditModal(OR_TIMES.ENTERED_AT)} />
                      )}
                    </TimeHeader>
                    <Value>
                      {showValueIf(
                        optionalFormat(enteredAtTime, 'HH:mm'),
                        isOrTimeActive(status, OR_TIMES_S.ENTERED_AT)
                      )}
                    </Value>
                  </Entry>
                  <Entry>
                    <TimeHeader>
                      <LabelLarge>Time Out</LabelLarge>
                      {isOrTimeActive(status, OR_TIMES_S.TIMEOUT_START) && (
                        <EditTimeIcon l onClick={openTimeEditModal(OR_TIMES.TIMEOUT_START)} />
                      )}
                    </TimeHeader>
                    <Value>
                      {showValueIf(
                        optionalFormat(timeoutTime, 'HH:mm'),
                        isOrTimeActive(status, OR_TIMES_S.TIMEOUT_START)
                      )}
                    </Value>
                  </Entry>
                  <Entry right>
                    <TimeHeader>
                      <LabelLarge>Procedure Start</LabelLarge>
                      {isOrTimeActive(status, OR_TIMES_S.PROCEDURE_START) && (
                        <EditTimeIcon l onClick={openTimeEditModal(OR_TIMES.PROCEDURE_START)} />
                      )}
                    </TimeHeader>
                    <Value>
                      {showValueIf(
                        optionalFormat(procedureStartTime, 'HH:mm'),
                        isOrTimeActive(status, OR_TIMES_S.PROCEDURE_START)
                      )}
                    </Value>
                  </Entry>
                  <Entry right>
                    <TimeHeader>
                      <LabelLarge>Closing Start</LabelLarge>
                      {isOrTimeActive(status, OR_TIMES_S.CLOSING_START) && (
                        <EditTimeIcon l onClick={openTimeEditModal(OR_TIMES.CLOSING_START)} />
                      )}
                    </TimeHeader>
                    <Value>
                      {showValueIf(
                        optionalFormat(closingTime, 'HH:mm'),
                        isOrTimeActive(status, OR_TIMES_S.CLOSING_START)
                      )}
                    </Value>
                  </Entry>
                </Times>
                <Controls>
                  <CurrentAction
                    action={actions[status]}
                    physician={physician}
                    nextProceduresInOperationRoom={nextProceduresInOperationRoom}
                    patient={patient}
                    patientType={patientType}
                    procedureType={procedureType}
                    handleExitProcedureButtonClick={showModal(true)}
                    status={status}
                    room={room}
                  />
                </Controls>
                <TimeEditModal
                  onClose={closeTimeEditModal}
                  patientId={patient.id}
                  roomId={room.id}
                  enteredAt={isOrTimeActive(status, OR_TIMES_S.ENTERED_AT) ? enteredAtTime : undefined}
                  timeoutStart={isOrTimeActive(status, OR_TIMES_S.TIMEOUT_START) ? timeoutTime : undefined}
                  procedureStart={isOrTimeActive(status, OR_TIMES_S.PROCEDURE_START) ? procedureStartTime : undefined}
                  closingStart={isOrTimeActive(status, OR_TIMES_S.CLOSING_START) ? closingTime : undefined}
                  opened={!!timeEditModal}
                  focused={timeEditModal}
                  enteredPrepAt={enteredPrepAt}
                  status={status}
                />
              </Fragment>
            )}
          </Fragment>
        ) : null}
      </Root>
      {!patientsModal && !status && <ClientUpdater />}
    </Fragment>
  );
};

Tablet.defaultProps = {
  room: {},
};

Tablet.propTypes = {
  room: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  mapProps(props => ({
    ...props,
    roomId: parseInt(props.match.params['operationRoomId'], 10),
  })),
  graphql(setRoomForPatient, { name: 'setRoomForPatient' }),
  graphql(resetProcedure, { name: 'resetProcedure' }),
  graphql(markAsComplete, { name: 'markAsComplete' }),
  graphql(startTimeout, { name: 'startTimeout' }),
  graphql(patientReadyInOR, { name: 'patientReady' }),
  graphql(startAdditionalTimeout, { name: 'startAdditionalTimeout' }),
  graphql(startProcedure, { name: 'startProcedure' }),
  graphql(startClosing, { name: 'startClosing' }),
  graphql(completeCleaning, { name: 'completeCleaning' }),
  graphql(exitProcedure, { name: 'exitProcedure' }),
  graphql(opreationRoomSubscription, {
    options: ({ roomId }) => ({ variables: { roomId } }),
  }),
  mapProps(({ data, match, ...rest }) => ({
    room: isDefinedAndNotNull(data) ? data['tabletApp'] || {} : {},
    error: data.error,
    ...rest,
  })),
  mapProps(({ room, ...rest }) => {
    const transformedRoom = transformORPatients(room);

    return {
      ...rest,
      room: update(transformedRoom, 'patient', patient =>
        merge(patient, getPatientProcedureDuration(patient, transformedRoom))
      ),
    };
  }),
  mapProps(({ room, ...rest }) => ({
    ...rest,
    room: mapTabletOperationRoom(room),
  })),
  // If patient is not defined then we must set
  // status to undefined for proper rendering
  mapProps(({ room, ...rest }) => ({
    ...rest,
    room: {
      ...room,
      status: !room.patient ? undefined : room.status,
    },
  })),
  withState('modal', 'setModal', false),
  withState('closingModal', 'setClosingModal', false),
  withState('timeEditModal', 'setTimeEditModal', null),
  withState('patientsModal', 'setPatientsModal', false),
  lifecycle({
    componentDidMount() {
      const thisComponent = this;
      const synthesizeVoice = message => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.callback) {
          window.webkit.messageHandlers.callback.postMessage(message);
        } else {
          console.log('Synthesizing voice:', message);
        }
      };

      window.orKit = {
        async startTimeout() {
          const { room, startTimeout, startAdditionalTimeout } = thisComponent.props;
          const { patient = {}, status } = room;

          if (patient.id) {
            if (status === PATIENT_STATUSES.IN_OR) {
              try {
                await startTimeout({
                  variables: {
                    id: patient.id,
                    patientId: patient.id,
                  },
                });
                synthesizeVoice('Timeout started.');
              } catch (e) {
                console.error(e);
                synthesizeVoice('Unable to start timeout.');
              }
            } else if (status === PATIENT_STATUSES.ONGOING) {
              try {
                await startAdditionalTimeout({
                  variables: {
                    id: patient.id,
                    patientId: patient.id,
                  },
                });
                synthesizeVoice('Timeout started.');
              } catch (e) {
                console.error(e);
                synthesizeVoice('Unable to start timeout.');
              }
            } else {
              synthesizeVoice('Unable to start timeout.');
            }
          } else {
            synthesizeVoice('Unable to start timeout.');
          }
        },
        async startProcedure() {
          const { room, startProcedure } = thisComponent.props;
          const { patient = {}, status } = room;

          if (patient.id && status === PATIENT_STATUSES.TIME_OUT) {
            try {
              await startProcedure({
                variables: {
                  id: patient.id,
                  patientId: patient.id,
                },
              });
              synthesizeVoice('Procedure started.');
            } catch (e) {
              console.error(e);
              synthesizeVoice('Unable to start procedure.');
            }
          } else {
            synthesizeVoice('Unable to start procedure.');
          }
        },
        async startClosing() {
          const { room, startClosing } = thisComponent.props;
          const { patient = {}, status } = room;

          if (patient.id && status === PATIENT_STATUSES.ONGOING) {
            try {
              await startClosing({
                variables: {
                  id: patient.id,
                  patientId: patient.id,
                },
              });
              synthesizeVoice('Closing started.');
            } catch (e) {
              console.error(e);
              synthesizeVoice('Unable to start closing.');
            }
          } else {
            synthesizeVoice('Unable to start closing.');
          }
        },
        async markAsComplete() {
          const { room, markAsComplete } = thisComponent.props;
          const { patient = {}, status } = room;

          if (patient.id && status === PATIENT_STATUSES.CLOSING) {
            try {
              await markAsComplete({
                variables: {
                  id: patient.id,
                  patientId: patient.id,
                },
              });
              synthesizeVoice('Marked as complete.');
            } catch (e) {
              console.error(e);
              synthesizeVoice('Unable to mark as complete.');
            }
          } else {
            synthesizeVoice('Unable to mark as complete.');
          }
        },
        async completeCleaning() {
          const { room, completeCleaning } = thisComponent.props;
          const { status } = room;

          if (status === ROOM_STATUSES.CLEANING) {
            try {
              await completeCleaning({
                variables: {
                  id: room.id,
                },
              });
              synthesizeVoice('Cleaning completed.');
            } catch (e) {
              console.error(e);
              synthesizeVoice('Unable to complete cleaning.');
            }
          } else {
            synthesizeVoice('Unable to complete cleaning.');
          }
        },
        async exitProcedure() {
          const { room, exitProcedure } = thisComponent.props;
          const { patient = {}, status } = room;

          if (patient.id && status === PATIENT_STATUSES.IN_OR) {
            try {
              await exitProcedure({
                variables: {
                  id: patient.id || 1,
                  patientId: patient.id || 1,
                },
              });
              synthesizeVoice('Procedure exited.');
            } catch (e) {
              console.error(e);
              synthesizeVoice('Unable to exit procedure.');
            }
          } else {
            synthesizeVoice('Unable to exit procedure.');
          }
        },
      };
    },
    componentWillUnmount() {
      delete window.orKit;
    },
  })
)(Tablet);

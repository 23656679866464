import get from 'lodash/get';
import { differenceInYears, format, parse } from 'date-fns';
import { capitalize, trim } from 'lodash/string';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import isObject from 'lodash/isObject';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import { defaultTo } from 'lodash';
import { fullNameToDrLastName } from '../../../pages/kiosk/schedule/shared/procedureUtils';

export const pad = slots => array =>
  array.length < slots ? [...array, ...[...new Array(slots - array.length)].map(_ => '')] : array;

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

export const openPdf = (pdf, title) => {
  if (isTouchDevice()) {
    pdf.open();
  } else {
    pdf.getDataUrl(dataUrl => {
      const url_with_name = dataUrl.replace('data:application/pdf;', `data:application/pdf;name=${title};`);

      const html =
        '<html>' +
        '<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>' +
        '<body>' +
        '<iframe type="application/pdf" src="' +
        url_with_name +
        '"></iframe>' +
        '</body></html>';

      const tab = window.open('about:blank', '_blank');
      if (tab) {
        tab.opener = null;
        tab.document.write(html);
        tab.document.close();
      }
    });
  }
};

const patientAge = procedure => {
  const dob = get(procedure, 'patientDateOfBirth', ' ');
  const age = differenceInYears(new Date(), parse(dob));
  return dob && age && age > 0 ? age : get(procedure, 'patientAge', '-') || '-';
};

const preopSignature = ({ preOpBy, preOpAt }) => ({
  fontSize: 8,
  marginTop: 4,
  text: [
    'Pre-Op Performed By ',
    { text: defaultTo(preOpBy, '_________________'), bold: true },
    ', Date ',
    { text: defaultTo(preOpAt, '__________________'), bold: true },
  ],
});

const extProviderString = scheduleProvider => {
  if (scheduleProvider) {
    return `${scheduleProvider} ID`;
  } else {
    return 'HST ID';
  }
};

export const makeHeader = ({
  procedure,
  hospitalName,
  disclamer,
  marginBottom,
  preOpInfo,
  scheduleProvider,
  location,
}) => ({
  stack: [
    {
      fontSize: 8,
      columns: [
        {
          stack: [
            { text: hospitalName, opacity: 0.8, fontSize: 14 },
            { text: get(procedure, 'procedureType', ''), opacity: 0.8, fontSize: 8 },
          ],
          width: '50%',
        },
        {
          stack: [
            {
              columns: [
                {
                  columns: [
                    {
                      stack: [
                        { text: extProviderString(scheduleProvider) },
                        { text: 'Surgery Date:' },
                        { text: 'Physician:' },
                      ],
                      style: { opacity: 0.8 },
                      marginRight: 5,
                      width: 'auto',
                    },
                    [
                      { text: `${get(procedure, 'patientHstId', '-') || '-'}` },
                      { text: format(get(procedure, 'startTime'), 'MM/DD/YYYY') },
                      {
                        text: fullNameToDrLastName(
                          defaultTo(get(procedure, 'physicianNameHST', ''), '')
                        ).toLocaleUpperCase(),
                      },
                    ],
                  ],
                },
                {
                  columns: [
                    {
                      stack: [
                        { text: 'Patient:' },
                        { text: 'DOB:' },
                        { text: 'Age/Sex:', color: patientAge(procedure) > 70 ? 'red' : 'inherit' },
                      ],
                      style: { opacity: 0.8 },
                      marginRight: 5,
                      width: 'auto',
                    },
                    [
                      { text: get(procedure, 'patientName', '-') },
                      { text: get(procedure, 'patientDateOfBirth', ' ') || '-' },
                      {
                        text: `${patientAge(procedure)} / ${get(procedure, 'patientSex', '-') || '-'}`,
                        color: patientAge(procedure) > 70 ? 'red' : 'inherit',
                      },
                    ],
                  ],
                },
              ],
            },
            preOpInfo ? preopSignature(preOpInfo) : undefined,
          ],
        },
      ],
    },
    disclamer,
  ],
  marginBottom: marginBottom || 20,
});

export const cbEmptyIcon = { text: 'A', style: 'icon' };
export const cbFullIcon = { text: 'B', style: 'icon', color: '#FF0000' };
export const cbCheckIcon = { text: '\uE800', style: 'checkmark', color: '#1BD588' };
export const iconText = (icon, text) => ({ text: [icon, ' ', text] });

export const defaultFooter = left => (currentPage, pageCount) => ({
  columns: [
    [{ width: 'auto', text: left || '' }],
    pageCount > 1
      ? [{ text: `- ${currentPage.toString()} -`, alignment: 'center', width: '*', fontSize: 9 }]
      : undefined,
    [{ width: '48%', text: 'Powered by Ospitek', alignment: 'right', marginRight: 40, opacity: 0.6, fontSize: 7 }],
  ],
  marginLeft: 40,
  marginTop: 5,
  marginBottom: 5,
  opacity: 0.8,
  fontSize: 8,
});

export const elementParserFactory = fields => {
  const parseElement = (element, ctx) => {
    if (typeof element !== 'object') {
      return element;
    }

    if (element === null) {
      return element;
    }

    if (element instanceof Array) {
      return element.map((el, i) => parseElement(el, ctx));
    }

    let { type, props, weight } = element;

    if (props.children) {
      props = { ...props, children: parseElement(props.children, ctx) };
    }

    return (fields[type] || (() => null))({ ...props, type, ...ctx, weight });
  };

  return parseElement;
};

export const capitalizeFirst = value =>
  typeof value === 'string' && value.length > 0 ? [capitalize(value[0]), value.slice(1)].join('') : value;

const formatQuestion = value => {
  const verbatim = value
    .replace(
      'Had surgery on your airway or have been told you have a difficult airway',
      'Surgery on airway or a difficult airway?'
    ) // ????
    .replace('Do you have any residual difficulties?', 'Residual difficulties') // ????
    .replace('Do you have any of the following implanted devices?', 'Implanted devices?') // ????
    .replace('Have you traveled outside the country in the last 30 days?', 'Traveled to') // ????
    .replace('Are you having difficulties to walk a block?', 'Difficulty walking a block') // ????
    .replace('Are you a female and under the age of 60', 'Female under 60') // ????
    .replace('Had heart attack', 'Heart attack') // ?!
    .replaceAll('?', '')
    .replaceAll('Has it been a', '')
    .replaceAll('Do you', '')
    .replaceAll('Are you', '')
    .replaceAll('Have you had', '')
    .replaceAll('Have you', '')
    .replaceAll('Have a', '')
    .replaceAll('Were you', '')
    .replaceAll('Is it', '')
    .replace(/\s*\(.*?\)\s*/g, ' ');

  return trim(verbatim)
    .replace(/^Had/gi, '')
    .replace(/^Having/gi, '')
    .replace(/^Have/gi, '')
    .replace(/^Ever/gi, '')
    .replace('Premature atrial contractions', 'PACs')
    .replace('Premature ventricular contractions', 'PVCs')
    .replace('Use drugs/substances not mentioned above', 'Use other drugs')
    .replace('Severe post-operative nausea and vomiting', 'PostOp nausea and vomiting')
    .replace('Surgery on airway or a difficult airway', 'Surgery on / difficult airway')
    .replace('Benign prostatic hyperplasia', 'Benign prostatic hyperplasia (BPH)')
    .replace('Deep Brain Stimulator', 'Deep Brain Stimulator (DPS)')
    .replace('Restless led syndrome', 'Restless led syndrome (RLS)')
    .replace('Atrial fibrillation', 'A fib');
};

export const removeQuestion = value =>
  typeof value === 'string' ? capitalizeFirst(trim(formatQuestion(value))) : value;

export const emphasize = (el, head, emph = true) =>
  emph ? (el && isObject(el) && !isArray(el) ? { ...el, opacity: head ? 1 : 0.6 } : el) : el;

export const separate = (elements, root, emph, head) =>
  /* prettier-ignore */
  isArray(elements)
    ? flatMap(
    compact(elements), (el, i) => flatten([emphasize(wrapInBrackets(el), (i === 0 && root) || head, emph), i === 0 ? ' ' : ', '])
    ).slice(0, -1) : emphasize(elements, root, emph);

export const wrapInBrackets = (listItem, emph = true, head, color, extra = {}) => {
  if (isArray(listItem) && listItem.length > 0) {
    const clean = compact(listItem);
    if (clean.length > 1) {
      return {
        text: [
          clean[0],
          { text: ' (', opacity: head ? 1 : 0.6, color: color },
          ...separate(clean.slice(1), false, emph, head),
          { text: ')', opacity: head ? 1 : 0.6, color: color },
        ],
        ...extra,
      };
    } else {
      return { text: [clean[0]], ...extra };
    }
  } else {
    return listItem;
  }
};

import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../Schedule';

const TimeWrapper = styled.div`
  font-weight: 700;
  font-size: 1.333333em;
  background-color: ${props => (props.hasErrors ? 'rgba(255, 137, 0, 0.25)' : 'rgba(255, 255, 255, 0.1)')};
  margin-right: 1px;
  padding: 0.2em 0.25em;
  line-height: ${props => (props.hasErrors ? '.65' : 'unset')};
`;

export const ErrorLabel = styled.span`
  color: #ff8900;
  font-size: 0.75rem;
`;

const StartTime = styled.div``;

const EndTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75em;
  font-weight: 400;
  color: #abe3ff;
  ${props =>
    props.duration
      ? css`
          opacity: 0.5;
          font-size: 0.75em;
          margin-top: 0.25em;
        `
      : css`
          opacity: 1;
          font-size: 1em;
          margin-top: 0;
        `}

  ${props =>
    props.delay
      ? css`
          margin: 0.125em 0;
        `
      : null}
`;

const PredictedEnd = styled.div`
  display: flex;
  flex-direction: column;
`;

const Time = ({ startTime, endTime, duration, late }) => (
  <TimeWrapper hasErrors={!startTime}>
    {startTime ? (
      <Fragment>
        <StartTime>{startTime}</StartTime>
        <EndTime delay={late} duration={duration}>
          {duration ? (
            <PredictedEnd>{endTime}</PredictedEnd>
          ) : (
            <Icon style={{ color: '#FF8900', marginTop: '.25em', opacity: 1 }}>error_outline</Icon>
          )}
        </EndTime>
      </Fragment>
    ) : (
      <Fragment>
        <Icon style={{ color: '#FF8900' }}>error_outline</Icon>
        <ErrorLabel>Time missing</ErrorLabel>
      </Fragment>
    )}
  </TimeWrapper>
);

export default Time;

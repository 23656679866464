import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { compose } from 'recompose';
import styled, { css } from 'styled-components';
import { addDays, addMinutes, format, isAfter, parse, startOfDay } from 'date-fns';
import { useQuery, useSubscription } from '@apollo/client';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import eq from 'lodash/fp/eq';
import map from 'lodash/fp/map';
import every from 'lodash/fp/every';
import size from 'lodash/fp/size';
import isEmpty from 'lodash/fp/isEmpty';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import CheckboxInput from '../../inputs/CheckboxInput';
import { roomsListWithStaffShiftSubscription } from '../../../graph/rooms';
import { listWithStaffShiftSubscription as listProceduresSubscription } from '../../../graph/procedures';
import HospitalInfo, { withScope } from '../../HospitalInfo';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../unpackSessionObject';
import RouterLink from '../../../se/components/RouterLink';
import ScheduleFooter from './schedule/ScheduleFooter';
import ToastBar from '../../core/ToastBar';

import Filters from './schedule/Filters';
import ClientUpdater, { ClientUpdaterPresetTvSchedule } from '../../ClientUpdater';
import { ScheduleUploadButton } from '../../inputs/upload/UploadButton';
import Overview from './schedule/overview/Overview';
import ORNavigation from './schedule/ORNavigation';
import ProcedureEditor from './schedule/edit/ProcedureEditor';
import Timeline from './schedule/timeline/Timeline';
import { Helmet } from 'react-helmet';
import LinkButton from '../../../se/components/LinkButton';
import { getOperationRooms, getProcedures, transformScheduleOperationRooms } from './schedule/transform';
import { Formats } from '../../inputs/upload/formats';
import { useTheme } from '@material-ui/core';
import { getScheduleAccessAll } from '../../../graph/staff';
import { getNestedValue } from '../../../se/utilities/data/object';
import { getScheduleAccessAllAnesthesiologist } from '../../../graph/anesthesiologists';
import { MultipleStaffShiftSelectionContextProvider } from './schedule/staff/MultipleStaffShiftSelection';
import MultipleStaffShiftSelectionBox from './schedule/staff/MultipleStaffShiftSelectionBox';
import Box from '@material-ui/core/Box';
import { getScheduleAccessAllPhysician } from '../../../graph/physicians';
import PDFScheduleGenerator from './schedule/PDFScheduleGenerator';
import { getRoomWithStaff, scheduleStaff } from './schedule/pdfGeneratorUtils';
import SendNotificationDropdown from './schedule/SendNotificationDropdown';
import { ArrowBack } from '@material-ui/icons';
import { Route } from 'react-router-dom';
import { ScheduleProcedureForm } from './schedule/procedure/ScheduleProcedureForm';
import EmptySchedule from './EmptySchedule';
import ToggleOverlay from './schedule/ToggleOverlay';
import { getScheduleAccessAllVendor } from '../../../graph/vendors';

const Page = styled.div`
  flex: 1;
  background-color: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  display: flex;
  flex-direction: column;
  font-size: 1.075rem;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  // Adjusts the UI for HDTV and down
  @media (max-width: 1920px) {
    font-size: 1rem;
  }

  // Adjusts the UI tv-s with lower browser resolution
  ${props =>
    props.isKiosk &&
    css`
      @media (max-width: 1600px) {
        font-size: 0.8125rem;
      }
      @media (max-width: 1400px) {
        font-size: 0.6875rem;
      }
    `}

  // Adjusts the UI for tablet and mobile devices
  ${responsive.md.andSmaller`
    font-size: .875rem;
    -webkit-overflow-scrolling: touch;
  `};
`;

const Header = styled.div`
  flex: 0 0 3em;

  ${responsive.md.andSmaller`
    flex: 0 0 auto;
  `};
`;

const EditingHeader = styled(Header)`
  padding: 0.875em 1em;
  display: flex;
  justify-content: stretch;
  align-items: center;

  ${responsive.md.andSmaller`
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: column;
  `};

  > div {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const FilterBox = styled.div`
  > div {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const ActionBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${responsive.md.andSmaller`
    overflow: hidden;
    flex-wrap: wrap;
    flex-flow: column;
  `};
`;

const IconWrapper = styled.i`
  font-size: 1em;
  margin-right: 0.33333em;
`;

const MainActions = styled.div`
  ${responsive.md.andSmaller`
    margin-bottom: .5em;
    .vanishing {
      display: none;
    }
  `};
`;

export const ActionSeparator = styled.div`
  width: 0.125rem;
  height: 1rem;
  background-color: white;
  opacity: 0.25;
  margin: 0 1rem;

  ${responsive.md.andSmaller`
    display: none;
  `};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div:first-of-type {
    margin-top: 0.75em;
    margin-bottom: 0;

    > label {
      align-items: center;
    }
  }
`;

export const Icon = ({ children, ...rest }) => (
  <IconWrapper className="material-icons" {...rest}>
    {children}
  </IconWrapper>
);

const HospitalName = styled.h3`
  opacity: 0.35;
  font-size: 1.125em;
  font-weight: 500;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  width: 100%;
`;

export const formats = [Formats.CSV, Formats.XLS, Formats.XLSX, Formats.TXT];

const shouldFilterOrWithoutId = hospitalId =>
  (window.location.hostname === 'view2.ospitek.com' && hospitalId === 24) ||
  (window.location.hostname === 'testing.ospitek.com' && hospitalId === 77);

const Schedule = props => {
  const {
    scope,
    session,
    isSuperAdmin,
    isGroupAdmin,
    isMonitoringEngineer,
    isPreOPNurse,
    isAdmin,
    isFrontDesk,
    isOperationsManager,
    isScheduleUser,
    location,
    history,
    match,
    isKiosk,
    date: forcedDate,
    config,
  } = props;

  const showBedNumber = isKiosk ? getNestedValue('showBedNumber', config) : true;
  const showStaffList = getNestedValue('showStaffListOnSchedule', config);

  const theme = useTheme();
  const hospitalId = props?.scope?.hospital?.id;
  const physicianId = get('account.email.PhysicianId.id')(session);
  const anesthesiologistId = get('account.email.AnesthesiologistId.id')(session);
  const staffId = get('account.email.StaffId.id')(session);
  const vendorId = get('account.email.VendorId.id')(session);
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint.md);
  const [isTimeline, setIsTimeline] = useState(queryString.parse(location.search).timeline === 'true');

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showOverlay, setShowOverlay] = useState(false);
  const useForcedDate = forcedDate && forcedDate.getTime && !isNaN(forcedDate.getTime());
  const dateOverride = parse(queryString.parse(location.search).date + 'T00:00:00');
  const useDateOverride = !isNaN(dateOverride.getTime());
  const date = useForcedDate ? forcedDate : useDateOverride ? dateOverride : currentDate;
  const physician = queryString.parse(location.search).physician;
  const currentOR = decodeURIComponent(location.hash).replace('#', '');

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < breakpoint.md);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsMobile]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [setCurrentDate]);

  const roomsQuery = useSubscription(roomsListWithStaffShiftSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
    },
    shouldResubscribe: true,
  });

  const proceduresQuery = useSubscription(listProceduresSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
      physician: physician ? parseInt(physician, 10) : undefined,
      isCanceled: false,
    },
    shouldResubscribe: true,
  });

  const [myProceduresOnly, setMyProceduresOnly] = useState(isScheduleUser || false);

  const scheduleAccessQuery = useQuery(getScheduleAccessAll, {
    variables: {
      id: staffId,
    },
    skip: !staffId,
  });
  const scheduleAccessQueryAnesthesiologist = useQuery(getScheduleAccessAllAnesthesiologist, {
    variables: {
      id: anesthesiologistId,
    },
    skip: !anesthesiologistId,
  });
  const scheduleAccessQueryPhysician = useQuery(getScheduleAccessAllPhysician, {
    variables: {
      id: physicianId,
    },
    skip: !physicianId,
  });
  const scheduleAccessQueryVendor = useQuery(getScheduleAccessAllVendor, {
    variables: {
      id: vendorId,
    },
    skip: !vendorId,
  });
  const showMyProceduresOnly =
    !!scheduleAccessQuery?.data?.getScheduleAccessAll ||
    !!scheduleAccessQueryAnesthesiologist?.data?.getScheduleAccessAllAnesthesiologist ||
    !!scheduleAccessQueryPhysician?.data?.getScheduleAccessAllPhysician ||
    !!scheduleAccessQueryVendor?.data?.getScheduleAccessAllVendor;

  const toggleView = () => {
    setIsTimeline(!isTimeline);
    const old = queryString.parse(location.search) || {};
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ ...old, timeline: !isTimeline }),
      hash: location.hash,
    });
  };

  const operationRooms = useMemo(() => {
    const rooms = getOperationRooms(roomsQuery);
    const procedures = getProcedures(proceduresQuery);

    const transformed = transformScheduleOperationRooms({
      rooms,
      procedures,
      myProceduresOnly,
      physicianId,
      anesthesiologistId,
      staffId,
      vendorId,
    });

    return shouldFilterOrWithoutId(hospitalId) ? transformed.filter(room => !!room.id) : transformed;
  }, [roomsQuery, proceduresQuery, myProceduresOnly, physicianId, anesthesiologistId, staffId, vendorId]);

  const unassignedProcedures = useMemo(() => {
    if (!proceduresQuery.data) {
      return [];
    }

    return filter(procedure => !procedure.orId && !procedure.or)(getProcedures(proceduresQuery));
  }, [proceduresQuery]);

  const isPowerUser = isSuperAdmin || isGroupAdmin || isAdmin || isOperationsManager;

  const canUseScheduleViewProcedure = isPowerUser || isPreOPNurse || isFrontDesk;

  const [editProcedure, setEditProcedure] = useState(null);

  const onFilterChange = value => {
    const old = queryString.parse(location.search) || {};
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ ...old, ...value }),
      hash: location.hash,
    });
  };

  const noProcedures = unassignedProcedures.length === 0 && every(flow(get('procedures'), size, eq(0)))(operationRooms);

  const timelineView = isKiosk ? getNestedValue('isTimeline', config) : isTimeline;
  const ScheduleView = timelineView ? Timeline : Overview;

  const scheduleFooter = useMemo(
    () => (
      <ScheduleFooter
        isFrontDesk={isFrontDesk}
        isPowerUser={isPowerUser || isPreOPNurse}
        hospitalId={hospitalId}
        date={format(date, 'YYYY-MM-DD')}
        rooms={roomsQuery?.data?.rooms || []}
        staffId={staffId}
        myProceduresOnly={myProceduresOnly}
        editableStaff={isPowerUser}
        isKiosk={isKiosk}
        isFooter={!showStaffList}
      />
    ),
    [
      isFrontDesk,
      isPowerUser,
      isPreOPNurse,
      hospitalId,
      date,
      roomsQuery?.data?.rooms,
      staffId,
      myProceduresOnly,
      isKiosk,
      showStaffList,
    ]
  );

  const staffPerRoom = useMemo(
    () =>
      scheduleStaff(
        roomsQuery?.data?.rooms?.map(room => ({
          ...room,
          staffShifts: room.staffShifts.filter(staffShift => !!staffShift.staff?.name),
        })),
        getRoomWithStaff(proceduresQuery?.data?.procedures)
      ),
    [roomsQuery?.data?.rooms, proceduresQuery?.data?.procedures]
  );

  const openProcedureForm = (procedure, operationRooms, operationRoom, date, timeRange) => {
    history.push({
      pathname: procedure?.id ? `${match.url}/procedure/${procedure?.id}` : `${match.url}/procedure`,
      state: {
        procedure,
        operationRooms,
        operationRoom,
        date,
        timeRange,
      },
    });
  };

  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;

  return (
    <>
      {isKiosk && <ClientUpdater {...ClientUpdaterPresetTvSchedule} />}
      {useForcedDate && (
        <ToastBar id="forcedDate" variant="warning">
          Note that this screen displays schedule for {format(forcedDate, 'dddd')}.
        </ToastBar>
      )}
      {isKiosk && (
        <Helmet>
          <meta name="viewport" content="width=1920, initial-scale=1" />
        </Helmet>
      )}
      <Page isKiosk={isKiosk}>
        {isPowerUser || isPreOPNurse || isFrontDesk || isMonitoringEngineer ? (
          <>
            <EditingHeader>
              <MainActions style={{ justifyContent: 'start' }}>
                <RouterLink to={isAdmin ? '/' : `/su/${get('hospital.id')(scope)}`}>
                  <ArrowBack fontSize={'small'} />
                  {` Back${isOperationsManager ? '' : isPowerUser ? ' to admin' : ''}`}
                </RouterLink>
                <ActionSeparator />
                {!createProcedureSchedule && !isMonitoringEngineer && (
                  <ScheduleUploadButton
                    history={history}
                    location={location}
                    formats={formats}
                    className={'vanishing'}
                  />
                )}
                <LinkButton onClick={toggleView} style={{ marginLeft: '1em' }}>
                  {isTimeline ? 'List View' : 'Timeline View'}
                </LinkButton>
              </MainActions>

              <h3>{scope?.hospital?.name}</h3>

              <ActionBox>
                {createProcedureSchedule && isTimeline && (
                  <ToggleOverlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
                )}
                {isPowerUser && <PDFScheduleGenerator date={date} staffPerRoom={staffPerRoom} />}
                {!isFrontDesk && !isMobile && !isPreOPNurse && !isMonitoringEngineer && (
                  <Box mr={2}>
                    <SendNotificationDropdown date={dateOverride} />
                  </Box>
                )}
                <FilterBox>
                  <Filters date={date} physician={physician} onChange={onFilterChange} />
                </FilterBox>
              </ActionBox>
            </EditingHeader>

            {!isFrontDesk && <MultipleStaffShiftSelectionBox />}

            {editProcedure && (
              <ProcedureEditor
                date={date}
                operationRooms={map(get('name'))(operationRooms)}
                procedure={editProcedure}
                onDone={() => setEditProcedure(null)}
                onClose={() => setEditProcedure(null)}
                fullWindow={isMobile}
              />
            )}
          </>
        ) : isScheduleUser ? (
          <EditingHeader>
            <LinkButton onClick={toggleView} style={{ marginLeft: '1em' }}>
              {isTimeline ? 'List View' : 'Timeline View'}
            </LinkButton>
            <HospitalName>{scope?.hospital?.name}</HospitalName>
            <Row>
              {showMyProceduresOnly && (
                <CheckboxInput
                  label="My procedures only"
                  name="myProceduresOnly"
                  value={myProceduresOnly}
                  onChange={setMyProceduresOnly}
                  layoutProps={{ style: { marginRight: theme.spacing(1) } }}
                />
              )}
              <Filters date={date} withoutPhysician onChange={onFilterChange} />
            </Row>
          </EditingHeader>
        ) : (
          <Header>
            <HospitalInfo />
          </Header>
        )}

        {roomsQuery.loading || proceduresQuery.loading ? (
          <Refresh inSeconds={60} />
        ) : roomsQuery.error || proceduresQuery.error ? (
          <Refresh inSeconds={15}>Error</Refresh>
        ) : (
          <>
            {noProcedures ? (
              <EmptySchedule
                date={date}
                timelineView={timelineView}
                physician={physician}
                isKiosk={isKiosk}
                isScheduleUser={isScheduleUser}
                myProceduresOnly={myProceduresOnly}
                hospitalId={hospitalId}
                currentOR={currentOR}
                isFrontDesk={isFrontDesk}
                isPowerUser={isPowerUser}
                editableStaff={isPowerUser}
                operationRooms={operationRooms}
                setEditProcedure={setEditProcedure}
                unassignedProcedures={unassignedProcedures}
                showBedNumber={showBedNumber}
                scheduleFooter={showStaffList ? scheduleFooter : null}
                history={history}
                match={match}
                canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                openProcedureForm={openProcedureForm}
                showOverlay={showOverlay}
              />
            ) : (
              <>
                {isMobile && <ORNavigation rooms={operationRooms} />}
                <ScheduleView
                  date={date}
                  hospitalId={hospitalId}
                  isKiosk={isKiosk}
                  currentOR={currentOR}
                  canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                  isFrontDesk={isFrontDesk}
                  isPowerUser={isPowerUser}
                  editableStaff={isPowerUser}
                  operationRooms={operationRooms}
                  setEditProcedure={setEditProcedure}
                  unassignedProcedures={unassignedProcedures}
                  showBedNumber={showBedNumber}
                  scheduleStaffList={showStaffList ? scheduleFooter : null}
                  history={history}
                  match={match}
                  openProcedureForm={openProcedureForm}
                  showOverlay={showOverlay}
                />
                {!showStaffList && scheduleFooter}
              </>
            )}
          </>
        )}
      </Page>
      <Route
        path={`${props.match.url}/procedure`}
        render={props => (
          <ScheduleProcedureForm
            operationRooms={props?.location?.state?.operationRooms}
            date={props?.location?.state?.date}
            operationRoom={props?.location?.state?.operationRoom}
            timeRange={props?.location?.state?.timeRange}
            handleClose={() => props.history.goBack()}
          />
        )}
      />
      <Route
        path={`${props.match.url}/procedure/:procedureId`}
        render={props => {
          const procedureId = parseInt(props?.match?.params?.procedureId, 10);
          return (
            <ScheduleProcedureForm
              procedureId={procedureId}
              procedure={props?.location?.state?.procedure}
              operationRooms={props?.location?.state?.operationRooms}
              date={props?.location?.state?.date}
              operationRoom={props?.location?.state?.operationRoom}
              timeRange={props?.location?.state?.timeRange}
              handleClose={() => props.history.goBack()}
            />
          );
        }}
      />
    </>
  );
};

const ScheduleWrapper = props => {
  const {
    location,
    isSuperAdmin,
    isGroupAdmin,
    isMonitoringEngineer,
    isAdmin,
    isFrontDesk,
    isOperationsManager,
    isScheduleUser,
    isPreOPNurse,
    date: forcedDate,
  } = props;

  const [currentDate, setCurrentDate] = useState(new Date());
  const useForcedDate = forcedDate && forcedDate.getTime && !isNaN(forcedDate.getTime());
  const dateOverride = parse(queryString.parse(location.search).date + 'T00:00:00');
  const useDateOverride = !isNaN(dateOverride.getTime());
  const date = useForcedDate ? forcedDate : useDateOverride ? dateOverride : currentDate;
  const physician = queryString.parse(location.search).physician;

  const isPowerUser =
    isSuperAdmin || isGroupAdmin || isMonitoringEngineer || isAdmin || isFrontDesk || isOperationsManager;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [setCurrentDate]);

  const proceduresQuery = useSubscription(listProceduresSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
      physician: physician ? parseInt(physician, 10) : undefined,
    },
  });

  const proceduresAreOver = () =>
    flow(
      get('data.procedures'),
      every(
        procedure =>
          procedure.isCanceled ||
          (!procedure.patient && isAfter(new Date(), addMinutes(procedure.startTime, procedure.duration))) ||
          (procedure.patient &&
            procedure.patient.events &&
            (procedure.patient.events.recoveryAt || procedure.patient.events.dischargedAt))
      )
    )(proceduresQuery);

  const dayIsOver =
    !isPowerUser &&
    !isScheduleUser &&
    !useDateOverride &&
    proceduresQuery.data &&
    !isPreOPNurse &&
    !isEmpty(proceduresQuery.data.procedures) &&
    proceduresAreOver();

  return (
    <MultipleStaffShiftSelectionContextProvider date={format(date, 'YYYY-MM-DD')}>
      <Schedule {...props} date={dayIsOver ? startOfDay(addDays(date, 1)) : null} />
    </MultipleStaffShiftSelectionContextProvider>
  );
};

export default compose(withRouter, withScope, withSession(unpackSessionObject))(ScheduleWrapper);

const Refresh = ({ inSeconds, children }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload(true);
    }, inSeconds * 1000);

    return () => clearTimeout(timeout);
  });

  return children || null;
};

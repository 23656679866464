import React, { ReactNode, useEffect, useState } from 'react';
import { alpha, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import isBoolean from 'lodash/isBoolean';
import { YesNoAnswer } from '../../types/Answer';
import { YesNoQuestion } from '../../types/Question';
import { useSlideIn, YesNoQuestionChildren } from '../Form';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { SubdirectoryArrowRightTwoTone } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

type YesNoInputProps = YesNoQuestion['props'] &
  YesNoQuestionChildren<ReactNode> & {
    name?: string;
    answer?: YesNoAnswer;
    onChange?: (newAnswer: YesNoAnswer) => void;
  };

const YesNoInput = ({ name, optional, label, answer, onChange, child, inline }: YesNoInputProps) => {
  const value = isBoolean(answer)
    ? answer
      ? 'Yes'
      : 'No'
    : isBoolean(answer?.value)
    ? answer?.value
      ? 'Yes'
      : 'No'
    : '';
  const booleanValue = isBoolean(answer) ? answer : isBoolean(answer?.value) ? answer?.value : undefined;

  const [invalid, setInvalid] = useState(false);
  const classes = useStyles();

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, slideIn]);

  return (
    <Box className={clsx({ [classes.hasChild]: child })} my={inline ? 0 : 3}>
      <FormControl fullWidth={true} error={invalid}>
        <div
          style={
            inline ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 16 } : undefined
          }
        >
          <FormLabel>{label}</FormLabel>
          <RadioGroup
            row
            aria-label={label}
            name={name}
            value={value}
            onChange={e => {
              onChange?.(e.target.value === 'Yes');
              setInvalid(false);
            }}
          >
            <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </div>
      </FormControl>
      {isBoolean(booleanValue) && child ? (
        <Box display="flex" pl={1}>
          <SubdirectoryArrowRightTwoTone color="disabled" />
          <Box ml={1} mt={-2} mb={-2}>
            {booleanValue === true && child}
            {booleanValue === false && child}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  hasChild: {
    backgroundColor: alpha(theme.palette.primary.dark, 0.1),
    border: `1px solid ${alpha(theme.palette.primary.dark, 0.25)}`,
    padding: theme.spacing(0, 1.5, 0),
    margin: theme.spacing(1, -1.5, 1),
    borderRadius: theme.shape.borderRadius,
  },
}));

export default YesNoInput;

export default {
  hospitalName: 'University Ambulatory Surgery Center',
  nodeEnv: process.env.NODE_ENV,
  publicURL: process.env.PUBLIC_URL || 'http://localhost:3000',
  apiURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  appURL: process.env.REACT_APP_URL || 'http://localhost:3000',
  careURL: process.env.REACT_APP_CARE_URL || 'http://localhost:4000',
  covidScreeningURL: process.env.REACT_APP_CS_URL || 'http://localhost:5000',
  assetsURL: process.env.ASSETS_URL || 'https://assets.ospitek.com',
  appVersion: process.env.REACT_APP_VERSION,
  appVersionTvInternal: process.env.REACT_APP_VERSION_TV_INTERNAL,
  appVersionTvSchedule: process.env.REACT_APP_VERSION_TV_SCHEDULE,
  appVersionTvWaitingRoom: process.env.REACT_APP_VERSION_TV_WAITING_ROOM,
};

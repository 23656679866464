import { compose, withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { TitleLabel } from '../../../types/StaffMember';
import { withLabel } from '../../../se/components/Label';

const TitleSelectInput = compose(
  withLabel('Title'),
  withProps({
    options: TitleLabel,
    placeholder: 'e.g., Registered Nurse',
    required: false,
  })
)(SelectInput);

export default TitleSelectInput;

import { ProcedureEvent, ProcedureStatus } from '../types/ProcedureEvent';
import findLast from 'lodash/findLast';
import sortBy from 'lodash/sortBy';

export const getLastProcedureEvent = (
  events: Array<ProcedureEvent>,
  procedureStatus: ProcedureStatus
): ProcedureEvent | undefined => {
  const sorted = sortBy(events, 'timestamp');
  return findLast(sorted, ({ status }: ProcedureEvent) => status === procedureStatus);
};

export default {
  getLastProcedureEvent,
};

import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment NotificationData on NotificationTemplate {
      id
      name
      content
      description
      trigger
      destination
      subject
      channel
      enabled
      additionalInfo
    }
  `,
};

export const list = gql`
  query notificationTemplates {
    notificationTemplates {
      ...NotificationData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editNotificationTemplate(
    $id: Long!
    $name: String!
    $content: String!
    $description: String!
    $trigger: NotificationTemplateTrigger!
    $destination: NotificationTemplateDestination!
    $subject: String
    $additionalInfo: String
  ) {
    editNotificationTemplate(
      id: $id
      name: $name
      content: $content
      description: $description
      trigger: $trigger
      destination: $destination
      subject: $subject
      additionalInfo: $additionalInfo
    ) {
      id
      name
      content
      description
      trigger
      destination
      enabled
      subject
      additionalInfo
    }
  }
`;

export const enable = gql`
  mutation enableNotificationTemplate($id: Long!) {
    enableNotificationTemplate(id: $id) {
      id
      name
      content
      description
      trigger
      destination
      enabled
    }
  }
`;

export const disable = gql`
  mutation disableNotificationTemplate($id: Long!) {
    disableNotificationTemplate(id: $id) {
      id
      name
      content
      description
      trigger
      destination
      enabled
    }
  }
`;

export const patientPickupMessage = gql`
  query getPatientPickupMessage {
    getPatientPickupMessage {
      ...NotificationData
    }
  }
  ${fragments.all}
`;

export const readyForFamilyMessage = gql`
  query getReadyForFamilyMessage {
    getReadyForFamilyMessage {
      ...NotificationData
    }
  }
  ${fragments.all}
`;

export const getNotificationTemplate = gql`
  query getNotificationTemplate(
    $trigger: NotificationTemplateTrigger!
    $channel: NotificationTemplateChannel
    $destination: NotificationTemplateDestination
  ) {
    getNotificationTemplate(trigger: $trigger, channel: $channel, destination: $destination) {
      ...NotificationData
    }
  }
  ${fragments.all}
`;

export default {
  list,
  update,
  enable,
  disable,
};

import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment SuperUserData on User {
      id
      name
      email
      superRole
      dateCreated
      lastLogin
      subscribedToNotifications
      lockdown
    }
  `,
};

export const list = gql`
  query users {
    users {
      ...SuperUserData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query user($id: Long!) {
    user(id: $id) {
      ...SuperUserData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createUser($name: String!, $email: String!, $password: String!, $role: EUserRole!) {
    createSuperUser(name: $name, email: $email, password: $password, role: $role) {
      ...SuperUserData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editUser(
    $id: Long!
    $name: String
    $email: String
    $password: String
    $role: EUserRole
    $subscribeToNotifications: Boolean
  ) {
    editSuperUser(
      id: $id
      name: $name
      email: $email
      password: $password
      role: $role
      subscribeToNotifications: $subscribeToNotifications
    ) {
      ...SuperUserData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteUser($id: Long!) {
    deleteUser(id: $id)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};

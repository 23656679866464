import React from 'react';
import TextAnswerComponent from './TextAnswer';
import { NotesAnswer } from '../../types/Answer';

interface Props {
  label: string;
  answer?: NotesAnswer;
}

const NotesInputAnswer = ({ label, answer }: Props) => <TextAnswerComponent question={label} answer={answer} />;

export default NotesInputAnswer;

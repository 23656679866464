export enum FormType {
  PreOpForm = 'PRE_OP_FORM',
  PatientChartForm = 'PATIENT_CHART_FORM',
}

export enum FormSaveType {
  Filled = 'Filled',
  Uploaded = 'Uploaded',
}

export default interface Form {
  id: number;
  name?: string;
  type: FormType;
  saveType: FormSaveType;
  content: string;
  order?: number;
  isSigned?: boolean;
}

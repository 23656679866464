import React, { FC, useCallback, useMemo } from 'react';
import Flag from 'react-flagkit';
import classNames from 'clsx';
import { Theme, createStyles, makeStyles, Menu, MenuItem, Link, Box } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { Locale, LocaleId } from '../../../types/localization';

export const locales: Locale[] = [
  { id: LocaleId.fromString('en_US'), name: 'English', nativeName: 'English' },
  { id: LocaleId.fromString('es_MX'), name: 'Spanish', nativeName: 'Español' },
];

interface PreferredLanguageProps {
  procedureId: any;
  language: string;
  className?: string;
  style?: React.CSSProperties;
  readOnly?: string;
}

const MutablePreferredLanguage: FC<PreferredLanguageProps> = ({ procedureId, language, className, style }) => {
  const classes = useStyles();

  const [setPreferredLanguage] = useMutation(gql`
    mutation setPreferredLanguage($procedureId: Long!, $language: String) {
      setPreferredLanguage(procedureId: $procedureId, language: $language)
    }
  `);

  const handleSelectLocale = useCallback(
    (localeId: LocaleId | undefined) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setAnchorEl(null);
      setPreferredLanguage({
        variables: {
          procedureId,
          language: localeId && localeId.language,
        },
      });
    },
    [procedureId, setPreferredLanguage]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedLocale = useMemo(() => locales.find(locale => locale.id.language === language), [language]);

  return (
    <div>
      <Link
        onClick={handleClick}
        // startIcon={selectedLocale && selectedLocale.id.country && <Flag country={selectedLocale.id.country as any} />}
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classNames(classes.link, className, { [classes.notSet]: !selectedLocale })}
        style={style}
      >
        {selectedLocale ? (
          <Box display="flex" alignItems="center">
            {selectedLocale.id.country && (
              <Flag country={selectedLocale.id.country as any} style={{ marginRight: '0.5em' }} />
            )}
            {selectedLocale.nativeName}
          </Box>
        ) : (
          'Not Set'
        )}
      </Link>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {selectedLocale && <MenuItem onClick={handleSelectLocale(undefined)}>Unset</MenuItem>}
        {locales.filter(selectedLocale ? locale => locale.id !== selectedLocale.id : () => true).map(locale => (
          <MenuItem key={locale.id.toString()} onClick={handleSelectLocale(locale.id)}>
            {locale.id.country && <Flag country={locale.id.country as any} style={{ marginRight: '0.5em' }} />}
            {locale.nativeName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const ImmutablePreferredLanguage: FC<PreferredLanguageProps> = ({ language }) => {
  const selectedLocale = useMemo(() => locales.find(locale => locale.id.language === language), [language]);
  return (
    <div>
      {selectedLocale ? (
        <Box display="flex" alignItems="center">
          {selectedLocale.id.country && (
            <Flag country={selectedLocale.id.country as any} style={{ marginRight: '0.5em' }} />
          )}
          {selectedLocale.nativeName}
        </Box>
      ) : (
        'Not Set'
      )}
    </div>
  );
};

const PreferredLanguage: FC<PreferredLanguageProps> = ({ procedureId, language, className, style, readOnly }) => {
  const receivedProps = { procedureId, language, className, style };
  return readOnly ? <ImmutablePreferredLanguage {...receivedProps} /> : <MutablePreferredLanguage {...receivedProps} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
      },
      '&:active': {
        color: theme.palette.primary.light,
      },
    },
    notSet: {
      fontStyle: 'italic',
      color: theme.palette.text.disabled,
    },
  })
);

export default PreferredLanguage;

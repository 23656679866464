import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import React, { VFC } from 'react';
import { Room } from '../../../../types/Room';
import { createPdf } from '../../../../vendor/pdfMake';
import { openPdf } from '../../../entities/procedures/pdf/shared';
import { format } from 'date-fns';
// @ts-ignore
import pdfMake from 'pdfmake/build/pdfmake.js';
// @ts-ignore
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { IconButton, Tooltip } from '@material-ui/core';
import StaffShift from '../../../../types/StaffShift';

// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const tableRowMargin = [0, 2, -10, -4];
const tableRowFontSize = 10;
const MINIMUM_NUMBER_OF_LINES = 7; // It's actually -> MINIMUM_NUMBER_OF_LINES - 1
const getRoomInfo = (room: RoomWithProcedureFlag) => {
  const staffShifts = room.staffShifts;
  const shiftsLength = room.staffShifts.length;

  let additionalNumOfLines =
    shiftsLength <= MINIMUM_NUMBER_OF_LINES ? MINIMUM_NUMBER_OF_LINES - shiftsLength : shiftsLength;
  if (shiftsLength >= MINIMUM_NUMBER_OF_LINES - 1) additionalNumOfLines = 0;

  return {
    stack: [
      {
        table: {
          headerRows: 1,
          widths: [100, 140],
          body: [
            [
              { text: room.name, style: 'tableHeaderRoomTitle' },
              {
                text: 'TIME IN',
                style: {
                  bold: true,
                  fontSize: 10,
                  color: 'black',
                  alignment: 'left',
                },
              },
            ],
            ...staffShifts.map(shift => {
              const shiftTimeSpan = +!!shift.from
                ? `${shift?.isProcedure ? 'Procedure: ' : ''}${shift.from}${shift.to ? ' - ' + shift.to : ''}`
                : '';
              const staffName = shift.staff?.name;
              const additionalText = shift?.additionalText;
              const secondColumn = `${shiftTimeSpan} ${!!additionalText ? '(' + additionalText + ')' : ''}`;
              return [
                {
                  text: staffName,
                  fontSize: tableRowFontSize,
                  margin: tableRowMargin,
                },
                {
                  text: secondColumn,
                  fontSize: tableRowFontSize,
                  margin: tableRowMargin,
                },
              ];
            }),
            ...[...new Array(additionalNumOfLines)]
              .map((element: any) => '')
              .map(line => [
                {
                  text: '   ',
                  margin: tableRowMargin,
                  fontSize: tableRowFontSize,
                },
                { text: '   ', margin: tableRowMargin, fontSize: tableRowFontSize },
              ]), // Is there some smarter way to do this?
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ],
  };
};

type PageBreak = 'after' | 'before';

const getPageContent = (
  date: Date,
  firstRoomTypeContent: RoomWithProcedureFlag[],
  secondRoomTypeContent: RoomWithProcedureFlag[] | undefined,
  title?: string,
  pageBreak?: PageBreak
) => ({
  stack: [
    {
      text: `DAILY STAFFING SCHEDULE ${!!title ? title : ''}`,
      style: 'header',
      alignment: 'center',
      margin: [0, 0, 0, 0],
    },
    {
      columns: [
        {
          text: `Date:`,
          alignment: 'right',
        },
        {
          text: `${format(date, 'MM.DD.YYYY')}`,
          style: {
            decoration: 'underline',
            alignment: 'left',
          },
        },
      ],
      style: 'subheader',
      alignment: 'center',
    },
    {
      columns: [
        {
          stack: [firstRoomTypeContent.map(room => getRoomInfo(room))],
        },
        {
          stack: [secondRoomTypeContent?.map(room => getRoomInfo(room))],
        },
      ],
    },
  ],
  pageBreak: pageBreak,
});

const geLayout = (date: Date, orRooms: RoomWithProcedureFlag[], notOrRooms: RoomWithProcedureFlag[]) => {
  if (orRooms.length <= 4 && notOrRooms.length <= 4) {
    return getPageContent(date, notOrRooms, orRooms, undefined);
  } else if (orRooms.length + notOrRooms.length <= 8) {
    if (notOrRooms.length > 4) {
      return getPageContent(date, notOrRooms.slice(0, 4), notOrRooms.slice(4).concat(orRooms), undefined);
    } else {
      return getPageContent(date, orRooms.slice(0, 4), orRooms.slice(4).concat(notOrRooms), undefined);
    }
  } else {
    return {
      stack: [
        getPageContent(date, notOrRooms.slice(0, 4), notOrRooms.slice(4), undefined, 'after'),
        getPageContent(date, orRooms.slice(0, 4), orRooms.slice(4), '(OR)'),
      ],
    };
  }
};

const getScheduleContent = (date: Date, rooms: RoomWithProcedureFlag[]) =>
  geLayout(
    date,
    rooms.filter(room => room.type === 'OR'),
    rooms.filter(room => room.type !== 'WAITING_ROOM' && room.type !== 'OR')
  );

const generateSchedule = (date: Date, rooms: RoomWithProcedureFlag[]) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Staff Schedule` },
    content: [
      getScheduleContent(
        date,
        rooms.map(room => ({ ...room, staffShifts: room.staffShifts.filter(staff => !!staff.staff?.name) }))
      ),
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify',
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10],
      },
      tableHeaderRoomTitle: {
        bold: true,
        fontSize: 16,
        color: 'black',
        fillColor: '#CCCCCC',
        margin: [2, 0, 0, -10],
      },
    },
  };

  openPdf(createPdf(docDefinition), `Staff_Schedule${format(date, 'MM_DD_YYYY_HH_mm_ss')}`);
};

interface StaffShiftExtended extends StaffShift {
  isProcedure?: boolean;
}

type RoomWithProcedureFlag = Omit<Room, 'staffShifts'> & { staffShifts: Array<StaffShiftExtended> };

const PDFScheduleGenerator: VFC<{ date: Date; staffPerRoom: RoomWithProcedureFlag[] }> = ({ date, staffPerRoom }) => (
  <Tooltip title={'Download Staff Schedule'}>
    <IconButton onClick={() => generateSchedule(date, staffPerRoom)} color="primary">
      <PictureAsPdfIcon />
    </IconButton>
  </Tooltip>
);

export default PDFScheduleGenerator;

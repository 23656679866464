import React from 'react';
import { useQuery } from '@apollo/client';
import Tooltip from '../../Tooltip';
import { WithWorking } from './tablet/Tablet';
import { NotifyButton } from './tablet/CurrentStatusHeader';
import scheme from '../../../graph/physicians';

const NotificationButton = ({ physician, action, notifyPhysician, working, notificationEnabled }) => {
  const { data: physicianData } = useQuery(scheme.item, {
    variables: {
      id: parseInt(physician.id, 10),
    },
  });

  const shouldSendNotification = physicianData?.physician?.shouldSendNotification || null;
  const hasPhoneNumber = physicianData?.physician?.notificationNumbers || null;

  const NotifyButtonComponent = () => (
    <NotifyButton
      onClick={notifyPhysician}
      disabled={!shouldSendNotification || working || !notificationEnabled || !hasPhoneNumber}
      style={{ minWidth: '6em' }}
    >
      <WithWorking working={working} size={'0.9em'}>
        <i className="material-icons">{action.notificationAction.icon}</i> {action.notificationAction.label}
      </WithWorking>
    </NotifyButton>
  );

  const MessagingTurnedOff = (
    <Tooltip content="Physician messaging has been turned off. Go to message configuration to enable it.">
      <NotifyButtonComponent />
    </Tooltip>
  );
  const NoPhoneNumber = (
    <Tooltip content="Physician does not have a phone number.">
      <NotifyButtonComponent />
    </Tooltip>
  );
  const PhysicianMessagingTurnedOff = (
    <Tooltip content="Physician messaging has been turned off. Physician doesn't want to receive notification.">
      <NotifyButtonComponent />
    </Tooltip>
  );

  return !notificationEnabled ? (
    MessagingTurnedOff
  ) : !hasPhoneNumber ? (
    NoPhoneNumber
  ) : shouldSendNotification ? (
    <NotifyButtonComponent />
  ) : (
    PhysicianMessagingTurnedOff
  );
};

export default NotificationButton;

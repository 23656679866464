import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { alpha, Box, Container, Divider, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ArrowBackTwoTone, ErrorOutlineTwoTone } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { useQuery, useSubscription } from '@apollo/client';
import { Patient } from '../../../../types/Patient';
import { QuestionnaireAndAnswers } from '../../../../types/Questionnaire';
import { sortBy } from 'lodash';
import { list } from '../../../../graph/config';
import clsx from 'clsx';
import get from 'lodash/get';
import MyPatientInfo from './PatientInfo';
import { PRE_OP } from '../../../entities/room/enums';
import { optionalFormat, patientEnteredRoomTypeTime } from '../tablet/utils';
import { item as patientWithCharts } from '../../../../graph/patientChart';
import { calculateBMIStr } from '../../../../questionnaire/toBMI';
import { parseHeight } from '../../../../form/questions/TextInput';
import { isValueOutOfRange } from '../../../../util/patientChart';
import DataItem from './components/DataItem';
import FormButtonDialog from './components/FormButtonDialog';

const PatientChart = withRouter(({ patientId, history }: { patientId?: string } & RouteComponentProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [form, setForm] = useState<QuestionnaireAndAnswers | null>(null);

  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });
  const patient: Patient | undefined = patientData?.patient;

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    patient?.procedure?.chartsAndAnswers || [],
    'questionnaire.order'
  );

  const handleClickOpen = (form: QuestionnaireAndAnswers) => {
    setOpen(true);
    setForm(form);
  };

  const handleClose = () => {
    setOpen(false);
    setForm(null);
  };

  const { data: configData } = useQuery(list);

  const mergedAnswers = charts?.reduce(
    (acc, e) => ({
      ...acc,
      ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
    }),
    {}
  );
  const calculatedValues = {
    bmi: calculateBMIStr((mergedAnswers as any)?.wt, ...parseHeight((mergedAnswers as any)?.ht)),
  };
  const combined = { ...mergedAnswers, ...calculatedValues };

  const enteredAt = optionalFormat(patientEnteredRoomTypeTime(patient, PRE_OP), 'HH:mm');

  return (
    <Container className={classes.container}>
      <Box className={classes.patientInfo}>
        <Box display="flex" alignItems="center" mb={4}>
          <Box ml={-2} mr={1}>
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackTwoTone color="primary" />
            </IconButton>
          </Box>
          <Box mr={2}>
            <Typography color="textSecondary">|</Typography>
          </Box>
          <MyPatientInfo
            patientName={patient?.name}
            physicianName={patient?.physician?.name}
            anesthesiologistName={patient?.procedure?.anesthesiologist?.name}
            anesthesiaType={patient?.procedure?.anesthesiaType}
            procedureTypeName={patient?.procedureType?.name}
            enteredAt={enteredAt}
          />
        </Box>
      </Box>

      <Box mb={2}>
        <Divider />
      </Box>

      {!!patient && (
        <Box className={classes.formFieldParams}>
          {sortBy(configData?.configs || [], 'id').map((e: any) => {
            const config = JSON.parse(e.values || '{}');
            const value = config?.path ? get(combined as any, config?.path) : undefined;
            const isRemarkable = isValueOutOfRange(config, value);

            return (
              <Box key={config?.name} className={clsx({ [classes.formFieldParamRemarkable]: isRemarkable })}>
                <DataItem
                  name={config?.name || '-'}
                  value={value}
                  unit={config?.unit}
                  icon={isRemarkable ? <ErrorOutlineTwoTone color="error" fontSize="inherit" /> : null}
                />
              </Box>
            );
          })}
        </Box>
      )}

      <Box mb={4}>
        <Divider />
      </Box>

      {!!patient && (
        <Box className={clsx(classes.grid, { [classes.increaseColumns]: charts.length >= 7 })}>
          {(charts || []).map((f: QuestionnaireAndAnswers, i: number) => (
            <Box key={f.id} className={classes.gridItem}>
              <FormButtonDialog
                patient={patient}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                form={f}
                index={i}
                open={open && f.id === form?.id}
              />
            </Box>
          ))}
        </Box>
      )}
    </Container>
  );
});

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  patientInfo: {
    marginBottom: theme.spacing(4),
  },
  grid: {
    flex: 1,
    alignItems: 'stretch',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '1fr',
    gap: theme.spacing(3),
  },
  gridItem: {
    display: 'flex',
  },
  formFieldParams: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  increaseColumns: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  formFieldParamRemarkable: {
    backgroundColor: alpha(theme.palette.error.dark, 0.3),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.75, 1.25),
    margin: theme.spacing(-0.75, -1.25),
  },
}));

export default PatientChart;

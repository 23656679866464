import React, { Dispatch, FC, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { getDisposition } from './Procedure';
import { Procedure as ProcedureT } from '../../../../../types/Procedure';
import { differenceInHours, format, getHours, setHours, setMinutes, setSeconds } from 'date-fns';
import { currentDateInZone } from '../../../../../util/dateTime';
import { useScope } from '../../../../HospitalInfo';
import { Box } from '@material-ui/core';
import StaffShifts from '../staff/StaffShifts';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import { StaffShiftRoomContext } from '../staff/StaffShiftContext';
import { StaffSlotRoomContext } from '../staff/StaffSlotContext';
import { RoomHeader } from '../overview/Overview';
import responsiveStyles from '../../../../../se/utilities/responsive';
import { Room } from '../../../../../types/Room';
import OperatingRoomProcedures from './OperatingRoomProcedures';
import { Time } from '../../../../entities/schedule/util/time';

const BORDER_COLOR = '#0e274c';
const LEFT_OFFSET = '4em';
const LEFT_OFFSET_MOBILE = '2.5em';
const TOP_OFFSET = '0.4rem';
const CURSOR_SIZE = 0.5;

const responsive = responsiveStyles as any;

const Root = styled.div<{ isKiosk?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 1em;
  font-family: 'Roboto Condensed', sans-serif;

  ${props =>
    props.isKiosk
      ? css`
          overflow: hidden;
          flex: 1 0 0;
        `
      : `
    overflow: auto;
    flex: 0 1 auto;
    height: auto;
  `}
`;

const Grid = styled.div<{ isKiosk?: boolean }>`
  display: flex;
  flex: 1 0 auto;
  position: relative;

  // stretch the grid to give a little more space on mobile
  height: ${props => (props.isKiosk ? 'auto' : '100vh')};
  min-height: ${props => (props.isKiosk ? 0 : '70rem')};
  overflow: hidden;
`;

export const XAxis = styled.div<{}>`
  display: flex;
  flex: 1 0 0;
  margin-left: ${LEFT_OFFSET};
  margin-bottom: 1em;
  > :last-child {
    // border-right: 1px solid ${BORDER_COLOR};
  }

  ${responsive.md.andSmaller`
    margin-left: ${LEFT_OFFSET_MOBILE};
  `}
`;

export const YAxis = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.8125em;
`;

export const Tick = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  text-align: start;
  padding-left: 1em;
  color: #abe3ff;
  opacity: 0.5;
  margin: 0 0.25em;

  :after {
    border-top: 1px solid ${BORDER_COLOR};
    opacity: 0.9;
    right: 0;
    content: '';
    left: ${LEFT_OFFSET};
    position: absolute;
    top: ${TOP_OFFSET};

    ${responsive.md.andSmaller`
      left: ${LEFT_OFFSET_MOBILE};
    `}
  }

  ${responsive.md.andSmaller`
    padding-left: 0.5em;
  `}
`;

const Rooms = styled.div<{ columns?: any }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  margin-left: ${LEFT_OFFSET};

  ${responsive.md.andSmaller`
    margin-left: ${LEFT_OFFSET_MOBILE};
  `}
`;

export const Cursor = styled.div<{ y?: any }>`
  position: absolute;
  top: calc(0.4rem + ${props => props.y}%);
  left: ${LEFT_OFFSET};
  right: 0;
  border-top: 1px solid rgba(232, 29, 29, 0.5);
  z-index: 2;

  ${responsive.md.andSmaller`
    left: ${LEFT_OFFSET_MOBILE};
  `}
`;

export const Dot = styled.div`
  border-radius: 50%;
  width: ${CURSOR_SIZE}em;
  height: ${CURSOR_SIZE}em;
  margin-top: -${CURSOR_SIZE / 2}em;
  margin-left: calc(-${CURSOR_SIZE / 2}em + 1px);
  background: rgba(232, 29, 29, 0.8);
`;

const Timeline: FC<{
  currentOR?: string;
  isKiosk?: boolean;
  isFrontDesk?: boolean;
  editableStaff?: boolean;
  operationRooms: Room[];
  canUseScheduleViewProcedure?: boolean;
  isPowerUser?: boolean;
  date: Date;
  setEditProcedure?: Dispatch<SetStateAction<ProcedureT | null>>;
  showBedNumber?: boolean;
  scheduleStaffList?: any;
  showOverlay: boolean;
  editMode: boolean;
  openProcedureForm?: () => void;
  startTime: Time;
  duration: number | null | undefined;
  setStartTime?: (val: Time) => void;
  setDuration?: (val: number | null | undefined) => void;
}> = ({
  currentOR,
  isFrontDesk,
  isKiosk,
  editableStaff,
  operationRooms,
  canUseScheduleViewProcedure,
  isPowerUser,
  date,
  setEditProcedure,
  showBedNumber,
  scheduleStaffList,
  showOverlay = false,
  editMode,
  openProcedureForm,
  startTime,
  duration,
  setStartTime,
  setDuration,
}) => {
  const canAddStaff = !isKiosk && editableStaff;
  const dayStart = setSeconds(setMinutes(setHours(date, 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(date, 18), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const scope = useScope();
  const tz = scope?.hospital.timezone.id;
  const hospitalId = scope?.hospital.id;

  const timelineContent = (
    <Root isKiosk={isKiosk}>
      <Rooms columns={operationRooms.length}>
        {operationRooms.map(({ id, name, staffShifts }) => (
          <StaffShiftRoomContext
            key={id || name}
            hospitalId={hospitalId}
            roomId={id}
            date={format(date, 'YYYY-MM-DD')}
            staffShifts={staffShifts || []}
          >
            <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 5,
                }}
              >
                {/* @ts-ignore */}
                <RoomHeader onlyRoomName={!(canAddStaff && id !== undefined)}>
                  <div></div>
                  {/*Only a placeholder so the layout would be proper*/}
                  <span>{name}</span>
                  <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                    {canAddStaff && <Box mr={1}>{!!id && <StaffShiftMenu />}</Box>}
                  </StaffSlotRoomContext>
                </RoomHeader>
                {(staffShifts || []).length !== 0 && (
                  <div style={{ marginRight: 20 }}>
                    <StaffShifts staffShifts={staffShifts || []} editableStaff={editableStaff} isKiosk={isKiosk} />
                  </div>
                )}
              </div>
            </StaffSlotRoomContext>
          </StaffShiftRoomContext>
        ))}
      </Rooms>
      <Grid isKiosk={isKiosk}>
        <YAxis>
          {hours.map(tick => (
            <Tick key={tick}>{tick}</Tick>
          ))}
        </YAxis>
        <Cursor y={getDisposition(dayStart, currentDateInZone(tz), dayEnd)}>
          <Dot />
        </Cursor>
        <XAxis>
          {operationRooms.map((room, i) => (
            <OperatingRoomProcedures
              operationRooms={operationRooms}
              key={room?.id || room?.name}
              index={i}
              date={date}
              room={room}
              currentOR={currentOR}
              isKiosk={isKiosk}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
              isFrontDesk={isFrontDesk}
              isPowerUser={isPowerUser}
              showOverlay={showOverlay}
              editMode={editMode}
              showBedNumber={showBedNumber}
              setEditProcedure={setEditProcedure}
              dayStart={dayStart}
              dayEnd={dayEnd}
              openProcedureForm={openProcedureForm}
              startTime={startTime}
              duration={duration}
              setStartTime={setStartTime}
              setDuration={setDuration}
            />
          ))}
        </XAxis>
      </Grid>
    </Root>
  );

  if (isKiosk && scheduleStaffList) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: !!scheduleStaffList ? 'row' : 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {timelineContent}
        {scheduleStaffList}
      </div>
    );
  }
  return timelineContent;
};

export default Timeline;

import { toLocalTime } from '../components/pages/kiosk/schedule/staff/StaffMember';

enum ValueType {
  BP = 'BP',
  HR = 'HR',
  RESP = 'RESP',
  SaO2 = 'SaO2',
  TEMP = 'TEMP',
  Liquids = 'Liquids',
  Solids = 'Solids',
  BMI = 'BMI',
  BLOOD_GLUCOSE = 'BLOOD_GLUCOSE',
}

interface Config {
  name: string;
  type: ValueType;
  path: string;
  unit?: string;
  min?: string;
  max?: string;
}

const isOutOfRangeInt = (value?: string, min?: string, max?: string): boolean => {
  const params = [parseInt(value || '', 10), parseInt(min || '', 10), parseInt(max || '', 10)];
  if (params.some(e => isNaN(e))) return false;

  return params[0] < params[1] || params[0] > params[2];
};

const isOutOfRangeFloat = (value?: string, min?: string, max?: string): boolean => {
  const params = [parseFloat(value || ''), parseFloat(min || ''), parseFloat(max || '')];
  if (params.some(e => isNaN(e))) return false;

  return params[0] < params[1] || params[0] > params[2];
};

const isOutOfRangeTime = (value?: string, min?: string, max?: string): boolean => {
  const params = [toLocalTime(value || ''), toLocalTime(min || ''), toLocalTime(max || '')];
  if (params.some(e => !e)) return false;

  return (
    (params[0] && params[1] ? params[0].isBefore(params[1]) : false) ||
    (params[0] && params[2] ? params[0]?.isAfter(params[2]) : false)
  );
};

export const isValueOutOfRange = (config: Config, value?: string): boolean => {
  if ([value, config?.max, config?.min].every(e => !e)) return false;

  switch (config.type) {
    case ValueType.BP: {
      const [sys, dia] = value ? value.split('/') : [];
      const [sysMin, diaMin] = config?.min ? config?.min?.split('/') : [];
      const [sysMax, diaMax] = config?.max ? config?.max?.split('/') : [];
      const isOutSys = isOutOfRangeInt(sys, sysMin, sysMax);
      const isOutDia = isOutOfRangeInt(dia, diaMin, diaMax);
      return isOutSys || isOutDia;
    }
    case ValueType.BMI:
      return isOutOfRangeFloat(value, config?.min, config?.max);
    case ValueType.Liquids:
    case ValueType.Solids:
      return isOutOfRangeTime(value, config?.min, config?.max);
    default: {
      return isOutOfRangeInt(value, config?.min, config?.max);
    }
  }
};

import React, { useEffect, useRef, useState } from 'react';
import IMask from 'imask';
import { Box, FormLabel, TextField } from '@material-ui/core';
import { TextAnswer } from '../../types/Answer';
import { TextQuestion } from '../../types/Question';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { useSlideIn } from '../Form';

type TextInputProps = TextQuestion['props'] & {
  name?: string;
  answer?: TextAnswer;
  onChange?: (newAnswer: TextAnswer) => void;
};

export const parseHeight = (val?: string) => {
  const match = val?.match(/((\d*)ft)*\s*((\d*)in)*/);
  return [match?.[2], match?.[4]];
};

const TextInput = ({ name, optional, label, format, answer, onChange }: TextInputProps) => {
  const inputRef1 = useRef<HTMLInputElement | undefined>();
  const inputRef2 = useRef<HTMLInputElement | undefined>();

  useEffect(() => {
    if (!inputRef1.current) {
      return;
    }

    if (format === 'BloodPressure') {
      const bloodPressureMask = IMask(inputRef1.current, {
        mask: '000{/}000',
      });

      return () => bloodPressureMask.destroy();
    }

    if (format === 'HeartRate' || format === 'RespirationRate' || format === 'Saturation' || format === 'Weight') {
      const mask = /^\d{1,3}$/;
      const digitsMask = IMask(inputRef1.current, {
        mask,
      });

      return () => digitsMask.destroy();
    }

    if (format === 'Height') {
      if (!inputRef2.current) {
        return;
      }

      const digitsMask1 = IMask(inputRef1.current, {
        mask: /^[4-8]$/,
      });

      const digitsMask2 = IMask(inputRef2.current, {
        mask: /^([0-9]|1[0-1])$/,
      });

      return () => {
        digitsMask1.destroy();
        digitsMask2.destroy();
      };
    }

    if (format === 'Temperature') {
      const mask = /^(\d{1,3}|\d{1,3}\.\d?)$/;
      const digitsMask = IMask(inputRef1.current, {
        mask: mask,
      });

      return () => digitsMask.destroy();
    }

    if (format === 'BMI') {
      const digitsMask = IMask(inputRef1.current, {
        mask: '00{.}0',
      });

      return () => digitsMask.destroy();
    }

    if (format === 'MilitaryTime') {
      const militaryTimeMask = IMask(inputRef1.current, {
        mask: 'HH{:}MM',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
          },
        },
      });

      return () => militaryTimeMask.destroy();
    }
  }, [format]);

  const [invalid, setInvalid] = useState(false);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, slideIn]);

  if (format === 'Height') {
    const [fts, inches] = parseHeight(answer);

    return (
      <Box display="flex" flexDirection="row" my={2} alignItems="center" style={{ maxWidth: '20rem' }}>
        <Box style={{ marginRight: '1em' }}>
          <FormLabel>HT</FormLabel>
        </Box>
        <TextField
          inputRef={inputRef1}
          type="text"
          autoComplete="off"
          label={'Feet'}
          variant="filled"
          aria-label={'Feet'}
          name={name}
          // InputProps={{
          //   endAdornment: 'ft',
          // }}
          fullWidth={false}
          value={fts ?? ''}
          onChange={e => {
            const text = inches ? `${e.target.value}ft ${inches}in` : `${e.target.value}ft`;
            onChange?.(text);
            setInvalid(false);
          }}
          style={{ marginRight: '1em' }}
          error={invalid}
        />
        <TextField
          inputRef={inputRef2}
          type="text"
          autoComplete="off"
          label={'Inches'}
          variant="filled"
          aria-label={'Inches'}
          name={name}
          // InputProps={{
          //   endAdornment: 'in',
          // }}
          value={inches ?? ''}
          onChange={e => {
            const text = fts ? `${fts}ft ${e.target.value}in` : `${e.target.value}in`;
            onChange?.(text);
            setInvalid(false);
          }}
          error={invalid}
          fullWidth={false}
        />
      </Box>
    );
  }

  return (
    <Box my={2}>
      <TextField
        inputRef={inputRef1}
        type="text"
        autoComplete="off"
        label={label}
        variant="filled"
        fullWidth={true}
        aria-label={label}
        name={name}
        inputProps={{
          placeholder: format === 'BloodPressure' ? 'SYS/DIA' : undefined,
        }}
        InputProps={{
          endAdornment:
            format === 'BloodPressure' ? (
              <span style={{ whiteSpace: 'nowrap' }}>mm Hg</span>
            ) : format === 'HeartRate' ? (
              'bpm'
            ) : format === 'Temperature' ? (
              '℉'
            ) : format === 'Saturation' ? (
              '%'
            ) : format === 'Weight' ? (
              'lbs'
            ) : undefined,
        }}
        value={answer ?? ''}
        onChange={e => {
          onChange?.(e.target.value);
          setInvalid(false);
        }}
        error={invalid}
      />
    </Box>
  );
};

export default TextInput;

import React from 'react';
import { withScope } from '../../HospitalInfo';
import get from 'lodash/get';
import QuestionnaireAnswersPreview from '../procedures/questionnaire/QuestionnaireAnswersPreview';
import { Label, QuestionnaireWrapper, Wrapper } from '../procedures/questionnaire/Common';
import QuestionnaireActions from '../procedures/questionnaire/QuestionnaireActions';
import QuestionnaireFulfilment from '../procedures/questionnaire/QuestionnaireFulfilment';
import QuestionnaireInvite from '../procedures/questionnaire/QuestionnaireInvite';
import parseJson from '../procedures/utils/parseJson';

export default withScope(({ entryQuestionnaire, procedure, scope }) => {
  const onPrint = fn => e => {
    e.preventDefault();
    e.stopPropagation();

    fn({
      questions: parseJson(entryQuestionnaire.questions),
      answers: parseJson(entryQuestionnaire.answers),
      procedure,
      hospitalName: get(scope, 'hospital.name'),
      scheduleProvider: get(scope, 'hospital.scheduleProvider'),
    });
  };

  return (
    <Wrapper style={{ width: '100%' }}>
      <div>
        <QuestionnaireWrapper>
          <QuestionnaireFulfilment formProgress={get(procedure, 'entryQuestionnaire.progress')} />
          <QuestionnaireInvite procedure={procedure} />
        </QuestionnaireWrapper>
        <QuestionnaireActions entryQuestionnaire={entryQuestionnaire} editActionLabel={'Edit Questionnaire'} />
        {!entryQuestionnaire && <Label>Entry form is not filled yet</Label>}
        {entryQuestionnaire && <QuestionnaireAnswersPreview {...entryQuestionnaire} onPrint={onPrint} />}
      </div>
    </Wrapper>
  );
});

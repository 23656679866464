import React, { FC, ReactElement } from 'react';
import { Chip, createStyles, makeStyles, MenuItem, MenuList, Popover, Theme } from '@material-ui/core';
import { useStaffSlotContext } from './StaffSlotContext';
import StaffShiftT from '../../../../../types/StaffShift';
import { RemoveCircleOutline } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NoteAdd from '@material-ui/icons/NoteAdd';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import { light } from '../../../../../theme';
import { useScope } from '../../../../HospitalInfo';

interface StaffSlotMenuProps extends StaffShiftT {
  children: (onClick: (event: React.MouseEvent<HTMLElement>) => void) => ReactElement<any, any> | null;
  editTime: boolean;
  setEditTime: (val: boolean) => void;
  setEditText: (val: boolean) => void;
}

const StaffSlotMenu: FC<StaffSlotMenuProps> = ({ id, staff, from, to, setEditTime, setEditText, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const hasTime = !!(from || to);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const staffSlotContext = useStaffSlotContext();
  const scope = useScope();
  const editTimeSlot = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setEditTime(true);
    handleClose();
  };

  const editTextSlot = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    setEditText(true);
    handleClose();
  };

  const editAccess = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    staff && staffSlotContext.openAccessEditor(staff);
    handleClose();
  };

  const removeSlot = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    await staffSlotContext.remove(id, scope?.hospital.id, scope?.hospital.id);
    handleClose();
  };

  return (
    <>
      {children(handleButtonClick)}
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <MenuList className={classes.menu} onClick={e => e.stopPropagation()}>
          <MenuItem onClick={editTimeSlot}>
            <Chip
              icon={<AccessTimeIcon className={classes.iconTime} />}
              label={hasTime ? 'Edit Time' : 'Add Time'}
              size="small"
              className={classes.chip}
            />
          </MenuItem>
          <MenuItem onClick={editTextSlot}>
            <Chip
              icon={<NoteAdd className={classes.iconTime} />}
              label={hasTime ? 'Edit Text' : 'Add Text'}
              size="small"
              className={classes.chip}
            />
          </MenuItem>
          <MenuItem onClick={editAccess}>
            <Chip
              icon={
                staff?.hasScheduleAccess ? (
                  <CloudDoneIcon className={classes.iconHasAccess} />
                ) : (
                  <CloudOffIcon className={classes.iconNoAccess} />
                )
              }
              label={staff?.hasScheduleAccess ? 'Has Access' : 'No Access'}
              size="small"
              className={classes.chip}
            />
          </MenuItem>
          <MenuItem onClick={removeSlot}>
            <Chip
              icon={<RemoveCircleOutline className={classes.iconRemove} />}
              label={'Remove Slot'}
              size="small"
              className={classes.chip}
            />
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      width: '14rem',
    },
    iconRemove: {
      color: 'red',
    },
    iconHasAccess: {
      color: theme.palette.success.light,
    },
    iconNoAccess: {
      color: theme.palette.grey.A200,
    },
    iconTime: {
      color: theme.palette.primary.light,
    },
    chip: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      color: 'white',
    },
    popover: {
      background: light.popover.background.string(),
    },
  })
);

export default StaffSlotMenu;

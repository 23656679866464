import styled, { css } from 'styled-components';

export const PatientStat = styled.div<any>`
  display: flex;
  align-items: center;
  background-color: ${props => (props.inline ? 'transparent' : 'rgba(255, 255, 255, 0.1)')};
  flex: ${props => (props.inline ? '0 1 auto' : 1)};
  margin-right: 1px;
  padding: ${props => (props.inline ? '0' : '0.25em 0.375em')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  ${props =>
    props.left &&
    css`
      justify-content: flex-start;
    `}
  ${props =>
    props.right &&
    css`
      justify-content: flex-end;
    `}
  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}
`;

import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useApolloClient, useMutation, useSubscription } from '@apollo/client';
import StackView from '../../../../se/components/StackView';
import { InlineOmniSearchWithRouter } from '../../../OmniSearch';
import proceduresSearchSource from '../../../../omnisearch/sources/surgeon/procedures';
import { a11yProps } from '../../../core/TabPanel';
import { generatePath, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import CollectionListOnlyOriginal from '../../../../se/components/collection/CollectionListOnly';
import procedures from '../../../../graph/surgeon/procedures';
import schema, { itemSubscription } from '../../../../graph/surgeon/procedures';
import get from 'lodash/get';
import pick from 'lodash/fp/pick';
import { plannedSurgeryColumns, scheduledSurgeryColumns, State, viewColumns } from '../columns';
import { withSession } from '../../../../state/Session';
import { unpackSessionObject } from '../../../pages/unpackSessionObject';
import { withScope } from '../../../HospitalInfo';
import withExchangeOnly from '../../../../util/withExchangeOnly';
import PatientProfile from '../PatientProfile';
import { compose } from 'recompose';
import { Box, Button, Chip, Divider, Hidden, Snackbar, Typography } from '@material-ui/core';
import TitleAction from '../../../../se/components/TitleAction';
import EditProcedureInput from '../../surgeonProcedures/EditProcedureInput';
import EntityEditOriginal from '../../../../se/components/entity/EntityEdit';
import Filters from '../../../pages/analytics/Filters';
import FormControl from '@material-ui/core/FormControl';
import PatientFileGenerator from '../../procedures/PatientFileGenerator';
import clsx from 'clsx';
import RequiresAttention from '../consultations/RequiresAttention';
import SurgeriesWithMissingDocuments from './SurgeriesWithMissingDocuments';
import { Pill, Pills, useStyles } from './styles';
import { plannedSurgeriesSubscription, scheduledSurgeriesSubscription } from '../../../../graph/surgeon/surgeries';
import compact from 'lodash/compact';
import { ButtonBack } from '../../../ButtonBack';
import ChangeStatusButton from '../ChangeStatusButton';
import { NamedRange } from '../../../core/DatePicker';
import SurgeonProcedureMobileListItem from '../../surgeonProcedures/SurgeonProcedureMobileListItem';
import EntityState from '../../../../se/components/EntityState';
import Fab from '@material-ui/core/Fab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { RouterlessModal } from '../../../../se/components/Modal';
import Form from '../../../../se/components/Form';
import InvitePatient from '../../surgeonProcedures/InvitePatient';
import { Alert } from '@material-ui/lab';

enum TabParameter {
  Planned = 'planned',
  Scheduled = 'scheduled',
  Past = 'past',
}

type Params = {
  tab: TabParameter;
};

const PrevPageContext = React.createContext<{
  prevTab: TabParameter | null;
  prevDateRange: any;
  set: (tab: TabParameter | null, dateRange: any) => void;
}>({
  prevTab: null,
  prevDateRange: null,
  set: (tab: TabParameter | null, dateRange: any) => {},
});

const getDateRange = (tab: TabParameter): any => {
  switch (tab) {
    case TabParameter.Planned:
      return undefined;
    case TabParameter.Scheduled:
      return NamedRange.next30Days();
    case TabParameter.Past:
      return NamedRange.last30Days();
    default:
      return undefined;
  }
};

interface SurgeriesProps extends RouteComponentProps<Params> {
  exchangeOnly?: boolean;
  children: React.ReactNode;
}

const CollectionListOnly = CollectionListOnlyOriginal as any;
const EntityEdit = EntityEditOriginal as any;

const Surgeries: FC<SurgeriesProps> = withExchangeOnly(
  ({ location, history, match, children, exchangeOnly }: SurgeriesProps) => {
    const classes = useStyles();
    const apolloClient = useApolloClient();
    const [open, setOpen] = useState<boolean>(false);
    const [severity, setSeverity] = useState<any>('info');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [patientId, setPatientId] = useState<number | undefined>(undefined);
    const [busy, setBusy] = useState<boolean>(false);
    const [createSurgery] = useMutation(schema.create);

    const onSubmit = async (value: any) => {
      setBusy(true);
      const { patient, physician } = value || {};
      try {
        const newSurgery = await createSurgery({
          variables: {
            patient,
            physician,
          },
        });
        setPatientId(newSurgery?.data?.createProcedure?.id);
      } catch (e) {
        console.log(e);
        setSeverity('error');
        setSnackbarMessage(
          `Patient could not be invited. Please try again or contact system administrator if the problem persists.`
        );
        setSnackbarOpen(true);
      } finally {
        setOpen(false);
        setBusy(false);
        setSeverity('success');
        setSnackbarMessage(`Success! Patient ${patient.name} has been invited.`);
        setSnackbarOpen(true);
        // handleChange(undefined, 'future');
      }
    };

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnackbarOpen(false);
    };

    const handleChange = (_: any, tab: string) => {
      history.replace(generatePath(match.path, { ...match.params, tab }));
    };

    const query = useSubscription(scheduledSurgeriesSubscription);

    const missingFiles = query.data?.scheduledSurgeries?.filter(
      (e: any) =>
        JSON.parse(e?.scheduleRequest?.state || '{}').__typename !== 'Cancelled' &&
        (e?.scheduleRequest?.issues || []).some((issue: any) => JSON.parse(issue.state).__typename === 'Requested')
    );

    const issues = compact([
      missingFiles?.length
        ? {
            severity: 'warning',
            title: `${missingFiles?.length} ${
              missingFiles?.length === 1 ? 'surgery has' : 'surgeries have'
            } missing documents`,
            actionTitle: 'Review All',
            onClick: () =>
              history.push(location.pathname.replace(`/${match.params.tab}`, `/requires-attention/need-docs`)),
          }
        : undefined,
    ]);

    return (
      <StackView>
        {exchangeOnly && (
          <Hidden smUp>
            <Box className={classes.fab}>
              <Fab variant="extended" onClick={() => setOpen(true)} color="primary" aria-label="add">
                <PersonAddIcon className={classes.extendedIcon} /> Invite Patient
              </Fab>
            </Box>
          </Hidden>
        )}
        <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
          <div className={classes.titleSearch}>
            <Typography variant="h2" className={classes.title}>
              Surgeries
            </Typography>
            <div className={classes.titleActions}>
              <InlineOmniSearchWithRouter source={[apolloClient, proceduresSearchSource]} />
              {exchangeOnly && (
                <Hidden smDown>
                  <Button
                    onClick={() => setOpen(true)}
                    color="primary"
                    variant="contained"
                    className={classes.titleButton}
                  >
                    Invite Patient
                  </Button>
                </Hidden>
              )}
            </div>
          </div>
        </Box>
        <Box my={2}>
          <Pills
            indicatorColor={'primary'}
            value={match.params.tab}
            variant="scrollable"
            onChange={handleChange}
            aria-label="surgery tabs"
          >
            <Pill label="Past Surgeries" value="past" {...a11yProps(0)} />
            <Pill label="Planned Surgeries" value="planned" {...a11yProps(1)} />
            <Pill label="Scheduled Surgeries" value="scheduled" {...a11yProps(2)} />
          </Pills>
        </Box>
        {children}
        {!!issues?.length && <RequiresAttention issues={issues} />}
        {open && (
          <RouterlessModal en title={'Invite Patient'} onClose={() => setOpen(false)}>
            <Form autoFocus label={'Invite Patient'} input={InvitePatient} onSubmit={onSubmit} busy={busy} />
          </RouterlessModal>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snackbarOpen}
          autoHideDuration={7000}
          onClose={handleClose}
        >
          <Alert
            severity={severity}
            variant="filled"
            action={
              severity === 'success' && (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={() => history.push(location.pathname.replace(`/${match.params.tab}`, `/${patientId}`))}
                >
                  JUMP TO PATIENT PROFILE
                </Button>
              )
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </StackView>
    );
  }
);

const Planned: FC<RouteComponentProps<Params>> = props => {
  const tab = props.match.params.tab;
  const { prevTab, set } = useContext(PrevPageContext);

  const [filter, setFilter] = useState<{
    physician: number | undefined;
    procedureStatus: string | undefined;
  }>({
    physician: undefined,
    procedureStatus: undefined,
  });

  useEffect(() => {
    if (prevTab !== tab) {
      set(tab, null);
    }
  }, [tab, prevTab, set]);

  const query = useSubscription(plannedSurgeriesSubscription, {
    variables: {
      filter: {
        physician: filter.physician,
      },
    },
  });

  const EmptyState = (props: any) => (
    <EntityState title="No Planned Surgeries" hint="Currently there are no surgeries." {...props} />
  );

  return (
    <Surgeries {...props}>
      <Filters
        value={filter}
        onChange={setFilter}
        reload={false}
        hidePhysicianSelectInput={false}
        hideDateFilter={true}
        hideProcedureTypeSelectInput={true}
      />
      <CollectionListOnly
        match={props.match}
        location={props.location}
        history={props.history}
        data={query}
        getList={(query: { data: { plannedSurgeries?: any[] } }) => query.data?.plannedSurgeries}
        columns={plannedSurgeryColumns}
        hideColumns={false}
        // View={View}
        // onViewClick={onViewClick}
        showUrlProvider={(id: any) => generatePath(props.match.path, { ...props.match.params, tab: id })}
        idProvider={(row: any) => row.id}
        useColumnSelection={true}
        defaultDisplayColumns={[
          '#',
          // 'Visit',
          'Name',
          'Consultation Date',
          'Physician',
          // 'Procedure Type',
          // 'DOB',
          'Age',
          'Sex',
          // 'Patient Email Address',
          // 'Patient Cell Phone Number',
          // 'Patient Home Phone Number',
          // 'Form %',
          // 'Last Invite',
          'Status',
          'Actions',
        ]}
        tableKey={props.location.pathname}
        // Loading={Loading}
        // Error={Error}
        Empty={EmptyState}
        // FilterComponent={FilterComponent}
        // FooterComponent={FooterComponent}
        // filter={filter}
        // setFilter={setFilter}
        // highlightedProvider={highlightedProvider}
        // highlightedRowStyles={highlightedRowStyles}
        // highlightedRowStyleProvider={highlightedRowStyleProvider}
        // AdditionalBlock={AdditionalBlock}
        // containsSeparator={containsSeparator}
        // useColumnSelection={useColumnSelection}
        // useCSVExport={useCSVExport}
        MobileItemComponent={SurgeonProcedureMobileListItem}
        // simpleFilter={simpleFilter}
        // defaultSort={defaultSort}
        // searchSource={searchSource}
      />
    </Surgeries>
  );
};

const Scheduled: FC<RouteComponentProps<Params>> = props => {
  const tab = props.match.params.tab;
  const { prevTab, prevDateRange, set } = useContext(PrevPageContext);
  const isFuture = tab === TabParameter.Scheduled;

  const [filter, setFilter] = useState<{
    physician: number | undefined;
    scheduleTransferState: string | undefined;
    dateRange: any;
  }>({
    physician: undefined,
    scheduleTransferState: undefined,
    dateRange: prevTab !== tab ? getDateRange(tab) : prevDateRange,
  });

  useEffect(() => {
    if (prevTab !== tab) {
      set(tab, getDateRange(tab));
      setFilter(prev => ({ ...prev, dateRange: getDateRange(tab) }));
    }
  }, [tab, prevTab, set]);

  useEffect(() => {
    if (!prevDateRange?.equals(filter.dateRange)) {
      set(tab, filter.dateRange);
    }
  }, [tab, prevDateRange, filter.dateRange, set]);

  const query = useSubscription(scheduledSurgeriesSubscription, {
    variables: {
      filter: {
        dateRange: filter.dateRange,
        physician: filter.physician,
        scheduleTransferState: filter.scheduleTransferState,
      },
    },
  });

  const EmptyState = (props: any) => (
    <EntityState title="No Scheduled Surgeries" hint="Currently there are no surgeries." {...props} />
  );

  return (
    <Surgeries {...props}>
      <Filters
        value={filter}
        onChange={setFilter}
        reload={false}
        hideSurgeryStatusSurgeonOfficeSelectInput={false}
        hidePhysicianSelectInput={false}
        hideDateFilter={false}
        hideProcedureTypeSelectInput={true}
        futurePicker={isFuture}
        dateTimePickerTitle="Filter by Date of Service"
        defaultValue={{
          dateRange: getDateRange(tab),
        }}
      />
      <CollectionListOnly
        match={props.match}
        location={props.location}
        history={props.history}
        data={query}
        getList={(query: { data: { scheduledSurgeries?: any[] } }) => query.data?.scheduledSurgeries}
        columns={scheduledSurgeryColumns}
        hideColumns={false}
        // View={View}
        // onViewClick={onViewClick}
        showUrlProvider={(id: any) => generatePath(props.match.path, { ...props.match.params, tab: id })}
        idProvider={(row: any) => row.id}
        useColumnSelection={true}
        defaultDisplayColumns={[
          '#',
          // 'Visit',
          'Name',
          'Surgery Date',
          'Physician',
          // 'Procedure Type',
          // 'DOB',
          'Age',
          'Sex',
          // 'Patient Email Address',
          // 'Patient Cell Phone Number',
          // 'Patient Home Phone Number',
          // 'Form %',
          // 'Last Invite',
          'Status',
        ]}
        tableKey={props.location.pathname}
        // Loading={Loading}
        // Error={Error}
        Empty={EmptyState}
        // FilterComponent={FilterComponent}
        // FooterComponent={FooterComponent}
        // filter={filter}
        // setFilter={setFilter}
        // highlightedProvider={highlightedProvider}
        // highlightedRowStyles={highlightedRowStyles}
        // highlightedRowStyleProvider={highlightedRowStyleProvider}
        // AdditionalBlock={AdditionalBlock}
        // containsSeparator={containsSeparator}
        // useCSVExport={useCSVExport}
        MobileItemComponent={SurgeonProcedureMobileListItem}
        // simpleFilter={simpleFilter}
        // defaultSort={defaultSort}
        // searchSource={searchSource}
      />
    </Surgeries>
  );
};

// @ts-ignore
const Show: any = compose(withRouter, withSession(unpackSessionObject), withScope, withExchangeOnly)(PatientProfile);

const Header: any = (props: any) => {
  const classes = useStyles();
  const id = get(props, 'data.id');
  const patientName = get(props, 'data.patient.name');
  const patient = get(props, 'data.patient');
  const physician = get(props, 'data.patient.physician');
  const status = get(props, 'data.status');
  const state = get(props, 'scheduleData.state') ? JSON.parse(get(props, 'scheduleData.state')) : null;
  const scheduleData = props.scheduleData;
  const transferId = get(props, 'scheduleData.id');
  const statusLabel = (
    <State {...props.data} state={state} scheduleTransferStatus={scheduleData ? scheduleData.status : null} />
  );
  const isNotCanceled = state?.__typename !== 'Cancelled';
  const needDocs =
    isNotCanceled &&
    (scheduleData?.issues || []).some((issue: any) => JSON.parse(issue.state).__typename === 'Requested');
  const documentsStatusLabel = needDocs ? 'Missing Docs' : '';

  return (
    <Box mb={3}>
      <TitleAction
        style={{ alignItems: 'flex-end' }}
        Title={() => (
          <Box display="flex" flexDirection="row" alignItems="center">
            {patientName && <Typography variant="h3">{patientName}</Typography>}
            <FormControl className={classes.formControl}>
              <PatientFileGenerator procedureId={id} physician={patient && physician} />
            </FormControl>
          </Box>
        )}
        Back={() => <ButtonBack onClick={props.history.goBack}>Surgeries</ButtonBack>}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box>
            {needDocs && (
              <Chip
                label={<span>{documentsStatusLabel}</span>}
                className={clsx({
                  [classes.warning]: needDocs,
                })}
              />
            )}
            <Chip label={<span>{statusLabel}</span>} />
          </Box>

          <Box height="2rem" mx={2}>
            <Divider orientation="vertical" />
          </Box>

          <ChangeStatusButton
            data={{
              id,
              transferId,
              status,
              state,
              variant: 'contained',
            }}
          />
        </Box>
      </TitleAction>
    </Box>
  );
};

const View: any = (props: any) => {
  const query = useSubscription(itemSubscription, {
    variables: {
      id: props.data.id,
    },
  });

  const apolloClient = useApolloClient();

  return query.data?.patient ? (
    <Fragment>
      <Show {...props} data={query.data.patient} Before={Header} />
      <Route
        exact
        path={props.match.path + '/edit'}
        render={props => (
          <EntityEdit
            {...props}
            Input={EditProcedureInput}
            data={query}
            getValue={(query: any) => ({
              ...query?.data?.patient,
              patient: {
                ...query?.data?.patient?.patient,
                physician: query?.data?.patient?.patient?.physician?.id,
              },
              locationId: query?.data?.patient?.organizationLocation?.id,
            })}
            handleUpdate={({ patient, ...rest }: any) => {
              apolloClient.mutate({
                mutation: procedures.update,
                variables: {
                  ...rest,
                  patient: pick(['name', 'age', 'sex', 'homePhone', 'cellPhone', 'emailAddress'])(patient),
                  physician: patient?.physician,
                  locationId: rest?.locationId,
                },
              });
            }}
            backUrlProvider={(id: any) => generatePath(props.match.path, { ...props.match.params, id }) + '/..'}
            replace={true}
            idProvider={() => props.match.params.id}
          />
        )}
      />
    </Fragment>
  ) : null;
};

const Routes: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [context, setContext] = useState<{ prevTab: TabParameter | null; prevDateRange: any }>({
    prevTab: null,
    prevDateRange: null,
  });

  return (
    <PrevPageContext.Provider
      value={{
        ...context,
        set: (prevTab, prevDateRange) => setContext({ prevTab, prevDateRange }),
      }}
    >
      <Switch>
        <Route
          path={`${match.path}/:id(\\d+)`}
          render={props => (
            <View
              {...props}
              data={{ id: parseInt(props.match.params.id, 10) }}
              columns={viewColumns}
              // {...rest}
              // Edit={Edit}
              // basePathProvider={() => `${basePath}/${baseAffix}:id`}
              // baseUrlProvider={id => `${baseUrl}/${baseAffix}${id}`}
              // viewBackUrlProvider={() => baseUrl}
              idProvider={({ match }: any) => parseInt(props.match.params.id, 10)}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/requires-attention/:tab`}
          render={props => {
            switch (props.match.params.tab) {
              case 'need-docs':
              case 'docs-updated':
              default:
                return <SurgeriesWithMissingDocuments {...props} />;
            }
          }}
        />
        <Route
          exact
          path={`${match.path}/:tab`}
          render={props => {
            switch (props.match.params.tab) {
              case 'planned':
                return <Planned {...props} />;
              case 'scheduled':
              case 'past':
                return <Scheduled {...props} />;
              default:
                return <Redirect to={`${match.url}/planned`} />;
            }
          }}
        />
        <Route render={() => <Redirect to={`${match.url}/planned`} />} />
      </Switch>
    </PrevPageContext.Provider>
  );
};

export default withRouter(Routes);
